import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import FormControl from '@mui/material/FormControl';
import api from '../../util/api';
import util from '../../util';
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { DatePicker, Progress, Input, Select, Modal, Tooltip } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import moment from 'moment'
import * as XLSX from "xlsx";
import 'moment/locale/th';
import {
  useSelector,
  useDispatch
} from 'react-redux'
import { increment } from '../../reducers/simpleReducer'

const { Option } = Select;
const { Search, TextArea } = Input;
const { RangePicker } = DatePicker;

function getCongenitalDisease(x) {
  let result = []
  if (x.COPD) result.push('โรคปอดอุดกั้นเรื้อรัง (Chronic Obstructive Pulmonary Disease หรือ COPD)')
  if (x.CKD) result.push('โรคไตวายเรื้อรัง CKD')
  if (x.Chronic_Heart_Disease) result.push('โรคหัวใจเรื้อรัง Chronic Heart Disease')
  if (x.CVA) result.push('โรคหลอดเลือดสมอง CVA')
  if (x.T2DM) result.push('โรคเบาหวาน ดื้ออินซูลิ T2DM')
  if (x.Cirrhosis) result.push('โรคตับแข็ง Cirrhosis')
  if (x.Immunocompromise) result.push('ภาวะภูมิคุ้มกันต่ำ Immunocompromise')
  if (x.Absolute_lymphocyte_min) result.push('ค่าเม็ดเลือดขาวน้อยกว่า 1000 cell/mm3')
  return result.toString().replace(/,/g, ', ')
}

function getRisk(x) {
  let result = []
  if (x.age_60) result.push('อายุ มากกว่า 60 ปี')
  if (x.weight >= 90 || ((x.weight / ((x.height * x.height) / 10000) > 30))) result.push('BMI มากกว่า 30 หรือ น้ำหนักมากกว่า 90 กิโลกรัม')
  return result.toString().replace(/,/g, ', ')
}

function getNewSymptom(x) {
  let result = []
  if (Array.isArray(x.form_daily_assessment) && x.form_daily_assessment.length > 0) {
    let tmp = [...x.form_daily_assessment.sort((a, b) => (a.date_make < b.date_make) ? -1 : (a.date_make > b.date_make) ? 1 : 0)]
    if (tmp[0].no_symptoms) result.push('ไม่มีอาการใดๆ/No Symptoms')
    if (tmp[0].running_nose) result.push('มีน้ำมูก/Running nose')
    if (tmp[0].sore_throat) result.push('เจ็บคอ/Sore throat')
    if (tmp[0].severe_cough) result.push('ไอเยอะ/Severe Cough')
    if (tmp[0].chesty_cough) result.push('มีเสมหะ/Chesty cough')
    if (tmp[0].fatigue) result.push('อ่อนเพลียมาก (Fatigue)')
    if (tmp[0].tired_when_talking) result.push('ขณะพูดหรือออกแรง/Tired when talking or having activity')
    if (tmp[0].tired_when_resting) result.push('หอบเหนื่อยขณะพัก/Tired when resting')
    if (tmp[0].chest_pain_when_breathing) result.push('เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing')
    if (tmp[0].diarrhea_3day) result.push('ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)')
  }
  return result.toString().replace(/,/g, ', ')
}

async function donwloadxlsx({ data_mock = [], search = "", age, ps, date = null, date2 = null, hostF }) {
  let _data = data_mock
    .filter(x => x.name.search(search) >= 0 || search === '' || x.cid.search(search) >= 0)
    .filter(x => (x.host === hostF || hostF === 'all') && (x.status === age || age === 'all') && ((x.date_atk_filter >= date && x.date_atk_filter <= date2) || date === null) && ((x.discharge ? 'discharge' : 'active') === ps || ps === 'all'))
  let data = [['ชื่อ นามสกุล', 'อายุ', 'เลขบัตร', 'น้ำหนัก', 'ส่วนสูง', 'ที่อยู่', 'โทรศัพท์', 'โรคประจำตัว', 'ภาวะเสี่ยง', 'อาการ', 'สี', 'สถานะผู้ป่วย', 'วันที่อัพเดทข้อมูล']]
  console.log("_data", _data)
  _data.forEach(x => {
    data.push([
      x.name,
      x.age,
      x.cid,
      x.weight,
      x.height,
      util.textAddr(x),
      x.tel,
      getCongenitalDisease(x),
      getRisk(x),
      getNewSymptom(x),
      MAP_STATUS[x.status].text,
      x.discharge ? 'discharge' : 'active',
      moment(x.updatetime, 'x').format('L'),
    ])
  })
  let ws = XLSX.utils.aoa_to_sheet(data);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "sheet1");
  XLSX.writeFile(wb, "รายชื่อคนไข้ ณ" + moment().format('L') + ".xlsx");
  let user = await localStorage.getItem('user_wecaremaesot')
  let sDate = moment()
  let _dataexport = {
    _id: sDate.format('x'),
    dateLog: sDate.format('L'),
    timeLog: sDate.format('LT'),
    user: user,
    search,
    age,
    ps,
    date,
    date2,
    hostF,
    size: _data.map(x => ({ cid: x.cid, name: x.name, status: x.status }))
  }
  await api.insert(`db/homecare/exportlog/data/${_dataexport._id}`, _dataexport)
  return
}

let MAP_CALL = {
  'ยังไม่ได้โทร': '#F08080',
  'โทรแล้ว': '#CEFFCF',
  'โทรใหม่อีกครั้ง': '#ffffb1',
}

let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const ReaderRow = ({ column, row, handleClick }) => {
  const value = row[column.id];
  if (column.id === 'status') {
    console.log("value", value)
    console.log("row", row)
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_STATUS[value].color, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {MAP_STATUS[value].text}</div>
        }
      </TableCell>
    );

  } else if (column.id === 'sdfsdfdate_atk') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {util.getTimeLong(value)}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'is_call') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, backgroundColor: value ? MAP_CALL[value] : '#666', color: '#333', justifyContent: 'center', display: 'flex' }}> {value}</div>
        }
      </TableCell >
    );
  } else if (column.id === 'count_day') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {row.discharge ? '-' : util.diffday(row.date_atk, moment().format('x')) + 1}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(value, 'x').format('L')}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'discharge') {
    if (row.discharge) {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#eee', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> discharge</div>
          }
        </TableCell>
      );
    } else {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#CEFFCF', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> active</div>
          }
        </TableCell>
      );
    }
  } else if (column.id === 'doctornote') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick('modal', row)}>
              Note
            </Button>) :
            value
        }
      </TableCell>
    );
  }
  return (
    <TableCell key={column.id} align={column.align}>
      {
        column.type === 'button' ?
          (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick('link', `patient/${md5(row.cid)}/${row.round}`)}>
            ดูข้อมูล
          </Button>) :
          value
      }
    </TableCell>
  );

}
const columns = [
  // { id: 'index', label: 'ลำดับ', minWidth: 20 },
  { id: 'date_atk', label: 'วันที่แรก', minWidth: 90, align: 'center', tooltip: 'วันที่ส่งแบบอาการประจำวันของผู้ป่วย Home Isolate ครั้งที่ 1' },
  { id: 'updatetime', label: 'วันที่อัพเดทข้อมูล', minWidth: 190, align: 'center' },
  { id: 'conutdate', label: 'จำนวนวัน', minWidth: 100, align: 'center', tooltip: 'จำนวนวันที่นับจากวันที่ส่งแบบอาการประจำวันของผู้ป่วย Home Isolate ครั้งที่ 1 ถึง วันปัจจุบัน' },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'status', label: 'ระดับผู้ป่วย', minWidth: 100, alignSelf: 'center' },
  { id: 'discharge', label: 'สถานะผู้ป่วย', minWidth: 100, alignSelf: 'center' },
  { id: 'is_call', label: 'โทรสอบถาม', minWidth: 140, alignSelf: 'center' },
  { id: 'doctornote', label: 'Doctor Note', minWidth: 140, type: 'button', align: 'center' },
  { id: 'name', label: 'ดูข้อมูล', minWidth: 140, type: 'button', align: 'center' },

];

function ColumnGroupingTable({ data_mock = [], search = "", age, ps, date = null, date2 = null, onFilter, hostF, updateRow }) {
  const [page, setPage] = React.useState(0);
  const [modal, setModal] = React.useState({
    show: false
  });

  const [doctornote, setDoctornote] = React.useState('');

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [sort, setSort] = React.useState({
    key: 'date_atk',
    state: "up"
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });
  function handleClick(type, data) {
    if (type === 'link') {
      history.push(data)
    } else {
      setDoctornote(data.doctornote)
      setModal({
        data: data,
        show: true
      })
    }
  }
  // doctornote

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.name.search(search) >= 0 || search === '' || x.cid.search(search) >= 0)
      .filter(x => (x.host === hostF || hostF === 'all') && (x.status === age || age === 'all') && ((x.date_atk_filter >= date && x.date_atk_filter <= date2) || date === null) && ((x.discharge ? 'discharge' : 'active') === ps || ps === 'all'))
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)
    setPage(0)
    onFilter(_data)
  }, [data_mock, search, age, date, ps, hostF]);

  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />

      <Modal
        title="Doctor Note"
        centered
        visible={modal.show}
        onOk={async () => {
          let _data = await api.query('db/homecare/form_first_assessment/query', {
            "aggregate": [
              {
                $match: {
                  cid: modal.data.cid,
                  round: modal.data.round,
                }
              }
            ]
          })
          if (_data.length > 0) {
            _data[0].doctornote = doctornote
            await api.insert(`db/homecare/form_first_assessment/data/${_data[0]._id}`, _data[0])
          }

          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
          setDoctornote('')
          modal.data.doctornote = doctornote
          updateRow({ ...modal.data })
          setModal({
            show: false
          })
        }}
        okText={'บันทึก'}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setDoctornote('')
          setModal({
            show: false
          })

        }}
        width={800}
      >
        <p>ชื่อ-นามสกุล: {modal?.data?.name}</p>
        <TextArea
          rows={4}
          placeholder="Note"
          maxLength={512}
          onChange={(event) => {
            setDoctornote(event.target.value)
          }}
          value={doctornote}
        />
      </Modal>
      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <Tooltip title={column.tooltip}>
                  <TableCell
                    onClick={() => {
                      let _tmpdata = [...data]
                      console.log("_tmpdata", _tmpdata)
                      if (column.id === 'date_atk') {
                        // _tmpdata = [..._tmpdata.sort((a, b) => (a.date_atk < b.date_atk) ? -1 : (a.date_atk > b.date_atk) ? 1 : 0)]
                        _tmpdata = [..._tmpdata.sort((a, b) => b.sort_date_atk - a.sort_date_atk)]
                      } else if (column.id === 'updatetime') {
                        _tmpdata = [..._tmpdata.sort((a, b) => b.updatetime - a.updatetime)]
                      } else if (column.id === 'name') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)]
                      } else if (column.id === 'host') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.host < b.host) ? -1 : (a.host > b.host) ? 1 : 0)]
                      } else if (column.id === 'status') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.status < b.status) ? -1 : (a.status > b.status) ? 1 : 0)]
                      } else if (column.id === 'discharge') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.dischargestatus < b.dischargestatus) ? -1 : (a.dischargestatus > b.dischargestatus) ? 1 : 0)]
                      } else if (column.id === 'conutdate') {
                        // _tmpdata = [..._tmpdata.sort((a, b) => (a.conutdate < b.conutdate) ? -1 : (a.conutdate > b.conutdate) ? 1 : 0)]
                        _tmpdata = [..._tmpdata.sort((a, b) => {
                          let _a = a.conutdate
                          let _b = b.conutdate
                          if (_a === '-') _a = 0
                          if (_b === '-') _b = 0
                          if (Number.isNaN(Number(_a))) _a = -1
                          if (Number.isNaN(Number(_b))) _b = -1
                          return _b - _a

                        })]
                      } else if (column.id === 'is_call') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.is_call < b.is_call) ? -1 : (a.is_call > b.is_call) ? 1 : 0)]
                      }
                      if (sort.state === "up") _tmpdata.reverse()
                      setData([..._tmpdata])
                      setSort({
                        key: column.id,
                        state: sort.state === 'down' ? 'up' : 'down'
                      })
                      setPage(0);
                    }}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                    {
                      sort.key === column.id ?
                        (sort.state === 'down' ? <ArrowDownOutlined style={{ color: 'green' }} /> : <ArrowUpOutlined style={{ color: 'green' }} />)
                        : null
                    }
                  </TableCell>

                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {

                      return <ReaderRow column={column} row={row} handleClick={handleClick} />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

const Card = ({ title = '', value = 0, total = 1, text = 'green', isNotShow = false }) => {
  return (<div style={{
    background: "#fff",
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  }}>
    {!isNotShow && <Progress type="circle" percent={(value / total * 100).toFixed(2)} strokeColor={MAP_STATUS[text].color_b} width={80} strokeWidth={10} trailColor="#ccc" />}
    <div
      style={{
        margin: 4,
        background: "#fff",
        display: 'flex',
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: 14, marginTop: 12 }}>
        {title}
      </div>
      <div style={{ fontSize: 22 }}>
        {value} ราย
      </div>
    </div>
  </div>
  )
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [dataShow, setShow] = useState([]);
  const [search, setSearch] = useState('');
  const numCompletedTodos = useSelector((state) => state)
  const dispatch = useDispatch()
  console.log("numCompletedTodos", numCompletedTodos)
  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    let user = localStorage.getItem('user_wecaremaesot')
    role = JSON.parse(role)
    user = JSON.parse(user)

    let aggregate = [
      {
        $lookup:
        {
          from: "patient",
          localField: "cid",
          foreignField: "_id",
          as: "patient"
        }
      },
      {
        $unwind: {
          path: "$patient",
          preserveNullAndEmptyArrays: false
        }
      },
      {
        $lookup: {
          from: "form_daily_assessment",
          let: {
            cid: "$cid",
            round: "$round"
          },
          pipeline: [
            {
              $match: {
                $expr: {
                  $and: [
                    { $eq: ["$cid", "$$cid"] },
                    { $eq: ["$ref_round", "$$round"] }
                  ]
                }
              }
            }
          ],
          as: "form_daily_assessment"
        },
      },
      {
        $project: {
          cid: 1,
          hash_cid: 1,
          date_atk: 1,
          is_call: 1,
          title: "$patient.title",
          firstname: "$patient.firstname",
          lastname: "$patient.lastname",
          name: { $concat: ["$patient.title", "$patient.firstname", " ", "$patient.lastname"] },
          updatetime: "$patient.updatetime",
          // status: "$patient.status",
          status: "$status",
          discharge: "$discharge",
          host: "$patient.host",
          hostid: "$patient.hostid",
          tel: "$patient.tel",
          dob: "$patient.dob",
          age: "$patient.age",

          housenum: "$patient.housenum",
          villageno: "$patient.villageno",
          alley: "$patient.alley",
          lane: "$patient.lane",
          road: "$patient.road",
          tumbonname: "$patient.tumbonname",
          cityname: "$patient.cityname",
          provincename: "$patient.provincename",
          round: "$round",
          form_daily_assessment: "$form_daily_assessment",

          weight: "$patient.weight",
          height: "$patient.height",

          age_60: "$age_60",
          BMI: "$BMI",
          COPD: "$COPD",
          CKD: "$CKD",
          Chronic_Heart_Disease: "$Chronic_Heart_Disease",
          CVA: "$CVA",
          T2DM: "$T2DM",
          Cirrhosis: "$Cirrhosis",
          Immunocompromise: "$Immunocompromise",
          Absolute_lymphocyte_min: "$Absolute_lymphocyte_min",
          doctornote: "$doctornote",
        }
      }

    ]

    if (user.hospital !== 'โรงพยาบาลแม่สอด') {
      aggregate.push({
        $match: {
          hostid: user.hospital_code
        }
      })
    }

    let _data = await api.query(
      'db/homecare/form_first_assessment/query',
      {
        "aggregate": aggregate
      }
    )

    let result = []
    _data.map(x => {
      x['dischargestatus'] = x.discharge ? 'discharge' : 'active'
      if (Array.isArray(x?.form_daily_assessment) && x?.form_daily_assessment.length > 0) {
        x.form_daily_assessment = x.form_daily_assessment.sort((a, b) => a.date_make - b.date_make)
        let firstday = x.form_daily_assessment[0].date_make
        let lastdate = x.form_daily_assessment[x.form_daily_assessment.length - 1].updatetime
        x['conutdate'] = x.discharge ? '-' : `${util.diffday(firstday, moment().format('x')) + 1}`
        x['updatetime'] = lastdate
        x['date_atk'] = util.getTimeLong(firstday)
        x['date_atk_filter'] = Number(firstday)
        x['sort_date_atk'] = Number(firstday)
      } else {
        x['date_atk'] = 'ยังไม่มีฟอร์ม'
        x['conutdate'] = 'ยังไม่มีฟอร์ม'
        x['sort_date_atk'] = 0
      }
      result.push(x)
    })
    setData([...result.sort((a, b) => b.sort_date_atk - a.sort_date_atk)])
  }, []);

  const [user, setUser] = useState("");
  const [age, setAge] = React.useState('all');
  const [ps, setPS] = React.useState('all');
  const [hostF, sethostF] = React.useState('all');
  const [date, setDate] = React.useState(null);
  const [date2, setDate2] = React.useState(null);
  const handleChange = (value) => {
    setAge(value);
  };

  const handleChangePS = (value) => {
    setPS(value);
  };

  return (<Layout ispadding>
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1550px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <p className="text-3xl mb-4">รายชื่อผู้ป่วย Isolation</p>
      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          marginBottom: 24
        }}
      >

        <Card title={'ผู้ป่วยทั้งหมด'} total={dataShow.length} value={dataShow.length} isNotShow={true} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยสีเขียว'} total={dataShow.length} value={dataShow.filter(x => x.status === 'green' && x.discharge !== true).length} text={"green"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยสีเหลืองอ่อน'} total={dataShow.length} value={dataShow.filter(x => x.status === 'lightyellow' && x.discharge !== true).length} text={"yellow"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยสีเหลืองเข้ม'} total={dataShow.length} value={dataShow.filter(x => x.status === 'yellow' && x.discharge !== true).length} text={"yellow"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยสีแดง'} total={dataShow.length} value={dataShow.filter(x => x.status === 'red' && x.discharge !== true).length} text={"red"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยรักษาหายแล้ว'} total={dataShow.length} value={dataShow.filter(x => x.discharge).length} text={"dischart"} />
      </div>
      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div style={{ flexDirection: 'row' }}>
          <FormControl sx={{ m: 1 }}>
            <div>ระดับผู้ป่วย</div>
            <Select defaultValue="lucy" style={{ width: 160 }} onChange={handleChange} size={"large"} value={age}>
              <Option value="all">แสดงทั้งหมด</Option>
              <Option value="green">ผู้ป่วยสีเชียว</Option>
              <Option value="lightyellow" >ผู้ป่วยสีเหลืองอ่อน</Option>
              <Option value="yellow" >ผู้ป่วยสีเหลืองเข้ม</Option>
              <Option value="red" >ผู้ป่วยสีแดง</Option>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <div>สถานะผู้ป่วย</div>
            <Select defaultValue="lucy" style={{ width: 140 }} onChange={handleChangePS} size={"large"} value={ps}>
              <Option value="all">ทั้งหมด</Option>
              <Option value="active">active</Option>
              <Option value="discharge" >discharge</Option>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 140 }}>
            <div>เลือกช่วงระหว่างวันแรก</div>
            <RangePicker
              style={{ width: 250, height: 40 }}
              value={[date ? moment(date, 'x') : null, date2 ? moment(date2, 'x') : null]}
              format={'DD/MM/YYYY'}
              onChange={(_date, dateString) => {
                if (Array.isArray(_date) && _date.length >= 2) {
                  _date[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  _date[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  _date[0].toISOString()
                  _date[1].toISOString()
                  setDate(Number(_date[0].format('x')))
                  setDate2(Number(_date[1].format('x')))
                } else {
                  setDate(null)
                  setDate2(null)
                }

              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <div>สถานพยาบาล</div>
            <Select defaultValue="lucy" style={{ width: 260 }} onChange={(value) => { sethostF(value) }} size={"large"} value={hostF}>
              <Option value="all">ทั้งหมด</Option>
              {[
                'รพ.สต.บ้านปางส่างคำ',
                'รพ.สต.บ้านห้วยกระโหลก',
                'รพ.สต.แม่ตาว',
                'รพ.สต.บ้านโกกโก่',
                'รพ.สต.ท่าสายลวด',
                'รพ.สต.บ้านวังตะเคียน',
                'รพ.สต.บ้านห้วยหินฝน',
                'รพ.สต.พระธาตุผาแดง',
                'รพ.สต.บ้านแม่กุเหนือ',
                'รพ.สต.บ้านแม่กื้ดสามท่า',
                'รพ.สต.บ้านแม่กุใหม่',
                'รพ.สต.พะวอ',
                'รพ.สต.มหาวัน',
                'รพ.สต.บ้านปางส้าน',
                'รพ.สต.บ้านปูเตอร์',
                'รพ.สต.บ้านเจดีย์โค๊ะ',
                'รพ.สต.บ้านห้วยยะอุ',
                'คลินิกชุมชนอบอุ่นเทศบาลนครแม่สอด',
                'รพ.สต.แม่กุ',
                'รพ.สต.บ้านแม่กาษา',
                'รพ.สต.บ้านห้วยไม้แป้น',
                'โรงพยาบาลแม่สอด',
                'รพ.สต.บ้านแม่กื๊ดหลวง',
                'รพ.สต.แม่ปะ',
              ].map(x => <Option value={x}>{x}</Option>)}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 200, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหาชื่อนามสกุล"
              style={{ width: 230, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>


          <FormControl sx={{ m: 1, minWidth: 100, height: 54 }}>
            < Button
              variant="contained"
              style={{ marginTop: 20 }}
              onClick={() => {
                setSearch('')
                setAge('all')
                setPS('all')
                sethostF('all')
                setDate(null)
                setDate2(null)
              }}>
              รีเซ็ต
            </Button>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 100, height: 54 }}>
            < Button
              variant="contained"
              style={{ marginTop: 20 }}
              onClick={async () => {
                // dispatch(increment())
                await donwloadxlsx({
                  data_mock: data,
                  search: search,
                  age: age,
                  ps: ps,
                  date: date,
                  date2: date2,
                  hostF: hostF
                })
              }}>
              โหลดรายชื่อ
            </Button>
          </FormControl>
        </div>
        <ColumnGroupingTable
          data_mock={data}
          search={search}
          age={age}
          ps={ps}
          date={date}
          date2={date2}
          hostF={hostF}
          onFilter={(x) => {
            setShow(x)
          }}
          updateRow={(dataupdate) => {
            let index = data.findIndex(x => x._id === dataupdate._id)
            data[index] = dataupdate
            setData([...data])
          }}
        />
      </div>
    </div>
  </Layout >)
}
