import * as React from 'react';
import HeaderPC from './HeaderPC'
import HeaderPCNew from './HeaderPCNew'
import HeaderE from './HeaderE'


export default function Layout({ children, ispadding, isHm, is4 }) {
  let _h = null
  if (isHm) {
    _h = <HeaderE />
  } else if (is4) {
    _h = <HeaderPCNew />
  } else {
    _h = <HeaderPC />
  }

  return (
    <div style={{ backgroundColor: "#F3F4F9" }}>
      {_h}
      {ispadding && <div style={{ height: '80px' }} />}
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', justifyItems: 'center' }}>
        {children}
      </div >
    </div >
  )
}
