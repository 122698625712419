import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import FormControl from '@mui/material/FormControl';
import api from '../../util/api';
import util from '../../util';
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { DatePicker, Progress, Input, Select, Modal, Tooltip, Table as TableAnd, Spin, Upload } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment'
import * as XLSX from "xlsx";
import 'moment/locale/th';
import {
  useSelector,
  useDispatch
} from 'react-redux'
import { increment } from '../../reducers/simpleReducer'

const { Option } = Select;
const { Search, TextArea } = Input;
const { RangePicker } = DatePicker;

function getCongenitalDisease(x) {
  let result = []
  if (x.COPD) result.push('โรคปอดอุดกั้นเรื้อรัง (Chronic Obstructive Pulmonary Disease หรือ COPD)')
  if (x.CKD) result.push('โรคไตวายเรื้อรัง CKD')
  if (x.Chronic_Heart_Disease) result.push('โรคหัวใจเรื้อรัง Chronic Heart Disease')
  if (x.CVA) result.push('โรคหลอดเลือดสมอง CVA')
  if (x.T2DM) result.push('โรคเบาหวาน ดื้ออินซูลิ T2DM')
  if (x.Cirrhosis) result.push('โรคตับแข็ง Cirrhosis')
  if (x.Immunocompromise) result.push('ภาวะภูมิคุ้มกันต่ำ Immunocompromise')
  if (x.Absolute_lymphocyte_min) result.push('ค่าเม็ดเลือดขาวน้อยกว่า 1000 cell/mm3')
  return result.toString().replace(/,/g, ', ')
}

const columnsFile = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    key: "index",
    width: 20,
  },
  {
    title: "ชื่อไฟล์",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "โหลด",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          icon={<DownloadOutlined />}
          onClick={data.onDonwlaod}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "green",
            color: "white",
          }}
        >
          download
        </Button>
      </div >
    ),
    width: 140,
  },
];


function getRisk(x) {
  let result = []
  if (x.age_60) result.push('อายุ มากกว่า 60 ปี')
  if (x.weight >= 90 || ((x.weight / ((x.height * x.height) / 10000) > 30))) result.push('BMI มากกว่า 30 หรือ น้ำหนักมากกว่า 90 กิโลกรัม')
  return result.toString().replace(/,/g, ', ')
}

function getNewSymptom(x) {
  let result = []
  if (Array.isArray(x.form_daily_assessment) && x.form_daily_assessment.length > 0) {
    let tmp = [...x.form_daily_assessment.sort((a, b) => (a.date_make < b.date_make) ? -1 : (a.date_make > b.date_make) ? 1 : 0)]
    if (tmp[0].no_symptoms) result.push('ไม่มีอาการใดๆ/No Symptoms')
    if (tmp[0].running_nose) result.push('มีน้ำมูก/Running nose')
    if (tmp[0].sore_throat) result.push('เจ็บคอ/Sore throat')
    if (tmp[0].severe_cough) result.push('ไอเยอะ/Severe Cough')
    if (tmp[0].chesty_cough) result.push('มีเสมหะ/Chesty cough')
    if (tmp[0].fatigue) result.push('อ่อนเพลียมาก (Fatigue)')
    if (tmp[0].tired_when_talking) result.push('ขณะพูดหรือออกแรง/Tired when talking or having activity')
    if (tmp[0].tired_when_resting) result.push('หอบเหนื่อยขณะพัก/Tired when resting')
    if (tmp[0].chest_pain_when_breathing) result.push('เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing')
    if (tmp[0].diarrhea_3day) result.push('ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)')
  }
  return result.toString().replace(/,/g, ', ')
}

async function donwloadxlsx({ data_mock = [], search = "", age, ps, date = null, date2 = null, hostF }) { // status_queue
  console.log("data_mock", data_mock)
  let _data = data_mock
    .filter(x => x.name.search(search) >= 0 || search === '' || x.cid.search(search) >= 0)
    .filter(x => (x.host === hostF || hostF === 'all') && (x.status === age || age === 'all') && ((x.date_atk_filter >= date && x.date_atk_filter <= date2) || date === null) && (x.status_queue || ps === 'all'))
  let data = [['ชื่อ นามสกุล', 'อายุ', 'เลขบัตร', 'น้ำหนัก', 'ส่วนสูง', 'ที่อยู่', 'โทรศัพท์', 'โรคประจำตัว', 'ภาวะเสี่ยง', 'อาการ', 'สี', 'สถานะผู้ป่วย', 'วันที่อัพเดทข้อมูล']]
  console.log("_data", _data)
  _data.forEach(x => {
    data.push([
      x.name,
      x.age,
      x.cid,
      x.weight,
      x.height,
      util.textAddr(x),
      x.tel,
      getCongenitalDisease(x),
      getRisk(x),
      getNewSymptom(x),
      MAP_STATUS[x.status].text,
      x.discharge ? 'discharge' : 'active',
      moment(x.updatetime, 'x').format('L'),
    ])
  })
  let ws = XLSX.utils.aoa_to_sheet(data);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "sheet1");
  XLSX.writeFile(wb, "รายชื่อคนไข้ ณ" + moment().format('L') + ".xlsx");
  let user = await localStorage.getItem('user_wecaremaesot')
  let sDate = moment()
  let _dataexport = {
    _id: sDate.format('x'),
    dateLog: sDate.format('L'),
    timeLog: sDate.format('LT'),
    user: user,
    search,
    age,
    ps,
    date,
    date2,
    hostF,
    size: _data.map(x => ({ cid: x.cid, name: x.name, status: x.status }))
  }
  await api.insert(`db/homecare/exportlog/data/${_dataexport._id}`, _dataexport)
  return
}
let MAP_FOLLOW = {
  'come': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'มา'
  },
  'not_come': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'ไม่มา'
  },
  'not_q': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    text: 'ไม่นัดต่อ'
  },
}


let MAP_CALL = {
  'ยังไม่ได้โทร': '#F08080',
  'โทรแล้ว': '#CEFFCF',
  'โทรใหม่อีกครั้ง': '#ffffb1',
}

let MAP_STATUS_WATH = {
  'wait_confirm_doctor': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'รอนัดจากแพทย์'
  },
  'wait_confirm_patient': {
    color: '#F1A661',
    color_b: '#F1A661',
    text: 'ผู้คัดกรองที่รอลงวันนัด'
  },
  'confirm_patient': {
    color: '#ceffcf',
    color_b: '#82ff84',
    // text: 'ผู้คัดกรองลงวันนัดแล้ว'
    text: 'ลงนัดแล้ว'
  },
  'stop': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'ไม่นัด'
  },
  'refer': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'ส่งต่อ'
  },
  'other': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'อื่นๆ'
  },
  'confirm_patient_new': {
    color: '#F08080',
    color_b: '#F08080',
    // text: 'ผู้คัดกรองลงวันนัดแล้ว'
    text: 'ลงนัดใหม่'
  },
}
let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },
  'blue': {
    color: '#80b8f0 ',
    color_b: '#80b8f0 ',
    text: 'น้ำเงิน'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const ReaderRow = ({ column, row, handleClick }) => {

  const value = row[column.id];
  if (column.id === 'status_queue') {
    if (!MAP_STATUS_WATH[value]) {
      return null
    }

    if (row?.status_come === 'not_q') {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#f0f0f0', width: 150, borderRadius: 24, justifyContent: 'center', display: 'flex', textAlign: 'center' }}> ไม่นัดต่อ</div>
          }
        </TableCell>
      );
    }

    console.log('s', value);
    console.log('s', MAP_STATUS_WATH[value]);
    let dateq = row['date_queue_patient']
    let date = (value === 'confirm_patient' || value === 'confirm_patient_new') ? `วันที่ ${dateq ? moment(dateq, 'x').format('L') : '-'}` : ''
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_STATUS_WATH[value].color, width: 150, borderRadius: 24, justifyContent: 'center', display: 'flex', textAlign: 'center' }}> {MAP_STATUS_WATH[value].text} <br />{date}</div>
        }
      </TableCell>
    );

  } else if (column.id === 'sdfsdfdate_atk') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {util.getTimeLong(value)}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'is_call') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, backgroundColor: value ? MAP_CALL[value] : '#666', color: '#333', justifyContent: 'center', display: 'flex' }}> {value}</div>
        }
      </TableCell >
    );
  } else if (column.id === 'count_day') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {row.discharge ? '-' : util.diffday(row.date_atk, moment().format('x')) + 1}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {value ? moment(value, 'x').format('L') : '-'}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'status_come') {
    if (!value) return (
      <TableCell key={column.id} align={column.align}>
        <div style={{ padding: 8, backgroundColor: '#f0f0f0', width: 80, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> -</div>
      </TableCell>
    )
    let count_not_com = row?.count_not_com ? row.count_not_com : '-'
    let date = value === 'not_come' ? `ครั้งที่ ${count_not_com}` : ''
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_FOLLOW[value].color, width: 120, borderRadius: 24, justifyContent: 'center', display: 'flex', textAlign: 'center' }}> {MAP_FOLLOW[value].text}{date}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'discharge') {
    if (row.discharge) {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#eee', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> discharge</div>
          }
        </TableCell>
      );
    } else {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#CEFFCF', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> active</div>
          }
        </TableCell>
      );
    }
  } else if (column.id === 'HN') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, display: 'flex' }}> {row['cid']} {value ? '(' + value + ')' : ''}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'button_result') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ padding: 4 }} onClick={() => handleClick('button_result', row)}>
              ดูผลการรักษา
            </Button>) :
            value
        }
      </TableCell>
    );
  } else if (column.id === 'button_q') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          (< Button variant="contained" style={{ padding: 4 }} onClick={() => handleClick('button_q', row)}>
            ดูข้อมูลนัด
          </Button>)
        }
      </TableCell>
    );
  } else if (column.id === 'doctornote') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick('modal', row)}>
              Note
            </Button>) :
            value
        }
      </TableCell>
    );
  }
  return (
    <TableCell key={column.id} align={column.align}>
      {
        column.type === 'button' ?
          (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick('link', `patient-queue/${md5(row.cid)}/${row.round}`)}>
            ดูข้อมูล
          </Button>) :
          value
      }
    </TableCell>
  );

}
const columns = [
  { id: 'HN', label: 'เลขบัตร', minWidth: 50, },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'status_queue', label: 'สถานะการนัด', minWidth: 100, alignSelf: 'center' },
  { id: 'button_q', label: 'ดูข้อมูลนัด', minWidth: 100, alignSelf: 'center' },
  { id: 'status_come', label: 'สถานะตอบกลับ', minWidth: 100, alignSelf: 'center' },
  { id: 'button_result', label: 'ดูผลแผนการรักษา', minWidth: 100, type: 'button', align: 'center' },
  { id: 'name', label: 'ดูข้อมูล', minWidth: 100, type: 'button', align: 'center' },

];

function ColumnGroupingTable({ data_mock = [], search = "", age, ps, date = null, date2 = null, onFilter, hostF, updateRow }) {


  const [fileList, setFileList] = useState([]);
  const [fileListUpload, setFileListUpload] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [page, setPage] = React.useState(0);
  const [modal, setModal] = React.useState({
    show: false
  });

  const [modal3, setModal3] = React.useState({
    show: false
  });

  const [modal4, setModal4] = React.useState({
    show: false
  });

  const [modalForm, setModalForm] = React.useState({
    show: false
  });

  const [doctornote, setDoctornote] = React.useState('');

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [sort, setSort] = React.useState({
    key: 'date_atk',
    state: "up"
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });
  function handleClick(type, data) {
    if (type === 'link') {
      // alert(data)
      history.push(data)
    } else if (type === 'doctornote') {
      setDoctornote(data.doctornote)
      if (['confirm_patient', 'stop', 'refer'].includes(data.status_queue)) {
        // setStepQ(data.status_queue)
      }
      if (data.date_queue_patient) {
        // let M = moment(data.date_queue_patient, 'x')
        // console.log(M.format('YYYY'))
        // console.log(M.format('MM'))
        // console.log(M.format('DD'))
        // setDateSc({
        //   day: M.format('DD') + '',
        //   month: M.format('MM') + '',
        //   year: M.format('YYYY') + '',
        // })
      }
      setModal({
        data: data,
        show: true
      })
    } else if (type === 'button_com') {
      setDoctornote(data.doctornote)
      // setModal2({
      //   data: data,
      //   show: true
      // })
    } else if (type === 'button_result') {
      setFileList(data.result_listfile)
      setModal3({
        data: data,
        show: true
      })
    } else if (type === 'button_q') {
      setFileList(data.q_listfile)
      setModal4({
        data: data,
        show: true
      })
    }
  }
  // doctornote

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.name.search(search) >= 0 || search === '' || x.cid.search(search) >= 0)
      .filter(x => (x.host === hostF || hostF === 'all') && (x.status === age || age === 'all') && ((x.date_atk_filter >= date && x.date_atk_filter <= date2) || date === null) && (x.status_queue == ps || ps === 'all'))
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)
    setPage(0)
    onFilter(_data)
  }, [data_mock, search, age, date, ps, hostF]);

  let onGenDataTable = (_data = []) => {
    let result = _data.map((x, i) => ({
      ...x, index: i + 1,
      onRemove: () => {
        const newFileList = fileList.slice();
        newFileList.splice(i, 1)
        setFileList([...newFileList])
      },
      onDonwlaod: (d) => {
        fetch(`https://screening.wecaremaesot.org/minio/download/test/${x.nameMinio}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/pdf',
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(
              new Blob([blob], { type: 'application/pdf' }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.download = x.name;
            link.click();
            link.parentNode.removeChild(link);
          });
      }
    }))
    return result
  }


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />

      <Modal
        title="ข้อมูลนัด"
        centered
        visible={modal4.show}
        onOk={async () => {
          // let _data = await api.query('db/homecare/form_risk/query', {
          //   "aggregate": [
          //     {
          //       $match: {
          //         cid: modal2.data.cid,
          //       }
          //     }
          //   ]
          // })
          // let _formFirstAssessment = { ..._data[0] }
          // _formFirstAssessment['status_come'] = stepQ
          // await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          // modal2.data.status_come = stepQ
          // updateRow({ ...modal2.data })
          // setStepQ(null)
          // setDateSc(
          //   {
          //     day: '',
          //     month: '',
          //     year: '',

          //   })
          // setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัดสำเร็จ' } })
          // setModal3({ show: false })
        }}
        onCancel={() => {
          // setStepQ(null)
          // setDateSc(
          //   {
          //     day: '',
          //     month: '',
          //     year: '',

          //   })
          setModal4({ show: false })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >HN: {modal4?.data?.HN ? modal4?.data?.HN : '-'} </p>
        <p className="text-lg" >ชื่อนามสกุล: {modal4?.data?.name} </p>
        <p className="text-lg" >เขตบริการ: {`${modal4?.data?.host}`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันที่นัดผู้ป่วยมาโรงบาล: {moment(modal4?.data?.date_queue_patient, 'x').format('l')}  </p>
        </div>
        <p className="text-xl mt-2" >ไฟล์รายละเอียดการนัดหมาย </p>
        <TableAnd
          dataSource={onGenDataTable(fileList)}
          bordered
          size="middle"
          columns={columnsFile}
          line
        />
        {/* <div style={{ flexDirection: 'column', display: 'flex' }}>
          <p className="text-lg" >ระบุแผนการรักษา: {modal4?.data?.textResult}</p>
        </div> */}

      </Modal>

      <Modal
        title="ดูผลการรักษา"
        centered
        visible={modal3.show}
        onOk={async () => {
          // let _data = await api.query('db/homecare/form_risk/query', {
          //   "aggregate": [
          //     {
          //       $match: {
          //         cid: modal2.data.cid,
          //       }
          //     }
          //   ]
          // })
          // let _formFirstAssessment = { ..._data[0] }
          // _formFirstAssessment['status_come'] = stepQ
          // await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          // modal2.data.status_come = stepQ
          // updateRow({ ...modal2.data })
          // setStepQ(null)
          // setDateSc(
          //   {
          //     day: '',
          //     month: '',
          //     year: '',

          //   })
          // setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัดสำเร็จ' } })
          // setModal3({ show: false })
        }}
        onCancel={() => {
          // setStepQ(null)
          // setDateSc(
          //   {
          //     day: '',
          //     month: '',
          //     year: '',

          //   })
          setModal3({ show: false })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >HN: {modal3?.data?.HN ? modal3?.data?.HN : '-'} </p>
        <p className="text-lg" >ชื่อนามสกุล: {modal3?.data?.name} </p>
        <p className="text-lg" >เขตบริการ: {`${modal3?.data?.host}`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันที่นัดผู้ป่วยมาโรงบาล: {moment(modal3?.data?.date_queue_patient, 'x').format('l')}  </p>
        </div>

        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <p className="text-lg" >ระบุแผนการรักษา: {modal3?.data?.textResult}</p>
        </div>
        <TableAnd
          dataSource={onGenDataTable(fileList)}
          bordered
          size="middle"
          columns={columnsFile}
          line
        />
      </Modal>
      <Modal
        title="Doctor Note"
        centered
        visible={modal.show}
        onOk={async () => {
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
          setDoctornote('')
          modal.data.doctornote = doctornote
          updateRow({ ...modal.data })
          setModal({
            show: false
          })
        }}
        okText={'บันทึก'}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setDoctornote('')
          setModal({
            show: false
          })

        }}
        width={800}
      >
        <p>ss</p>

        {/* <p>ชื่อ-นามสกุล: {modal?.data?.name}</p>
        <TextArea
          rows={4}
          placeholder="Note"
          maxLength={512}
          onChange={(event) => {
            setDoctornote(event.target.value)
          }}
          value={doctornote}
        /> */}
      </Modal>

      <Modal
        title="Doctor Note"
        centered
        visible={modalForm.show}
        onOk={async () => {
          let _data = await api.query('db/homecare/form_first_assessment/query', {
            "aggregate": [
              {
                $match: {
                  cid: modalForm.data.cid,
                  round: modalForm.data.round,
                }
              }
            ]
          })
          if (_data.length > 0) {
            _data[0].doctornote = doctornote
            await api.insert(`db/homecare/form_first_assessment/data/${_data[0]._id}`, _data[0])
          }

          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
          // setDoctornote('')
          modalForm.data.doctornote = doctornote
          updateRow({ ...modalForm.data })
          setModalForm({
            show: false
          })
        }}
        okText={'บันทึก'}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          // setDoctornote('')
          setModalForm({
            show: false
          })

        }}
        width={800}
      >
        <p>ชื่อ-นามสกุล: {modal?.data?.name}</p>
        <TextArea
          rows={4}
          placeholder="Note"
          maxLength={512}
          onChange={(event) => {
            setDoctornote(event.target.value)
          }}
          value={doctornote}
        />
      </Modal>


      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <Tooltip title={column.tooltip}>
                  <TableCell
                    onClick={() => {
                      let _tmpdata = [...data]
                      console.log("_tmpdata", _tmpdata)
                      if (column.id === 'date_atk') {
                        // _tmpdata = [..._tmpdata.sort((a, b) => (a.date_atk < b.date_atk) ? -1 : (a.date_atk > b.date_atk) ? 1 : 0)]
                        _tmpdata = [..._tmpdata.sort((a, b) => b.sort_date_atk - a.sort_date_atk)]
                      } else if (column.id === 'updatetime') {
                        _tmpdata = [..._tmpdata.sort((a, b) => b.updatetime - a.updatetime)]
                      } else if (column.id === 'name') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)]
                      } else if (column.id === 'host') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.host < b.host) ? -1 : (a.host > b.host) ? 1 : 0)]
                      } else if (column.id === 'status') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.status < b.status) ? -1 : (a.status > b.status) ? 1 : 0)]
                      } else if (column.id === 'discharge') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.dischargestatus < b.dischargestatus) ? -1 : (a.dischargestatus > b.dischargestatus) ? 1 : 0)]
                      } else if (column.id === 'conutdate') {
                        // _tmpdata = [..._tmpdata.sort((a, b) => (a.conutdate < b.conutdate) ? -1 : (a.conutdate > b.conutdate) ? 1 : 0)]
                        _tmpdata = [..._tmpdata.sort((a, b) => {
                          let _a = a.conutdate
                          let _b = b.conutdate
                          if (_a === '-') _a = 0
                          if (_b === '-') _b = 0
                          if (Number.isNaN(Number(_a))) _a = -1
                          if (Number.isNaN(Number(_b))) _b = -1
                          return _b - _a

                        })]
                      } else if (column.id === 'is_call') {
                        _tmpdata = [..._tmpdata.sort((a, b) => (a.is_call < b.is_call) ? -1 : (a.is_call > b.is_call) ? 1 : 0)]
                      }
                      if (sort.state === "up") _tmpdata.reverse()
                      setData([..._tmpdata])
                      setSort({
                        key: column.id,
                        state: sort.state === 'down' ? 'up' : 'down'
                      })
                      setPage(0);
                    }}
                    style={{ minWidth: column.minWidth, align: 'justify' }}
                  >
                    {column.label}
                    {
                      sort.key === column.id ?
                        (sort.state === 'down' ? <ArrowDownOutlined style={{ color: 'green' }} /> : <ArrowUpOutlined style={{ color: 'green' }} />)
                        : null
                    }
                  </TableCell>

                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {

                      return <ReaderRow column={column} row={row} handleClick={handleClick} />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

const Card = ({ title = '', value = 0, total = 1, text = 'green', isNotShow = false }) => {
  return (<div style={{
    background: "#fff",
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  }}>
    {!isNotShow && <Progress type="circle" percent={(value / total * 100).toFixed(2)} strokeColor={MAP_STATUS[text].color_b} width={80} strokeWidth={10} trailColor="#ccc" />}
    <div
      style={{
        margin: 4,
        background: "#fff",
        display: 'flex',
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: 14, marginTop: 12 }}>
        {title}
      </div>
      <div style={{ fontSize: 22 }}>
        {value} ราย
      </div>
    </div>
  </div>
  )
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [dataShow, setShow] = useState([]);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch()
  let [R, setR] = useState(null)
  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    let user = localStorage.getItem('user_wecaremaesot')
    role = JSON.parse(role)
    user = JSON.parse(user)

    let aggregate = [
      {
        $lookup:
        {
          from: "form_risk",
          localField: "cid",
          foreignField: "cid",
          as: "form_risk"
        }
      },
      {
        $unwind: {
          path: "$form_risk",
          preserveNullAndEmptyArrays: false
        }
      },
      {
        $project: {
          cid: 1,
          hash_cid: 1,
          is_call: 1,
          title: "$title",
          HN: "$HN",
          firstname: "$firstname",
          lastname: "$lastname",
          name: { $concat: ["$title", "$firstname", " ", "$lastname"] },
          host: 1,
          hostid: 1,
          // updatetime: "$updatetime",
          status: "$status",
          status_queue: "$form_risk.status_queue",
          status_come: "$form_risk.status_come",
          count_not_com: "$form_risk.count_not_com",
          date_queue_patient_new: "$form_risk.date_queue_patient_new",
          updatetime: "$form_risk.date_queue_patient",
          date_queue_patient: "$form_risk.date_queue_patient",
          textResult: "$form_risk.textResult",
          q_listfile: "$form_risk.q_listfile",
          result_listfile: "$form_risk.result_listfile",
        }
      }
    ]

    if (user.hospital !== 'โรงพยาบาลแม่สอด') {
      aggregate.push({
        $match: {
          hostid: user.hospital_code
        }
      })
    }

    let _data = await api.query(
      'db/homecare/patient_risk/query',
      {
        "aggregate": aggregate
      }
    )

    let result = []
    _data.map(x => {
      x['dischargestatus'] = x.discharge ? 'discharge' : 'active'
      if (Array.isArray(x?.form_daily_assessment) && x?.form_daily_assessment.length > 0) {
        x.form_daily_assessment = x.form_daily_assessment.sort((a, b) => a.date_make - b.date_make)
        let firstday = x.form_daily_assessment[0].date_make
        let lastdate = x.form_daily_assessment[x.form_daily_assessment.length - 1].updatetime
        x['conutdate'] = x.discharge ? '-' : `${util.diffday(firstday, moment().format('x')) + 1}`
        x['date_atk'] = util.getTimeLong(firstday)
        x['date_atk_filter'] = Number(firstday)
        x['sort_date_atk'] = Number(firstday)
      } else {
        x['date_atk'] = 'ยังไม่มีฟอร์ม'
        x['conutdate'] = 'ยังไม่มีฟอร์ม'
        x['date_queue'] = x.date_queue_patient
        // status_queue
      }
      result.push(x)
    })
    setR(role[0])
    setData([...result.sort((a, b) => b.sort_date_atk - a.sort_date_atk)])
    console.log("R", R)

  }, []);

  const [user, setUser] = useState("");
  const [age, setAge] = React.useState('all');
  const [ps, setPS] = React.useState('all');
  const [hostF, sethostF] = React.useState('all');
  const [date, setDate] = React.useState(null);
  const [date2, setDate2] = React.useState(null);
  const handleChange = (value) => {
    setAge(value);
  };

  const handleChangePS = (value) => {
    setPS(value);
  };

  return (<Layout ispadding is4>
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1550px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <p className="text-3xl ">รายชื่อผู้คัดกรองโรคไม่ติดต่อเรื้อรังNCDs</p>

      <div className="flex flex-row pb-4">
        {
          R === 'SHPH' &&
          < Button
            variant="contained"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              history.push('/AddPatient4')
            }}>
            ลงทะเบียนผู้ป่วยนัด
          </Button>
        }

      </div>

      {/*     
      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          marginBottom: 24
        }}
      >

        <Card title={'ผู้คัดกรองที่มีความเสี่ยง'} total={dataShow.length} value={dataShow.length} isNotShow={true} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'รอนัดจากแพทย์'} total={dataShow.length} value={dataShow.filter(x => x.status_queue === 'wait_confirm_doctor').length} text={"blue"} />
        <Card title={'ลงนัดแล้ว'} total={dataShow.length} value={dataShow.filter(x => x.status_queue === 'confirm_patient').length} text={"green"} />
        <Card title={'ส่งต่อ'} total={dataShow.length} value={dataShow.filter(x => x.status_queue === 'refer').length} text={"yellow"} />
        <Card title={'ไม่นัด'} total={dataShow.length} value={dataShow.filter(x => x.status_queue === 'stop').length} text={"red"} />
        <Card title={'อื่นๆ'} total={dataShow.length} value={dataShow.filter(x => x.status_queue === 'other').length} text={"red"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
      </div>
    
     */}
      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >

        <div style={{ flexDirection: 'row' }}>
          <FormControl sx={{ m: 1 }}>
            <div>สถานะผู้คัดกรอง</div>
            <Select defaultValue="lucy" style={{ width: 200 }} onChange={handleChangePS} size={"large"} value={ps}>
              <Option value="all">ทั้งหมด</Option>
              <Option value="wait_confirm_doctor">รอนัดจากแพทย์</Option>
              <Option value="confirm_patient" >ลงนัดแล้ว</Option>
              {/* <Option value="refer" >ส่งต่อ</Option>
              <Option value="stop" >ไม่นัด</Option>
              <Option value="other" >อื่นๆ</Option> */}
            </Select>
          </FormControl>
          {
            R !== 'SHPH' &&
            <FormControl sx={{ m: 1 }}>
              <div>สถานพยาบาล</div>
              <Select defaultValue="lucy" style={{ width: 260 }} onChange={(value) => { sethostF(value) }} size={"large"} value={hostF}>
                <Option value="all">ทั้งหมด</Option>
                {[
                  'รพ.สต.บ้านปางส่างคำ',
                  'รพ.สต.บ้านห้วยกระโหลก',
                  'รพ.สต.แม่ตาว',
                  'รพ.สต.บ้านโกกโก่',
                  'รพ.สต.ท่าสายลวด',
                  'รพ.สต.บ้านวังตะเคียน',
                  'รพ.สต.บ้านห้วยหินฝน',
                  'รพ.สต.พระธาตุผาแดง',
                  'รพ.สต.บ้านแม่กุเหนือ',
                  'รพ.สต.บ้านแม่กื้ดสามท่า',
                  'รพ.สต.บ้านแม่กุใหม่',
                  'รพ.สต.พะวอ',
                  'รพ.สต.มหาวัน',
                  'รพ.สต.บ้านปางส้าน',
                  'รพ.สต.บ้านปูเตอร์',
                  'รพ.สต.บ้านเจดีย์โค๊ะ',
                  'รพ.สต.บ้านห้วยยะอุ',
                  'คลินิกชุมชนอบอุ่นเทศบาลนครแม่สอด',
                  'รพ.สต.แม่กุ',
                  'รพ.สต.บ้านแม่กาษา',
                  'รพ.สต.บ้านห้วยไม้แป้น',
                  'โรงพยาบาลแม่สอด',
                  'รพ.สต.บ้านแม่กื๊ดหลวง',
                  'รพ.สต.แม่ปะ',
                ].map(x => <Option value={x}>{x}</Option>)}
              </Select>
            </FormControl>
          }

          <FormControl sx={{ m: 1, minWidth: 200, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหาชื่อนามสกุล"
              style={{ width: 230, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>


          <FormControl sx={{ m: 1, minWidth: 100, height: 54 }}>
            < Button
              variant="contained"
              style={{ marginTop: 20 }}
              onClick={() => {
                setSearch('')
                setAge('all')
                setPS('all')
                sethostF('all')
                setDate(null)
                setDate2(null)
              }}>
              รีเซ็ต
            </Button>
          </FormControl>
        </div>
        <ColumnGroupingTable
          data_mock={data}
          search={search}
          age={age}
          ps={ps}
          date={date}
          date2={date2}
          hostF={hostF}
          onFilter={(x) => {
            setShow(x)
          }}
          updateRow={(dataupdate) => {
            let index = data.findIndex(x => x._id === dataupdate._id)
            data[index] = dataupdate
            setData([...data])
          }}
        />
      </div>
    </div>
  </Layout >)
}
