import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from '../../util/api';
import Layout from '../../components/Layout';
import { Progress } from 'antd';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },
  
  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const Card = ({ title = '', value = 0, total = 1, text = 'green', isNotShow = false }) => {
  return (<div style={{
    background: "#fff",
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  }}>
    {!isNotShow && <Progress type="circle" percent={value / total * 100} strokeColor={MAP_STATUS[text].color_b} width={80} strokeWidth={10} trailColor="#ccc" />}
    <div
      style={{
        margin: 4,
        background: "#fff",
        display: 'flex',
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: 14, marginTop: 12 }}>
        {title}
      </div>
      <div style={{ fontSize: 22 }}>
        {value} ราย
      </div>
    </div>
  </div>
  )
}


export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');


  React.useEffect(async () => {
    console.log(">> start")
    let _data = await api.query(
      'db/homecare/patient/query',
      {
        "query": {
          "hostid": "10723"
        }
      }
    )
    setData(_data.map(x => ({ ...x, name: `${x.title}${x.firstname} ${x.lastname}` })))
  }, []);

  const [user, setUser] = useState("");
  const [age, setAge] = React.useState('all');
  const handleChange = (value) => {
    setAge(value);
  };

  let year = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  function handleClick(path) {
    history.push(path);
  }

  return (<Layout ispadding>
    {/*  
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          "boxShadow": "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          marginBottom: 24
        }}
      >
        <Card title={'ผู้ป่วยทั้งหมด'} total={data.length} value={data.length} isNotShow={true} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยสีเขียว'} total={data.length} value={data.filter(x => x.status === 'green').length} text={"green"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยสีเหลืองอ่อน'} total={data.length} value={data.filter(x => x.status === 'yellow').length} text={"yellow"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยสีเหลืองเข้ม'} total={data.length} value={data.filter(x => x.status === 'lightyellow').length} text={"yellow"} />
        <div style={{ height: "80px", backgroundColor: '#eee', width: '1px' }} />
        <Card title={'ผู้ป่วยรักษาหายแล้ว'} total={data.length} value={data.filter(x => x.status === 'dischart').length} text={"dischart"} />

      </div>
    </div>
  */}
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div class="grid grid-cols-6 lg:grid-cols-12 p-5 gap-4 flex justify-self-center	w-[1200px]">

        {/* <div class="shadow-lg bg-white p-4 rounded-lg col-span-3">
          ผู้ป่วยทั้งหมด
        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-3">
          ผู้ติดเชื่อใหม่
        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-3">
          ผู้ติดเชื่อใหม่
        </div>


        <div class="shadow-lg bg-white p-4 rounded-lg col-span-3">
          หายป่วยวันนี้
        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-3">
          หายป่วยสะสม
        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-3">
          ผู้ติดเชื่อใหม่
        </div>


        <div class="flex flex-col content-center shadow-lg bg-white p-4 rounded-lg col-span-6 md:col-span-6 row-span-3">

          <div class="mx-auto">
            {
              user?.status &&
              <p class="bg-blue-100 p-2 m-2 rounded-full" style={{ backgroundColor: MAP_STATUS[user?.status].color }}>สถานะ: {`${MAP_STATUS[user?.status].text}`}</p>
            }

          </div>
        </div> */}
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-2 bg-green-600">
          <p className="text-center text-lg text-white"> หายป่วยวันนี้ </p>
          <p className="text-center text-5xl text-white"> +110 </p>

        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-2 bg-red-600">
          <p className="text-center text-lg text-white"> ติดเชื่อวันนี้ </p>
          <p className="text-center text-5xl text-white"> +137 </p>
        </div>
        <div class="flex flex-col content-center shadow-lg bg-white p-4 rounded-lg col-span-6 md:col-span-8 row-span-3">

          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'column'
              },
              title: {
                text: 'สถานะการณ์ covid 19 '
              },
              subtitle: {
                text: 'อำเภอแม่สอด จังหวัด ตาก'
              },
              xAxis: {
                categories: [
                  '4/04/64',
                  '5/04/64',
                  '6/04/64',
                  '7/04/64',
                  '8/04/64',
                  '9/04/64',
                  '10/04/64',
                ],
                crosshair: true
              },
              yAxis: {
                min: 0,
                title: {
                  text: 'จำนวนผู้ป่วย (คน)'
                }
              },
              tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.0f} คน</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
              },
              plotOptions: {
                column: {
                  dataLabels: {
                    enabled: true,
                    color: "black",
                    style: {
                      textOutline: false
                    }
                  }
                },
                pointPadding: 0.2,
                borderWidth: 0
                // column: {
                //   pointPadding: 0.2,
                //   borderWidth: 0
                // }
              },
              series: [{
                name: 'สี่เขียว',
                color: '#2ECC71',
                data: [804, 698, 621, 580, 571, 568, 569]

              }, {
                name: 'สีเหลืองอ่อน',
                color: '#FFC300',
                data: [21, 31, 30, 35, 37, 43, 46]

              }, {
                name: 'สีเหลืองเข้ม',
                color: '#FF5733',
                data: [17, 13, 13, 12, 13, 13, 13]

              }, {
                name: 'หายป่วย',
                color: '#5DADE2',
                data: [133, 192, 172, 171, 104, 82, 110]

              }]
            }
            }
          />
        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-2 bg-green-600">
          <p className="text-center text-lg text-white"> หายป่วยสะสม </p>
          <p className="text-center text-5xl text-white"> 11,486 </p>

        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-2 bg-red-600">
          <p className="text-center text-lg text-white"> ป่วยสะสม </p>
          <p className="text-center text-5xl text-white"> 12,123 </p>

        </div>
        <div class="shadow-lg bg-white p-4 rounded-lg col-span-4 bg-green-600">

          <p className="text-center text-lg text-white"> กำลังรักษา </p>
          <p className="text-center text-5xl text-white"> 637 </p>
        </div>
        {/* <div class="shadow-lg bg-white p-4 rounded-lg col-span-3 bg-red-600">
          <p className="text-center text-lg text-white"> หายป่วยวันนี้ </p>
          <p className="text-center text-5xl text-white"> 20,000 </p>
        </div> */}


      </div>
    </div>

  </Layout >)
}
