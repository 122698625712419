import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import api from "../../util/api";
import { Divider } from "@mui/material";
import AddressData from "../../util/AddressData";
import SweetAlert from 'sweetalert2-react';
import md5 from 'md5';
import { checkSum } from "../../util/validatecid"
import { Grid } from '@mui/material';
import { Spin, Checkbox, Select } from 'antd';
const { Option } = Select;
// import hospital from '../../util/maphost.json'
let MONTH = {
  'ม.ค.': '01',
  'ก.พ.': '02',
  'มี.ค.': '03',
  'เม.ย.': '04',
  'พ.ค.': '05',
  'มิ.ย.': '06',
  'ก.ค.': '07',
  'ส.ค.': '08',
  'ก.ย.': '09',
  'ต.ค.': '10',
  'พ.ย.': '11',
  'ธ.ค.': '12'
}

let MAP_GENDER = {
  'นาง': 'นาง',
  'น.ส.': 'นางสาว',
  'นางสาว': 'นางสาว',
  'นาย': 'นาย',
  'เด็กชาย': 'เด็กชาย',
  'เด็กหญิง': 'เด็กหญิง',
  'ด.ช.': 'เด็กชาย',
  'ด.ญ.': 'เด็กหญิง',
}

export default function Home(props) {
  let history = useHistory();
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [isload, setLoad] = useState(true)
  const [isBOD, setBOD] = useState(false)
  const [list_hospital, setListHospital] = useState(false)

  const [page, setPage] = useState(0);
  const [form, setForm] = useState({
    "pass": '',
    "found_Data": false,
    "cid": '',
    "date": '',
    "month": '',
    "year": '',

    "date_atk": '',
    "month_atk": '',
    "year_atk": '',

    "vaccinate": null,
    "list_vaccinate": null,
    "hospital": '',
    // list_hospital

    "hostid": null,
    "title": null,
    "firstname": null,
    "lastname": null,
    "updatetime": null,
    "host": null,
    "status": null,
    "dob": null,
    "gender": null,
    "tel": null,
    "email": null,
    "line": null,
    "profile": null,
    "housenum": null,
    "villageno": null,
    "alley": null,
    "lane": null,
    "road": null,
    "provincename": null,
    "cityname": null,
    "tumbonname": null,
    "postcode": null,
    "lat": null,
    "lng": null,
    "weight": null,
    "height": null,
    "age": null
  })

  const [line, setLine] = useState(null);

  const handleChange = (event) => {
    setForm({ ...form, ...{ [event.target.name]: event.target.value } })
  }

  let titleall = [
    "นาง",
    "นางสาว",
    "นาย",
    "เด็กชาย",
    "เด็กหญิง"
  ]

  if (form.title && !titleall.includes(form.title)) {
    titleall.push(form.title)
  }

  React.useEffect(async () => {
    console.log("props start")
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    let profile = await api.authLineGetProfile(code, state)
    if (profile.statu === 'ok') {
      history.push('/')
    } else {
      setLine(profile)
      setLoad(false)
    }
  }, [])

  let year = []
  let day = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  let dayOfMonth = 31
  if (form.month) {
    let sufixMonth = form.month.slice(form.month.length - 2, form.month.length)
    if (sufixMonth == 'พ.' && (form.date === 30 || form.date === 31)) {
      dayOfMonth = 29
    } else if (['ย.'].includes(form.month) && (form.date === 31)) {
      dayOfMonth = 30
      setForm({ ...form, date: null })
    }

    if (sufixMonth == 'พ.') {
      dayOfMonth = 29
    } else if (['ย.'].includes(form.month)) {
      dayOfMonth = 30
    }
  }

  for (let i = 1; i <= dayOfMonth; i++) {
    day.push(i)
  }


  let list_city = {}
  let list_tumbon = {}
  // let list_hospital = {}


  AddressData
    .AddressData
    .forEach(x => {
      if (x.provincename === form.provincename) {
        list_city[x.cityname] = true
      }

      if (x.provincename === form.provincename && x.cityname === form.cityname) {
        list_tumbon[x.tumbonname] = true
      }
    })


  React.useEffect(async () => {
    let _list_hospital = {}
    AddressData.hospitals.forEach(x => {
      if (x.cityname == form.cityname && x.tumbonname == form.tumbonname && x.moo == form.villageno) {
        _list_hospital[x.hospital_name] = x
      }
    })


    if (Object.keys(_list_hospital).length === 0) {
      AddressData.hospitals.forEach(x => {
        if (x.cityname == form.cityname && x.tumbonname == form.tumbonname) {
          _list_hospital[x.hospital_name] = x
        }
      })
    }

    if (Object.keys(_list_hospital).length === 1) {
      let _hospital = _list_hospital[Object.keys(_list_hospital)[0]]
      console.log("_hospital [2] :", _hospital)
      if (_hospital) {
        setForm({
          ...form, ...{
            hostid: _hospital.hospital_code,
            host: _hospital.hospital_name,
            hostmainid: _hospital.hospital_main_code,
            hostmain: _hospital.hospital_main_name,
          }
        })
      }
    }

    setListHospital({ ..._list_hospital })

  }, [form.provincename, form.cityname, form.tumbonname, form.villageno])


  if (page === 0) {
    return (
      <div class="w-full h-screen flex justify-center mx-auto p-5 bg-gradient-to-bl from-green-200 to-green-700 flex-col">
        <SweetAlert
          show={show.show}
          title={show.title}
          text={show.message}
          // success
          onConfirm={() => {
            setShow({ ...show, ...{ show: false } })
          }}
        />

        <div class="rounded-3xl shadow-lg bg-white p-2 flex flex-col h-fit self-center m-2">
          <p class="text-center text-2xl">ตรวจสอบบัตรประชาชน</p>
          <Divider />
          <div
            style={{
              background: "#fff",
              borderRadius: 12,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              marginBottom: "24px",
            }}
          >
            <div
              style={{
                background: "#fff",
                borderRadius: 12,
                width: "320px",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
              }}
            >
            </div>
          </div>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <TextField
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 13,
                  inputmode: "tel"
                }}
                id="cid"
                name="cid"
                label="บัตรประชาชน"
                variant="outlined"
                value={form.cid}
                onChange={handleChange}
              />
            </ FormControl>
            {
              !isBOD &&
              (
                <div style={{ flexDirection: 'row' }}>

                  <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <TextField
                      value={form.date}
                      onChange={(e) => {
                        setForm({ ...form, ...{ date: e.target.value } })
                      }}
                      select // tell TextField to render select
                      label="วันเกิด"
                    >
                      {
                        day.map(x => (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </ FormControl>
                  <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <TextField
                      value={form.month}
                      onChange={(e) => {
                        setForm({ ...form, ...{ month: e.target.value } })
                      }}
                      select // tell TextField to render select
                      label="เดือนเกิด"
                    >
                      {
                        Object.keys(MONTH).map(x => (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        ))
                      }
                    </TextField>

                  </ FormControl>

                  <FormControl sx={{ m: 1, minWidth: 90 }}>
                    <TextField
                      value={form.year}
                      onChange={(e) => {
                        setForm({ ...form, ...{ year: e.target.value } })
                      }}
                      select // tell TextField to render select
                      label="ปีเกิด"
                    >
                      {
                        year.map(x => (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </ FormControl>
                </div>)
            }
            < FormControl sx={{ m: 1, minWidth: 120 }}>
              <Checkbox
                checked={isBOD}
                onChange={() => {
                  setBOD(!isBOD)
                }}
              >
                ไม่รู้วันเกิด
              </Checkbox>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>

              <Button variant="contained" onClick={async () => {
                try {
                  if (!form.cid) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่เลขบัตรประชาชน' } })
                  if (!isBOD) {
                    if (!form.date) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่วันที่เกิด' } })
                    if (!form.month) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่เดือนที่เกิด' } })
                    if (!form.year) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ปีที่เกิด' } })
                  }

                  // think ?
                  // PASS G // 

                  let caseCid = checkSum(form.cid)

                  if (!caseCid.ok) {
                    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง' } })
                  }

                  let dob = null
                  let res = null
                  if (!isBOD) {
                    dob = `${form.year - 543}-` + `${MONTH[form.month]}-` + `${form.date}`.padStart(2, '0')
                    res = await api.checkPatient(form.cid, dob)
                  }
                  //  `${form.year - 543}-` + `${MONTH[form.month]}-` + `${form.date}`.padStart(2, '0')

                  if (res?.case === 'have_dob') {
                    let address = res.user.data.result.patient.Address[0]
                    let patient = res.user.data.result.patient
                    let vaccine_certificate = res.user.data.result.vaccine_certificate[0].vaccination_list
                    let title = MAP_GENDER[patient.prefix]
                    if (!title) {
                      title = patient.prefix
                    }
                    let firstname = patient.first_name
                    let lastname = patient.last_name
                    if (!lastname) {
                      lastname = "-"
                    }

                    let housenum = address.Address
                    let villageno = `Number${address['moo']}`.replace(/\D/g, '');
                    if (villageno) {
                      villageno = `${Number(villageno)}`
                    }
                    let tumbonname = address.tmb_name
                    let cityname = address.amp_name
                    let provincename = address.chw_name

                    let list_vaccinate = vaccine_certificate
                    let vaccinate = vaccine_certificate.length
                    let found_Data = true
                    setForm({
                      ...form,
                      ...{
                        title,
                        firstname,
                        lastname,
                        dob,
                        housenum,
                        villageno,
                        tumbonname,
                        cityname,
                        provincename,
                        list_vaccinate,
                        vaccinate,
                        found_Data,
                        data_source: 'api'
                      }
                    })
                    setPage(1)
                  } else if (res?.case === 'not_have_dob') {
                    setForm({
                      ...form,
                      ...{
                        dob,
                        data_source: 'input_by_user'
                      }
                    })
                    setPage(1)
                  } else {
                    setForm({
                      ...form,
                      ...{
                        dob,
                        data_source: 'input_by_user_nodob'
                      }
                    })
                    setPage(1)
                  }

                } catch (err) {
                  console.log("err.message", err)
                  if (err.message) {
                    alert(err)
                  } else {
                    setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'ข้อมูลไม่ถูกต้อง' } })
                  }
                }

              }} >
                ถัดไป
              </Button>
            </FormControl>
          </div>
        </div>


      </div >
    )
  } else {
    return (
      <div class="w-full flex justify-center mx-auto p-5 bg-gradient-to-bl from-green-200 to-green-700 flex-col">
        <SweetAlert
          show={show.show}
          title={show.title}
          text={show.message}
          onConfirm={() => {
            setShow({ ...show, ...{ show: false } })
          }}
        />
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 12,
            padding: "18px",
            display: "flex",
            maxWidth: "1200px",
            alignSelf: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              background: "#fff",
              borderRadius: 12,
              paddingLeft: 12,
              paddingRight: 12,
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              marginBottom: "24px",
            }}
          >
            <div
              style={{
                background: "#fff",
                borderRadius: 12,
                width: "320px",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
              }}
            >
            </div>
          </div>
          <div style={{ flexDirection: 'column', display: 'flex' }}>


            <p class="text-center text-3xl"> ลงทะเบียนใช้งาน</p>
            < Divider class="m-4" />

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  // error
                  // helperText="Incorrect entry."
                  style={{ width: '100%' }}
                  pattern="[0-9]*"
                  id="pass"
                  name="pass"
                  type="password"
                  inputProps={{
                    maxLength: 30,
                  }}
                  label="รหัสผ่าน  *"
                  variant="outlined"
                  value={form.pass}
                  onChange={(event) => {
                    setForm({ ...form, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  disabled={true}
                  style={{ backgroundColor: "#e1e1e1", width: '100%' }}
                  pattern="[0-9]*"
                  inputProps={{
                    maxLength: 13,
                    inputmode: "tel"
                  }}
                  id="cid"
                  name="cid"
                  label="เลขบัตรประชาชน  *"
                  variant="outlined"
                  value={form.cid}
                  onChange={handleChange}
                />
              </Grid>


              {/* Empty */}
              <Grid item xs={0} md={4} style={{ padding: 0, margin: 0 }} />


              <Grid item xs={12} md={3}>
                <TextField
                  disabled={form.found_Data}
                  style={{ backgroundColor: form.found_Data ? "#e1e1e1" : '#fff', width: '100%' }}
                  value={form.title}
                  onChange={(e) => {
                    setForm({ ...form, ...{ title: e.target.value } })
                  }}
                  select // tell TextField to render select
                  label="คำนำหน้า  *"
                >
                  {
                    titleall.map(x => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  disabled={form.found_Data}
                  style={{ backgroundColor: form.found_Data ? "#e1e1e1" : '#fff', width: '100%' }}

                  id="firstname"
                  name="firstname"
                  label="ชื่อ  *"
                  variant="outlined"
                  value={form.firstname}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  disabled={form.found_Data}
                  style={{ backgroundColor: form.found_Data ? "#e1e1e1" : '#fff', width: '100%' }}

                  id="lastname"
                  name="lastname"
                  label="นามสกุล  *"
                  variant="outlined"
                  value={form.lastname}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  style={{ width: '100%' }}
                  pattern="[0-9]*"
                  inputProps={{
                    maxLength: 10,
                    inputmode: "tel"
                  }}
                  id="tel"
                  name="tel"
                  label="เบอร์โทร  *"
                  variant="outlined"
                  value={form.tel}
                  onChange={(event) => {
                    setForm({
                      ...form, ...{
                        tel: `${event.target.value}`.replace(/[^0-9]/g, ''),
                      }
                    })
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  style={{ width: '100%' }}
                  id="email"
                  name="email"
                  label="email"
                  variant="outlined"
                  value={form.email}
                  onChange={handleChange}
                />
              </Grid>

              {/* Empty */}
              <Grid item xs={0} md={4} style={{ padding: 0, margin: 0 }} />


              <Grid item xs={12} md={4}>
                <TextField
                  style={{ width: '100%' }}
                  id="housenum"
                  name="housenum"
                  label="เลขที่ *"
                  variant="outlined"
                  value={form.housenum}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  style={{ width: '100%' }}
                  pattern="[0-9]*"
                  inputProps={{
                    maxLength: 2,
                    inputmode: "decimal"
                  }}
                  id="villageno"
                  name="villageno"
                  label="หมู่ที่"
                  variant="outlined"
                  value={form.villageno}
                  // onChange={handleChange}
                  helperText="*ถ้าไม่มีหมู่ไม่ต้องใส่"
                  onChange={(event) => {
                    setForm({
                      ...form, ...{
                        villageno: `${event.target.value}`.replace(/[^0-9]/g, ''),
                      }
                    })
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  style={{ width: '100%' }}
                  id="road"
                  name="road"
                  label="ถนน"
                  variant="outlined"
                  value={form.road}
                  onChange={handleChange}
                />
              </Grid>



              <Grid item xs={12} md={4}>
                <p>จังหวัด  *</p>
                <Select
                  labelInValue
                  size="large"
                  value={form.provincename}
                  style={{ width: '100%' }}
                  onChange={({ value }) => {
                    setForm({
                      ...form, ...{
                        hostid: null,
                        host: null,
                        hostmainid: null,
                        hostmain: null,
                        provincename: value,
                        tumbonname: null,
                        cityname: null,
                      }
                    })
                  }}
                >
                  {
                    AddressData
                      .ThaiProvinces
                      .map(x => (
                        <Option value={x}>{x}</Option>
                      ))
                  }

                </Select>

              </Grid>


              <Grid item xs={12} md={4}>
                <p>อำเภอ  *</p>
                <Select
                  labelInValue
                  size="large"
                  value={form.cityname}
                  style={{ width: '100%' }}
                  onChange={({ value }) => {
                    setForm({
                      ...form, ...{
                        hostid: null,
                        host: null,
                        hostmainid: null,
                        hostmain: null,
                        cityname: value,
                        tumbonname: null

                      }
                    })
                  }}
                >
                  {
                    Object.keys(list_city)
                      .map(x => (
                        <Option value={x}>{x}</Option>
                      ))
                  }
                </Select>

              </Grid>


              <Grid item xs={12} md={4}>

                <p>ตำบล  *</p>
                <Select
                  labelInValue
                  size="large"
                  value={form.tumbonname}
                  style={{ width: '100%' }}
                  onChange={({ value }) => {
                    setForm({
                      ...form, ...{
                        hostid: null,
                        host: null,
                        hostmainid: null,
                        hostmain: null,
                        tumbonname: value,
                      }
                    })
                  }}
                >
                  {
                    Object.keys(list_tumbon)
                      .map(x => (
                        <Option value={x}>{x}</Option>
                      ))
                  }
                </Select>

              </Grid>
              {console.log("_hospital [3]", Object.keys(list_hospital))}
              {console.log("_hospital [4]", Object.keys(list_hospital).length)}



              {
                Object.keys(list_hospital).length > 0 &&

                <Grid item xs={12} md={4}>
                  <p>โรงพยาบาล</p>
                  <Select
                    labelInValue
                    size="large"
                    value={form.host}
                    style={{ width: '100%' }}
                    onChange={({ value }) => {
                      let _hospital = list_hospital[value]
                      if (_hospital) {
                        console.log("_hospital", _hospital)
                        setForm({
                          ...form, ...{
                            hostid: _hospital.hospital_code,
                            host: _hospital.hospital_name,
                            hostmainid: _hospital.hospital_main_code,
                            hostmain: _hospital.hospital_main_name,
                          }
                        })
                      }
                    }}
                  >
                    {
                      Object.keys(list_hospital)
                        .map(x => (
                          <Option value={x}>{x}</Option>
                        ))
                    }
                  </Select>
                </Grid>

              }

              {Object.keys(list_hospital).length > 0 &&
                <>
                  <Grid item xs={12} md={4} style={{ padding: 0, margin: 0 }} />
                  <Grid item xs={12} md={4} style={{ padding: 0, margin: 0 }} />
                </>
              }

              <Grid item xs={12} md={3}>
                < button
                  class="bg-green-600 p-2 text-white rounded flex justify-self-center w-full lg:w-full  flex justify-center"
                  onClick={async () => {

                    try {

                      // hospital 
                      let _form = { ...form }
                      if (form['villageno']) {
                        _form['villageno'] = `${_form['villageno']} `.replace(/\D/g, '');
                      }

                      if (form['height']) {
                        _form['height'] = `${_form['height']} `.replace(/\D/g, '');
                      }

                      if (form['weight']) {
                        _form['weight'] = `${_form['weight']} `.replace(/\D/g, '');
                      }

                      if (form['villageno']) {
                        _form['villageno'] = `${_form['villageno']} `.replace(/\D/g, '');
                      }

                      if (!_form.title) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ คำนำหน้า' } })
                      if (!_form.firstname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ ชื่อ' } })
                      if (!_form.lastname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ นามสกุล' } })
                      if (!_form.pass) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ รหัสผ่าน' } })
                      if (_form.pass.length < 6) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ รหัสผ่านอย่างน้อย 6 ตัว' } })
                      if (!_form.provincename) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ จังหวัด' } })
                      if (!_form.cityname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ อำเภอ' } })
                      if (!_form.tumbonname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ ตำบล' } })
                      if (!_form.tel) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ เบอร์โทร' } })
                      // if (!_form.email) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ email' } })
                      if (!_form.housenum) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ บ้านเลขที่' } })
                      if (Object.keys(list_hospital).length === 0) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'พื่นที่ของคุณยังไม่ให้บริการ' } })
                      if (!_form.host) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ โรงพยาบาล' } })

                      if (form['villageno']) {
                        _form['villageno'] = `${_form['villageno']} `.replace(/\D/g, '');
                      }

                      // let hospital = AddressData.hospitals.find(x => {
                      //   return x.tumbonname === _form.tumbonname && _form.villageno === x.moo
                      // })

                      if (!_form.hostid) {
                        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'ระบบไม่มีโรงบาล' } })
                      }
                      if (_form?.year) {
                        let date = new Date()
                        let age = Number((date.getFullYear() + 543) - (_form.year))
                        if (!Number.isNaN(age)) {
                          _form.age = age
                        }
                      }
                      delete _form.date
                      delete _form.month
                      delete _form.year
                      delete _form.date_atk
                      delete _form.month_atk
                      delete _form.year_atk
                      delete _form.found_Data
                      _form['updatetime'] = new Date().getTime()
                      _form['url'] = "https://firebasestorage.googleapis.com/v0/b/hood-flashcard.appspot.com/o/image%20226%20(1).webp?alt=media&token=c2350064-bbef-478f-adce-15aa1481fafb"
                      if (line && line.data.data.picture) {
                        _form['url'] = line.data.data.picture
                      }

                      _form['gender'] = ['นาย', 'เด็กชาย'].includes(_form.title) ? 'ช' : 'ญ'
                      // _form['day_atk'] = dayATK
                      // _form['hostid'] = hospital.hospital_code
                      // _form['host'] = hospital.hospital_name
                      // _form['hostmainid'] = hospital.hospital_main_code
                      // _form['hostmain'] = hospital.hospital_main
                      _form['status'] = 'green'
                      _form['hash_cid'] = md5(_form.cid)
                      // _form['_id'] = `${ _form.cid } h${ _form.hostid } `
                      _form['_id'] = `${_form.cid}`


                      await api.register(
                        {
                          "user": form.cid,
                          "pass": form.pass,
                          "application": "homecare",
                          "profile": {
                            "cid": _form.cid,
                            "title": _form.title,
                            "firstname": _form.firstname,
                            "lastname": _form.lastname,
                            "email": _form.email,
                            "tel": _form.tel,
                            "hospital": _form.host,
                          },
                          line: line.data.data,
                          "roles": ["patient"]
                        }
                        ,
                        _form
                      )

                      let user = await api.auth({ username: _form.cid, password: form.pass })
                      history.push('/home-patient')
                      setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'สมัครสมาชิกสำเร็จ' } })
                    } catch (err) {
                      console.log("err", err)
                      if (err.message) {
                        setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: err.message } })
                      } else {
                        setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: err } })
                      }
                    }

                  }}
                >
                  สมัครสมาชิก
                </button>
              </Grid>
              <Grid item xs={12} md={3}>

                < button
                  class="bg-blue-600 p-2 text-white rounded flex justify-self-center w-full lg:w-full flex justify-center"
                  onClick={() => history.goBack()}
                >
                  ย้อนกลับ
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div >
    )
  }

}
