import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import './index.css';
import Root from './pages/root'
import Login from './pages/login'
import LoginWithLine from './pages/loginWithLine'
import Privacy from './pages/privacy'
import HomePatient from './pages/HomePatient'
import Doctor from './pages/Doctor'
import DoctorRisk from './pages/DoctorRisk'
import DoctorPatient from './pages/DoctorPatient'
import DoctorPatientQueue from './pages/DoctorPatientQueue'
import DashBoard from './pages/DashBoard'
import Register from './pages/register'
import RegisterLine from './pages/register-line'
import FirstForm from './pages/FirstForm'
import FormDailyAssessment from './pages/FormDailyAssessment'
import PatientQueue from './pages/PatientQueue'
import DoctorQueue from './pages/DoctorQueue'
import Discharge from './pages/Discharge'
import ManageUser from './pages/ManageUser'
import ManageUserPatent from './pages/ManageUserPatent'
import DoctorCertificate from './pages/DoctorCertificate'
import HistoryPatient from './pages/HistoryPatient'
import EditProfile from './pages/EditProfile'
import Main from './pages/Main'
import AddPatient4 from './pages/AddPatient4'
import DoctorQueueRisk4 from './pages/DoctorQueueRisk4'
import DoctorRiskDoctor from './pages/DoctorRiskDoctor'


import {
  createTheme,
  ThemeProvider
} from '@mui/material/styles';

export default function App() {
  let theme = createTheme({
    typography: {
      'fontFamily': `'Kanit', sans-serif`,
    },
    palette: {
      primary: {
        main: '#048967',
      },
      secondary: {
        main: '#E8FDF0',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Switch>
            <Route path="/login-with-line">
              <LoginWithLine />
            </Route>

            <Route path="/manageUser">
              <ManageUser />
            </Route>

            <Route path="/manageUserPatent">
              <ManageUserPatent />
            </Route>

            <Route path="/DoctorCertificate">
              <DoctorCertificate />
            </Route>

            <Route path="/register">
              <Register />
            </Route>


            <Route path="/register-line">
              <RegisterLine />
            </Route>


            <Route path="/login">
              <Login />
            </Route>


            <Route path="/privacy">
              <Privacy />
            </Route>

            <Route path="/home-patient">
              <HomePatient />
            </Route>

            <Route path="/doctor">
              <Doctor />
            </Route>

            <Route path="/dashboard">
              <DashBoard />
            </Route>


            <Route path="/doctor-risk">
              <DoctorRisk />
            </Route>

            <Route path="/doctor-h-risk">
              <DoctorRiskDoctor />
            </Route>

            <Route path="/risk4-doctor">
              <DoctorRisk />
            </Route>

            <Route path="/main">
              <Main />
            </Route>


            <Route path="/doctor-queue">
              <DoctorQueue />
            </Route>

            <Route path="/doctor-queue-risk4">
              <DoctorQueueRisk4 />
            </Route>

            <Route path="/patient/:userid/:round" component={DoctorPatient} />

            <Route path="/patient-queue/:userid/:round" component={DoctorPatientQueue} />

            <Route path="/DashBoard">
              <DashBoard />
            </Route>

            <Route path="/first-form">
              <FirstForm />
            </Route>

            <Route path="/form-daily-assessment">
              <FormDailyAssessment />
            </Route>

            <Route path="/patient-queue">
              <PatientQueue />
            </Route>

            <Route path="/discharge">
              <Discharge />
            </Route>

            <Route path="/history-patient">
              <HistoryPatient />
            </Route>

            <Route path="/edit-profile">
              <EditProfile />
            </Route>

            <Route path="/AddPatient4">
              <AddPatient4 />
            </Route>


            <Route path="/">
              <Root />
            </Route>

          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}
