import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useHistory } from "react-router-dom";
import Logo from '../images/logo/iseelogosmall.png'
import PersonIcon from '@mui/icons-material/Person';
const ROLE_MAP = {
  SHPH: 'รพสต',
  doctor: 'โรงพยาบาลแม่สอด',
  patient: 'ผู้ป่วย',
  admin: 'ผู้ดูแลระบบ',
  // โรงพยาบาล
}

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let history = useHistory();
  const [isLogin, setLogin] = React.useState(false)
  const [img_profile, setImageProfile] = React.useState(null)
  const [user_info, setUserInfo] = React.useState(null)
  const [menu, setMenu] = React.useState('-')
  React.useEffect(() => {
    let user = localStorage.getItem('user_wecaremaesot')
    let userdata = localStorage.getItem('user_data')
    let role = localStorage.getItem('role')
    if (user && userdata) {
      setLogin(true)
      let _data = JSON.parse(userdata)
      let _role = JSON.parse(role)
      setUserInfo({
        user: user, name: _data.firstname + ' ' + _data.lastname,
        key_role: _role[0],
        role: _data.hospital,
        rolename: ROLE_MAP[_role[0]],
      })
    }
    if (window?.location?.pathname) {

    }
  }, [])


  return (

    <div style={{
      position: 'fixed',
      zIndex: 1,
      height: 80,
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#fff',
      // backgroundColor: 'rgba(0, 0, 0, 0.4)',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      backdropFilter: 'blur(8px)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }}>
      <div style={{
        maxWidth: '1300px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 80,
        paddingLeft: 4,
        paddingRight: 4
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row'
        }}>
          <a href="/main"  >
            <img alt={`description`} src={Logo} width='80px' height='80px'></img>
          </a>


        </div>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <div style={{ flexDirection: 'column', flexDirection: 'column', display: 'flex', justifyContent: 'center', marginRight: 4 }}>
            <div style={{ fontSize: '16px', alignSelf: 'flex-end', marginLeft: 12 }} >{user_info?.name}</div>
            {
              user_info?.key_role === 'patient' ?
                <div style={{ fontSize: '14px', alignSelf: 'flex-end', marginLeft: 12 }} >{user_info?.rolename}</div> :
                <div style={{ fontSize: '14px', alignSelf: 'flex-end', marginLeft: 12 }} >{user_info?.role}</div>
            }
          </div>
          <div style={{ flexDirection: 'column', flexDirection: 'column', display: 'flex', justifyContent: 'center', marginRight: 4 }}>
            {/* <Avatar style={{ backgroundColor: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center' }} size={50} icon={<LogoutOutlined />} onClick={() => {
              localStorage.removeItem('user_data')
              localStorage.removeItem('img_profile')
              localStorage.removeItem('user')
              localStorage.removeItem('userdata')
              localStorage.removeItem('key')
              localStorage.removeItem('role')
              history.push('/login')
            }} /> */}
          </div>
        </div >
      </div >
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            style={{ margin: 0 }}
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 42, height: 42 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          user_info?.key_role !== 'admin' &&
          <MenuItem onClick={() => {
            history.push('/edit-profile')
          }}
          >
            <Avatar /> Profile
          </MenuItem>
        }
        <Divider />
        <MenuItem onClick={() => {
          localStorage.removeItem('user_data')
          localStorage.removeItem('img_profile')
          localStorage.removeItem('user')
          localStorage.removeItem('userdata')
          localStorage.removeItem('key')
          localStorage.removeItem('role')
          history.push('/login')
        }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}