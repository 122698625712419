const _ = require("lodash")
const moment = require("moment")


function getArrayNodeGraphql(array, addObj = {}) {
    let key = Object.keys(array.data)[0]
    let _array = array.data[key].edges.map(_edge => ({ ..._edge.node, ...addObj }))
    return _array
}

function getTimeLong(long) {
    let data = moment(long, 'x')
    return data.format('DD/MM') + '/' + (Number(data.format('YYYY')) + 543)
}

function diffday(date_atk1, date_atk2) {
    let data1 = moment(date_atk1, 'x')
    let data2 = moment(date_atk2, 'x')
    return data2.diff(data1, 'days')
}

function textAddr(item, prefix = "") {
    let { housenum,
        villageno,
        alley,
        lane,
        road,
        tumbonname,
        cityname,
        provincename } = item

    if (villageno) {
        villageno = villageno.replace(/\D/g, "")
    }
    let address = (housenum ? housenum + ' ' : '')
        + (villageno ? 'หมู่ที่ ' + villageno + ' ' : '')
        + (alley ? 'ตรอก' + alley + ' ' : '')
        + (lane ? 'ซอย' + lane + ' ' : '')
        + (road ? 'ถนน' + road + ' ' : '')
        + (tumbonname ? 'ต.' + tumbonname + ' ' : '')
        + (cityname ? 'อ.' + cityname + ' ' : '')
        + (provincename ? 'จ.' + provincename + ' ' : '')
    return (prefix + address)
}

export default {
    getArrayNodeGraphql,
    getTimeLong,
    diffday,
    textAddr
}
