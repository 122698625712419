import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import AddressData from "../../util/AddressData";
import encryption from '../../util/encryption';
// const encryption = require('./encryption')
// https://wecaremaesot.org/encryption-b
import api from '../../util/api';
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { Form, RadioForm, DatePicker, Space, Progress, Input, Select, Divider, Modal } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import moment from 'moment'
import SweetAlert from 'sweetalert2-react';
import { UserOutlined, FileDoneOutlined, BankOutlined, IdcardOutlined } from '@ant-design/icons';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const USER = {
  "user": "",
  "pass": "",
  "title": "",
  "firstname": "",
  "lastname": "",
  "certid": null,
  "hospital": "",
  "hospitalid": "",
  "role": "",
}


const TitleView = ({ title, text = '', icon }) => {
  return (
    <div className="flex flex-row mb-2 mt-2">
      <div style={{
        backgroundColor: '#999',
        height: 48,
        width: 48,
        borderRadius: 24,
        marginRight: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {icon}
      </div>
      <div className="flex flex-col">
        <p className="text-xl text-gray-400">{title}</p>
        <p className="text-lg">{text}</p>
      </div>
    </div>
  )
}
export default function Home(props) {
  let history = useHistory();
  const [user, setUser] = useState('');
  const [userInfo, setUserInfo] = useState({
    certid: "",
    firstname: "",
    hospital: "",
    hospital_code: "",
    lastname: "",
    title: "",

  });

  React.useEffect(() => {
    let userdata = localStorage.getItem('user_wecaremaesot')
    let user = localStorage.getItem('user')
    let role = localStorage.getItem('role')
    if (user && userdata) {
      // setLogin(true)
      let _data = JSON.parse(userdata)
      setUserInfo(_data)
      setUser(user)
      setDataShow({ user })
    }

  }, [])


  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  const [addUser, setAddUser] = useState({ ...USER });
  const OBJhospital = {}
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [dataShow, setDataShow] = useState({
    user: ''
  });

  const [dataEdit, setDataEdit] = useState({
    user: ''
  });

  const [pass, setPass] = useState({
    pass: '',
    confirmPass: ''
  });

  AddressData
    .hospitals
    .forEach(x => {
      OBJhospital[x.hospital_name] = x
    })



  return (<Layout ispadding>
    <SweetAlert
      show={show.show}
      title={show.title}
      text={show.message}
      onConfirm={() => {
        setShow({ ...show, ...{ show: false } })
      }}
    />
    <Modal
      title="รีเซ็ตพาสเวิร์ด"
      centered
      visible={visible}
      onOk={async () => {
        if (pass.pass.length < 6) return setAlert('โปรดใส่พาสเวิร์ดอย่างน้อย 6 ตัว')
        if (pass.pass !== pass.confirmPass) return setAlert('รหัสผ่านใหม่ไม่ตรงกัน')

        let _pass = await api.encryption(pass.pass)
        let pre_data = await api.query(`db/cores/user/query`, {
          query: { User: dataShow.user }
        })
        if (pre_data.length > 0) {
          pre_data[0].Pass_Salt = _pass.pass_salt
          pre_data[0].Pass_Hash = _pass.pass_hash
          console.log("pre_data", pre_data)
          await api.insert(`db/cores/user/data/${pre_data[0]._id}`, pre_data[0])
        }
        setVisible(false)
        setPass({
          pass: '',
          confirmPass: ''
        })
        setAlert('รีเซ็ตพาสเวิร์ดสำเร็จ')
      }}
      onCancel={() => {
        setVisible(false)
        setPass({
          pass: '',
          confirmPass: ''
        })
      }}
      width={800}
      okText={'บันทึก'}
      okButtonProps={{ style: { background: 'green' } }}
      cancelText={'ยกเลิก'}
    >
      <p>Username: {dataShow.user}</p>
      <p>Password: </p>
      <Input
        name="pass"
        type="password"
        placeholder="Password" style={{ width: 200, marginBottom: 12 }}
        value={pass.pass}
        onChange={(event) => {
          setPass({ ...pass, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
        }}
      />

      <p>Confirm Password: </p>
      <Input
        name="pass"
        type="password"
        placeholder="Password" style={{ width: 200, marginBottom: 12 }}
        value={pass.confirmPass}
        onChange={(event) => {
          setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
        }}
      />
      <p></p>
    </Modal>

    <Modal
      title="แก้ไขผู้ใช้งาน"
      centered
      visible={visibleEdit}
      onOk={async () => {
        if (!dataEdit.title) return setAlert('โปรดเลือกคำนำหน้า')
        if (!dataEdit.firstname) return setAlert('โปรดใส่ชื่อ')
        if (!dataEdit.lastname) return setAlert('โปรดใส่นามสกุล')
        if (!dataEdit.hospital) return setAlert('โปรดเลือกโรงพยาบาล/รพ.สต')
        let _data = await api.query('db/cores/role/query', {
          "aggregate": [
            {
              $match: {
                user: dataEdit.user,
              }
            }
          ]
        })
        let copy = JSON.parse(JSON.stringify(_data[0]))
        _data[0].profile.title = dataEdit.title
        _data[0].profile.firstname = dataEdit.firstname
        _data[0].profile.lastname = dataEdit.lastname
        _data[0].profile.certid = dataEdit.certid
        _data[0].profile.hospital = dataEdit.hospital
        _data[0].profile.hospital_code = dataEdit.hospital_code
        copy['_id'] = md5(`${new Date().getTime()}`)
        copy['time'] = new Date().toLocaleDateString()
        console.log("copy", copy)
        await api.insert(`db/cores/rolePrechange/data/${copy._id}`, copy)
        await api.insert(`db/cores/role/data/${_data[0]._id}`, _data[0])
        // updateChild(_data)
        setVisibleEdit(false)
        setDataEdit({ ...USER })
        setUserInfo(dataEdit);
        localStorage.setItem('user_wecaremaesot', JSON.stringify(_data[0].profile))
        localStorage.setItem('user_data', JSON.stringify(_data[0].profile))
        setAlert('แก้ไขผู้ใช้งานสำเร็จ')
      }}

      onCancel={() => {
        setDataEdit({ ...USER })
        setVisibleEdit(false)
      }}
      width={800}
      okText={'บันทึก'}
      okButtonProps={{ style: { background: 'green' } }}
      cancelText={'ยกเลิก'}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p>username: {dataEdit.user} </p>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> คำนำหน้า: *</p>
          <Select

            placeholder="เลือกคำนำหน้า"
            style={{ width: 120 }}
            onChange={(e) => {
              setDataEdit({ ...dataEdit, ...{ title: e } })
            }}
            value={dataEdit?.title}
          >
            <Option value="นายแพทย์">นายแพทย์</Option>
            <Option value="แพทย์หญิง">แพทย์หญิง</Option>
            <Option value="นาย">นาย</Option>
            <Option value="นาง">นาง</Option>
            <Option value="นางสาว">นางสาว</Option>
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> ชื่อ: </p>
          <Input
            placeholder="ชื่อ" style={{ width: 200, marginBottom: 12 }}
            value={dataEdit?.firstname}
            onChange={(event) => {
              setDataEdit({ ...dataEdit, ...{ firstname: `${event.target.value}`.replace(/\s/g, '') } })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> นามสกุล: *</p>
          <Input
            placeholder="นามสกุล"
            style={{ width: 200, marginBottom: 12 }}
            value={dataEdit?.lastname}
            onChange={(event) => {
              setDataEdit({ ...dataEdit, ...{ lastname: `${event.target.value}`.replace(/\s/g, '') } })
            }}
          />
        </div>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> โรงพยาบาล/รพ.สต.: *</p>
          <Select
            disabled
            placeholder="โปรดเลือกโรงพยาบาล/รพสต."
            style={{ width: 300 }}
            onChange={(e) => {
              setDataEdit({ ...dataEdit, ...{ hospital: e } })
            }}
            value={dataEdit?.hospital}
          >
            {/* <Option value="โรงพยาบาลแม่สอด">โรงพยาบาลแม่สอด</Option> */}
            {
              Object.keys(OBJhospital)
                .map(_x => (
                  <Option value={_x}>{_x}</Option>
                ))
            }
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> เลขที่ใบอนุญาต: </p>
          <Input
            value={dataEdit?.certid}
            placeholder="ระบุเลขที่ใบอนุญาต" style={{ width: 200, marginBottom: 12 }}
            onChange={(event) => {
              setDataEdit({ ...dataEdit, ...{ certid: event.target.value } })
            }}
          />
        </div>
      </div>


    </Modal>
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >

      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: 12,
          padding: "18px",
          display: "flex",
          width: "1200px",
          height: "500px",
          flexDirection: "column",
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <p className="text-3xl mb-4">Profile</p>
          <div>
            <button
              class="p-2 m-2 bg-yellow-500 w-[120px] rounded-md shadow text-white self-center"
              onClick={() => {
                setVisibleEdit(true)
                setDataEdit({
                  user: user,
                  certid: userInfo.certid,
                  firstname: userInfo.firstname,
                  hospital: userInfo.hospital,
                  hospital_code: userInfo.hospital_code,
                  lastname: userInfo.lastname,
                  title: userInfo.title,
                })
              }} >แก้ไขข้อมูล</button>
            <button
              class="p-2 m-2 bg-green-500 w-[120px] rounded-md shadow text-white self-center"
              onClick={() => {
                setVisible(true)
              }} >เปลี่ยนรหัสผ่าน</button>
          </div>
        </div>
        < Divider style={{ margin: 8 }} />
        < TitleView title={"username"} text={`${user}`} icon={<IdcardOutlined style={{ fontSize: '28px', color: '#fff' }} />} />
        < TitleView title={"ชื่อ-นานสกุล:"} text={`${userInfo.title}${userInfo.firstname} ${userInfo.lastname}`} icon={<UserOutlined style={{ fontSize: '28px', color: '#fff' }} />} />
        < TitleView title={"โรงพยาบาล:"} text={`${userInfo.hospital} (${userInfo.hospital_code})`} icon={<BankOutlined style={{ fontSize: '28px', color: '#fff' }} />} />
        < TitleView title={"เลขที่ใบอนุญาต:"} text={`${userInfo.certid} `} icon={<FileDoneOutlined style={{ fontSize: '28px', color: '#fff' }} />} />

      </div>
    </div>
  </Layout >)
}
