
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "./Pdfmake_js/vfs_fonts"
// import { getFormByCid, DOC_FORM_V3 } from '../../services/surveyServices'
// import { downloadImage } from '../../services/imageServices'
import moment from 'moment';
import util from '../../util';
import api from '../../util/api';
// _id
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var font = {
  THSarabun: {
    normal: 'THSarabun.ttf',
    bold: 'THSarabunBold.ttf',
    italics: 'THSarabunItalics.ttf'
  }
};

const textAddr = (item) => {
  let { housenum,
    villageno,
    alley,
    lane,
    road,
    tumbonname,
    cityname,
    provincename } = item

  if (villageno) {
    villageno = villageno.replace(/\D/g, "")
  }
  let address = (housenum ? housenum + ' ' : '')
    + (villageno ? 'หมู่ที่ ' + villageno + ' ' : '')
    + (alley ? 'ตรอก' + alley + ' ' : '')
    + (lane ? 'ซอย' + lane + ' ' : '')
    + (road ? 'ถนน' + road + ' ' : '')
    + (tumbonname ? 'ต.' + tumbonname + ' ' : '')
    + (cityname ? 'อ.' + cityname + ' ' : '')
    + (provincename ? 'จ.' + provincename + ' ' : '')
  return (address)
}

var call_value = async ({
  name = '',
  certid = '',
  host = '',
  array = [],
}) => {


  let tmpServicesConslude = []

  // array.forEach((_data, idx) => {
  for (let idx = 0; idx < array.length; idx++) {
    let _data = array[idx]
    let pre_data = await api.query(`db/homecare/gen/query`, {
      query: { _id: 'certificate' }
    })
    console.log(pre_data)
    pre_data[0].count = pre_data[0].count + 1
    await api.insert(`db/homecare/gen/data/${pre_data[0]._id}`, pre_data[0])
    console.log("pre_data", pre_data[0])

    let datetotal = 6
    // if (moment(_data.form_first.date_atk, "x").isBefore(moment("1655312400000", "x"))) {
    if (moment(_data.form_first.date_atk, "x").isBefore(moment("1655226000000", "x"))) {
      datetotal = 9
    }

    // let datetotal = util.diffday(_data.form_first.date_atk, _data.form_first.discharge_time)
    // util.diffday(_data.first_hlep, _data.form_first.discharge_time)
    // let datestart = util.getTimeLong(_data.first_hlep)
    // var new_date = moment(startdate, "DD-MM-YYYY").add(5, 'days');
    let datestart = util.getTimeLong(_data.form_first.date_atk)

    console.log("datestart", datestart)
    let dateend = util.getTimeLong(moment(_data.form_first.date_atk, "x").add(datetotal + 1, 'days').format('x'))
    console.log("datestart", moment(_data.form_first.date_atk, "x"))
    let addr = textAddr(_data.patient)
    let pid = _data.cid
    let namep = _data.name
    let dotname = ''
    let dotnamedoctor = ''
    let is_atk = _data?.form_first?.check_covid === 'ATK' ? true : false
    for (let i = 0; i < namep.length; i++) {
      dotname += '..'
    }
    for (let i = 0; i < name.length; i++) {
      dotnamedoctor += '..'
    }

    if (dotname.length <= 30) {
      dotname = "................................"
    }

    if (dotnamedoctor.length <= 30) {
      dotnamedoctor = "................................"
    }
    let age = _data.patient.age

    let _tmp = []
    if (is_atk) {

      _tmp = [
        {
          columns: [
            {
              text: ['ใบรับรองแพทย์'],
            },
          ],
          style: 'header'
        },
        {
          columns: [
            { text: 'เลขที่', },
            genDashLine(4, `${pre_data[0].count}/2022`, 30, 65, [1, 0, 0, 0], 'letf'),
          ],
          style: 'righttest'
        },
        {
          columns: [
            { width: 70, text: 'แพทย์ผู้ตรวจ', },
            genDashLine(4, `${name}`, 30, 200, [1, 0, 0, 0]),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 185, text: 'ผู้ประกอบวิชาชีพเวชกรรมใบอนุญาต เลขที่', },
            genDashLine(4, `${certid}`, 30, 70, [1, 0, 0, 0]),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 250, text: 'สถานที่ประกอบวิชาเวชกรรม หรือสถานที่ปฏิบัติงานประจำ', },
            genDashLine(4, `${host}`, 30, 170, [1, 0, 0, 0]),
          ],
          style: 'content'
        },

        {
          columns: [
            { text: `หนังสือรับรองฉบับนี้ ขอรับรองว่า`, alignment: 'center' },
          ],
          margin: [0, 24, 0, 6],
          fontSize: 16,
          bold: true,
        },
        {
          columns: [
            {
              text: [`ข้าพเจ้าแพทย์ผู้ตรวจผู้มีชื่อข้างต้นนี้ ได้ทำการตรวจร่างกายบุคคลดังต่อไปนี้`],
            },
          ],
          style: 'content'
        },
        {
          columns: [
            // { text: [`ชื่อ - สกุล ${namep} อายุ ${age} ปี`], },
            { width: 50, text: 'ชื่อ - สกุล', },
            genDashLine(4, `${namep}`, 30, 180, [1, 0, 0, 0]),
            { width: 20, text: 'อายุ', },
            genDashLine(4, `${age}`, 30, 30, [1, 0, 0, 0], 'center'),
            { text: 'ปี', },
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 85, text: 'บัตรประจำตัวเลขที่', },
            genDashLine(4, `${pid}`, 30, 100, [1, 0, 0, 0], 'center'),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 140, text: 'ณ โรงพยาบาลแม่สอด เมื่อวันที่', },
            genDashLine(0, `${datestart}`, 30, 80, [1, 0, 0, 0], 'center'),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 50, text: [`วินิจฉัยโรค`], },
            genDashLine(0, `COVID-19 infection (โดยวิธีการตรวจ COVID-19 Antigen Test / Self-Test Kits เมื่อที่ ${datestart})`, 30, 440, [1, 0, 0, 0], 'left'),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 155, text: [`สรุปความเห็น มารับการรักษาแบบ`], },
            genDashLine(0, `Home Isolation จริง`, 30, 120, [1, 0, 0, 0], 'center'),
          ],
          style: 'content'
        },

        {
          columns: [
            { width: 'auto', text: [`ตั้งแต่วันที่`], },
            genDashLine(0, `${datestart}`, 30, 90, [1, 0, 0, 0], 'center'),
            { width: 'auto', text: [`ถึงวันที่`], },
            genDashLine(0, `${dateend}`, 30, 90, [1, 0, 0, 0], 'center'),
            { width: 80, text: [`รวมเป็นระยะเวลา`], },
            genDashLine(0, `${datetotal + 1}`, 30, 30, [1, 0, 0, 0], 'center'),
            { width: 90, text: [`วัน ครบกำหนด`], },

            // {
            //   text: [`ตั้งแต่วันที่  ${datestart}  ถึงวันที่  ${dateend} รวมเป็นระยะเวลา ${datetotal + 1} วัน ครบกำหนดตามระยะเวลากักตัวแล้ว บุคคลดังกล่าวจึงสามารถประกอบอาชีพ ร่วมกิจกรรม หรือปฎิบัติภารกิจได้ตามปกติภายใต้มาตรการ DMHTT ต่อไป`],
            // },
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 500, text: [`ตามระยะเวลากักตัวแล้ว บุคคลดังกล่าวจึงสามารถประกอบอาชีพร่วมกิจกรรมหรือปฏิบัติภารกิจได้ตามปกติภายใต้ มาตรการ DMHTT ต่อไป`], },
          ],
          style: 'content'
        },
        {
          columns: [
            { text: `ใบรับรองฉบับนี้ออกให้เมื่อวันที่ ${moment().format('DD/MM')}/${Number(moment().format('YYYY')) + 543}` },
          ],
          margin: [0, 48, 0, 6],
          alignment: 'center',
          fontSize: 16
        },
        {
          columns: [
            { text: `ขอรับรองข้อความข้างต้นเป็นความจริง` },
          ],
          alignment: 'center',
          fontSize: 16,
        },
        {
          columns: [
            { width: 250, text: `ลงชื่อแพทย์ผู้ตรวจ`, alignment: 'right' },
            { text: `...${dotnamedoctor}` },
          ],
          fontSize: 16,
          margin: [0, 32, 0, 0,]
        },
        {
          columns: [
            { width: 250, text: ` ` },
            { width: 300, text: ` ( ${name} )` },
          ],
          fontSize: 16,
        },
        {
          columns: [
            { width: 250, text: `ลายมือชื่อผู้รับการตรวจ`, alignment: 'right' },
            { text: `...${dotname}` },
          ],
          fontSize: 16,
          margin: [0, 32, 0, 0,]
        },
        {
          columns: [
            { width: 250, text: ` ` },
            { width: 300, text: ` ( ${namep} )` },
          ],
          fontSize: 16,
        },
      ]

    } else {
      _tmp = [
        {
          columns: [
            {
              text: ['ใบรับรองแพทย์'],
            },
          ],
          style: 'header'
        },
        {
          columns: [
            { text: 'เลขที่', },
            genDashLine(4, `${pre_data[0].count}/2022`, 30, 65, [1, 0, 0, 0], 'letf'),
          ],
          style: 'righttest'
        },
        {
          columns: [
            { width: 70, text: 'แพทย์ผู้ตรวจ', },
            genDashLine(4, `${name}`, 30, 200, [1, 0, 0, 0]),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 185, text: 'ผู้ประกอบวิชาชีพเวชกรรมใบอนุญาต เลขที่', },
            genDashLine(4, `${certid}`, 30, 70, [1, 0, 0, 0]),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 250, text: 'สถานที่ประกอบวิชาเวชกรรม หรือสถานที่ปฏิบัติงานประจำ', },
            genDashLine(4, `${host}`, 30, 170, [1, 0, 0, 0]),
          ],
          style: 'content'
        },

        {
          columns: [
            { text: `หนังสือรับรองฉบับนี้ ขอรับรองว่า`, alignment: 'center' },
          ],
          margin: [0, 24, 0, 6],
          fontSize: 16,
          bold: true,
        },
        {
          columns: [
            {
              text: [`ข้าพเจ้าแพทย์ผู้ตรวจผู้มีชื่อข้างต้นนี้ ได้ทำการตรวจร่างกายบุคคลดังต่อไปนี้`],
            },
          ],
          style: 'content'
        },
        {
          columns: [
            // { text: [`ชื่อ - สกุล ${namep} อายุ ${age} ปี`], },
            { width: 50, text: 'ชื่อ - สกุล', },
            genDashLine(4, `${namep}`, 30, 180, [1, 0, 0, 0]),
            { width: 20, text: 'อายุ', },
            genDashLine(4, `${age}`, 30, 30, [1, 0, 0, 0], 'center'),
            { text: 'ปี', },
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 85, text: 'บัตรประจำตัวเลขที่', },
            genDashLine(4, `${pid}`, 30, 100, [1, 0, 0, 0], 'center'),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 140, text: 'ณ โรงพยาบาลแม่สอด เมื่อวันที่', },
            genDashLine(0, `${datestart}`, 30, 80, [1, 0, 0, 0], 'center'),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 50, text: [`วินิจฉัยโรค`], },
            genDashLine(0, `COVID-19 infection (พบผล detected ${datestart})`, 30, 250, [1, 0, 0, 0], 'left'),
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 155, text: [`สรุปความเห็น มารับการรักษาแบบ`], },
            genDashLine(0, `Home Isolation จริง`, 30, 120, [1, 0, 0, 0], 'center'),
          ],
          style: 'content'
        },

        {
          columns: [
            { width: 50, text: [`ตั้งแต่วันที่`], },
            genDashLine(0, `${datestart}`, 30, 90, [1, 0, 0, 0], 'center'),
            { width: 50, text: [`ถึงวันที่`], },
            genDashLine(0, `${dateend}`, 30, 90, [1, 0, 0, 0], 'center'),
            { width: 80, text: [`รวมเป็นระยะเวลา`], },
            genDashLine(0, `${datetotal + 1}`, 30, 30, [1, 0, 0, 0], 'center'),
            { width: 90, text: [`วัน ครบกำหนด`], },

            // {
            //   text: [`ตั้งแต่วันที่  ${datestart}  ถึงวันที่  ${dateend} รวมเป็นระยะเวลา ${datetotal + 1} วัน ครบกำหนดตามระยะเวลากักตัวแล้ว บุคคลดังกล่าวจึงสามารถประกอบอาชีพ ร่วมกิจกรรม หรือปฎิบัติภารกิจได้ตามปกติภายใต้มาตรการ DMHTT ต่อไป`],
            // },
          ],
          style: 'content'
        },
        {
          columns: [
            { width: 500, text: [`ตามระยะเวลากักตัวแล้ว บุคคลดังกล่าวจึงสามารถประกอบอาชีพร่วมกิจกรรมหรือปฏิบัติภารกิจได้ตามปกติภายใต้ มาตรการ DMHTT ต่อไป`], },
          ],
          style: 'content'
        },
        {
          columns: [
            { text: `ใบรับรองฉบับนี้ออกให้เมื่อวันที่ ${moment().format('DD/MM')}/${Number(moment().format('YYYY')) + 543}` },
          ],
          margin: [0, 48, 0, 6],
          alignment: 'center',
          fontSize: 16
        },
        {
          columns: [
            { text: `ขอรับรองข้อความข้างต้นเป็นความจริง` },
          ],
          alignment: 'center',
          fontSize: 16,
        },
        {
          columns: [
            { width: 250, text: `ลงชื่อแพทย์ผู้ตรวจ`, alignment: 'right' },
            { text: `...${dotnamedoctor}` },
          ],
          fontSize: 16,
          margin: [0, 32, 0, 0,]
        },
        {
          columns: [
            { width: 250, text: ` ` },
            { width: 300, text: ` ( ${name} )` },
          ],
          fontSize: 16,
        },
        {
          columns: [
            { width: 250, text: `ลายมือชื่อผู้รับการตรวจ`, alignment: 'right' },
            { text: `...${dotname}` },
          ],
          fontSize: 16,
          margin: [0, 32, 0, 0,]
        },
        {
          columns: [
            { width: 250, text: ` ` },
            { width: 300, text: ` ( ${namep} )` },
          ],
          fontSize: 16,
        },
      ]
    }

    if (idx + 1 !== array.length)
      _tmp.push(
        {
          columns: [
            { text: '', fontSize: 14, bold: true, pageBreak: 'before', margin: [0, 0, 0, 8] },
          ],
          style: 'subheader'
        },
      )

    // idx
    tmpServicesConslude = [
      ...tmpServicesConslude,
      ..._tmp
    ]

  }

  var docDefinition = {
    info: {
      title: 'รายชื่อผู้ป่วย',
      author: '-',
      subject: 'รายชื่อผู้ป่วย',
      keywords: 'รายชื่อผู้ป่วย',
    },
    defaultStyle: {
      font: 'THSarabun',
      fontSize: 14,
    },
    // pageOrientation: 'landscape',
    content: tmpServicesConslude,
    styles: {
      header: {
        fontSize: 20,
        bold: true,
        alignment: 'center',
        margin: [20, 0, 0, 30]
      },
      subheader2: {
        fontSize: 16,
        bold: true,
        margin: [30, 10, 10, 10]
      },
      subheader: {
        fontSize: 18,
        bold: true,
        // decoration: 'underline',
        margin: [0, 0, 0, 5]
      },
      content: {
        fontSize: 16,
        margin: [0, 0, 0, 5]
      },
      subcontent: {
        margin: [50, 0, 0, 5]
      },
      tableHeader: {
        bold: true,
        fontSize: 14,
      },
      tableExample: {
        margin: [0, 0, 0, 15]
      },
      paddingCaseNumber: {
        margin: [200, 0, 0, 0],
      },
      paddingFirstpage: {
        margin: [0, 160, 0, 0],
        fontSize: 36,
        bold: true
      },
      paddingTop: {
        margin: [0, 12, 0, 0],
      },
      paddingBottom: {
        margin: [0, 0, 0, 12],
      },
      right: {
        fontSize: 16,
        alignment: 'center',
        margin: [0, 0, 0, 4],
      },
      right1: {
        fontSize: 16,
        alignment: 'center',
        margin: [0, 0, 0, 4],
      },
      right2: {
        fontSize: 16,
        alignment: 'center',
        margin: [0, 14, 0, 4],
      },
      right3: {
        fontSize: 16,
        alignment: 'center',
        margin: [0, 20, 0, 4],
      },
      righttest: {
        fontSize: 16,
        alignment: 'right',
      }
    },
    // footer: {
    //   margin: [20, 0],
    //   columns: [
    //     {
    //       alignment: 'right',
    //       text: 'วันที่ออกรายงาน: ' + new Date().toLocaleString(),
    //     }
    //   ]
    // },
    // function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
  }
  pdfMake.fonts = font;
  pdfMake.createPdf(docDefinition).open();
  // pdfMake.createPdf(docDefinition).open(`รายงานผลประเมินอาการรายวันของ.pdf`);
  // pdfMake.createPdf(docDefinition).open();
  // IMG_CHILD = null

  // }
}



var PdfCertificateATK = (data) => {
  call_value(data)
    .then(res => {

    })
    .catch(err => {
      console.error(err);
    })
}
export default PdfCertificateATK


function genDashLine(locat, text, length, width, margin, alignment) {
  let dash = {
    stack: [{
      width: length,
      text: text,
      margin: [locat, 0, 0, 0],
      alignment,
    },
    {
      canvas: [{
        type: 'line',
        x1: 0,
        y1: 0,
        x2: width,
        y2: 0,
        lineWidth: 0.75,
        dash: {
          length: 1
        }
      }],
      margin: margin
    }
    ],
    width: width
  }
  return dash
}