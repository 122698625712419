import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import api from "../../util/api";
import { Divider } from "@mui/material";
import AddressData from "../../util/AddressData";
import Layout from '../../components/LayoutPC';
import SweetAlert from 'sweetalert2-react';
import md5 from 'md5';
import { checkSum } from "../../util/validatecid"
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid } from '@mui/material';
import { Spin, Checkbox, Select, DatePicker, Input, Steps, Calendar, Modal, Alert, Space, Row, Table, Upload, InputNumber } from 'antd';
import axios from "axios";
import { v4 } from "uuid";
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/th_TH';
import 'moment/locale/th';

let MONTH = {
  'ม.ค.': '01',
  'ก.พ.': '02',
  'มี.ค.': '03',
  'เม.ย.': '04',
  'พ.ค.': '05',
  'มิ.ย.': '06',
  'ก.ค.': '07',
  'ส.ค.': '08',
  'ก.ย.': '09',
  'ต.ค.': '10',
  'พ.ย.': '11',
  'ธ.ค.': '12'
}

let Holidays = {
  '1681318800000': true,
  '1681405200000': true,
  '1681664400000': true,
  '1683133200000': true,
  '1683219600000': true,
  '1684256400000': true,

  '1685898000000': true,
  '1690477200000': true,
  '1690822800000': true,
  '1690909200000': true,
  '1691946000000': true,
  '1697130000000': true,
  '1697994000000': true,
  '1701709200000': true,
  '1701709200000': true,
  '1702227600000': true,
}


let FIX_Cl = {
  '1': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '2': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 20 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '3': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '4': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '5': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ]
}


let defaultform = {
  "_id": null,
  "hash_cid": null,
  "cid": null,
  "screening_breast_cancer": false,
  "screening_fit_test": false,
  "screening_hpv_dna_test": false,
  "screening_hepatites": false,
  "results_breast_cancer": false,
  "results_breast_cancer_palpation_lump": false,
  "results_breast_cancer_other": false,
  "results_breast_cancer_other_text": null,
  "results_fit_test": false,
  "results_fit_test_text": null,
  "results_hpv_dna_test": false,
  "results_hpv_dna_test_type16": false,
  "results_hpv_dna_test_type18": false,
  "results_hpv_dna_test_nontype": false,
  "results_hpv_dna_test_abnormal_call": false,
  "results_hpv_dna_test_abnormal_call_text": null,
  "results_hepatites": false,
  "results_hepatites_HBsAg": false,
  "results_hepatites_anti_hcv": false,

  "workup_breast_cancer": false,
  "workup_breast_cancer_x_ray": false,
  "workup_breast_cancer_send_doctor": false,

  "workup_fit_test": false,
  "workup_fit_test_send_doctor": false,

  "workup_hpv_dna_test": false,
  "workup_hpv_dna_test_send_doctor": false,

  "workup_hepatites": false,
  "workup_hepatites_HBsAg": false,
  "workup_hepatites_lft_afp": false,
  "workup_hepatites_used_to_us": false,

  "workup_hepatites_anti_hcv": false,
  "workup_hepatites_anti_hcv_cbc_lft": false,

  "date_queue_patient": null,
  "status_queue": "wait_confirm_doctor",
  "date_queue_doctor": null,
  "date_queue_confirm": null,
  "fileList": [],
  "q_listfile": [],
  "result_listfile": []

}

let defaultuser = {
  "_id": null,
  "cid": null,
  "HN": null,
  "hash_cid": null,
  "list_vaccinate": [],
  "hospital": null,
  "hostid": null,
  "title": null,
  "firstname": null,
  "lastname": null,
  "updatetime": null,
  "host": null,
  // "dob": "1977-05-03",
  "dob": null,
  "tel": null,
  "line": null,
  "housenum": null,
  "villageno": null,
  "alley": null,
  "lane": null,
  "road": null,
  "provincename": 'ตาก',
  "cityname": null,
  "tumbonname": null,
  "postcode": null,
  "age": null,
  "hostmainid": "10723",
  "hostmain": "โรงพยาบาลแม่สอด",
  "url": "https://firebasestorage.googleapis.com/v0/b/hood-flashcard.appspot.com/o/image%20226.png?alt=media&token=aba9ed02-02aa-40b9-acd1-f82e536af46b",
}

let MAP_GENDER = {
  'นาง': 'นาง',
  'น.ส.': 'นางสาว',
  'นางสาว': 'นางสาว',
  'นาย': 'นาย',
  'เด็กชาย': 'เด็กชาย',
  'เด็กหญิง': 'เด็กหญิง',
  'ด.ช.': 'เด็กชาย',
  'ด.ญ.': 'เด็กหญิง',
}
function CountType({ isb, text, listPatient = [], total }) {
  let count = listPatient.filter(x => x.type === text).length
  let color = ''
  if (isb === false) {
    color = 'text-gray-500'
  } else if (count === 0) {
    color = "text-gray-500"
  } else if (count < total) {
    color = "text-green-500"
  } else {
    color = "text-red-500"
  }
  return <p style={{ fontSize: 12, color: isb ? 'black' : '#D3D3D3' }}>{`- ${text}  `}<span className={color}>{count}</span>{` / ${total} คน`}</p>
}
const columnsFile = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    key: "index",
    width: 20,
  },
  {
    title: "ชื่อไฟล์",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "โหลด",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          icon={<DownloadOutlined />}
          onClick={data.onDonwlaod}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "green",
            color: "white",
          }}
        >
          download
        </Button>
      </div >
    ),
    width: 140,
  },
  {
    title: "ลบ",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          onClick={data.onRemove}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "red",
            color: "white",
          }}
        >
          ลบ
        </Button>
      </div >
    ),
    width: 140,
  },
];

const { Option } = Select;

function dobScanpid(text) {
  let year = Number(`${text[0]}${text[1]}${text[2]}${text[3]}`)
  return `${year - 543}-${text[4]}${text[5]}-${text[6]}${text[7]}`
  // "1977-05-03"
}

function PageCheckCid({ setShow, show, setUser, user, nextPage }) {
  let [cid, setCid] = useState('')
  const _onChangeText = (e) => {
    const _value = e.target.value;
    setCid(_value)
  };
  return <div style={{ width: '1050px', height: '350px', padding: "40px" }}>
    <div className="w-full flex flex-col">
      <div className="w-[285px] flex self-center flex-col">
        <p className="text-md">เลขบัตรประชาชนของผู้คัดกรอง<span className="text-red-500">*</span></p>
        <Input
          size={'large'}
          style={{ width: '285px', marginBottom: '10px' }}
          maxLength={13}
          onChange={_onChangeText}
          placeholder=""
        />
        <div className="flex flex-row justify-between w-[285px]">
          <Button style={{ backgroundColor: '#2563EB', color: 'white', width: '140px' }}
            onClick={async () => {
              // let res = await api.checkPatientDoctor(cid)
              // let address = {}
              // if (res.user.data.result.patient.Address.length > 0) {
              //   address = res.user.data.result.patient.Address[0]
              // }
              // let dob = res.user.data.result.patient.birth_date
              // let patient = res.user.data.result.patient
              // let vaccine_certificate = res.user.data.result.vaccine_certificate[0].vaccination_list
              // let title = MAP_GENDER[patient.prefix]
              // if (!title) {
              //   title = patient.prefix
              // }
              // let firstname = patient.first_name
              // let lastname = patient.last_name
              // if (!lastname) {
              //   lastname = ""
              // }

              // let housenum = address.Address
              // let villageno = ''
              // let tumbonname = address.tmb_name
              // let cityname = address.amp_name
              // let provincename = address.chw_name


              let address = {}
              let dob = null
              let patient = null
              let vaccine_certificate = []
              let title = ""
              let firstname = ""
              let lastname = ""
              let housenum = null
              let villageno = null
              let tumbonname = null
              let cityname = null
              let provincename = null
              let list_vaccinate = null
              // let vaccinate = vaccine_certificate.length
              // let found_Data = true

              // let res = await api.checkPatientDoctor(cid)
              let _data = await api.query(`db/homecare/patient_risk/query`, {
                aggregate: [
                  {
                    $match: {
                      cid: cid
                    }
                  }
                ]
              })
              if (_data.length === 0) {
                nextPage({

                  "_id": cid,
                  "cid": cid,
                  "hash_cid": null,
                  "list_vaccinate": list_vaccinate,
                  "hospital": null,
                  "hostid": null,
                  "title": title,
                  "firstname": firstname,
                  "lastname": lastname,
                  "updatetime": null,
                  "host": null,
                  // "dob": "1977-05-03",
                  "dob": dob,
                  "tel": null,
                  "line": null,
                  "housenum": housenum,
                  "villageno": villageno,
                  "alley": "",
                  "lane": "",
                  "road": "",
                  "provincename": provincename,
                  "cityname": cityname,
                  "tumbonname": tumbonname,
                  // "postcode": _user.Moo,
                  "data_source": 'api',
                  "age": null,
                  "hostmainid": "10723",
                  "hostmain": "โรงพยาบาลแม่สอด",
                  "url": "https://firebasestorage.googleapis.com/v0/b/hood-flashcard.appspot.com/o/image%20226.png?alt=media&token=aba9ed02-02aa-40b9-acd1-f82e536af46b"
                })
              } else {
                return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'ผู้ป่วยคนนี้ได้ทำแบบคัดกรองโรคไม่ติดต่อเรื้อรังปี 2566 ไปแล้ว' } })
              }


            }}
          // checkPatientDoctor
          >
            ตรวจสอบ
          </Button>
          <Button
            onClick={() => {
              axios.get('https://localhost:8182/thaiid/read.jsonp?callback&section1=true&section2a=true')
                .then(res => {
                  let _user = JSON.parse(res.data.slice(13, res.data.length - 1))
                  if (_user === null) {
                    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'โปรดใส่บัตรประชาชนใหม่อีกครั้ง' } })
                  } else {
                    nextPage({
                      ...user, ...{
                        "_id": _user.CitizenNo,
                        "cid": _user.CitizenNo,
                        "hash_cid": null,
                        "list_vaccinate": [],
                        "hospital": null,
                        "hostid": null,
                        "title": _user.TitleNameTh,
                        "firstname": _user.FirstNameTh,
                        "lastname": _user.LastNameTh,
                        "updatetime": null,
                        "host": null,
                        // "dob": "1977-05-03",
                        "dob": dobScanpid(_user.BirthDate),
                        "tel": null,
                        "line": null,
                        "housenum": _user.HomeNo,
                        "villageno": `${_user.Moo} `.replace(/\D/g, ''),
                        "alley": _user.Trok,
                        "lane": _user.Soi,
                        "road": _user.Road,
                        "provincename": _user.Province,
                        "cityname": _user.Amphur,
                        "tumbonname": _user.Tumbol,
                        // "postcode": _user.Moo,
                        "age": null,
                        "hostmainid": "10723",
                        "hostmain": "โรงพยาบาลแม่สอด",
                        "url": "https://firebasestorage.googleapis.com/v0/b/hood-flashcard.appspot.com/o/image%20226.png?alt=media&token=aba9ed02-02aa-40b9-acd1-f82e536af46b",
                      }
                    })
                  }
                })
                .catch(err => {
                  return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'โปรดเปิดโปรแกรมอ่านบัตร' } })
                })
            }}

            style={{ backgroundColor: '#2563EB', color: 'white', width: '140px' }}>
            ตึงข้อมูลจากเลขบัตร
          </Button>
        </div>
      </div>
    </div>
  </div >
}

function PageCid({ setShow, show, setUserData, userData, nextPage, backPage }) {
  let [user, setUser] = useState({})
  let [list_hospital, setListHospital] = useState({})

  let year = []
  let day = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  let dayOfMonth = 31
  if (user.month) {
    let sufixMonth = user.month.slice(user.month.length - 2, user.month.length)
    if (sufixMonth == 'พ.' && (user.date === 30 || user.date === 31)) {
      dayOfMonth = 29
    } else if (['ย.'].includes(user.month) && (user.date === 31)) {
      dayOfMonth = 30
      setUser({ ...user, date: null })
    }

    if (sufixMonth == 'พ.') {
      dayOfMonth = 29
    } else if (['ย.'].includes(user.month)) {
      dayOfMonth = 30
    }
  }

  for (let i = 1; i <= dayOfMonth; i++) {
    day.push(i)
  }

  useEffect(() => {
    setUser({ ...userData })
  }, [userData])

  let list_city = {}
  let list_tumbon = {}

  AddressData
    .AddressData
    .forEach(x => {
      if (x.provincename === user.provincename) {
        list_city[x.cityname] = true
      }

      if (x.provincename === user.provincename && x.cityname === user.cityname) {
        list_tumbon[x.tumbonname] = true
      }
    })


  React.useEffect(async () => {
    let _list_hospital = {}
    AddressData.hospitals.forEach(x => {
      if (x.cityname == user.cityname && x.tumbonname == user.tumbonname && x.moo == user.villageno) {
        _list_hospital[x.hospital_name] = x
      }
    })

    if (Object.keys(_list_hospital).length === 0) {
      AddressData.hospitals.forEach(x => {
        if (x.cityname == user.cityname && x.tumbonname == user.tumbonname) {
          _list_hospital[x.hospital_name] = x
        }
      })
    }

    if (Object.keys(_list_hospital).length === 1) {
      let _hospital = _list_hospital[Object.keys(_list_hospital)[0]]
      if (_hospital) {
        setUser({
          ...user, ...{
            hostid: _hospital.hospital_code,
            host: _hospital.hospital_name,
            hostmainid: _hospital.hospital_main_code,
            hostmain: _hospital.hospital_main_name,
          }
        })
      }
    }

    setListHospital({ ..._list_hospital })

  }, [user.provincename, user.cityname, user.tumbonname, user.villageno])


  return <div className="mt-2">
    <Grid container spacing={2}>
      <Grid item xs={3} md={3}>
        <p className="text-xl blod"></p>
        ข้อมูลผู้ป่วย
      </Grid>
      <Grid container xs={9} md={9} spacing={1}>
        <Grid item xs={4}>
          <p>เลขบัตรประชาชน<span className="text-red-500">*</span></p>
          <Input
            disabled
            value={user.cid}
            style={{ width: '100%' }}
            placeholder=""
          />
        </Grid>
        <Grid item xs={4}>
          <p>HN</p>
          <Input
            value={user.HN}
            style={{ width: '100%' }}
            placeholder=""
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user,
                HN: _value
              })
            }}
          />
        </Grid>
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={4}>
          <p>วันเดือนปีเกิด</p>
          <div className="flex flex-row">
            <Select
              placeholder='วัดเกิด'
              value={user.day}
              style={{ width: 100, marginRight: 4 }}
              onChange={value => {
                setUser({
                  ...user,
                  day: value
                })
              }}
            >
              {
                day.map(x => (
                  <Option value={x}>{x}</Option>
                ))
              }

            </Select>

            <Select
              placeholder='เดือนเกิด'
              value={user.month}
              style={{ width: 100, marginRight: 4 }}
              onChange={value => {
                setUser({
                  ...user,
                  month: value
                })
              }}
            >
              {
                Object.keys(MONTH).map(x => (
                  <Option value={x}>{x}</Option>
                ))
              }

            </Select>

            <Select
              placeholder='ปีเกิด'
              value={user.year}
              style={{ width: 100, marginRight: 4 }}
              onChange={value => {
                setUser({
                  ...user,
                  year: value,
                  age: 2566 - Number(value)
                })
              }}
            >
              {
                year.map(x => (
                  <Option value={x}>{x}</Option>
                ))
              }

            </Select>

          </div>


        </Grid>

        <Grid item xs={2}>
          <p>อายุ</p>
          <InputNumber
            max={100}
            style={{ width: '100%' }}
            value={user.age}
            placeholder=""
            onChange={(_value) => {
              // const _value = e.target.value;
              setUser({
                ...user,
                age: _value
              })
            }}
          />
        </Grid>
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={3}>
          <p>คำนำหน้า<span className="text-red-500">*</span></p>
          <Select
            value={user.title}
            style={{ width: '100%' }}
            onChange={value => {
              setUser({
                ...user,
                title: value
              })
            }}
          >
            {
              [
                "นาง",
                "นางสาว",
                "นาย",
                "เด็กชาย",
                "เด็กหญิง",
              ]
                .map(x => (
                  <Option value={x}>{x}</Option>
                ))
            }
          </Select>
        </Grid>
        <Grid item xs={4}>
          <p>ชื่อ<span className="text-red-500">*</span></p>
          <Input
            // disabled
            value={user.firstname}
            style={{ width: '100%' }}
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user,
                firstname: _value
              })
            }}
          // placeholder=""
          />
        </Grid>
        <Grid item xs={4}>
          <p>นามสกุล<span className="text-red-500">*</span></p>
          <Input
            // disabled
            style={{ width: '100%' }}
            value={user.lastname}
            placeholder=""
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user,
                lastname: _value
              })
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <p>บ้านเลขที่<span className="text-red-500">*</span></p>
          <Input
            value={user.housenum}
            style={{ width: '100%' }}
            placeholder=""
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user,
                housenum: _value
              })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p>หมู่ที่(ถ้าไม่มีหมู่ไม่ต้องใส่)<span className="text-red-500">*</span> </p>
          <Input
            value={user.villageno}
            style={{ width: '100%' }}
            placeholder=""
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user,
                villageno: _value
              })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p>ถนน</p>
          <Input
            value={user.road}
            style={{ width: '100%' }}
            placeholder=""
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user,
                road: _value
              })
            }}

          />
        </Grid>

        <Grid item xs={4}>
          <p>จังหวัด<span className="text-red-500">*</span></p>
          <Select
            showSearch
            labelInValue
            value={user.provincename}
            style={{ width: '100%' }}
            onChange={({ value }) => {
              setUser({
                ...user, ...{
                  hostid: null,
                  host: null,
                  hostmainid: null,
                  hostmain: null,
                  provincename: value,
                  tumbonname: null,
                  cityname: null,
                }
              })
            }}
          >
            {
              AddressData
                .ThaiProvinces
                .map(x => (
                  <Option value={x}>{x}</Option>
                ))
            }

          </Select>
        </Grid>
        <Grid item xs={4}>
          <p>อำเภอ<span className="text-red-500">*</span></p>
          <Select
            labelInValue
            showSearch
            value={user.cityname}
            style={{ width: '100%' }}
            onChange={({ value }) => {
              setUser({
                ...user, ...{
                  hostid: null,
                  host: null,
                  hostmainid: null,
                  hostmain: null,
                  cityname: value,
                  tumbonname: null

                }
              })
            }}
          >
            {
              Object.keys(list_city)
                .map(x => (
                  <Option value={x}>{x}</Option>
                ))
            }
          </Select>
        </Grid>
        <Grid item xs={4}>
          <p>ตำบล<span className="text-red-500">*</span></p>
          <Select
            labelInValue
            showSearch
            value={user.tumbonname}
            style={{ width: '100%' }}
            onChange={({ value }) => {
              setUser({
                ...user, ...{
                  hostid: null,
                  host: null,
                  hostmainid: null,
                  hostmain: null,
                  tumbonname: value,
                }
              })
            }}
          >
            {
              Object.keys(list_tumbon)
                .map(x => (
                  <Option value={x}>{x}</Option>
                ))
            }
          </Select>
        </Grid>

        <Grid item xs={4}>
          <p>โรงพยาบาล<span className="text-red-500">*</span></p>
          <Select
            labelInValue
            value={user.host}
            style={{ width: '100%' }}
            onChange={({ value }) => {
              let _hospital = list_hospital[value]
              if (_hospital) {
                setUser({
                  ...user, ...{
                    hostid: _hospital.hospital_code,
                    host: _hospital.hospital_name,
                    hostmainid: _hospital.hospital_main_code,
                    hostmain: _hospital.hospital_main_name,
                  }
                })
              }
            }}
          >
            {
              Object.keys(list_hospital)
                .map(x => (
                  <Option value={x}>{x}</Option>
                ))
            }
          </Select>
        </Grid>

      </Grid>
    </Grid>
    < Divider class="m-4" />

    <Grid container spacing={2} style={{ marginTop: 2 }}>
      <Grid item xs={3} md={3}>
        <p className="text-xl blod"></p>
        ข้อมูลสำหรับติดต่อ
      </Grid>
      <Grid container xs={9} md={9}>
        <Grid item xs={4}>
          <p>เบอร์โทร<span className="text-red-500">*</span></p>
          <Input
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user, ...{
                  tel: _value
                }
              })
            }}
            maxLength={10}
            style={{ width: '95%' }}
            placeholder=""
            value={user.tel}
          />
        </Grid>
        <Grid item xs={4}>
          <p>Email</p>
          <Input
            onChange={(e) => {
              const _value = e.target.value;
              setUser({
                ...user, ...{
                  email: _value
                }
              })
            }}
            value={user.email}
            style={{ width: '95%' }}
            placeholder=""
          />
        </Grid>
      </Grid>
    </Grid>


    <Divider style={{ marginTop: 20 }} />
    <div class="container px-5 mt-[12px] mx-auto w-full flex flex-row">
      <div className="flex-row flex w-[340px] mx-auto mt-4">
        < button
          class="flex text-white mr-8 bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-800 rounded text-lg"
          onClick={() => {
            backPage()
          }}
        >
          {`< ย้อนกลับ`}
        </button>

        < button
          class="flex  text-white ml-8 bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-800 rounded text-lg"
          onClick={() => {

            if (!user.title) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ คำนำหน้า' } })
            if (!user.firstname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ ชื่อ' } })
            if (!user.lastname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ นามสกุล' } })
            if (!user.provincename) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ จังหวัด' } })
            if (!user.cityname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ อำเภอ' } })
            if (!user.tumbonname) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ ตำบล' } })
            if (!user.tel) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ เบอร์โทร' } })
            if (!user.housenum) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ บ้านเลขที่' } })
            if (Object.keys(list_hospital).length === 0) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'พื่นที่ของคุณยังไม่ให้บริการ' } })
            if (!user.host) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ โรงพยาบาล' } })
            nextPage({
              ...user
            })
          }}
        >
          {`ถัดไป >`}

        </button>
      </div>
    </div>
  </div >
}

function PageForm({ formData, nextPage, backPage, setShow, show }) {
  let [form, setForm] = useState({})
  useEffect(() => {
    setForm({ ...formData })
  }, [formData])

  const [fileList, setFileList] = useState([]);
  const [fileListUpload, setFileListUpload] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [isModalOpenFile, setIsModalOpenFile] = useState(false);
  const [ModalMessage, setModalMessage] = useState({
    show: false,
    title: "",
    message: "",
  });

  let onGenDataTable = (_data = []) => {
    let result = _data.map((x, i) => ({
      ...x, index: i + 1,
      onRemove: () => {
        const newFileList = fileList.slice();
        newFileList.splice(i, 1)
        setFileList([...newFileList])
      },
      onDonwlaod: (d) => {
        fetch(`https://screening.wecaremaesot.org/minio/download/test/c53cad01fbf04b36b60da64062856e35`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            console.log("response: ", response)
            return response.blob()
          })
          .then((blob) => {
            // const url = window.URL.createObjectURL(
            //   new Blob([blob], { type: 'application/pdf' }),
            // );
            // const link = document.createElement('a');
            // link.href = url;
            // link.download = x.name;
            // link.click();
            // link.parentNode.removeChild(link);
            const url = window.URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.download = x.name;
            link.click();
            // link.parentNode.removeChild(link);
            return "ok";
          });
      }
    }))
    return result
  }


  const onChange = (e) => {
    if (e.target.name === 'results_breast_cancer' && e.target.checked === false) {
      form['results_breast_cancer_palpation_lump'] = false
      form['results_breast_cancer_other'] = false
      form['results_breast_cancer_other_text'] = null
    }

    if (e.target.name === 'results_fit_test' && e.target.checked === false) {
      form['results_fit_test_text'] = null
    }

    if (e.target.name === 'results_hpv_dna_test' && e.target.checked === false) {
      form['results_hpv_dna_test_type16'] = false
      form['results_hpv_dna_test_type18'] = false
      form['results_hpv_dna_test_nontype'] = false
      form['results_hpv_dna_test_abnormal_call'] = false
      form['results_hpv_dna_test_abnormal_call_text'] = null
    }

    if (e.target.name === 'results_hpv_dna_test_abnormal_call' && e.target.checked === false) {
      form['results_hpv_dna_test_abnormal_call_text'] = null
    }

    if (e.target.name === 'workup_breast_cancer' && e.target.checked === false) {
      form['workup_breast_cancer_x_ray'] = false
      form['workup_breast_cancer_send_doctor'] = false
    }


    if (e.target.name === 'workup_fit_test' && e.target.checked === false) {
      form['workup_fit_test_send_doctor'] = false
    }

    if (e.target.name === 'workup_hpv_dna_test' && e.target.checked === false) {
      form['workup_hpv_dna_test_send_doctor'] = false
    }

    if (e.target.name === 'workup_hepatites' && e.target.checked === false) {
      form['workup_hepatites_HBsAg'] = false
      form['workup_hepatites_lft_afp'] = false
      form['workup_hepatites_used_to_us'] = false
      form['workup_hepatites_anti_hcv'] = false
      form['workup_hepatites_anti_hcv_cbc_lft'] = false

    }


    form[e.target.name] = e.target.checked
    setForm({ ...form });
  };

  const handleUpload = async () => {
    let listDocFile = []
    const formData = new FormData();
    fileListUpload.forEach((file) => {
      formData.append('files[]', file);
    });
    setUploading(true);

    for (let i = 0; i < fileListUpload.length; i++) {
      let filename = v4().toString().replace(/-/g, "");
      let url = `https://screening.wecaremaesot.org/minio/download/test/${filename}`
      listDocFile.push({
        path: '',
        bucket: 'test',
        name: fileListUpload[i].name,
        nameMinio: filename,
      })
      let urlForPut = await axios.post(url)
      let res = await axios.put(urlForPut.data, fileListUpload[i])
    }
    setFileList([...fileList, ...listDocFile])
    setUploading(false)
    setIsModalOpenFile()
    setIsModalOpenFile(false)
    setFileListUpload([])
  };

  return <div className="mt-2 w-[1200px]">
    <Modal
      title="อัพโหลดไฟล์"
      centered
      okText={"เพิ่ม"}
      cancelText={"ปิด"}
      width={800}
      okButtonProps={{ style: { background: "green" } }}
      open={isModalOpenFile}
      maskClosable={false}
      onOk={async () => {
        try {
          if (!uploading) {
            handleUpload()
          } else {
            alert('กำลังอัพโหลดข้อมูลอยู่')
          }
        } catch (err) { }
      }}
      onCancel={() => {
        if (!uploading) {
          setIsModalOpenFile(false)
        } else {
          alert('กำลังอัพโหลดข้อมูลอยู่')
        }
      }}
    >
      <Spin tip="upload..." spinning={uploading}>
        <Upload
          onRemove={(file) => {
            const index = fileListUpload.indexOf(file);
            const newFileList = fileListUpload.slice();
            newFileList.splice(index, 1);
            setFileListUpload(newFileList);
          }}
          beforeUpload={(file) => {
            setFileListUpload([...fileListUpload, file])
            return false;
          }}
          fileList={fileListUpload}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Spin>
    </Modal>

    <Grid container spacing={1}>
      <Grid item xs={3} style={{ padding: 0 }}>
        <p className="ml-5 mt-0 pt-0 text-lg blod">การคัดกรอง</p>
      </Grid>
      <Grid container xs={9} md={9} spacing={0}>
        <Grid item xs={12}>
          <Checkbox name="screening_breast_cancer" checked={form.screening_breast_cancer} style={{ fontSize: '16px' }}
            onChange={() => {
              setForm({
                ...form,
                "screening_breast_cancer": true,
                "screening_fit_test": false,
                "screening_hpv_dna_test": false,
                "screening_hepatites": false,
                "results_breast_cancer": false,
                "results_breast_cancer_palpation_lump": false,
                "results_breast_cancer_other": false,
                "results_breast_cancer_other_text": null,
                "results_fit_test": false,
                "results_fit_test_text": null,
                "results_hpv_dna_test": false,
                "results_hpv_dna_test_type16": false,
                "results_hpv_dna_test_type18": false,
                "results_hpv_dna_test_nontype": false,
                "results_hpv_dna_test_abnormal_call": false,
                "results_hpv_dna_test_abnormal_call_text": null,
                "results_hepatites": false,
                "results_hepatites_HBsAg": false,
                "results_hepatites_anti_hcv": false,

                "workup_breast_cancer": false,
                "workup_breast_cancer_x_ray": false,
                "workup_breast_cancer_send_doctor": false,

                "workup_fit_test": false,
                "workup_fit_test_send_doctor": false,

                "workup_hpv_dna_test": false,
                "workup_hpv_dna_test_send_doctor": false,

                "workup_hepatites": false,
                "workup_hepatites_HBsAg": false,
                "workup_hepatites_lft_afp": false,
                "workup_hepatites_used_to_us": false,

                "workup_hepatites_anti_hcv": false,
                "workup_hepatites_anti_hcv_cbc_lft": false,

                "date_queue_patient": null,
                "status_queue": "wait_confirm_doctor",
                "date_queue_doctor": null,
                "date_queue_confirm": null,
              });
            }}>
            มะเร็งเต้านม
          </Checkbox>

          <Checkbox name="screening_fit_test" checked={form.screening_fit_test} style={{ fontSize: '16px' }}
            onChange={() => {
              setForm({
                ...form,
                "screening_breast_cancer": false,
                "screening_fit_test": true,
                "screening_hpv_dna_test": false,
                "screening_hepatites": false,
                "results_breast_cancer": false,
                "results_breast_cancer_palpation_lump": false,
                "results_breast_cancer_other": false,
                "results_breast_cancer_other_text": null,
                "results_fit_test": false,
                "results_fit_test_text": null,
                "results_hpv_dna_test": false,
                "results_hpv_dna_test_type16": false,
                "results_hpv_dna_test_type18": false,
                "results_hpv_dna_test_nontype": false,
                "results_hpv_dna_test_abnormal_call": false,
                "results_hpv_dna_test_abnormal_call_text": null,
                "results_hepatites": false,
                "results_hepatites_HBsAg": false,
                "results_hepatites_anti_hcv": false,

                "workup_breast_cancer": false,
                "workup_breast_cancer_x_ray": false,
                "workup_breast_cancer_send_doctor": false,

                "workup_fit_test": false,
                "workup_fit_test_send_doctor": false,

                "workup_hpv_dna_test": false,
                "workup_hpv_dna_test_send_doctor": false,

                "workup_hepatites": false,
                "workup_hepatites_HBsAg": false,
                "workup_hepatites_lft_afp": false,
                "workup_hepatites_used_to_us": false,

                "workup_hepatites_anti_hcv": false,
                "workup_hepatites_anti_hcv_cbc_lft": false,

                "date_queue_patient": null,
                "status_queue": "wait_confirm_doctor",
                "date_queue_doctor": null,
                "date_queue_confirm": null,
              });
            }}>
            Fit Test
          </Checkbox>


          <Checkbox name="screening_hpv_dna_test" checked={form.screening_hpv_dna_test} style={{ fontSize: '16px' }}
            onChange={() => {
              setForm({
                ...form,
                "screening_breast_cancer": false,
                "screening_fit_test": false,
                "screening_hpv_dna_test": true,
                "screening_hepatites": false,
                "results_breast_cancer": false,
                "results_breast_cancer_palpation_lump": false,
                "results_breast_cancer_other": false,
                "results_breast_cancer_other_text": null,
                "results_fit_test": false,
                "results_fit_test_text": null,
                "results_hpv_dna_test": false,
                "results_hpv_dna_test_type16": false,
                "results_hpv_dna_test_type18": false,
                "results_hpv_dna_test_nontype": false,
                "results_hpv_dna_test_abnormal_call": false,
                "results_hpv_dna_test_abnormal_call_text": null,
                "results_hepatites": false,
                "results_hepatites_HBsAg": false,
                "results_hepatites_anti_hcv": false,

                "workup_breast_cancer": false,
                "workup_breast_cancer_x_ray": false,
                "workup_breast_cancer_send_doctor": false,

                "workup_fit_test": false,
                "workup_fit_test_send_doctor": false,

                "workup_hpv_dna_test": false,
                "workup_hpv_dna_test_send_doctor": false,

                "workup_hepatites": false,
                "workup_hepatites_HBsAg": false,
                "workup_hepatites_lft_afp": false,
                "workup_hepatites_used_to_us": false,

                "workup_hepatites_anti_hcv": false,
                "workup_hepatites_anti_hcv_cbc_lft": false,

                "date_queue_patient": null,
                "status_queue": "wait_confirm_doctor",
                "date_queue_doctor": null,
                "date_queue_confirm": null,
              });
            }}>
            HPV DNA test
          </Checkbox>

          <Checkbox name="screening_hepatites" checked={form.screening_hepatites} style={{ fontSize: '16px' }}
            onChange={() => {
              setForm({
                ...form,
                "screening_breast_cancer": false,
                "screening_fit_test": false,
                "screening_hpv_dna_test": false,
                "screening_hepatites": true,
                "results_breast_cancer": false,
                "results_breast_cancer_palpation_lump": false,
                "results_breast_cancer_other": false,
                "results_breast_cancer_other_text": null,
                "results_fit_test": false,
                "results_fit_test_text": null,
                "results_hpv_dna_test": false,
                "results_hpv_dna_test_type16": false,
                "results_hpv_dna_test_type18": false,
                "results_hpv_dna_test_nontype": false,
                "results_hpv_dna_test_abnormal_call": false,
                "results_hpv_dna_test_abnormal_call_text": null,
                "results_hepatites": false,
                "results_hepatites_HBsAg": false,
                "results_hepatites_anti_hcv": false,

                "workup_breast_cancer": false,
                "workup_breast_cancer_x_ray": false,
                "workup_breast_cancer_send_doctor": false,

                "workup_fit_test": false,
                "workup_fit_test_send_doctor": false,

                "workup_hpv_dna_test": false,
                "workup_hpv_dna_test_send_doctor": false,

                "workup_hepatites": false,
                "workup_hepatites_HBsAg": false,
                "workup_hepatites_lft_afp": false,
                "workup_hepatites_used_to_us": false,

                "workup_hepatites_anti_hcv": false,
                "workup_hepatites_anti_hcv_cbc_lft": false,

                "date_queue_patient": null,
                "status_queue": "wait_confirm_doctor",
                "date_queue_doctor": null,
                "date_queue_confirm": null,
              });
            }}>
            ไวรัสตับอักเสบ
          </Checkbox>

        </Grid>
      </Grid>
    </Grid>
    < Divider class="m-4" />

    <Grid container spacing={1}>
      <Grid item xs={3} style={{ padding: 0 }}>
        <p className="ml-5 mt-0 pt-0 text-lg blod">ผลการตรวจ</p>
      </Grid>
      <Grid container xs={9} md={9}>
        {
          form.screening_breast_cancer &&
          <Grid item xs={12}>
            <div>
              <Checkbox name="results_breast_cancer" checked={form.results_breast_cancer} style={{ fontSize: '16px' }} onChange={onChange}>
                มะเร็งเต้านม:
              </Checkbox>
              <div className="ml-8 mt-1 mb-2 flex flex-col">
                <div>
                  <Checkbox
                    name="results_breast_cancer_palpation_lump"
                    disabled={!form.results_breast_cancer}
                    checked={form.results_breast_cancer_palpation_lump} style={{ fontSize: '14px' }}
                    onChange={onChange}
                  >
                    คลำพบก้อน
                  </Checkbox>
                </div>
                <div className="flex flex-row">
                  <Checkbox
                    disabled={!form.results_breast_cancer}
                    name="results_breast_cancer_other" checked={form.results_breast_cancer_other} style={{ fontSize: '14px' }} onChange={onChange}>
                    อื่นๆ
                  </Checkbox>
                  <Input
                    onChange={(e) => {
                      const _value = e.target.value;
                      setForm({
                        ...form, ...{
                          results_breast_cancer_other_text: _value
                        }
                      })
                    }}
                    disabled={!form.results_breast_cancer_other}
                    value={form.results_breast_cancer_other_text}
                    style={{ width: '200px' }}
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </Grid>
        }
        {
          form.screening_fit_test &&
          <Grid item xs={12}>
            <Checkbox name="results_fit_test" checked={form.results_fit_test} style={{ fontSize: '16px' }} onChange={onChange}>
              Fit test:
            </Checkbox>
            <Input
              onChange={(e) => {
                const _value = e.target.value;
                setForm({
                  ...form, ...{
                    results_fit_test_text: _value
                  }
                })
              }}
              value={form.results_fit_test_text}
              disabled={!form.results_fit_test}
              style={{ width: '100px', marginBottom: 8 }}
              placeholder=""
              suffix="+Ve"
            />
          </Grid>
        }
        {
          form.screening_hpv_dna_test &&
          <Grid item xs={12}>
            <Checkbox name="results_hpv_dna_test" checked={form.results_hpv_dna_test} style={{ fontSize: '16px' }} onChange={onChange}>
              HPV DNA test:
            </Checkbox>
            <div className="ml-8 mt-1 mb-2 flex flex-col">
              <div>
                <Checkbox
                  name="results_hpv_dna_test_type16"
                  disabled={!form.results_hpv_dna_test}
                  checked={form.results_hpv_dna_test_type16} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  Type 16
                </Checkbox>
                <Checkbox
                  name="results_hpv_dna_test_type18"
                  disabled={!form.results_hpv_dna_test}
                  checked={form.results_hpv_dna_test_type18} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  Type 18
                </Checkbox>
              </div>

              <div>
                <Checkbox
                  name="results_hpv_dna_test_nontype"
                  disabled={!form.results_hpv_dna_test}
                  checked={form.results_hpv_dna_test_nontype} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  Non Type 16 18
                </Checkbox>
                <Checkbox
                  name="results_hpv_dna_test_abnormal_call"
                  disabled={!form.results_hpv_dna_test}
                  checked={form.results_hpv_dna_test_abnormal_call} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  call ผิดปกติอื่นๆ
                </Checkbox>
                <Input
                  disabled={!form.results_hpv_dna_test_abnormal_call}
                  onChange={(e) => {
                    const _value = e.target.value;
                    setForm({
                      ...form, ...{
                        results_hpv_dna_test_abnormal_call_text: _value
                      }
                    })
                  }}
                  value={form.results_hpv_dna_test_abnormal_call_text}
                  style={{ width: '200px' }}
                  placeholder=""
                />
              </div>
            </div>
          </Grid>
        }

        {
          form.screening_hepatites &&
          <Grid item xs={12}>
            <Checkbox name="results_hepatites" checked={form.results_hepatites} style={{ fontSize: '16px' }} onChange={onChange}>
              ไวรัสตับอักเสบ:
            </Checkbox>
            <div className="ml-8 mt-1 mb-2 flex flex-col">
              <div>
                <Checkbox
                  name="results_hepatites_HBsAg"
                  disabled={!form.results_hepatites}
                  checked={form.results_hepatites_HBsAg} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  HBsAg positive
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  name="results_hepatites_anti_hcv"
                  disabled={!form.results_hepatites}
                  checked={form.results_hepatites_anti_hcv} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  Anti HCV Positive
                </Checkbox>
              </div>
            </div>
          </Grid>
        }

      </Grid>
    </Grid>


    < Divider class="m-4" />

    <Grid container spacing={1}>
      <Grid item xs={3} style={{ padding: 0 }}>
        <p className="ml-5 mt-0 pt-0 text-lg blod">Work up</p>
      </Grid>
      <Grid container xs={9} md={9}>

        {
          form.screening_breast_cancer &&
          <Grid item xs={12}>
            <Checkbox name="workup_breast_cancer" checked={form.workup_breast_cancer} style={{ fontSize: '16px' }} onChange={onChange}>
              มะเร็งเต้านม:
            </Checkbox>
            <div className="ml-8 mt-1 mb-2 flex flex-col">
              <div>
                <Checkbox
                  name="workup_breast_cancer_x_ray"
                  disabled={!form.workup_breast_cancer}
                  checked={form.workup_breast_cancer_x_ray} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  กรณีมีแพทย์ศัลนกรรมต้องการ Ultrasound สามารถนัดที่ห้อง X-ray ได้เลย
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  name="workup_breast_cancer_send_doctor"
                  disabled={!form.workup_breast_cancer}
                  checked={form.workup_breast_cancer_send_doctor} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  ส่งพบแพทย์
                </Checkbox>
              </div>
            </div>
          </Grid>
        }


        {
          form.screening_fit_test &&
          <Grid item xs={12}>
            <Checkbox name="workup_fit_test" checked={form.workup_fit_test} style={{ fontSize: '16px' }} onChange={onChange}>
              Fit test:
            </Checkbox>
            <div className="ml-8 mt-1 mb-2 flex flex-col">
              <div>
                <Checkbox
                  name="workup_fit_test_send_doctor"
                  disabled={!form.workup_fit_test}
                  checked={form.workup_fit_test_send_doctor} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  ส่งพบแพทย์
                </Checkbox>
              </div>
            </div>
          </Grid>
        }

        {
          form.screening_hpv_dna_test &&
          <Grid item xs={12}>
            <Checkbox name="workup_fit_test" checked={form.workup_hpv_dna_test} style={{ fontSize: '16px' }} onChange={onChange}>
              HPV DNA test:
            </Checkbox>
            <div className="ml-8 mt-1 mb-2 flex flex-col">
              <div>
                <Checkbox
                  name="workup_hpv_dna_test_send_doctor"
                  disabled={!form.workup_hpv_dna_test}
                  checked={form.workup_hpv_dna_test_send_doctor} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  ส่งพบแพทย์
                </Checkbox>
              </div>
            </div>
          </Grid>
        }

        {
          form.screening_hepatites &&
          <Grid item xs={12}>
            <Checkbox name="workup_hepatites" checked={form.workup_hepatites} style={{ fontSize: '16px' }} onChange={onChange}>
              ไวรัสตับอักเสบ:
            </Checkbox>
            <div className="ml-8 mt-1 mb-2 flex flex-col">
              <div>
                <Checkbox
                  name="workup_hepatites_HBsAg"
                  disabled={!form.workup_hepatites}
                  checked={form.workup_hepatites_HBsAg} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  HBsAg positive
                </Checkbox>
                <Checkbox
                  name="workup_hepatites_lft_afp"
                  disabled={!form.workup_hepatites}
                  checked={form.workup_hepatites_lft_afp} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  เจาะ LFT AFP
                </Checkbox>
                <Checkbox
                  name="workup_hepatites_used_to_us"
                  disabled={!form.workup_hepatites}
                  checked={form.workup_hepatites_used_to_us} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  เคย US
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  name="workup_hepatites_anti_hcv"
                  disabled={!form.workup_hepatites}
                  checked={form.workup_hepatites_anti_hcv} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  Anti HCV Positive
                </Checkbox>
                <Checkbox
                  name="workup_hepatites_anti_hcv_cbc_lft"
                  disabled={!form.workup_hepatites}
                  checked={form.workup_hepatites_anti_hcv_cbc_lft} style={{ fontSize: '14px' }}
                  onChange={onChange}
                >
                  Anti HIV CBC LFT
                </Checkbox>
              </div>
            </div>
          </Grid>
        }


      </Grid>
    </Grid>

    <Divider style={{ margin: 0, padding: 0 }} />
    <div className=" bg-white p-[24px] mt-8 rounded-xl">
      <p style={{ fontSize: 24 }}>ไฟล์</p>
      <Row>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Table
            dataSource={onGenDataTable(fileList)}
            footer={() => {
              // =>
              return (
                <Button
                  onClick={() => setIsModalOpenFile(true)}
                  style={{ backgroundColor: "green", color: "#fff" }}>
                  เพิ่มไฟล์
                </Button>
              );
            }}
            bordered
            size="middle"
            columns={columnsFile}
            line
          />
        </div>
      </Row>
    </div>

    < Divider class="m-4" />
    <div class="container px-5 mt-[12px] mx-auto w-full flex flex-row">
      <div className="flex-row flex w-[340px] mx-auto">
        < button
          class="flex text-white mr-8 bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-800 rounded text-lg"
          onClick={() => {
            backPage()
          }}
        >
          {`< ย้อนกลับ`}
        </button>

        < button
          class="flex  text-white ml-8 bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-800 rounded text-lg"
          onClick={() => {
            if (
              (form.screening_breast_cancer ||
                form.screening_fit_test ||
                form.screening_hpv_dna_test ||
                form.screening_hepatites) === false
            ) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาเลือกโรคที่ค้องการคัดกรอง' } })
            nextPage({
              ...form,
              fileList
            })
          }}
        >
          {`ถัดไป >`}
        </button>
      </div>
    </div>
  </div >
}

function PageQueue({ formData, userData, nextPage, backPage, setShow, show }) {
  let history = useHistory();


  let [typesurvey, setTypesurvey] = useState(null)

  const [modalTest, setModalTest] = React.useState({
    show: false
  });

  const [modalTest2, setModalTest2] = React.useState({
    show: false
  });

  let [dataModal, setDataModal] = React.useState({

  })

  let [ModalDataDay, setModaDatalDay] = React.useState({
    listDay: [],
    amount: 0,
    text: '',
    date: null
  })

  let count_total = 0
  let textType = ''
  const [DataCalendar, setCalendar] = useState({})
  const dateCellRender = (value) => {
    value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    let isb = moment().add(-1, "days").isBefore(value)
    // isb = true
    let listData = {
      amount: 0,
      data: [],
      listPatient: [],
      date: value.format('x')
    }
    let isNotAddEvent = false
    if (DataCalendar[`${value.format('x')}`]) {
      listData = DataCalendar[`${value.format('x')}`]
      isNotAddEvent = true
    }
    let day = value.day();
    let content = null
    let stop = false
    if (Holidays[`${value.format('x')}`]) {
      stop = true
      content = <>
        <p style={{ width: '100%', fontSize: 12, color: isb ? 'black' : '#D3D3D3', display: 'flex', justifyContent: 'center', paddingTop: 20 }}>วันหยุด</p>
      </>
    } else if (day !== 0 && day !== 6 && day !== 2) {
      content = <>
        <CountType isb={isb} text={'มะเร็งเต้านม'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งปากมดลูก'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งลำไส้'} listPatient={listData?.listPatient} total={10} />
      </>
    } else if (day == 2) {
      content = <>
        <CountType isb={isb} text={'มะเร็งเต้านม'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งปากมดลูก'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งลำไส้'} listPatient={listData?.listPatient} total={20} />
      </>
    } else {
      stop = true
      content = <>
        <p style={{ width: '100%', fontSize: 12, color: isb ? 'black' : '#D3D3D3', display: 'flex', justifyContent: 'center', paddingTop: 20 }}>วันหยุด</p>
      </>
    }
    if (listData?.listPatient) {
      if (formData.screening_breast_cancer) {
        textType = 'มะเร็งเต้านม'
      } else if (formData.screening_hpv_dna_test) {
        textType = 'มะเร็งปากมดลูก'
      } else if (formData.screening_fit_test) {
        textType = 'มะเร็งลำไส้'
      }
      count_total = listData?.listPatient.filter(x => x.type === textType).length
    }
    return (
      <div className="w-full h-full" onClick={() => {
        if (!isb) {
          return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถเลือกวันหลังจากวันที่ ${moment().add(0, "days").format("LL")}` } })
        }
        else {

          if (stop) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${value.format('LL')} เป็นวันหยุด` } })
          } else if (textType === 'มะเร็งลำไส้') {
            if (listData?.listPatient.filter(x => x.type === textType).length >= 20 && value.day() == 2) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถลงนัดวันที่ ${value.format('LL')} ได้เนื่องจากการตรวจ ${textType}เต็มแล้ว` } })
            } else if (listData?.listPatient.filter(x => x.type === textType).length >= 10) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถลงนัดวันที่ ${value.format('LL')} ได้เนื่องจากการตรวจ ${textType}เต็มแล้ว` } })
            } else {
              setModalTest({ show: true })
              setDataModal({ ...listData, day })
            }
          } else if (listData?.listPatient.filter(x => x.type === textType).length >= 5) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถลงนัดวันที่ ${value.format('LL')} ได้เนื่องจากการตรวจ ${textType}เต็มแล้ว` } })
          } else {
            setModalTest({ show: true })
            setDataModal({ ...listData, day })
          }
          // return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถลงนัดวันที่ ${value.format('LL')} ได้เนื่องจากการตรวจ ${textType}เต็มแล้ว` } })
        }
      }}>
        <ul className="events">
          {content}
        </ul>
      </div >
    );
  };

  React.useEffect(async () => {
    if (formData.screening_breast_cancer) {
      setTypesurvey('มะเร็งเต้านม')
    } else if (formData.screening_fit_test) {
      setTypesurvey('มะเร็งลำไส้')
    } else if (formData.screening_hpv_dna_test) {
      setTypesurvey('มะเร็งปากมดลูก')
    }
    let data = await api.query(`db/homecare/form_risk/query`, {
      aggregate: [
        {
          $lookup: {
            from: "patient_risk",
            localField: "cid",
            foreignField: "cid",
            as: "listPatient"
          }
        },
        {
          $unwind: {
            path: "$listPatient",
            preserveNullAndEmptyArrays: true
          }
        },
        {
          $addFields: { "listPatient.type": "$type" }
        },
        {
          $group: {
            _id: { x: "$date_queue_patient" },
            date: { $first: "$date_queue_patient" },

            listPatient: { $push: "$listPatient" }
          }
        }
      ]
    })

    let result = {}
    data.forEach(x => {
      result[`${x.date}`] = { ...x }
    })

    setCalendar({ ...result })

  }, []);


  return <div className="mt-2">
    <Modal
      title={null}
      centered
      open={modalTest.show}
      onOk={async () => {
        try {

          if (!typesurvey) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'โปรดเลือกสิ่งที่ต้องการนัดตรวจ' } })

          formData['_id'] = userData.cid
          formData['cid'] = userData.cid
          formData['type'] = typesurvey
          formData['date_queue_patient'] = dataModal.date
          // formData['status_queue'] = 'confirm_patient'
          formData['status_queue'] = 'wait_confirm_doctor'
          formData['status_come'] = null
          formData['hash_cid'] = md5(userData.cid)
          userData['hash_cid'] = md5(userData.cid)
          if (userData && userData.day && userData.month && userData.year) {
            try {
              userData['dob'] = `${userData.year - 543}-` + `${MONTH[userData.month]}-` + `${userData.day}`.padStart(2, '0')
            } catch (err) {

            }
          }
          await api.insert(`db/homecare/patient_risk/data/${userData._id}`, userData)
          await api.insert(`db/homecare/form_risk/data/${formData._id}`, formData)
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกแบบคัดกรองสำเร็จ' } })
          history.push('/risk4-doctor')
        } catch (err) {
          console.log(err)
        }
      }}
      // okText={null}
      okText={'ยืนยันนัด'}
      closable={false}
      cancelText={'ปิด'}
      // okButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { background: 'green', color: "white" } }}
      cancelButtonProps={{ style: { background: 'red', color: "white" } }}
      onCancel={() => {
        setModalTest({ show: false })
      }}
      width={700}
    >
      <div className='w-full flex flex-row'>
      </div>
      {/* < Divider /> */}
      <div className="w-full h-[250px]">
        <div>
          <p className="text-2xl" style={{ color: 'black' }}>{`ข้อมูลนัดผู้ป่วยณ ${moment(dataModal.date, 'x').format('LL')}`}</p>
          < Divider style={{ margin: 6 }} />
          <div class="w-full">

            <div class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-1 sm:flex-row flex-col bg-[#E6F7FF]">
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-1">{`จำนวนที่เปิดนัดคนไข้ที่ ${Array.isArray(dataModal?.listPatient) ? dataModal?.listPatient.length : '-'}/ 15 คน`}</h2>
                <p className="text-md">ข้อมูลเพื่มเติม</p>
                {
                  FIX_Cl[dataModal?.day] &&
                  FIX_Cl[dataModal?.day].map(item => (
                    <li key={item} style={{ color: 'black' }}>
                      <p className="text-md">- {item}</p>
                    </li>
                  ))
                }
              </div>
            </div>
            <div className="mt-5">
              <Space>
                <p className="text-2xl">คุณต้องการนัดตรวจ{typesurvey}วันที่ {`${moment(dataModal.date, 'x').format('LL')}`}หรือไม่</p>
              </Space>

            </div>
          </div>

        </div>

      </div>
    </Modal>

    <Modal
      title={null}
      // "จัดการข้อมูล 12 มีนาคม 2566"
      centered
      open={modalTest2.show}
      onOk={async () => {
        let _data = {}
        _data['_id'] = ModalDataDay.date
        _data['date'] = Number(ModalDataDay.date)
        _data['data'] = ModalDataDay.listDay
        _data['amount'] = ModalDataDay.amount
        await api.insert(`db/homecare/calender/data/${_data._id}`, _data)
        setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกแบบคัดกรองสำเร็จ' } })
        DataCalendar[`${ModalDataDay.date}`] = {
          "_id": `${ModalDataDay.date}`,
          "date": Number(ModalDataDay.date),
          "amount": ModalDataDay.amount,
          "data": ModalDataDay.listDay,
          "form_risk": {},
          "listPatient": []
        }
        setCalendar({ ...DataCalendar })
      }}
      okText={'บันทึก'}
      closable={false}
      cancelText={'ปิด'}
      okButtonProps={{ style: { background: 'green' } }}
      onCancel={() => {
        setModalTest2({ show: false })
        ModalDataDay.amount = 0
        ModalDataDay.listDay = []
        setModaDatalDay({ ...ModalDataDay })
      }}
      width={650}
    >
      <div className='w-full flex flex-row'>
      </div>
      {/* < Divider /> */}
      <div className="w-full h-[550px]">
        <div>
          <p className="text-2xl" style={{ color: 'black' }}>ข้อมูลนัดผู้ป่วยณ 12 มีนาคม 2566</p>
          < Divider style={{ margin: 6 }} />
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <p>จำนวนคนไข้ที่เปิดรับนัด* </p>
              <Input
                style={{ width: '150px', height: 50, }}
                placeholder=""
                name="number"
                maxLength={4}
                value={ModalDataDay.amount}
                // onChange={onChangeText}
                addonAfter="คน"
              />
            </Grid>
            <Grid item xs={12} md={12}>

              <p>ข้อมูลเพื่มเติม</p>
              <Input
                style={{ width: '350px', height: 35, }}
                placeholder="ข้อความ"
                value={ModalDataDay.text}
                // onChange={onChangeArray}
                name="number"
              />
              < Button variant="contained" style={{ marginLeft: 12 }} onClick={() => {
                if (ModalDataDay.text) {
                  ModalDataDay.listDay.push(ModalDataDay.text)
                  ModalDataDay.text = ''
                  setModaDatalDay({ ...ModalDataDay })
                } else {
                  alert('โปรดใส่ข้อความเพื่มเติม')
                }
              }}>
                เพื่ม
              </Button>
            </Grid>
          </Grid>
          < Divider style={{ margin: 6 }} />
          <div class="w-full">
            <div class="flex-grow">
              {
                Array.isArray(ModalDataDay?.listDay) &&
                ModalDataDay?.listDay.map((item, index) => (
                  <div class="mt-2 w-full">
                    <div class="border-2 rounded-lg border-gray-200 border-opacity-50 p-1 flex flex-row">
                      <div class="flex-1">
                        <p class="leading-relaxed text-base">{item}</p>
                      </div>
                      < Button variant="contained" style={{ marginLeft: 12, backgroundColor: 'red' }} onClick={() => {
                        const index = ModalDataDay?.listDay.indexOf(item);
                        if (index > -1) { // only splice array when item is found
                          ModalDataDay?.listDay.splice(index, 1); // 2nd parameter means remove one item only
                        }
                        setModaDatalDay({ ...ModalDataDay })
                      }}>
                        ลบ
                      </Button>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Calendar
      headerRender={({ value, type, onChange, onTypeChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];

        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current.month(i);
          months.push(localeData.monthsShort(current));
        }

        for (let index = start; index < end; index++) {
          monthOptions.push(
            <Select.Option className="month-item" key={`${index}`}>
              {months[index]}
            </Select.Option>
          );
        }
        const month = value.month();

        const year = value.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          );
        }
        return (
          <div style={{ padding: 10 }}>
            <div className="flex flex-row m-auto w-[280px] text-center justify-between">
              <ArrowBackIosNewIcon value="month" onClick={() => {
                const newValue = value.clone();
                newValue.subtract(1, 'months')
                onChange(newValue);
              }}>back</ArrowBackIosNewIcon>
              <div className="text-xl">{value.format('MMMM')} {Number(value.format('YYYY')) + 543}</div>
              <ArrowForwardIosIcon value="month" onClick={() => {
                const newValue = value.clone();
                newValue.add(1, 'months')
                onChange(newValue);
              }}></ArrowForwardIosIcon>
            </div>
          </div>
        );
      }}

      locale={locale}
      dateRender={(current) => {
        const num = (current.toString().length < 2) ? "0" + current : current
        return { num }
      }}
      dateCellRender={dateCellRender}
      onSelect={(date) => {
      }}
    />


    {/* Alert  */}
    < Divider class="m-4" />
    <div class="container px-5 mt-[12px] mx-auto w-full flex flex-row">
      <div className="flex-row flex mx-auto">
        < button
          class="flex text-white mr-8 bg-green-600 border-0 py-2 px-8 focus:outline-none hover:bg-green-800 rounded text-lg"
          onClick={() => {
            backPage()
          }}
        >
          ย้อนกลับ
        </button>
      </div>
    </div>
  </div >
}

const steps = [
  'เลขบัตรประชาชน',
  'ข้อมูลผู้ป่วย',
  'แบบฟอร์ม',
  'จองคิวนัด',
];


export default function Home(props) {
  let history = useHistory();
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });
  const [list_hospital, setListHospital] = useState(false)
  let [datemake, setDatemake] = useState(null)
  const [page, setPage] = useState(0);
  const [form, setForm] = useState({ ...defaultform })
  const [user, setUser] = useState({ ...defaultuser })
  return (
    <Layout ispadding is4>
      <div class="w-full flex justify-center mx-auto p-5 flex-col">
        <SweetAlert
          show={show.show}
          title={show.title}
          text={show.message}
          onConfirm={() => {
            setShow({ ...show, ...{ show: false } })
          }}
        />
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            padding: 12,
            padding: "18px",
            display: "flex",
            maxWidth: "1350px",
            alignSelf: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ flexDirection: 'column', display: 'flex' }}>

            <div className="flex-row flex justify-around">
              <p class="text-center text-3xl"> แบบคัดกรองโรคไม่ติดต่อเรื้อรัง(NCDs)</p>
            </div>

            < Divider class="mb-4 mt-2" />
            <div className="w-full m-auto">
              <Box sx={{ width: '80%', margin: 'auto' }}>
                <Stepper activeStep={page} >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel sx={{ fontSize: '100px' }}>
                        <p className="text-lg">
                          {label}
                        </p>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

            </div>
            < Divider class="m-4" />

            {
              page === 0 && <PageCheckCid
                nextPage={(result) => {
                  setUser({ ...result })
                  setPage(1)
                }}
                setShow={setShow}
                show={show}
                setUser={setUser}
                user={user}
              />
            }

            {
              page === 1 && <PageCid
                nextPage={(result) => {
                  setUser({ ...result })
                  setPage(2)
                }}
                backPage={() => {
                  setPage(0)
                  setUser({ ...defaultuser })
                  setForm({ ...defaultform })
                }}
                setShow={setShow}
                show={show}
                setUserData={setUser}
                userData={user}
              />
            }
            {
              page === 2 && <PageForm
                nextPage={(result) => {
                  setForm({ ...result })
                  setPage(3)
                }}
                backPage={() => {
                  setPage(1)
                }}
                formData={form}
                setShow={setShow}
                show={show}
                setUserData={setUser}
                userData={user}

              />
            }
            {
              page === 3 && <PageQueue
                nextPage={(result) => {
                  setPage(4)
                }}
                backPage={() => {
                  setPage(2)
                }}
                formData={form}
                setShow={setShow}
                show={show}
                setUserData={setUser}
                userData={user}
              />
            }
          </div>
        </div>
      </div >
    </Layout >
  )
}


