export function checkSum(cid) {
  if (!cid) {
    return ({ ok: false, messgae: "ระบุหมายเลขประจำตัวประชาชนถูกต้อง" });
  }

  let firstCid = `${cid}`.substr(0, 1)
  if (firstCid.toUpperCase() === 'G') {
    return ({
      ok: true,
      case: 'cid-G',
    })
  } else {
    let total = 0;
    let iPID;
    let chk;
    let Validchk;
    iPID = cid.replace(/-/g, "");
    Validchk = iPID.substr(12, 1);
    let j = 0;
    let pidcut;
    for (let n = 0; n < 12; n++) {
      pidcut = parseInt(iPID.substr(j, 1));
      total = (total + ((pidcut) * (13 - n)));
      j++;
    }
    chk = 11 - (total % 11);
    if (chk == 10) {
      chk = 0;
    } else if (chk == 11) {
      chk = 1;
    }
    if (chk == Validchk) {
      return ({
        ok: true, messgae: "ระบุหมายเลขประจำตัวประชาชนถูกต้อง",
        case: 'cid-ok',
      });
    } else {
      return ({
        ok: false,
        messgae: "ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง",
      });
    }
  }
}