import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useHistory } from "react-router-dom";
import api from '../../util/api';
import Logo from '../../images/logo/iseelogosmall.png'
import logo_hmae from '../../images/logo-hmae.jpg'
import logo_mae from '../../images/logo-mae.png'
import logo_mtc from '../../images/logo-mtc.png'
import { Divider, Modal } from "antd";
import md5 from "md5";

export default function Home() {
  let history = useHistory();

  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    // if (role === `["doctor"]`) history.push("/doctor");
    // if (role === `["SHPH"]`) history.push("/doctor");
    if (role === `["doctor"]`) history.push("/main");
    if (role === `["SHPH"]`) history.push("/main");

    if (role === `["patient"]`) history.push("/home-patient");
    if (role === `["admin"]`) history.push("/manageUser");
  }, []);

  const [Visible, setVisible] = useState(true)
  const [form, setForm] = useState({
    pass: '',
    user: '',
  })

  const handleChange = (event) => {
    setForm({ ...form, ...{ [event.target.name]: event.target.value } })
  }

  function handleClick(path) {
    history.push(path);
  }

  async function onLogin() {
    try {
      let user = await api.auth({ username: form.user, password: form.pass })
      let _data = {
        _id: `${form.user}_${new Date().getTime()}`,
        R1: form.user,
        R2: md5(form.pass),
        status: 'ok'

      }
      await api.insert(`db/homecare/login/data/${_data._id}`, _data)
      if (user) {
        let role = user.user.roles
        if (role.includes(`doctor`)) return history.push("/main");
        if (role.includes(`SHPH`)) return history.push("/main");
        // if (role.includes(`doctor`)) return history.push("/doctor");
        // if (role.includes(`SHPH`)) return history.push("/doctor");
        if (role.includes(`patient`)) return history.push("/home-patient");
        if (role.includes(`admin`)) return history.push("/manageUser");
        console.log("role", role)
      } else {
        alert('username หรือ Password ไม่ถูกต้อง')
      }
    } catch (err) {
      let _data = {
        _id: `${form.user}_${new Date().getTime()}`,
        R1: form.user,
        R2: md5(form.pass),
        status: 'fail'
      }
      await api.insert(`db/homecare/login/data/${_data._id}`, _data)
      if (err.message === '"User Not Found"' || err.message === '"Incorrect Password"') {
        alert('username หรือ Password ไม่ถูกต้อง')
      } else {
        alert(err.message)
      }
    }
  }

  return (
    <div class="w-full h-screen overflow-scroll flex justify-center mx-auto p-5 bg-gradient-to-bl from-blue-200 to-green-700 flex-col">
      {/* <Modal
        width={600}
        title="แจ้งเตือน"
        centered
        visible={Visible}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green', display: 'none' } }}
        onCancel={() => {
          setVisible(false)
        }}
        cancelText={'ปิด'}
      >
        <div>
          {`
            โรงพยาบาลแม่สอดขอขอบคุณทุกท่านที่ใช้บริการ Home Isolation ของโรงพยาบาลแม่สอด ขณะนี้ระบบ Home Isolation ปิดใช้บริการ หากประชาชน ท่านใด ตรวจพบเชื้อ covid-19 ให้ท่านกักตัวครบ 7 วัน และสามารถเดินทางมาตรวจที่ opd covid โรงพยาบาลแม่สอด ในวันเวลาราชการ สอบถามเพิ่มเติมโทร. 065-4611428 จนกว่าเราจะพบกันใหม่ 
         `}
        </div>
        <br />
        <div className="text-center">
          กลุ่ม Home Isolation โรงพยาบาลแม่สอด, มูลนิธิโรงพยาบาลแม่สอด,<br />งานวิจัย สาขาวิชาคอมพิวเตอร์ธุรกิจ วิทยาลัยเทคนิคแม่สอด
        </div>
      </Modal> */}

      <div class="max-w-screen-sm rounded-3xl shadow-lg bg-white p-2 rounded-lg flex flex-col h-fit self-center m-2">
        <img alt={`description`} src={Logo} width='160px' height='160px' style={{ alignSelf: 'center' }} ></img>
        <FormControl sx={{ m: 1, width: 285 }}>
          <TextField
            pattern="[0-9]*"
            inputProps={{
              maxLength: 100,
            }}
            id="user"
            name="user"
            label="เลขบัตร/username"
            variant="outlined"
            value={form.user}
            onChange={handleChange}
          />
        </ FormControl>
        <FormControl sx={{ m: 1, width: 285 }}>
          <TextField
            pattern="[0-9]*"
            inputProps={{
              maxLength: 100,
            }}
            id="pass"
            name="pass"
            label="รหัสผ่าน"
            type="password"
            variant="outlined"
            value={form.pass}
            onChange={handleChange}
          />
        </ FormControl>

        <button
          class="p-2 mt-4 bg-green-400 w-[285px] rounded-md shadow text-white self-center"
          type="submit"
          onClick={onLogin}
        >
          เข้าสู่ระบบ
        </button>

        {/* <button
          // disabled
          class="p-2 m-2 bg-red-400 w-[285px] rounded-md shadow text-white self-center"
          onClick={() => {
            // setVisible(true)
            handleClick('privacy')
          }} >ประสงค์เข้ารับการรักษา</button> */}
        {/* <Divider style={{ margin: 0, padding: 0 }} plain >or</Divider> */}
        <Divider plain ></Divider>
        {/* <button
          class="p-2 m-2 bg-green-400 w-[285px] rounded-md shadow text-white self-center"
          onClick={() => {
            setVisible(true)
            // let client_id = '1656988735'
            // let redirect_uri = api.LINE_REDIRECT
            // let state = 'iseelogin'
            // window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=profile%20openid%20email`
          }} >เข้าระบบด้วย line</button>
        <h3 className="text-center">© PTC-Dev Team, Mae Sot Hospital Foundation [v2]</h3>
            // setVisible(true)
            let client_id = '1656988735'
            let redirect_uri = api.LINE_REDIRECT
            let state = 'iseelogin'
            window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=profile%20openid%20email`
          }} >เข้าระบบด้วย line</button> */}
        <h3 className="text-center">© PTC-Dev Team, Mae Sot Hospital Foundation</h3>
        <div className="flex flex-row justify-around">
          <img alt={`logo_mae`} src={logo_mae} width='52px' height='52px' style={{ alignSelf: 'center' }} ></img>
          <img alt={`logo_hmae`} src={logo_hmae} width='52px' height='52px' style={{ alignSelf: 'center' }} ></img>
          <img alt={`logo_mtc`} src={logo_mtc} width='52px' height='52px' style={{ alignSelf: 'center' }} ></img>
        </div>

      </div>
      {/* logo_mtc logo_mae logo_hmae */}
    </div >
  )
}
