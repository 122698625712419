import React, { useState } from "react";
import Layout from '../../components/LayoutPC';
import Logo1 from '../../images/IMG_1276.png'
import Logo2 from '../../images/IMG_1275.png'
import { Input, } from 'antd';
import 'moment/locale/th';
const { Search, TextArea } = Input;
export default function Home(props) {
  let [role, setRole] = useState(null)
  React.useEffect(() => {
    let userdata = localStorage.getItem('user_data')
    let _role = localStorage.getItem('role')
    setRole(_role)
  }, [])
  return (<Layout ispadding isHm>
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1550px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div className="w-[1000px]">
        <a href={role === '["doctor"]' ? "/doctor-h-risk" : '/risk4-doctor'}>
          <img alt={`description`} src={Logo1} width='1000px' height='1000px'></img>
        </a>
        <div style={{ fontSize: '24px', textAlign: 'center' }}>
          ลงทะเบียนผู้ป่วยนัดโรคไม่ติดต่อเรื้อรังNCDs
        </div>
      </div>

      <div className="w-[1000px]">
        <a href="/doctor">
          <img alt={`description`} src={Logo2} width='1000px' height='1000px'></img>
        </a>
        <a href="/doctor" >
          <div style={{ fontSize: '24px', textAlign: 'center' }}>
            Home Isolation Covid19
          </div>
        </a>
      </div>
      {/* <div className="flex flex-row">
        <p className="text-3xl mb-4">รายชื่อผู้ป่วย คัดกรอง4โรค</p>
      </div> */}

    </div>
  </Layout >)
}
