import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import api from '../../util/api';
import util from '../../util';
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { Form, DatePicker, Space, Progress, Input, Select, Divider, Modal, Checkbox } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import PdfVisit from "../../services/PrintoutPdf/PdfVisit";
import moment from 'moment'
import SweetAlert from 'sweetalert2-react';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;
const { TextArea, Search } = Input;


const { RangePicker } = DatePicker;

let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const ReaderRow = ({ column, row, handleClick, setVisible }) => {
  const value = row[`${column.id}`];
  if (column.id === 'status') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_STATUS[value].color, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {MAP_STATUS[value].text}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    console.log("value.date", value)
    return (
      <TableCell key={column.id} align={column.align}>
        {
          'ss'
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(`${value}`, 'x').format('LL')}</div>
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(value, 'x').format('LL') new Date(value).toLocaleString()}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'quesue' && column.type === 'button') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Button variant="contained" style={{ margin: 8 }} onClick={() => setVisible(row)}>
          เลือกเวลานัด
        </Button>
        {/* < Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
          เลือกเวลานัด
          </Button> */}
      </TableCell>
    );
  }
  else if (column.id === 'quesue') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {moment(value.date, 'x').format('L')}</div>
        }
      </TableCell>
    );
  }




  return (
    <TableCell key={column.id} align={column.align}>
      {
        column.type === 'button' ?
          (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
            ดูข้อมูล
          </Button>) :
          value
      }
    </TableCell>
  );

}

const ReaderRow2 = ({ column, row, handleClick, setVisible }) => {
  const value = row[`${column.id}`];
  if (column.id === 'status') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_STATUS[value].color, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {MAP_STATUS[value].text}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    console.log("value.date", value)
    return (
      <TableCell key={column.id} align={column.align}>
        {
          'ss'
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(`${value}`, 'x').format('LL')}</div>
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(value, 'x').format('LL') new Date(value).toLocaleString()}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'quesue' && column.type === 'button') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Button variant="contained" style={{ margin: 8 }} onClick={() => setVisible(row)}>
          เลือกเวลานัด
        </Button>
        {/* < Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
          เลือกเวลานัด
          </Button> */}
      </TableCell>
    );
  }
  else if (column.id === 'quesue') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {moment(row.quesue.date_doctor, 'x').format('L')}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'time') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {row.quesue.time_doctor}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'addr') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {row.quesue.addr}</div>
        }
      </TableCell>
    );
  }


  return (
    <TableCell key={column.id} align={column.align}>
      {
        column.type === 'button' ?
          (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
            ดูข้อมูล
          </Button>) :
          value
      }
    </TableCell>
  );

}

const columns = [
  { id: 'index', label: 'ลำดับ', minWidth: 20, align: 'center' },
  { id: 'quesue', label: 'เวลาที่ขอนัด', minWidth: 140, align: 'center' },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'quesue', label: 'นัดเวลา', minWidth: 170, type: 'button', align: 'center' },
  // { id: 'name', label: 'ดูข้อมูล', nWidth: 170, type: 'button', align: 'center' },
];


const columns2 = [
  { id: 'index', label: 'ลำดับ', minWidth: 20, align: 'center' },
  { id: 'quesue', label: 'วันที่นัด', minWidth: 140, align: 'center' },
  { id: 'time', label: 'ช่วงเวลา', minWidth: 140, align: 'center' },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  // { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'addr', label: 'สถานที่นัด', minWidth: 170 },

  // { id: 'name', label: 'ดูข้อมูล', nWidth: 170, type: 'button', align: 'center' },
];

function ColumnGroupingTable({ data_mock = [], search = "", age, setVisible }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.host.indexOf(search) >= 0 || x.name.indexOf(search) > 0 || search === '')
      .filter(x => x.status === age || age === 'all')
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)

  }, [data_mock, search, age]);


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {

                      return <ReaderRow column={column} row={row} handleClick={handleClick} setVisible={setVisible} />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}


function ColumnGroupingTable2({ data_mock = [], search = "", age, setVisible }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.host.indexOf(search) >= 0 || x.name.indexOf(search) > 0 || search === '')
      .filter(x => x.status === age || age === 'all')
      .sort((a, b) => b?.quesue?.date_doctor - a?.quesue?.date_doctor)
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)

  }, [data_mock, search, age]);


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns2.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns2.map((column) => {

                      return <ReaderRow2 column={column} row={row} handleClick={handleClick} setVisible={setVisible} />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

  const [donwloadVisit, setDonwloadVisit] = useState({
    isVisit: false,
    date: null,
    dateD: null
  });


  const [dataQueue, setDataQueue] = React.useState({
    "active": null,
    "date": null,
    "other": null,
    "date_doctor": null,
    "time_doctor": null,
    "confrim_doctor": null,
    "addr": null,
    "name": null,
    "status": null,
    "patient": null,
    "patient_symptoms1": true,
    "patient_symptoms2": true,
    "patient_symptoms3": true
  });


  React.useEffect(async () => {
    console.log(">> start")
    let _data = await api.query(
      'db/homecare/patient/query',
      // {
      //   "query": {
      //     "hostid": "10723"
      //   }
      // }
      {
        "aggregate": [
        ]
      }
    )
    setData(_data.filter(x => x.quesue?.active).map(x => ({ ...x, name: `${x.title}${x.firstname} ${x.lastname}` })))
  }, []);

  const [page, setPage] = useState(1);
  const [user, setUser] = useState("");
  const [age, setAge] = React.useState('all');
  const handleChange = (value) => {
    setAge(value);
  };

  let year = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  function handleClick(path) {
    history.push(path);
  }

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  const [visible, setVisible] = useState(false);
  // {search}
  // let _data = data
  return (<Layout ispadding>
    <SweetAlert
      show={show.show}
      title={show.title}
      text={show.message}
      onConfirm={() => {
        setShow({ ...show, ...{ show: false } })
      }}
    />
    <Modal
      title="นัดหมายคนไข้"
      centered
      visible={visible}
      onCancel={() => {
        setDataQueue({
          "active": null,
          "date": null,
          "other": null,
          "date_doctor": null,
          "time_doctor": null,
          "confrim_doctor": null,
          "addr": null,
          "name": null,
          "status": null,
          "patient": null,
          "patient_symptoms1": true,
          "patient_symptoms2": true,
          "patient_symptoms3": true
        })
        setVisible(false)
      }}
      onOk={async () => {

        if (!dataQueue?.date_doctor) {
          return setAlert('โปรดเลือกวัน')
        }
        if (!dataQueue?.time_doctor) {
          return setAlert('โปรดเลือกช่วงเวลา')
        }
        if (!dataQueue?.addr) {
          return setAlert('โปรดเลือกสถานที่')
        }

        if (dataQueue?.addr === 'อื่นๆ' && !dataQueue.other) {
          return setAlert('โปรดเลือกสถานที่')
        }

        let user = localStorage.getItem('user')
        let _patient = { ...dataQueue.patient }

        dataQueue.date_doctor.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        dataQueue.date_doctor.toISOString()

        _patient['quesue'] = {
          "active": true,
          "date": dataQueue.date,
          "date_doctor": dataQueue.date_doctor.format('x'),
          "time_doctor": dataQueue.time_doctor,
          "patient_symptoms1": dataQueue.patient_symptoms1,
          "patient_symptoms2": dataQueue.patient_symptoms2,
          "patient_symptoms3": dataQueue.patient_symptoms3,
          "confrim_doctor": true,
          "addr": dataQueue.addr === 'อื่นๆ' ? dataQueue.other : dataQueue.addr,
        }
        await api.insert(`db/homecare/patient/data/${_patient._id}`, _patient)
        let _index = data.findIndex(x => x.cid === _patient._id)
        data[_index] = _patient
        setData([...data])
        setDataQueue({
          "active": null,
          "date": null,
          "other": null,
          "date_doctor": null,
          "time_doctor": null,
          "confrim_doctor": null,
          "addr": null,
          "name": null,
          "status": null,
          "patient": null,
          "patient_symptoms1": true,
          "patient_symptoms2": true,
          "patient_symptoms3": true
        })
        setVisible(false)

      }}
      okText={'ใช่'}
      cancelText={'ยกเลิก'}
      okButtonProps={{ style: { background: 'green' } }}
      width={800}
    >
      {console.log("dataQueue", dataQueue)}
      <p class="text-lg">ชื่อนามสกุล: {dataQueue.name} </p>
      <p class="text-lg">เวลาที่ขอนัด: {moment(dataQueue?.date, 'x').format('L')}</p>
      {/* เหนื่อย แน่นหน้าอก ไอมาก */}
      <p class="text-lg">มีอาการ</p>
      <div className="ml-2">
        <Checkbox checked={dataQueue.patient_symptoms1} > เหนื่อย</Checkbox>  <br />
        <Checkbox checked={dataQueue.patient_symptoms2} > แน่นหน้าอก</Checkbox>  <br />
        <Checkbox checked={dataQueue.patient_symptoms3} > ไอมาก</Checkbox>  <br />
      </div>
      <Divider />
      <p class="text-xl mt-6 mb-/">เลือกวันเวลาและสถานที่</p>
      <div class="flex-col flex">
        <div class="w-285px">
          <DatePicker
            // disabledDate={disabledDate}
            style={{ width: '285px', height: 50, margin: 6 }}
            size={"large"}
            format={'DD/MM/YYYY'}
            locale={locale}
            onChange={(date, dateString) => {
              setDataQueue({ ...dataQueue, date_doctor: date })
            }}
            value={dataQueue.date_doctor}
          />
        </div>

        <div style={{ flexDirection: 'column', display: 'flex', marginTop: 4 }}>
          <p className="text-lg">ช่วงเวลา: </p>
          <Select
            value={dataQueue.time_doctor}
            style={{ width: '285px', height: 50, margin: 6 }}
            size={"large"}
            onChange={(value) => {
              setDataQueue({ ...dataQueue, time_doctor: value })
            }}
          >
            <Option value="09:00-12:00">09:00 - 12:00</Option>
            <Option value="13:00-16:00">13:00 - 16:00</Option>
            <Option value="17:00-21:00">17:00 - 21:00</Option>
          </Select>

        </div>

        <div style={{ flexDirection: 'column', display: 'flex', marginTop: 4 }}>
          <p className="text-lg">สถานที่: </p>
          <Select
            value={dataQueue.addr}
            style={{ width: '285px', height: 50, margin: 6 }}
            size={"large"}
            onChange={(value) => {
              setDataQueue({ ...dataQueue, addr: value })
            }}
          >
            <Option value="ห้องฉุกเฉินโรงพยาบาล">ห้องฉุกเฉินโรงพยาบาล</Option>
            <Option value="Covid clinic ตึกผู้ป่วยนอก(ตึกเก่า)">Covid clinic ตึกผู้ป่วยนอก(ตึกเก่า)</Option>
            <Option value="อื่นๆ">อื่นๆ</Option>
          </Select>

          {
            dataQueue.addr === 'อื่นๆ' &&
            <>
              <p className="text-lg">ระบุสถานอื่นๆ: </p>
              <TextArea rows={2} maxLength={140}
                value={dataQueue.other}
                onChange={(e) => {
                  setDataQueue({ ...dataQueue, other: e.target.value })
                }}
              />
            </>
          }

        </div>


      </div>
    </Modal>



    <Modal
      title="โหลดรายชื่อ visit"
      centered
      visible={donwloadVisit.isVisit}
      onCancel={() => {
        setDonwloadVisit({ ...donwloadVisit, isVisit: false })
      }}
      okText={'ดาวโหลด'}
      cancelText={'ปิด'}
      okButtonProps={{ style: { background: 'green' } }}
      onOk={async () => {
        console.log("donwloadVisit", donwloadVisit)
        console.log("donwloadVisit data", data)
        if (donwloadVisit.date) {
          PdfVisit({
            name: '',
            date: util.getTimeLong(donwloadVisit.date),
            addr: '',
            array: [],
            arrayNew: data.filter(x => x.quesue.confrim_doctor && donwloadVisit.date === x.quesue.date_doctor)
          })
        }

        // setDonwloadVisit({ ...donwloadVisit, isVisit: false })
      }}
      width={800}
    >

      <p class="text-xl">เลือกวันที่ต้องการดาวโหลด</p>
      <div class="flex-col flex">
        <div class="w-285px">
          <DatePicker
            // disabledDate={disabledDate}
            style={{ width: '285px', height: 50, margin: 6 }}
            size={"large"}
            format={'DD/MM/YYYY'}
            locale={locale}
            onChange={(date, dateString) => {
              try {
                date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                date.toISOString()
                setDonwloadVisit({
                  ...donwloadVisit,
                  ...{
                    dateD: date,
                    date: date.format('x'),
                  }
                })
              } catch (err) {
                console.log("err", err)
              }

            }}
            value={donwloadVisit.dateD}
          />
        </div>
      </div>
    </Modal>


    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",

      }}
    >
      <p className="text-3xl mb-4">จัดการนัดผู้ป่วย</p>

      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          "boxShadow": "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between'
          }}
        >
          <div>
            < Button variant={page === 1 ? "contained" : 'outlined'} style={{ margin: 8 }}
              onClick={() => {
                setPage(1)
              }}
            >
              รายชื่อผู้ป่วยที่ขอนัด
            </Button>

            < Button variant={page === 2 ? "contained" : 'outlined'} style={{ margin: 8 }}
              onClick={() => {
                setPage(2)
              }}
            >
              รายชื่อที่นัดแล้ว
            </Button>
          </div>
          {
            page === 2 &&
            < Button style={{ margin: 8, background: '#2980B9', color: '#fff' }}
              onClick={() => {
                // setPage(1)
                setDonwloadVisit({ ...donwloadVisit, isVisit: true })
              }}
            >
              โหลดรายชื่อ visit
            </Button>
          }
        </div>
        <div style={{ flexDirection: 'row' }}>
          <FormControl sx={{ m: 1, minWidth: 300, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหาชื่อนามสกุล และ สถานพยาบาล"
              style={{ width: 400, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>
        </div>


        {
          page === 1 &&
          <ColumnGroupingTable data_mock={data.filter(x => !x.quesue.confrim_doctor)} search={search} age={age} setVisible={(data) => {
            console.log(">>", data)
            setDataQueue({
              "active": data.quesue.active,
              "date": data.quesue.date,
              "date_doctor": null,
              "time_doctor": null,
              "confrim_doctor": null,
              "other": null,
              "addr": null,
              "name": `${data.title}${data.firstname} ${data.lastname}`,
              "status": data.quesue.active,
              "patient": data,
              "patient_symptoms1": data.quesue.patient_symptoms1,
              "patient_symptoms2": data.quesue.patient_symptoms2,
              "patient_symptoms3": data.quesue.patient_symptoms3,
            })
            setVisible(true)
          }} />
        }

        {
          page === 2 &&
          <ColumnGroupingTable2 data_mock={data.filter(x => x.quesue.confrim_doctor)} search={search} age={age} setVisible={(data) => {
            console.log(">>", data)
            setDataQueue({
              "active": data.quesue.active,
              "date": data.quesue.date,
              "date_doctor": null,
              "other": null,
              "time_doctor": null,
              "confrim_doctor": null,
              "addr": null,
              "name": `${data.title}${data.firstname} ${data.lastname}`,
              "status": data.quesue.active,
              "patient_symptoms1": data.quesue.patient_symptoms1,
              "patient_symptoms2": data.quesue.patient_symptoms2,
              "patient_symptoms3": data.quesue.patient_symptoms3,
              "patient": data
            })
            setVisible(true)
          }} />
        }
      </div>
    </div>
  </Layout >)
}
