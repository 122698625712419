import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Divider from '@mui/material/Divider';
import md5 from 'md5';
import api from "../../util/api";
import { Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { DatePicker, Space, Calendar } from 'antd';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import Layout from '../../components/LayoutPC';
import SweetAlert from 'sweetalert2-react';
import { Menu, Input, Modal, Checkbox, Select, Row, Table } from 'antd';
// Button, 
import Button from '@mui/material/Button';
import { AppstoreOutlined, FieldTimeOutlined } from '@ant-design/icons';
import bg from '../../images/bg.png'
import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';
import util from "../../util";

const { Option } = Select;
const { Search, TextArea } = Input;

const columnsFile = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    key: "index",
    width: 20,
  },
  {
    title: "ชื่อไฟล์",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "ดาวน์โหลด",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          icon={<DownloadOutlined />}
          onClick={data.onDonwlaod}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "green",
            color: "white",
          }}
        >
          download
        </Button>
      </div >
    ),
    width: 140,
  },
];


// let MAP_STATUS_WATH = {
//   'wait_confirm_doctor': {
//     color: '#ffffb1',
//     color_b: '#ffea7e',
//     text: 'รอนัดจากแพทย์'
//   },
//   'wait_confirm_patient': {
//     color: '#F1A661',
//     color_b: '#F1A661',
//     text: 'ผู้คัดกรองที่รอลงวันนัด'
//   },
//   'confirm_patient': {
//     color: '#ceffcf',
//     color_b: '#82ff84',
//     // text: 'ผู้ป่วยยื่นยันวันนัด'
//     // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
//     text: 'ลงนัดแล้ว'
//   },
//   'stop': {
//     color: '#ffffb1',
//     color_b: '#ffea7e',
//     // text: 'ผู้ป่วยยื่นยันวันนัด'
//     // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
//     text: 'ไม่นัด'
//   },
//   'refer': {
//     color: '#ffffb1',
//     color_b: '#ffea7e',
//     // text: 'ผู้ป่วยยื่นยันวันนัด'
//     // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
//     text: 'ส่งต่อ'
//   },
//   'other': {
//     color: '#ffffb1',
//     color_b: '#ffea7e',
//     // text: 'ผู้ป่วยยื่นยันวันนัด'
//     // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
//     text: 'อื่นๆ'
//   },
// }

let MAP_STATUS_WATH = {
  'wait_confirm_doctor': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'รอนัดจากแพทย์'
  },
  'wait_confirm_patient': {
    color: '#F1A661',
    color_b: '#F1A661',
    text: 'ผู้คัดกรองที่รอลงวันนัด'
  },
  'confirm_patient': {
    color: '#ceffcf',
    color_b: '#82ff84',
    // text: 'ผู้คัดกรองลงวันนัดแล้ว'
    text: 'ลงนัดแล้ว'
  },
  'stop': {
    // color: '#F08080',
    // color_b: '#F08080',
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'ไม่นัด'
  },
  'refer': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'ส่งต่อ'
  },
  'other': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'อื่นๆ'
  },
  'confirm_patient_new': {
    color: '#F08080',
    color_b: '#F08080',
    // text: 'ผู้คัดกรองลงวันนัดแล้ว'
    text: 'ลงนัดใหม่'
  },
}


const StatuTest = ({ formFirstAssessment }) => {
  if (!formFirstAssessment?.status_queue) return null

  if (formFirstAssessment?.status_queue === 'other') {
    return (
      <div style={{ padding: 4, backgroundColor: MAP_STATUS_WATH[formFirstAssessment?.status_queue].color, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {MAP_STATUS_WATH[formFirstAssessment?.status_queue].text} {` ${formFirstAssessment?.other_messages}`}</div>
    )
  } else {
    return (
      <div style={{ padding: 4, backgroundColor: MAP_STATUS_WATH[formFirstAssessment?.status_queue].color, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {MAP_STATUS_WATH[formFirstAssessment?.status_queue].text}</div>
    )
  }

}

function ModalCalendar({ patient, setShow, show, setForm }) {
  let history = useHistory();
  const [modalTest, setModalTest] = React.useState({
    show: false
  });

  const [modalTest2, setModalTest2] = React.useState({
    show: false
  });

  let [dataModal, setDataModal] = React.useState({

  })

  let [ModalDataDay, setModaDatalDay] = React.useState({
    listDay: [],
    amount: 0,
    text: '',
    date: null
  })

  const [DataCalendar, setCalendar] = useState({})
  const dateCellRender = (value) => {
    value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    let isb = moment().add(2, "days").isBefore(value)
    let listData = {
      amount: 0,
      data: [],
      listPatient: [],
      date: value.format('x')
    }
    let isNotAddEvent = false
    if (DataCalendar[`${value.format('x')}`]) {
      listData = DataCalendar[`${value.format('x')}`]
      isNotAddEvent = true
    }
    return (
      <div className="w-full h-full" onClick={() => {
        if (!isb) {
          return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถเลือกวันหลังจากวันที่ ${moment().add(2, "days").format("LL")}` } })
        } else {
          if (isNotAddEvent) {
            setModalTest({ show: true })
            setDataModal({ ...listData })
          } else {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${value.format('LL')} ยังไม่เปิดรับนัดจากโรงพยาบาล` } })
          }

        }
      }}>
        <ul className="events">
          <p style={{ color: isb ? 'black' : '#D3D3D3' }}>{listData.amount === 0 ? '' : `จำนวนนัดคนไข้ ${listData.listPatient.length} / ${listData.amount} คน`}</p>
          {
            isb && listData.data.map((item) => (
              <li key={item} style={{ color: isb ? 'black' : '#D3D3D3' }}>
                <p>- {item}</p>
              </li>
            ))
          }

        </ul>
      </div>
    );
  };

  React.useEffect(async () => {
    let data = await api.query(`db/homecare/calender/query`, {
      aggregate: [
        {
          $lookup:
          {
            from: "form_risk",
            localField: "date",
            foreignField: "date_queue_patient",
            as: "form_risk"
          }
        },
        {
          $unwind: {
            path: "$form_risk",
            preserveNullAndEmptyArrays: true
          }
        },
        {
          $lookup: {
            from: "patient_risk",
            localField: "form_risk.cid",
            foreignField: "cid",
            as: "listPatient"
          }
        },
        {
          $unwind: {
            path: "$listPatient",
            preserveNullAndEmptyArrays: true
          }
        },
        {
          $group: {
            _id: { x: "$_id" },
            data: { $first: "$data" },
            date: { $first: "$date" },

            amount: { $first: "$amount" },
            listPatient: { $push: "$listPatient" }
          }
        }
      ]
    })
    let result = {}
    data.forEach(x => {
      result[`${x.date}`] = { ...x }
    })

    setCalendar({ ...result })

  }, []);


  return <div className="mt-2">
    <Modal
      title={null}
      centered
      open={modalTest.show}
      onOk={async () => {
        try {
          let formData = await api.query(`db/homecare/form_risk/query`, {
            "query": {
              "cid": patient.cid
            }
          })
          console.log("formData", formData[0])
          formData[0]['status_queue'] = 'confirm_patient'
          formData[0]['date_queue_patient'] = dataModal.date
          await api.insert(`db/homecare/form_risk/data/${formData[0]._id}`, formData[0])
          setForm(formData[0])
          setModalTest({ show: false })
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกแบบคัดกรองสำเร็จ' } })
        } catch (err) {
          console.log(err)
        }
      }}
      // okText={null}
      okText={'ยืนยันนัด'}
      closable={false}
      cancelText={'ปิด'}
      // okButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { background: 'green', color: "white" } }}
      cancelButtonProps={{ style: { background: 'red', color: "white" } }}
      onCancel={() => {
        setModalTest({ show: false })
      }}
      width={600}
    >
      <div className='w-full flex flex-row'>
      </div>
      {/* < Divider /> */}
      <div className="w-full h-[350px]">
        <div>
          <p className="text-2xl" style={{ color: 'black' }}>{`ข้อมูลนัดผู้ป่วยณ ${moment(dataModal.date, 'x').format('LL')}`}</p>
          < Divider style={{ margin: 6 }} />
          <div class="w-full">

            <div class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-1 sm:flex-row flex-col bg-[#E6F7FF]">
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-1">{dataModal.amount === 0 ? '' : `จำนวนที่เปิดนัดคนไข้ที่ ${Array.isArray(dataModal?.listPatient) ? dataModal?.listPatient.length : '-'}/${dataModal?.amount} คน`}</h2>
                <p className="text-md">ข้อมูลเพื่มเติม</p>
                {
                  Array.isArray(dataModal?.data) &&
                  dataModal?.data.map((item) => (
                    <li key={item} style={{ color: 'black' }}>
                      <p className="text-md">- {item}</p>
                    </li>
                  ))
                }
              </div>
            </div>
          </div>

        </div>

      </div>
    </Modal>

    <Modal
      title={null}
      // "จัดการข้อมูล 12 มีนาคม 2566"
      centered
      open={modalTest2.show}
      onOk={async () => {
        let _data = {}
        _data['_id'] = ModalDataDay.date
        _data['date'] = Number(ModalDataDay.date)
        _data['data'] = ModalDataDay.listDay
        _data['amount'] = ModalDataDay.amount
        await api.insert(`db/homecare/calender/data/${_data._id}`, _data)
        setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกแบบคัดกรองสำเร็จ' } })
        DataCalendar[`${ModalDataDay.date}`] = {
          "_id": `${ModalDataDay.date}`,
          "date": Number(ModalDataDay.date),
          "amount": ModalDataDay.amount,
          "data": ModalDataDay.listDay,
          "form_risk": {},
          "listPatient": []
        }
        setCalendar({ ...DataCalendar })
      }}
      okText={'บันทึก'}
      closable={false}
      cancelText={'ปิด'}
      okButtonProps={{ style: { background: 'green' } }}
      onCancel={() => {
        setModalTest2({ show: false })
        ModalDataDay.amount = 0
        ModalDataDay.listDay = []
        setModaDatalDay({ ...ModalDataDay })
      }}
      width={650}
    >
      <div className='w-full flex flex-row'>
      </div>
      {/* < Divider /> */}
      <div className="w-full h-[550px]">
        <div>
          <p className="text-2xl" style={{ color: 'black' }}>ข้อมูลนัดผู้ป่วยณ 12 มีนาคม 2566</p>
          < Divider style={{ margin: 6 }} />
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <p>จำนวนคนไข้ที่เปิดรับนัด* </p>
              <Input
                style={{ width: '150px', height: 50, }}
                placeholder=""
                name="number"
                maxLength={4}
                value={ModalDataDay.amount}
                // onChange={onChangeText}
                addonAfter="คน"
              />
            </Grid>
            <Grid item xs={12} md={12}>

              <p>ข้อมูลเพื่มเติม</p>
              <Input
                style={{ width: '350px', height: 35, }}
                placeholder="ข้อความ"
                value={ModalDataDay.text}
                // onChange={onChangeArray}
                name="number"
              />
              < Button variant="contained" style={{ marginLeft: 12 }} onClick={() => {
                if (ModalDataDay.text) {
                  ModalDataDay.listDay.push(ModalDataDay.text)
                  ModalDataDay.text = ''
                  setModaDatalDay({ ...ModalDataDay })
                } else {
                  alert('โปรดใส่ข้อความเพื่มเติม')
                }
              }}>
                เพื่ม
              </Button>
            </Grid>
          </Grid>
          < Divider style={{ margin: 6 }} />
          <div class="w-full">
            <div class="flex-grow">
              {
                Array.isArray(ModalDataDay?.listDay) &&
                ModalDataDay?.listDay.map((item, index) => (
                  <div class="mt-2 w-full">
                    <div class="border-2 rounded-lg border-gray-200 border-opacity-50 p-1 flex flex-row">
                      <div class="flex-1">
                        <p class="leading-relaxed text-base">{item}</p>
                      </div>
                      < Button variant="contained" style={{ marginLeft: 12, backgroundColor: 'red' }} onClick={() => {
                        const index = ModalDataDay?.listDay.indexOf(item);
                        if (index > -1) { // only splice array when item is found
                          ModalDataDay?.listDay.splice(index, 1); // 2nd parameter means remove one item only
                        }
                        setModaDatalDay({ ...ModalDataDay })
                      }}>
                        ลบ
                      </Button>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Calendar
      headerRender={({ value, type, onChange, onTypeChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];

        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current.month(i);
          months.push(localeData.monthsShort(current));
        }

        for (let index = start; index < end; index++) {
          monthOptions.push(
            <Select.Option className="month-item" key={`${index}`}>
              {months[index]}
            </Select.Option>
          );
        }
        const month = value.month();

        const year = value.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          );
        }
        return (
          <div style={{ padding: 10 }}>
            <div className="flex flex-row m-auto w-[280px] text-center justify-between">
              <ArrowBackIosNewIcon value="month" onClick={() => {
                const newValue = value.clone();
                newValue.subtract(1, 'months')
                onChange(newValue);
              }}>back</ArrowBackIosNewIcon>
              <div className="text-xl">{value.format('MMMM')} {Number(value.format('YYYY')) + 543}</div>
              <ArrowForwardIosIcon value="month" onClick={() => {
                const newValue = value.clone();
                newValue.add(1, 'months')
                onChange(newValue);
              }}></ArrowForwardIosIcon>
            </div>

          </div>
        );
      }}

      locale={locale}
      dateRender={(current) => {
        const num = (current.toString().length < 2) ? "0" + current : current
        return { num }
      }}
      dateCellRender={dateCellRender}
      onSelect={(date) => {
      }}
    />

  </div >
}


export default function Home(props) {
  console.log("props?.match?.params?.userid", props?.match?.params?.userid)
  let history = useHistory();
  const [page, setPage] = useState(1);
  const [isLoad, setLoading] = useState(true);
  const [patient, setPatient] = useState(null);
  const [daily, setDaily] = useState([]);
  const [stepQ, setStepQ] = useState(null);
  const [other, setOther] = useState(null);
  const [vaccinate, setVaccinate] = useState('-');
  const [age, setAge] = useState('-');
  const [url, setUrl] = useState("https://wscba.or.th/wp-content/uploads/2020/07/avatar-1577909_1280.png");
  const [statusColor, setStatusColor] = useState(null);
  const [call, setCall] = useState(null);
  const [doctornote, setDoctornote] = useState(null);
  const [formFirstAssessment, setFormFirstAssessment] = useState(null);
  let [U, setU] = useState(null)
  let [R, setR] = useState(null)
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  const [ismodal1, setModal1] = useState(false);
  const [isModalCall, setModalCall] = useState(false);
  const [isModalDoctornote, setModalDoctornote] = useState(false);
  const [ismodal2, setModal2] = useState(false);
  const [ismodal3, setModal3] = useState(false);
  const [ismodal4, setModal4] = useState(false);
  const [ismodal5, setModal5] = useState(false);

  const [form, setForm] = useState({
    "_id": null,
    "hash_cid": null,
    "cid": null,
    "temperature": null,
    "oxygen_sat": null,
    "heart_rate": null,
    "no_symptoms": false,
    "fever": false,
    "running_nose": false,
    "sore_throat": false,
    "severe_cough": false,
    "chesty_cough": false,
    "poor_appetite": false,
    "fatigue": false,
    "tired_when_talking": false,
    "tired_when_resting": false,
    "chest_pain_when_breathing": false,
    "diarrhea_3day": false,
    "other_symptoms": null,
    "other_symptoms_value": null,
    "age_60": false,
    "BMI": false,
    "COPD": false,
    "CKD": false,
    "Chronic_Heart_Disease": false,
    "CVA": false,
    "T2DM": false,
    "Cirrhosis": false,
    "Immunocompromise": false,
    "weight": null,
    "height": null,
    "Absolute_lymphocyte_min": false,
    "age": null,
    "has_hr": false,
    "has_o2": false,
    "has_t": false,
    "pregnant": null,
    "eat_e1": false,
    "eat_e2": false,
    "date_atk": false,
    "who_atk": null,
    "check_covid": null,
    "hospital_atk": null,
    "xray": null
  });

  let [datemake, setDatemake] = useState(null)
  let [datemakeM3, setDatemakeM3] = useState(null)
  let [datemakeM2, setDatemakeM2] = useState(null)
  let [datemakeFinal, setDatemakeFinal] = useState(null)

  const [isModalDaily, setModalDaily] = useState(false);
  // const [modal2, setmodaldata2] = useState(null);

  const handleChange = (event) => {
    setForm({ ...form, ...{ [event.target.name]: event.target.value } })
  }

  function handleChangeCheck(event, value) {
    setForm({ ...form, ...{ [event.target.name]: !form[event.target.name], "no_symptoms": false, } })
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current < moment().endOf('day');
  }

  useEffect(async () => {
    let userid = props?.match?.params?.userid
    let round = props?.match?.params?.round

    let _data = await api.query(
      'db/homecare/patient_risk/query',
      {
        "query": {
          "hash_cid": userid
        }
      }
    )

    let form_first_assessment = await api.query(
      'db/homecare/form_risk/query',
      {
        "query": {
          "hash_cid": userid,
          // "round": round
        }
      }
    )

    console.log("_data", _data)
    if (_data[0].url) setUrl(_data[0].url)
    if (_data[0].vaccinate) setVaccinate(_data[0].vaccinate)
    if (_data[0].age) setAge(_data[0].age)
    setPatient(_data[0])

    let role = await localStorage.getItem('role')
    let user = await localStorage.getItem('user_wecaremaesot')
    setU(JSON.parse(user))
    setR(JSON.parse(role)[0])

    setFormFirstAssessment(form_first_assessment[0])
    setLoading(false)
    if (form_first_assessment[0].date_queue_patient) {
      setDatemakeM2(moment(form_first_assessment[0].date_queue_patient, 'x'))
      setDatemakeFinal(moment(form_first_assessment[0].date_queue_patient, 'x'))
    }

    console.log("form_first_assessment[0].date_queue_confirm", form_first_assessment[0].date_queue_confirm)

    // if (form_first_assessment[0].date_queue_confirm) {
    //   setDatemakeM3(moment(form_first_assessment[0].date_queue_confirm, 'x'))
    //   setDatemakeFinal(moment(form_first_assessment[0].date_queue_confirm, 'x'))
    // }

  }, [])

  useEffect(() => {

  }, [formFirstAssessment])

  if (isLoad) {
    return (<Layout ispadding >

      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundSize: 'contain'
      }}>
        <p> Loading </p>
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    </Layout>)
  }
  return (
    <Layout ispadding is4 >

      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        // success
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />

      <Modal
        title="จำหน่ายผู้ป่วยออกจากโรงพยาบาล"
        centered
        visible={ismodal1}
        onOk={async () => {

          let user = localStorage.getItem('user')
          let _formFirstAssessment = { ...formFirstAssessment }
          _formFirstAssessment['discharge'] = true
          _formFirstAssessment['discharge_time'] = new Date().getTime()
          _formFirstAssessment['discharge_user'] = user
          await api.insert(`db/homecare/form_first_assessment/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          // setPatient({ ..._patient })
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModal1(false)
        }}
        okText={'ใช่'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModal1(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >คุณต้องการจำหน่ายผู้ป่วย ชื่อ{`${patient?.title}${patient?.firstname} ${patient?.lastname}`} เขตบริการ {`${patient?.hostmain} (${patient?.hostmainid})`} ออกจาก home isolation ไหม?</p>

      </Modal>


      <Modal
        title="จัดการนัดหมายคนไข้"
        maskClosable={false}
        centered
        visible={ismodal2}
        onOk={async () => {

          let _formFirstAssessment = { ...formFirstAssessment }
          _formFirstAssessment['status_queue'] = stepQ
          _formFirstAssessment['other_messages'] = other

          _formFirstAssessment.date_queue_patient = datemakeM2.format('x')
          _formFirstAssessment.date_queue_doctor = datemakeM2.format('x')
          _formFirstAssessment.date_queue_confirm = datemakeM2.format('x')

          await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setDatemakeFinal(moment(datemakeM2.format('x'), 'x'))
          setModal2(false)
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัดสำเร็จ' } })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModal2(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >ชื่อนามสกุล: {`${patient?.title}${patient?.firstname} ${patient?.lastname}`} </p>
        <p className="text-lg" >เขตบริการ: {`${patient?.hostmain} (${patient?.hostmainid})`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันนัดจากผู้ป่วยจากฟอร์ม: {moment(formFirstAssessment.date_queue_patient, 'x').format('l')}  </p>
        </div>
        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <p className="text-lg" >เลือก: </p>
          <Select
            value={stepQ}
            style={{ width: 200 }}
            size={"large"}
            onChange={(value) => {
              console.log("value", value)
              setStepQ(value)
            }}
          >
            <Option value="confirm_patient">นัด</Option>
            <Option value="stop" >ไม่นัด</Option>
            <Option value="refer">ส่งต่อ</Option>
            <Option value="other" >อื่นๆ</Option>
          </Select>
        </div>
        {
          stepQ == 'other' &&
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ flexDirection: 'column', display: 'flex', marginTop: 24 }}>
              <p className="text-lg" >ข้อความ: </p>
              <Input
                style={{ width: 700 }}
                placeholder=""
                name="number"
                value={other}
                onChange={(e) => {
                  const _value = e.target.value;
                  setOther(_value)
                }}
              />

            </div>
          </div>
        }
        {
          stepQ == 'confirm_patient' &&
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ flexDirection: 'column', display: 'flex', marginTop: 24 }}>
              <p className="text-lg" >เลือกวันนัด: </p>
              <DatePicker
                style={{ width: '285px', height: 50, margin: 6 }}
                size={"large"}
                format={'DD/MM/YYYY'}
                disabledDate={disabledDate}
                locale={locale}
                value={datemakeM2}
                onChange={(date) => {
                  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  date.toISOString()
                  setDatemakeM2(date)
                  setFormFirstAssessment({
                    ...formFirstAssessment, ...{
                      date_queue_doctor: Number(date.format('x')),
                    }
                  })
                }}
              />

            </div>
          </div>
        }

      </Modal>


      <Modal
        title="เปลี่ยนนัดหมายคนไข้"
        centered
        visible={ismodal5}
        onOk={async () => {

          let _formFirstAssessment = { ...formFirstAssessment }

          _formFirstAssessment.date_queue_patient = datemakeM2.format('x')
          _formFirstAssessment.date_queue_doctor = datemakeM2.format('x')
          _formFirstAssessment.date_queue_confirm = datemakeM2.format('x')

          await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setDatemakeFinal(moment(datemakeM2.format('x'), 'x'))
          setModal5(false)
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัดสำเร็จ' } })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModal5(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >ชื่อนามสกุล: {`${patient?.title}${patient?.firstname} ${patient?.lastname}`} </p>
        <p className="text-lg" >เขตบริการ: {`${patient?.hostmain} (${patient?.hostmainid})`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันนัดจากผู้ป่วยจากฟอร์ม: {moment(formFirstAssessment.date_queue_patient, 'x').format('l')}  </p>
        </div>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div style={{ flexDirection: 'column', display: 'flex', marginTop: 24 }}>
            <p className="text-lg" >เลือกวันนัดใหม่: </p>
            <DatePicker
              style={{ width: '285px', height: 50, margin: 6 }}
              size={"large"}
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
              locale={locale}
              value={datemakeM2}
              onChange={(date) => {
                date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                date.toISOString()
                setDatemakeM2(date)
                setFormFirstAssessment({
                  ...formFirstAssessment, ...{
                    date_queue_doctor: Number(date.format('x')),
                  }
                })
              }}
            />

          </div>
        </div>

      </Modal>


      <Modal
        title="บันทึกวันนัด"
        centered
        visible={ismodal3}
        onOk={async () => {
          let _formFirstAssessment = { ...formFirstAssessment }
          console.log("_formFirstAssessment", _formFirstAssessment)
          if (!_formFirstAssessment.date_queue_confirm) {
            _formFirstAssessment.date_queue_confirm = datemakeM3.format('x')
          }
          _formFirstAssessment['status_queue'] = 'confirm_patient'
          _formFirstAssessment['confirm_patient'] = 'SHPH'
          await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModal3(false)
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัดสำเร็จ' } })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModal3(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >ชื่อนามสกุล: {`${patient?.title}${patient?.firstname} ${patient?.lastname}`} </p>
        <p className="text-lg" >เขตบริการ: {`${patient?.host} (${patient?.hostid})`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันนัดจากหมอ: {moment(formFirstAssessment.date_queue_doctor, 'x').format('l')}  </p>
        </div>
        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <p className="text-lg" >เลือกวันนัดใหม่: </p>
          <DatePicker
            style={{ width: '285px', height: 50, margin: 6 }}
            size={"large"}
            format={'DD/MM/YYYY'}
            disabledDate={disabledDate}
            locale={locale}
            value={datemakeM3}
            onChange={(date) => {
              date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              date.toISOString()
              setDatemakeM3(date)
              // setForm({
              //   ...form, ...{
              //     date_queue_confirm: Number(date.format('x')),
              //   }
              // })
              setFormFirstAssessment({
                ...formFirstAssessment, ...{
                  date_queue_confirm: Number(date.format('x')),
                }
              })
            }}
          />

        </div>
      </Modal>

      <Modal
        title="บันทึกวันนัด"
        centered
        visible={ismodal4}
        onOk={async () => {

        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green', display: 'none' } }}
        onCancel={() => setModal4(false)}
        cancelText={'ยกเลิก'}
        width={1400}
      >
        <ModalCalendar patient={patient} show={show} setShow={setShow} setForm={(res) => {
          setForm({ ...res })
          setModal4(false)
        }} />
      </Modal>
      <Modal
        title="เปลี่ยนระดับสีของผู้ป่วย"
        centered
        visible={isModalCall}
        onOk={async () => {
          let user = localStorage.getItem('user_wecaremaesot')
          let _formFirstAssessment = { ...formFirstAssessment }
          let _patient = { ...patient }
          _formFirstAssessment['is_call'] = call
          await api.insert(`db/homecare/form_first_assessment/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModalCall(false)
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModalCall(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >ชื่อนามสกุล: {`${patient?.title}${patient?.firstname} ${patient?.lastname}`} </p>
        <p className="text-lg" >เขตบริการ: {`${patient?.hostmain} (${patient?.hostmainid})`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg">โทรสอบถาม: </p>
          <Select
            defaultValue="lucy"
            style={{ width: 190, marginLeft: 4 }}
            value={call}
            onChange={(value) => {
              setCall(value)
            }}
          >
            <Option value="ยังไม่ได้โทร">ยังไม่ได้โทร</Option>
            <Option value="โทรแล้ว">โทรแล้ว</Option>
            <Option value="โทรใหม่อีกครั้ง">โทรใหม่อีกครั้ง</Option>
          </Select>

        </div>

      </Modal>

      <Modal
        title="Doctor Note"
        centered
        visible={isModalDoctornote}
        onOk={async () => {
          let user = localStorage.getItem('user_wecaremaesot')
          let _formFirstAssessment = { ...formFirstAssessment }
          let _patient = { ...patient }
          _formFirstAssessment['doctornote'] = doctornote
          await api.insert(`db/homecare/form_first_assessment/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModalDoctornote(false)
          setDoctornote('')
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setModalDoctornote(false)
          setDoctornote('')

        }}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <TextArea
          rows={4}
          placeholder="Note"
          maxLength={512}
          onChange={(event) => {
            setDoctornote(event.target.value)
          }}
          value={doctornote}
        />
      </Modal>

      <Modal
        title="เพิ่มแบบส่งอาการประจำวันของผู้ป่วย Home Isolate"
        centered
        visible={isModalDaily}
        onOk={async () => {
          let _form = { ...form }
          let status = 'green'
          if (!_form.date_make) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่วันที่' } })

          if (_form.has_hr === false) {
            if (!_form.heart_rate) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรของคุณ' } })
            let _heart_rate = Number(_form.heart_rate)
            if (Number.isNaN(_heart_rate)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรของคุณเป็นตัวเลข' } })
            if (_heart_rate < 30 || _heart_rate > 300) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรในช่วง 30 - 300 bmp' } })
            }
            _form['heart_rate'] = _heart_rate
          }

          if (_form.has_t === false) {
            if (!_form.temperature) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายของคุณ' } })
            let _temperature = Number(_form.temperature)
            if (Number.isNaN(_temperature)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายของคุณเป็นตัวเลข' } })
            if (_temperature < 33 || _temperature > 43) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายในช่วง 33 - 43 °C' } })
            }
            _form['temperature'] = _temperature
          }


          if (_form.has_o2 === false) {
            if (!_form.oxygen_sat) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ออกซิเจนในเลือดของคุณ' } })
            let _oxygen_sat = Number(_form.oxygen_sat)
            if (Number.isNaN(_oxygen_sat)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ออกซิเจนในเลือดของคุณเป็นตัวเลข' } })
            if (_oxygen_sat < 50 || _oxygen_sat > 100) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กกรุณาใส่ออกซิเจนในเลือดในช่วง 50 - 100 Sp02' } })
            }
            _form['oxygen_sat'] = _oxygen_sat
          }

          if (
            (_form.fever ||
              _form.running_nose ||
              _form.sore_throat ||
              _form.severe_cough ||
              _form.chesty_cough ||
              _form.poor_appetite ||
              _form.fatigue ||
              _form.tired_when_talking ||
              _form.tired_when_resting ||
              _form.chest_pain_when_breathing ||
              _form.diarrhea_3day ||
              _form.other_symptoms ||
              _form.no_symptoms) === false
          ) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'โปรดตอบข้อ 2.มีอาการอะไรบ้าง (ถ้าไม่มีอาการโปรดเลือกว่าไม่มีอาการใดๆ)' } })

          if (_form.has_o2 === false && typeof _form['oxygen_sat'] === 'number' && (_form['oxygen_sat'] >= 50 && _form['oxygen_sat'] < 96)) {
            status = 'red'
          }

          let user_wecaremaesot = await localStorage.getItem('user')
          _form['cid'] = formFirstAssessment.cid
          let pre_data = await api.query(`db/homecare/patient/query`, { query: { cid: formFirstAssessment.cid } })
          let pre_form_first_assessment = await api.query('db/homecare/form_first_assessment/query',
            {
              "aggregate": [
                {
                  "$match": {
                    "cid": formFirstAssessment.cid,
                    "round": formFirstAssessment.round
                  }
                },
              ]
            })
          let pre_form_daily_assessment = await api.query('db/homecare/form_daily_assessment/query',
            {
              "aggregate": [
                {
                  "$match": {
                    "cid": formFirstAssessment.cid,
                    "date_make": _form.date_make,
                    "ref_round": formFirstAssessment.round
                  }
                },
              ]
            })
          if (pre_form_daily_assessment.length > 0) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `คุณได้ส่งแบบส่งอาการประจำวัน ${util.getTimeLong(_form.date_make)} แล้ว` } })
          }
          _form['_id'] = `${formFirstAssessment.cid}_r${formFirstAssessment.round}_d${datemake.format('YYYYMMDD')}`
          _form['ref_round'] = formFirstAssessment.round
          _form['hash_cid'] = md5(formFirstAssessment.cid)
          _form['updatetime'] = new Date().getTime()
          _form['make_by'] = user_wecaremaesot

          await api.insert(`db/homecare/form_daily_assessment/data/${_form._id}`, _form)

          let _datenow = moment()
          _datenow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          _datenow.toISOString()
          if (status == 'red' && _datenow.format('x') == _form.date_make) {
            pre_form_first_assessment[0].status = status
            await api.insert(`db/homecare/form_first_assessment/data/${pre_form_first_assessment[0]._id}`, pre_form_first_assessment[0])
            // await api.notify(`ผู้ป่วยสีแดง\nเนื่องจาก: SpO2 < 96% (${_form.oxygen_sat}%)\nID/HN:${formFirstAssessment.cid}\nชื่อ${pre_data[0].title}${pre_data[0].firstname} ${pre_data[0]?.lastname}\nอายุ: ${pre_data[0].age} ปี\nเบอร์โทร: ${pre_data[0].tel}\nเขต:${pre_data[0].host}`)
          }

          let _form_daily_assessment = await api.query(
            'db/homecare/form_daily_assessment/query',
            {
              "aggregate": [
                {
                  $match: {
                    "hash_cid": md5(formFirstAssessment.cid),
                    "ref_round": formFirstAssessment.round
                  }
                },
                {
                  $addFields: {
                    date_make: { $toDouble: "$date_make" },
                  }
                },
                { $sort: { date_make: 1 } }
              ]
            }
          )

          setDaily(_form_daily_assessment)
          setForm({
            "_id": null,
            "hash_cid": null,
            "cid": null,
            "temperature": "",
            "oxygen_sat": "",
            "heart_rate": "",
            "no_symptoms": false,
            "fever": false,
            "running_nose": false,
            "sore_throat": false,
            "severe_cough": false,
            "chesty_cough": false,
            "poor_appetite": false,
            "fatigue": false,
            "tired_when_talking": false,
            "tired_when_resting": false,
            "chest_pain_when_breathing": false,
            "diarrhea_3day": false,
            "other_symptoms": null,
            "other_symptoms_value": null,
            "age_60": false,
            "BMI": false,
            "COPD": false,
            "CKD": false,
            "Chronic_Heart_Disease": false,
            "CVA": false,
            "T2DM": false,
            "Cirrhosis": false,
            "Immunocompromise": false,
            "weight": null,
            "height": null,
            "Absolute_lymphocyte_min": false,
            "age": null,
            "has_hr": false,
            "has_o2": false,
            "has_t": false,
            "pregnant": null,
            "eat_e1": false,
            "eat_e2": false,
            "date_atk": false,
            "who_atk": null,
            "check_covid": null,
            "hospital_atk": null,
            xray: null
          });
          setDatemake(null)
          setModalDaily(false)
          setFormFirstAssessment(pre_form_first_assessment[0])
          return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setForm({
            "_id": null,
            "hash_cid": null,
            "cid": null,
            "temperature": "",
            "oxygen_sat": "",
            "heart_rate": "",
            "no_symptoms": false,
            "fever": false,
            "running_nose": false,
            "sore_throat": false,
            "severe_cough": false,
            "chesty_cough": false,
            "poor_appetite": false,
            "fatigue": false,
            "tired_when_talking": false,
            "tired_when_resting": false,
            "chest_pain_when_breathing": false,
            "diarrhea_3day": false,
            "other_symptoms": null,
            "other_symptoms_value": null,
            "age_60": false,
            "BMI": false,
            "COPD": false,
            "CKD": false,
            "Chronic_Heart_Disease": false,
            "CVA": false,
            "T2DM": false,
            "Cirrhosis": false,
            "Immunocompromise": false,
            "weight": null,
            "height": null,
            "Absolute_lymphocyte_min": false,
            "age": null,
            "has_hr": false,
            "has_o2": false,
            "has_t": false,
            "pregnant": null,
            "eat_e1": false,
            "eat_e2": false,
            "date_atk": false,
            "who_atk": null,
            "check_covid": null,
            "hospital_atk": null,
            xray: null
          });
          setDatemake(null)
          setModalDaily(false)
        }}
        cancelText={'ยกเลิก'}
        width={800}
      >

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            วันที่แบบส่งอาการประจำวัน
            <DatePicker
              style={{ width: '285px', height: 50, margin: 6 }}
              size={"large"}
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
              locale={locale}
              value={datemake}
              onChange={(date) => {
                date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                date.toISOString()
                setDatemake(date)
                setForm({
                  ...form, ...{
                    date_make: Number(date.format('x')),
                    date_make_see: date.format('DDMMYYYY')
                  }
                })
              }}
            />
          </Grid>
          <Grid item xs={0} md={6} style={{ padding: 0, margin: 0 }} />
          <Grid item xs={4} md={4}>
            <TextField
              disabled={form.has_hr}
              style={{ width: '100%', backgroundColor: form.has_hr ? '#999' : '#fff' }}
              pattern="[0-9]*"
              inputProps={{
                maxLength: 3,
                inputmode: "decimal"
              }}
              id="heart_rate"
              name="heart_rate"
              label="ชีพจร HR"
              variant="outlined"
              value={form.heart_rate}
              onChange={handleChange}
            />

            <FormControlLabel
              control={<Checkbox style={{ marginLeft: '12px' }} checked={form.has_hr} onChange={(event) => {
                setForm({
                  ...form, ...{
                    [event.target.name]: !form[event.target.name],
                    "heart_rate": "",
                  }
                })
              }} name='has_hr' />}
              label="ไม่มีเครื่องวัดชีพจร"
            />
          </Grid>


          <Grid item xs={4} md={4}>
            <TextField
              disabled={form.has_t}
              style={{ width: '100%', backgroundColor: form.has_t ? '#999' : '#fff' }}
              pattern="[0-9]*"
              inputProps={{
                maxLength: 5,
                inputmode: "decimal"
              }}
              id="temperature"
              name="temperature"
              label="อุณหภูมิร่างกาย"
              variant="outlined"
              value={form.temperature}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox style={{ marginLeft: '12px' }} checked={form.has_t} onChange={(event) => {
                setForm({
                  ...form, ...{
                    [event.target.name]: !form[event.target.name],
                    "temperature": "",
                  }
                })
              }} name='has_t' />}
              label="ไม่มีเครื่องอุณหภูมิร่างกาย"
            />

          </Grid>


          <Grid item xs={4} md={4}>
            <TextField
              disabled={form.has_o2}
              style={{ width: '100%', backgroundColor: form.has_o2 ? '#999' : '#fff' }}
              pattern="[0-9]*"
              inputProps={{
                maxLength: 5,
                inputmode: "decimal"
              }}
              id="oxygen_sat"
              name="oxygen_sat"
              label="ออกซิเจนในเลือด O2SAT"
              variant="outlined"
              value={form.oxygen_sat}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox style={{ marginLeft: '12px' }} checked={form.has_o2} onChange={(event) => {
                setForm({
                  ...form, ...{
                    [event.target.name]: !form[event.target.name],
                    "oxygen_sat": "",
                  }
                })
              }} name='has_o2' />}
              label="ไม่มีเครื่องออกซิเจนในเลือด"
            />
          </Grid>



          <Grid item xs={12} md={12}>
            <FormGroup>
              <p class="text-2xl"> มีอาการอะไรบ้าง</p>
              <FormControlLabel
                control={<Checkbox
                  style={{ margin: 4 }}
                  checked={form.no_symptoms}
                  onChange={() => {
                    setForm({
                      ...form, ...{
                        "no_symptoms": !form.no_symptoms,
                        "fever": false,
                        "running_nose": false,
                        "sore_throat": false,
                        "severe_cough": false,
                        "chesty_cough": false,
                        "poor_appetite": false,
                        "fatigue": false,
                        "tired_when_talking": false,
                        "tired_when_resting": false,
                        "chest_pain_when_breathing": false,
                        "diarrhea_3day": false,
                      }
                    })
                  }}
                  name='no_symptoms' />}
                label="ไม่มีอาการใดๆ/No Symptoms"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.running_nose} onChange={handleChangeCheck} name='running_nose' />}
                label="มีน้ำมูก/Running nose"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.sore_throat} onChange={handleChangeCheck} name='sore_throat' />}
                label="เจ็บคอ/Sore throat"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.severe_cough} onChange={handleChangeCheck} name='severe_cough' />}
                label="ไอเยอะ/Severe Cough"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.chesty_cough} onChange={handleChangeCheck} name='chesty_cough' />}
                label="มีเสมหะ/Chesty cough"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.fatigue} onChange={handleChangeCheck} name='fatigue' />}
                label="อ่อนเพลียมาก (Fatigue)"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.tired_when_talking} onChange={handleChangeCheck} name='tired_when_talking' />}
                label="หอบเหนื่อย ขณะพูดหรือออกแรง/Tired when talking or having activity"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.tired_when_resting} onChange={handleChangeCheck} name='tired_when_resting' />}
                label="หอบเหนื่อยขณะพัก/Tired when resting"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.chest_pain_when_breathing} onChange={handleChangeCheck} name='chest_pain_when_breathing' />}
                label="เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing"
              />

              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.diarrhea_3day} onChange={handleChangeCheck} name='diarrhea_3day' />}
                label="ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)"
              />
              <Divider />
              {/* <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.other_symptoms} onChange={handleChangeCheck} name='other_symptomsother_symptoms' />}
                label="อื่นๆ"
              />
              <Divider /> */}

            </FormGroup>
          </Grid>

        </Grid>


      </Modal>

      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: 'url(' + bg + ')',
        backgroundSize: 'contain'
      }}>
        <div
          style={{
            borderRadius: 12,
            width: "1200px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: 'column',
            alignSelf: 'center'
          }}
        >

          <div
            style={{
              background: "#fff",
              borderRadius: 12,
              width: "100%",
              display: "flex",
              flexDirection: 'column',
              marginTop: 12,
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            }}
          >

            <div style={{ width: '100%', borderRadius: 24, flexDirection: "row", display: "flex", padding: 12, paddingBottom: 0 }}>
              <img
                class="w-32 h-32"
                src={url}
                alt=""
              />
              <div
                style={{
                  borderRadius: 12,
                  display: "flex",
                  padding: 12,
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <div style={{ fontSize: 24 }}>
                  {`${patient?.title}${patient?.firstname} ${patient?.lastname}`}
                </div>
                <div style={{}}>
                  HN. : {`${patient?.HN ? patient?.HN : '-'}`}
                </div>
                <div style={{}}>
                  รพสต. : {`${patient?.host} (${patient?.hostid})`}
                </div>
                <div style={{}}>
                  เขตบริการ : {`${patient?.hostmain} (${patient?.hostmainid})`}
                </div>
                <div style={{}}>
                  เบอร์โทรศัพท์ : {patient?.tel}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  วันที่นัด :
                  {
                    datemakeFinal &&
                    datemakeFinal.format('l')
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  สถานะการนัด :
                  {
                    <StatuTest formFirstAssessment={formFirstAssessment} />
                  }
                </div>
                < div style={{ flexDirection: 'row-reverse', display: 'flex', width: '100%' }}>

                  {/* {
                    R === 'doctor' && formFirstAssessment.status_queue == 'wait_confirm_doctor' &&
                    <Button style={{ backgroundColor: 'green', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModal2(true)
                        setStatusColor(formFirstAssessment?.status)
                      }}
                    >
                      จัดการนัดหมายคนไข้
                    </Button>
                  }
                  {
                    R === 'SHPH' && formFirstAssessment.status_queue == 'wait_confirm_patient' &&
                    < Button style={{ backgroundColor: 'DodgerBlue', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModal3(true)
                        setStatusColor(formFirstAssessment?.status)
                      }}
                    >
                      ยื่นยันแทนผู้ป่วย
                    </Button>
                  }
                  {
                    R === 'SHPH' && formFirstAssessment.status_queue == 'wait_confirm_patient' &&
                    < Button style={{ backgroundColor: 'DodgerBlue', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModal4(true)
                      }}
                    >
                      นัดหมายคนไข้
                    </Button>
                  }

                  {
                    formFirstAssessment.status_queue == 'confirm_patient' &&
                    <Button style={{ backgroundColor: 'red', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModal5(true)
                        setStatusColor(formFirstAssessment?.status)
                      }}
                    >
                      เปลี่ยนนัดหมายคนไข้
                    </Button>
                  }



                  <Button style={{ backgroundColor: 'DodgerBlue', margin: 2, color: '#fff' }}
                    onClick={() => {
                      setModalDoctornote(true)
                      setDoctornote(formFirstAssessment?.doctornote)
                    }}
                  >
                    doctor note
                  </Button> */}

                </div>
              </div>



            </div>
            <Divider />
            <div style={{ padding: 4 }}>
              <Menu mode="horizontal" style={{ backgroundColor: 'rgba(255,0,0,0)' }} defaultSelectedKeys="mail">
                <Menu.Item key="mail" icon={<FieldTimeOutlined />} onClick={() => {
                  setPage(1)
                }}>
                  แบบประเมิน
                </Menu.Item>
                <Menu.Item key="alipay" icon={<AppstoreOutlined />}
                  onClick={() => {
                    setPage(4)
                  }}
                >
                  ข้อมูลติดต่อ
                </Menu.Item>
                {/* <Menu.Item key="al" icon={<AppstoreOutlined />}
                  onClick={() => {
                    setPage(5)
                  }}
                >
                  แจ้งผลการรักษา
                </Menu.Item> */}
              </Menu>

            </div>
          </div>


          {
            page === 1 && <>
              <div
                style={{
                  marginTop: 12,
                  borderRadius: 12,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    borderRadius: 12,
                    width: "1200px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: 'row',
                  }}
                >

                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 12,
                      width: "100%",
                      display: "flex",
                      // justifyContent: "space-between",
                      flexDirection: 'column',
                      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                      marginBottom: 48
                    }}
                  >

                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ padding: 12 }}>
                        <div style={{ fontSize: 24 }}>
                          แบบประเมินคัดกรองโรคเสี่ยง
                        </div>
                      </div>
                      <div style={{ paddingTop: '24px', paddingRight: '12px' }}>
                      </div>
                    </div>


                    <Divider />
                    <div style={{ fontSize: 24, }}>
                      <Grid container spacing={2} padding={2}>
                        <Grid item xs={12} md={12}>
                          <p class="text-xl"> การคัดกรอง</p>
                        </Grid>

                        <Grid item xs={12} md={12} style={{ padding: 12, paddingLeft: 48 }}>
                          <FormControlLabel
                            style={{ cursor: 'default' }}
                            control={<Checkbox
                              style={{
                                margin: 4,
                                cursor: 'default'
                              }}
                              checked={formFirstAssessment?.screening_breast_cancer}
                              name='chesty_cough'
                            />}
                            label="มะเร็งเต้านม"
                          />
                          <FormControlLabel
                            style={{ cursor: 'default' }}
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.screening_fit_test} name='chesty_cough' />}
                            label="Fit Test"
                          />
                          <FormControlLabel
                            style={{ cursor: 'default' }}
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.screening_hpv_dna_test} name='chesty_cough' />}
                            label="HPV DNA test"
                          />
                          <FormControlLabel
                            style={{ cursor: 'default' }}
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.screening_hepatites} name='chesty_cough' />}
                            label="ไวรัสตับอักเสบ"
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <p class="text-xl"> ผลการตรวจ</p>
                        </Grid>

                        <Grid item xs={12} md={12} marginLeft={4} paddingTop={0}>
                          {/* p1 */}
                          <FormControlLabel
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_breast_cancer} name='chesty_cough' />}
                            label="มะเร็งเต้านม"
                          />
                          <Grid item xs={12} md={12} paddingLeft={4}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_breast_cancer_palpation_lump} name='chesty_cough' />}
                              label="คลำพบก้อน"
                            />
                            <Space>
                              <FormControlLabel
                                control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_breast_cancer_other} name='chesty_cough' />}
                                label="อื่นๆ"
                              />
                              <p className="text-[16px] -ml-4 mt-1">{formFirstAssessment.results_breast_cancer_other_text}</p>
                            </Space>
                          </Grid>
                          <FormControlLabel
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_fit_test} name='chesty_cough' />}
                            label={`Fit test ... ${formFirstAssessment?.results_fit_test_text ? formFirstAssessment?.results_fit_test_text : ''} .... +Ve`}
                          />
                        </Grid>


                        <Grid item xs={12} md={12} marginLeft={4} paddingTop={0}>
                          <FormControlLabel
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hpv_dna_test} name='chesty_cough' />}
                            label="HPV DNA test"
                          />

                          <Grid item xs={12} md={12} paddingLeft={4} paddingTop={0}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hpv_dna_test_type16} name='chesty_cough' />}
                              label="Type 16"
                            />
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hpv_dna_test_type18} name='chesty_cough' />}
                              label="Type 18"
                            />
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hpv_dna_test_nontype} name='chesty_cough' />}
                              label="Non Type 16 18"
                            />
                            <Space>
                              <FormControlLabel
                                control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hpv_dna_test_abnormal_call} name='chesty_cough' />}
                                label="call ผิดปกติอื่นๆ"
                              />
                              <p className="text-[16px] -ml-4 mt-1">{formFirstAssessment.results_hpv_dna_test_abnormal_call_text}</p>
                            </Space>

                          </Grid>
                        </Grid>



                        <Grid item xs={12} md={12} marginLeft={4} paddingTop={0}>
                          <FormControlLabel
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hpv_dna_test} name='results_hpv_dna_test' />}
                            label="ไวรัสตับอักเสบ"
                          />

                          <Grid item xs={12} md={12} paddingLeft={4} paddingTop={0}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hepatites_HBsAg} name='results_hepatites_HBsAg' />}
                              label="HBsAg positive"
                            />
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.results_hepatites_anti_hcv} name='results_hepatites_anti_hcv' />}
                              label="Anti HCV Positive"
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <p class="text-xl">Work up</p>
                        </Grid>

                        <Grid item xs={12} md={12} marginLeft={4} paddingTop={0}>
                          {/* p1 */}
                          <FormControlLabel paddingLeft={4}
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_breast_cancer} name='workup_breast_cancer' />}
                            label="มะเร็งเต้านม"
                          />
                          <Grid item xs={12} md={12} paddingLeft={4}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_breast_cancer_x_ray} name='workup_breast_cancer_x_ray' />}
                              label="กรณีมีแพทย์ศัลนกรรมต้องการ Ultrasound สามารถนัดที่ห้อง X-ray ได้เลย"
                            />
                          </Grid>
                          <Grid item xs={12} md={12} paddingLeft={4}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_breast_cancer_send_doctor} name='workup_breast_cancer_send_doctor' />}
                              label="ส่งพบแพทย์"
                            />
                          </Grid>
                          <FormControlLabel paddingLeft={4}
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_fit_test} name='workup_fit_test' />}
                            label="Fit test"
                          />
                          <Grid item xs={12} md={12} paddingLeft={4}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_fit_test_send_doctor} name='workup_fit_test_send_doctor' />}
                              label="ส่งพบแพทย์"
                            />
                          </Grid>
                          <FormControlLabel paddingLeft={4}
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hpv_dna_test} name='workup_hpv_dna_test' />}
                            label="HPV DNA test"
                          />
                          <Grid item xs={12} md={12} paddingLeft={4}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hpv_dna_test_send_doctor} name='workup_hpv_dna_test_send_doctor' />}
                              label="ส่งพบแพทย์"
                            />
                          </Grid>
                          <FormControlLabel paddingLeft={4}
                            control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hepatites} name='workup_hepatites' />}
                            label="ไวรัสตับอักเสบ"
                          />
                          <Grid item xs={12} md={12} paddingLeft={4}>
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hepatites_HBsAg} name='workup_hepatites_HBsAg' />}
                              label="HBsAg positive"
                            />
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hepatites_used_to_us} name='workup_hepatites_used_to_us' />}
                              label="เคย  US"
                            />
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hepatites_lft_afp} name='workup_hepatites_lft_afp' />}
                              label="เจาะ LFT AFP"
                            />
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hepatites_anti_hcv} name='workup_hepatites_anti_hcv' />}
                              label="Anti HCV Positive"
                            />
                            <FormControlLabel
                              control={<Checkbox style={{ margin: 4 }} checked={formFirstAssessment?.workup_hepatites_anti_hcv_cbc_lft} name='workup_hepatites_anti_hcv_cbc_lft' />}
                              label="Anti HIV CBC LFT"
                            />

                          </Grid>

                        </Grid>


                        <div
                          style={{
                            width: '100%',
                            backgroundColor: "#fff",
                            padding: 24,
                            borderRadius: 12,
                            marginTop: 32,
                          }}
                        >
                          <p style={{ fontSize: 24 }}>ไฟล์</p>
                          <Divider />
                          <Row>
                            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                              <Table
                                bordered
                                size="middle"
                                // dataSource={form?.fileList}
                                columns={columnsFile}
                                dataSource={formFirstAssessment?.fileList?.map((x, i) => ({
                                  ...x, index: i + 1, onDonwlaod: (d) => {
                                    fetch(`https://screening.wecaremaesot.org/minio/download/test/${x.nameMinio}`, {
                                      method: 'POST',
                                      headers: {
                                        'Content-Type': 'application/pdf',
                                      },
                                    })
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        const url = window.URL.createObjectURL(
                                          new Blob([blob], { type: 'application/pdf' }),
                                        );
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.download = x.name;
                                        link.click();
                                        link.parentNode.removeChild(link);
                                      });
                                  }
                                }))}

                                line
                              />
                            </div>
                          </Row>
                        </div>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }



          {
            page === 4 && <>
              <div
                style={{
                  marginTop: 12,
                  borderRadius: 12,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    borderRadius: 12,
                    width: "1200px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: 'row',
                  }}
                >

                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 12,
                      width: "100%",
                      display: "flex",
                      // justifyContent: "space-between",
                      flexDirection: 'column',
                      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                    }}
                  >
                    <div style={{ padding: 12 }}>
                      <div style={{ fontSize: 24 }}>
                        ข้อมูลติดต่อ
                      </div>
                      <div style={{}}>
                        ข้อมูลพื้นฐานทางคลินิกเมื่อผู้ป่วยลงทะเบียนใช้งานครั้งแรก
                      </div>
                    </div>
                    <Divider />
                    <div style={{ padding: 12 }}>
                      <div style={{}}> บ้านเลขที่ : <span style={{ color: 'green' }}>{patient.housenum}</span> </div>
                      <div style={{}}> หมู่ที่ : <span style={{ color: 'green' }}>{patient.villageno}</span> </div>
                      <div style={{}}> ซอย : <span style={{ color: 'green' }}>{patient.lane}</span> </div>
                      <div style={{}}> ถนน : <span style={{ color: 'green' }}>{patient.road}</span> </div>
                      <div style={{}}> ตำบล : <span style={{ color: 'green' }}>{patient.tumbonname}</span> </div>
                      <div style={{}}> อำเภอ : <span style={{ color: 'green' }}>{patient.cityname}</span> </div>
                      <div style={{}}> จังหวัด : <span style={{ color: 'green' }}>{patient.provincename}</span> </div>
                      <div style={{}}> รหัสไปรษณีย์ : <span style={{ color: 'green' }}>{patient.postcode}</span> </div>
                      <div style={{}}> เบอร์โทรศัพท์ : <span style={{ color: 'green' }}>{patient.tel}</span> </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }


        </div>
      </div>
    </Layout >
  )
}
