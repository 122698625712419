import React, { useState, useEffect } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { useHistory } from "react-router-dom";
import Divider from '@mui/material/Divider';
import md5 from 'md5';
import api from "../../util/api";
import { Grid } from '@mui/material';

import { DatePicker, Space } from 'antd';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import PdfCertificateATK from "../../services/PrintoutPdf/PdfCertificateATK";
import Layout from '../../components/LayoutPC';
import SweetAlert from 'sweetalert2-react';
import { Menu, Input, Avatar, Image, Timeline, Modal, Checkbox, Select } from 'antd';
// Button, 
import Button from '@mui/material/Button';
import { MailOutlined, AppstoreOutlined, FieldTimeOutlined, DeliveredProcedureOutlined, DashboardOutlined, ScanOutlined } from '@ant-design/icons';
import bg from '../../images/bg.png'
import { LensTwoTone } from "@mui/icons-material";
import { LoadingOutlined } from '@ant-design/icons';

import PdfReport from "../../services/PrintoutPdf/PdfReport";

import moment from 'moment';
import util from "../../util";

const { Option } = Select;
const { Search, TextArea } = Input;

const convertToDms = (dd, isLng) => {
  var dir = dd < 0
    ? isLng ? 'W' : 'S'
    : isLng ? 'E' : 'N';

  var absDd = Math.abs(dd);
  var deg = absDd | 0;
  var frac = absDd - deg;
  var min = (frac * 60) | 0;
  var sec = frac * 3600 - min * 60;
  // Round it to 2 decimal points.
  sec = Math.round(sec * 100) / 100;
  return deg + "°" + min + "'" + sec + '"' + dir;
}

const textAddr = (item) => {
  let { housenum,
    villageno,
    alley,
    lane,
    road,
    tumbonname,
    cityname,
    provincename } = item

  if (villageno) {
    villageno = villageno.replace(/\D/g, "")
  }
  let address = (housenum ? housenum + ' ' : '')
    + (villageno ? 'หมู่ที่ ' + villageno + ' ' : '')
    + (alley ? 'ตรอก' + alley + ' ' : '')
    + (lane ? 'ซอย' + lane + ' ' : '')
    + (road ? 'ถนน' + road + ' ' : '')
    + (tumbonname ? 'ต.' + tumbonname + ' ' : '')
    + (cityname ? 'อ.' + cityname + ' ' : '')
    + (provincename ? 'จ.' + provincename + ' ' : '')
  return (address)
}

let MAP_CALL = {
  'ยังไม่ได้โทร': '#F08080',
  'โทรแล้ว': '#CEFFCF',
  'โทรใหม่อีกครั้ง': '#ffffb1',
}
let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const symptoms = (x) => {
  if (x.no_symptoms) {
    return 'ไม่มีอาการใดๆ'
  } else {
    let text = ''
    if (x?.running_nose) {
      if (text !== '') text += ', '
      text += 'มีน้ำมูก'
    }

    if (x?.sore_throat) {
      if (text !== '') text += ', '
      text += 'เจ็บคอ'
    }

    if (x?.severe_cough) {
      if (text !== '') text += ', '
      text += 'ไอเยอะ'
    }

    if (x?.chesty_cough) {
      if (text !== '') text += ', '
      text += 'มีเสมหะ'
    }

    if (x?.fatigue) {
      if (text !== '') text += ', '
      text += 'อ่อนเพลียมาก'
    }

    if (x?.tired_when_talking) {
      if (text !== '') text += ', '
      text += 'ขณะพูดหรือออกแรง'
    }

    if (x?.tired_when_resting) {
      if (text !== '') text += ', '
      text += 'หอบเหนื่อยขณะพัก'
    }

    if (x?.chest_pain_when_breathing) {
      if (text !== '') text += ', '
      text += 'เจ็บอกเมื่อหายใจเข้าออก'
    }

    if (x?.diarrhea_3day) {
      if (text !== '') text += ', '
      text += 'ท้องเสีย มากกว่า 3 ครั้ง/วัน'
    }
    return text
  }
}

const columns = [
  { id: 'index', label: 'ครั้งที่', minWidth: 30 },
  { id: 'date_make', label: 'วันเวลาที่ทำแบบฟอร์ม', minWidth: 170 },
  { id: 'temperature', label: 'อุณหภูมิร่างกาย', minWidth: 170, align: 'center' },
  { id: 'oxygen_sat', label: 'ออกซิเจนในเลือด', minWidth: 170, align: 'center' },
  { id: 'heart_rate', label: 'ชีพจร', minWidth: 170, align: 'center' },
  { id: '-', label: 'ดูแบบฟอร์ม', minWidth: 170, type: 'button' },
];

const RanderQuesue = ({ patient }) => {

  if (!patient?.quesue) {
    return <p>สถานะการนัดผู้ป่วย: ไม่มีนัดผู้ป่วย</p>
  }

  if (patient?.quesue?.confrim_doctor) {
    let date = moment(patient?.quesue?.date_doctor, 'x').format('L')
    let time = patient?.quesue?.time_doctor
    let addr = patient?.quesue?.addr

    return (<>
      <p>สถานะการนัดผู้ป่วย: วันที่ {date} ช่วงเวลา {time} สถานที่: {addr}</p></>
    )
  }
  return null

}


function ColumnGroupingTable({ datas = [] }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [ismodal1, setModal1] = useState(false);
  const [modal1, setmodaldata1] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    if (datas) {
      setData([...datas]
        .sort((a, b) => b.date_make - a.date_make)
        .map((x, i) => ({ ...x, ...{ index: datas.length - i } })))
    }
  }, [datas])

  let ReaderRow = ({ value, column, row }) => {
    if (column.id === 'date_make') {
      return (<p>
        {util.getTimeLong(value)}
        {/* {moment(value, 'x').format('L')} */}
        {/* {new Date(value).toLocaleDateString()} */}
      </p>)
    } else if (column.type === 'button') {
      return (<Button style={{ backgroundColor: 'green', color: "#fff" }} onClick={() => {
        setModal1(true)
        setmodaldata1(row)
      }}>
        ดูแบบฟอร์ม
      </Button>)
    } else {
      return (<p>
        {value ? value : '-'}
      </p>)
    }
    return null
  }

  return (
    <Paper sx={{ width: '100%' }}>
      <Modal
        title={null}
        centered
        footer={null}
        onCancel={() => {
          setModal1(false)
          modal1(null)
        }}
        visible={ismodal1}
        width={800}
      >
        <div className="text-center text-xl"> แบบฟอร์มส่งอาการประจำวันของผู้ป่วย Home Isolate</div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div style={{ fontSize: 18 }}> วันเวลาที่ทำแบบฟอร์ม : <span style={{ color: 'green' }}>  {moment(modal1?.date_make, 'x').format('L')}</span></div>
        <div style={{ fontSize: 18 }}> อุณหภูมิร่างกาย : <span style={{ color: 'green' }}> {modal1?.temperature ? modal1?.temperature : '-'}<sup>°C</sup></span></div>
        <div style={{ fontSize: 18 }}> ออกซิเจนในเลือด O2SAT : <span style={{ color: 'green' }}> {modal1?.oxygen_sat ? modal1?.oxygen_sat : '-'}%SPO<sub>2</sub></span></div>
        <div style={{ fontSize: 18 }}> ชีพจร HR : <span style={{ color: 'green' }}>  {modal1?.heart_rate ? modal1?.heart_rate : '-'}  bmp</span></div>
        <div style={{ fontSize: 18 }}> อาการของผู้ป่วย</div>


        <div style={{ marginLeft: 12 }}>
          <Checkbox checked={modal1?.no_symptoms} > ไม่มีอาการใดๆ/No Symptoms</Checkbox>  <br />
          <Checkbox checked={modal1?.running_nose} > มีน้ำมูก/Running nose</Checkbox>  <br />
          <Checkbox checked={modal1?.sore_throat} > เจ็บคอ/Sore throat</Checkbox>  <br />
          <Checkbox checked={modal1?.severe_cough} > ไอเยอะ/Severe Cough</Checkbox>  <br />
          <Checkbox checked={modal1?.chesty_cough} > มีเสมหะ/Chesty cough</Checkbox>  <br />
          <Checkbox checked={modal1?.fatigue} > อ่อนเพลียมาก (Fatigue)</Checkbox>  <br />
          <Checkbox checked={modal1?.tired_when_talking} > หอบเหนื่อย ขณะพูดหรือออกแรง/Tired when talking or having activity</Checkbox> <br />
          <Checkbox checked={modal1?.tired_when_resting} > หอบเหนื่อยขณะพัก/Tired when resting</Checkbox> <br />
          <Checkbox checked={modal1?.chest_pain_when_breathing} > เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing</Checkbox> <br />
          <Checkbox checked={modal1?.diarrhea_3day} > ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)</Checkbox> <br />
          <Checkbox checked={modal1?.other_symptoms} > อื่นๆ {modal1?.other_symptoms ? `ระบุ${modal1?.other_symptoms_value}` : ''}</Checkbox> <br />
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
          <Button style={{ backgroundColor: 'green', color: "#fff" }} onClick={() => {
            setModal1(false)
            modal1(null)
          }}>
            ปิด
          </Button>

        </div>

      </Modal>
      <TableContainer sx={{ maxHeight: '100%', width: '100%' }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          < ReaderRow column={column} value={value} row={row} />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

const arrayhander = (arr = []) => {
  let result = [...arr]
  let size = 14 - arr.length
  while (size > 0) {
    result.push(null)
    --size
  }
  result = result.map(x => x === 0 ? null : x)
  return result
}


function genCertificate({ patient, form }) {
  console.log(">> form", form)
  // let _patient =
  return ([{
    "_id": form._id,
    "cid": patient.cid,
    "first_hlep": "1652029200000",
    "last_hlep": "1652029200000",
    "date_create": 1652082889030.0,
    "done": true,
    "date": "1652428467035",
    "time": "10.00 น.",
    "addr": "โรงพยาบาลแม่สอด (ตึกส้ม)",
    "is_atk": false,
    "patient": patient,
    "form_first": form,
    "name": `${patient?.title}${patient?.firstname} ${patient?.lastname}`
  }])
}


export default function Home(props) {
  let history = useHistory();
  const [page, setPage] = useState(1);
  const [isLoad, setLoading] = useState(true);
  const [patient, setPatient] = useState(null);
  const [daily, setDaily] = useState([]);
  const [date, setDate] = useState('-');
  const [dateCovid19, setDateCovid19] = useState('-');
  const [who_atk, setWho_ATK] = useState('-');
  const [hospital_atk, setHospital_ATK] = useState('-');
  const [check_covid, seCheck_Covid] = useState('-');
  const [bmi, setBmi] = useState('-');
  const [vaccinate, setVaccinate] = useState('-');
  const [age, setAge] = useState('-');
  const [url, setUrl] = useState("https://firebasestorage.googleapis.com/v0/b/hood-flashcard.appspot.com/o/image%20226%20(1).webp?alt=media&token=c2350064-bbef-478f-adce-15aa1481fafb");
  const [statusColor, setStatusColor] = useState(null);
  const [call, setCall] = useState(null);
  const [doctornote, setDoctornote] = useState(null);

  const [formFirstAssessment, setFormFirstAssessment] = useState(null);
  const [OO, setOO] = useState({
    oxygen_sat: '-',
    temperature: '-',
    heart_rate: '-'
  });

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  const [ismodal1, setModal1] = useState(false);
  const [isModalCall, setModalCall] = useState(false);
  const [isModalDoctornote, setModalDoctornote] = useState(false);
  const [ismodal2, setModal2] = useState(false);

  const [form, setForm] = useState({
    "_id": null,
    "hash_cid": null,
    "cid": null,
    "temperature": null,
    "oxygen_sat": null,
    "heart_rate": null,
    "no_symptoms": false,
    "fever": false,
    "running_nose": false,
    "sore_throat": false,
    "severe_cough": false,
    "chesty_cough": false,
    "poor_appetite": false,
    "fatigue": false,
    "tired_when_talking": false,
    "tired_when_resting": false,
    "chest_pain_when_breathing": false,
    "diarrhea_3day": false,
    "other_symptoms": null,
    "other_symptoms_value": null,
    "age_60": false,
    "BMI": false,
    "COPD": false,
    "CKD": false,
    "Chronic_Heart_Disease": false,
    "CVA": false,
    "T2DM": false,
    "Cirrhosis": false,
    "Immunocompromise": false,
    "weight": null,
    "height": null,
    "Absolute_lymphocyte_min": false,
    "age": null,
    "has_hr": false,
    "has_o2": false,
    "has_t": false,
    "pregnant": null,
    "eat_e1": false,
    "eat_e2": false,
    "date_atk": false,
    "who_atk": null,
    "check_covid": null,
    "hospital_atk": null,
    "xray": null
  });

  let [datemake, setDatemake] = useState(null)

  const [Quesue, setQuesue] = useState({
    isVisit: false,
    date: null,
    dateD: null
  });

  const [donwloadVisit, setDonwloadVisit] = useState({
    isVisit: false,
    date: null,
    dateD: null
  });
  const [listDoctor, setListDocter] = React.useState([]);
  const [doctor, setDocter] = React.useState({
    name: null,
    host: null,
    certid: null,
  });
  const [isModalDaily, setModalDaily] = useState(false);

  const handleChange = (event) => {
    setForm({ ...form, ...{ [event.target.name]: event.target.value } })
  }

  function handleChangeCheck(event, value) {
    setForm({ ...form, ...{ [event.target.name]: !form[event.target.name], "no_symptoms": false, } })
  }

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }


  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  useEffect(async () => {
    let userid = props?.match?.params?.userid
    let round = props?.match?.params?.round
    let _data = await api.query(
      'db/homecare/patient/query',
      {
        "query": {
          "hash_cid": userid
        }
      }
    )

    let form_daily_assessment = await api.query(
      'db/homecare/form_daily_assessment/query',
      {
        "aggregate": [
          {
            $match: {
              "hash_cid": userid,
              "ref_round": round
            }
          },
          {
            $addFields: {
              date_make: { $toDouble: "$date_make" },
            }
          },
          { $sort: { date_make: 1 } }
        ]
      }
    )

    let form_first_assessment = await api.query(
      'db/homecare/form_first_assessment/query',
      {
        "query": {
          "hash_cid": userid,
          "round": round
        }
      }
    )

    if (form_daily_assessment.length > 0) {
      let lastForm = form_daily_assessment[form_daily_assessment.length - 1]
      let firstFormDaily = form_daily_assessment[0]
      setDate(util.getTimeLong(firstFormDaily?.date_make))
      setOO({
        oxygen_sat: lastForm.oxygen_sat,
        temperature: lastForm.temperature,
        heart_rate: lastForm.heart_rate
      })
    } else {
      setDate('ยังไม่มีฟอร์ม')
    }
    if (form_first_assessment.length > 0) {
      setDateCovid19(util.getTimeLong(form_first_assessment[0]?.date_atk))
      seCheck_Covid(form_first_assessment[0].check_covid)
      setWho_ATK(form_first_assessment[0].who_atk)
      setHospital_ATK(form_first_assessment[0].hospital_atk)
      setBmi((form_first_assessment[0].weight / ((form_first_assessment[0].height * form_first_assessment[0].height) / 10000)).toFixed(2))
    }

    let _listDoctor = await api.query('db/cores/role/query', {
      "aggregate": [
        {
          $match: {
            roles: 'doctor',
            $and: [
              { "profile.certid": { $ne: null } },
              { "profile.certid": { $ne: '' } }
            ]
          }
        }
      ]
    })

    setListDocter(_listDoctor.map(x => ({
      name: `${x.profile.title}${x.profile.firstname} ${x.profile.lastname}`,
      host: `${x.profile.hospital}`,
      certid: `${x.profile.certid}`,
    })))

    if (_data[0].url) setUrl(_data[0].url)
    if (_data[0].vaccinate) setVaccinate(_data[0].vaccinate)
    if (_data[0].age) setAge(_data[0].age)

    setPatient(_data[0])
    setDaily(form_daily_assessment)
    setFormFirstAssessment(form_first_assessment[0])
    setLoading(false)
  }, [])

  if (isLoad) {
    return (<Layout ispadding >

      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundSize: 'contain'
      }}>
        <p> Loading </p>
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    </Layout>)
  }
  return (
    <Layout ispadding >

      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        // success
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />


      <Modal
        title="นัดผู้ป่วย"
        centered
        visible={Quesue.isVisit}
        onCancel={() => {
          setQuesue({ isVisit: false })
        }}
        okText={'บันทึก'}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onOk={async () => {

          if (!Quesue?.date_doctor) {
            return setAlert('โปรดเลือกวัน')
          }
          if (!Quesue?.time_doctor) {
            return setAlert('โปรดเลือกช่วงเวลา')
          }
          if (!Quesue?.addr) {
            return setAlert('โปรดเลือกสถานที่')
          }

          if (Quesue?.addr === 'อื่นๆ' && !Quesue.other) {
            return setAlert('โปรดเลือกสถานที่')
          }


          let _patient = await api.query('db/homecare/patient/query',
            {
              "query": {
                "hash_cid": md5(patient.cid)
              }
            }
          )
          if (!_patient[0]['tmpquesue']) {
            _patient[0]['tmpquesue'] = []
          }
          if (_patient[0].quesue) {
            _patient[0]['tmpquesue'].push(JSON.parse(JSON.stringify(_patient[0].quesue)))
          }

          Quesue.date_doctor.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          Quesue.date_doctor.toISOString()

          _patient[0]['quesue'] = {
            "active": true,
            "date": Quesue.date,
            "date_doctor": Quesue.date_doctor.format('x'),
            "time_doctor": Quesue.time_doctor,
            "patient_symptoms1": null,
            "patient_symptoms2": null,
            "patient_symptoms3": null,
            "confrim_doctor": true,
            "addr": Quesue.addr === 'อื่นๆ' ? Quesue.other : Quesue.addr,
          }
          await api.insert(`db/homecare/patient/data/${_patient[0]._id}`, _patient[0])
          setPatient({ ..._patient[0] })
          setQuesue({ isVisit: false })
          return setAlert('นัดผู้ป่วยสำเร็จ')
        }}
        width={800}
      >
        <p className="text-lg" >ชื่อนามสกุล: {`${patient?.title}${patient?.firstname} ${patient?.lastname}`} </p>
        <p className="text-lg" >เขตบริการ: {`${patient?.hostmain} (${patient?.hostmainid})`}  </p>
        <Divider />
        <p class="text-xl mt-6 mb-/">เลือกวันเวลาและสถานที่</p>
        <div class="flex-col flex">
          <div class="w-285px">
            <DatePicker
              disabledDate={(current) => current < moment().startOf('day')}
              style={{ width: '285px', height: 50, margin: 6 }}
              size={"large"}
              format={'DD/MM/YYYY'}
              locale={locale}
              onChange={(date, dateString) => {
                setQuesue({
                  ...Quesue,
                  ...{
                    date_doctor: date
                  }
                })
              }}
              value={Quesue.date_doctor}
            />
          </div>

          <div style={{ flexDirection: 'column', display: 'flex', marginTop: 4 }}>
            <p className="text-lg">ช่วงเวลา: </p>
            <Select
              value={Quesue.time_doctor}
              style={{ width: '285px', height: 50, margin: 6 }}
              size={"large"}
              onChange={(value) => {
                setQuesue({
                  ...Quesue,
                  ...{
                    time_doctor: value
                  }
                })
              }}
            >
              <Option value="09:00-12:00">09:00 - 12:00</Option>
              <Option value="13:00-16:00">13:00 - 16:00</Option>
              <Option value="17:00-21:00">17:00 - 21:00</Option>
            </Select>

          </div>

          <div style={{ flexDirection: 'column', display: 'flex', marginTop: 4 }}>
            <p className="text-lg">สถานที่: </p>
            <Select
              value={Quesue.addr}
              style={{ width: '285px', height: 50, margin: 6 }}
              size={"large"}
              onChange={(value) => {
                setQuesue({
                  ...Quesue,
                  ...{
                    addr: value
                  }
                })
              }}
            >
              <Option value="ห้องฉุกเฉินโรงพยาบาล">ห้องฉุกเฉินโรงพยาบาล</Option>
              <Option value="Covid clinic ตึกผู้ป่วยนอก(ตึกเก่า)">Covid clinic ตึกผู้ป่วยนอก(ตึกเก่า)</Option>
              <Option value="อื่นๆ">อื่นๆ</Option>
            </Select>
            {
              Quesue.addr === 'อื่นๆ' &&
              <>
                <p className="text-lg">ระบุสถานอื่นๆ: </p>
                <TextArea rows={2} maxLength={140}
                  value={Quesue.other}
                  onChange={(e) => {
                    setQuesue({ ...Quesue, other: e.target.value })
                  }}
                />
              </>
            }
          </div>


        </div>
      </Modal>


      <Modal
        title="ดาวโหลดรับรองแพทย์"
        centered
        visible={donwloadVisit.isVisit}
        onCancel={() => {
          setDonwloadVisit({ ...donwloadVisit, isVisit: false })
        }}
        okText={'ดาวโหลด'}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onOk={async () => {
          let longtime = new Date().getTime()
          let user = localStorage.getItem('user_wecaremaesot')
          await api.insert(`db/homecare/exportPdfCertificateATK/data/${longtime}`, { _id: `${longtime}`, certid: doctor.certid, user: JSON.parse(user), patient: patient.hash_cid })
          if (doctor.certid) {
            PdfCertificateATK({
              name: doctor.name,
              certid: doctor.certid,
              host: doctor.host,
              date: '',
              addr: '',
              array: genCertificate({
                patient: patient,
                form: formFirstAssessment
              }),
            })
          }
          // setDonwloadVisit({ ...donwloadVisit, isVisit: false })
        }}
        width={800}
      >
        <p class="text-lg"> เลือกชื่อแพทย์: </p>
        <Select
          value={doctor.certid}
          style={{ width: '385px', height: 50, margin: 6 }}
          size={"large"}
          onChange={(value) => {
            let _data = listDoctor.find(x => x.certid === value)
            setDocter({ ..._data })
          }}
        >

          {
            listDoctor.map(x => (
              <Option value={x.certid}>{x.name} ({x.certid})</Option>
            ))
          }
        </Select>


      </Modal>


      <Modal
        title="จำหน่ายผู้ป่วยออกจากโรงพยาบาล"
        centered
        visible={ismodal1}
        onOk={async () => {

          let user = localStorage.getItem('user')
          let _formFirstAssessment = { ...formFirstAssessment }
          _formFirstAssessment['discharge'] = true
          _formFirstAssessment['discharge_time'] = new Date().getTime()
          _formFirstAssessment['discharge_user'] = user
          await api.insert(`db/homecare/form_first_assessment/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModal1(false)
        }}
        okText={'ใช่'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModal1(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >คุณต้องการจำหน่ายผู้ป่วย ชื่อ{`${patient?.title}${patient?.firstname} ${patient?.lastname}`} เขตบริการ {`${patient?.hostmain} (${patient?.hostmainid})`} ออกจาก home isolation ไหม?</p>

      </Modal>


      <Modal
        title="เปลี่ยนระดับสีของผู้ป่วย"
        centered
        visible={ismodal2}
        onOk={async () => {
          let _formFirstAssessment = { ...formFirstAssessment }
          let _patient = { ...patient }
          _formFirstAssessment['status'] = statusColor
          await api.insert(`db/homecare/form_first_assessment/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          if (statusColor === 'red') {
            // TODO: check
            await api.notify(`ผู้ป่วยสีแดง\nเนื่องจาก: ${patient.host} เปลี่ยนสถานะเป็นสีแดง\nID/HN:${_patient._id}\nชื่อ${_patient?.title}${_patient?.firstname} ${_patient?.lastname}\nอายุ: ${_patient.age} ปี\nเบอร์โทร: ${_patient.tel}\nเขต:${_patient.host}`)
          }
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModal2(false)
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModal2(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >ชื่อนามสกุล: {`${patient?.title}${patient?.firstname} ${patient?.lastname}`} </p>
        <p className="text-lg" >เขตบริการ: {`${patient?.hostmain} (${patient?.hostmainid})`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg">ระดับสีผู้ป่วย: </p>
          <Select
            defaultValue="lucy"
            style={{ width: 120, marginLeft: 4 }}
            value={statusColor}
            onChange={(value) => {
              setStatusColor(value)
            }}
          >
            <Option value="green">สีเขียว</Option>
            <Option value="lightyellow">สีเหลืองอ่อน</Option>
            <Option value="yellow">สีเหลืองเข้ม</Option>
            <Option value="red">สีแดง</Option>
          </Select>

        </div>

      </Modal>

      {/* setModalDoctornote */}
      <Modal
        title="เปลี่ยนระดับสีของผู้ป่วย"
        centered
        visible={isModalCall}
        onOk={async () => {
          let user = localStorage.getItem('user_wecaremaesot')
          let _formFirstAssessment = { ...formFirstAssessment }
          let _patient = { ...patient }
          _formFirstAssessment['is_call'] = call
          await api.insert(`db/homecare/form_first_assessment/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModalCall(false)
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => setModalCall(false)}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >ชื่อนามสกุล: {`${patient?.title}${patient?.firstname} ${patient?.lastname}`} </p>
        <p className="text-lg" >เขตบริการ: {`${patient?.hostmain} (${patient?.hostmainid})`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg">โทรสอบถาม: </p>
          <Select
            defaultValue="lucy"
            style={{ width: 190, marginLeft: 4 }}
            value={call}
            onChange={(value) => {
              setCall(value)
            }}
          >
            <Option value="ยังไม่ได้โทร">ยังไม่ได้โทร</Option>
            <Option value="โทรแล้ว">โทรแล้ว</Option>
            <Option value="โทรใหม่อีกครั้ง">โทรใหม่อีกครั้ง</Option>
          </Select>

        </div>

      </Modal>

      <Modal
        title="Doctor Note"
        centered
        visible={isModalDoctornote}
        onOk={async () => {
          let _formFirstAssessment = { ...formFirstAssessment }
          _formFirstAssessment['doctornote'] = doctornote
          await api.insert(`db/homecare/form_first_assessment/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          setFormFirstAssessment({ ..._formFirstAssessment })
          setModalDoctornote(false)
          setDoctornote('')
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setModalDoctornote(false)
          setDoctornote('')

        }}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <TextArea
          rows={4}
          placeholder="Note"
          maxLength={512}
          onChange={(event) => {
            setDoctornote(event.target.value)
          }}
          value={doctornote}
        />
      </Modal>


      <Modal
        title="เพิ่มแบบส่งอาการประจำวันของผู้ป่วย Home Isolate"
        centered
        visible={isModalDaily}
        onOk={async () => {
          let _form = { ...form }
          let status = 'green'
          if (!_form.date_make) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่วันที่' } })

          if (_form.has_hr === false) {
            if (!_form.heart_rate) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรของคุณ' } })
            let _heart_rate = Number(_form.heart_rate)
            if (Number.isNaN(_heart_rate)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรของคุณเป็นตัวเลข' } })
            if (_heart_rate < 30 || _heart_rate > 300) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรในช่วง 30 - 300 bmp' } })
            }
            _form['heart_rate'] = _heart_rate
          }

          if (_form.has_t === false) {
            if (!_form.temperature) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายของคุณ' } })
            let _temperature = Number(_form.temperature)
            if (Number.isNaN(_temperature)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายของคุณเป็นตัวเลข' } })
            if (_temperature < 33 || _temperature > 43) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายในช่วง 33 - 43 °C' } })
            }
            _form['temperature'] = _temperature
          }


          if (_form.has_o2 === false) {
            if (!_form.oxygen_sat) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ออกซิเจนในเลือดของคุณ' } })
            let _oxygen_sat = Number(_form.oxygen_sat)
            if (Number.isNaN(_oxygen_sat)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ออกซิเจนในเลือดของคุณเป็นตัวเลข' } })
            if (_oxygen_sat < 50 || _oxygen_sat > 100) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กกรุณาใส่ออกซิเจนในเลือดในช่วง 50 - 100 Sp02' } })
            }
            _form['oxygen_sat'] = _oxygen_sat
          }

          if (
            (_form.fever ||
              _form.running_nose ||
              _form.sore_throat ||
              _form.severe_cough ||
              _form.chesty_cough ||
              _form.poor_appetite ||
              _form.fatigue ||
              _form.tired_when_talking ||
              _form.tired_when_resting ||
              _form.chest_pain_when_breathing ||
              _form.diarrhea_3day ||
              _form.other_symptoms ||
              _form.no_symptoms) === false
          ) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'โปรดตอบข้อ 2.มีอาการอะไรบ้าง (ถ้าไม่มีอาการโปรดเลือกว่าไม่มีอาการใดๆ)' } })

          if (_form.has_o2 === false && typeof _form['oxygen_sat'] === 'number' && (_form['oxygen_sat'] >= 50 && _form['oxygen_sat'] < 96)) {
            status = 'red'
          }

          let user_wecaremaesot = await localStorage.getItem('user')
          _form['cid'] = formFirstAssessment.cid
          let pre_data = await api.query(`db/homecare/patient/query`, { query: { cid: formFirstAssessment.cid } })
          let pre_form_first_assessment = await api.query('db/homecare/form_first_assessment/query',
            {
              "aggregate": [
                {
                  "$match": {
                    "cid": formFirstAssessment.cid,
                    "round": formFirstAssessment.round
                  }
                },
              ]
            })
          let pre_form_daily_assessment = await api.query('db/homecare/form_daily_assessment/query',
            {
              "aggregate": [
                {
                  "$match": {
                    "cid": formFirstAssessment.cid,
                    "date_make": _form.date_make,
                    "ref_round": formFirstAssessment.round
                  }
                },
              ]
            })
          if (pre_form_daily_assessment.length > 0) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `คุณได้ส่งแบบส่งอาการประจำวัน ${util.getTimeLong(_form.date_make)} แล้ว` } })
          }
          _form['_id'] = `${formFirstAssessment.cid}_r${formFirstAssessment.round}_d${datemake.format('YYYYMMDD')}`
          _form['ref_round'] = formFirstAssessment.round
          _form['hash_cid'] = md5(formFirstAssessment.cid)
          _form['updatetime'] = new Date().getTime()
          _form['make_by'] = user_wecaremaesot

          await api.insert(`db/homecare/form_daily_assessment/data/${_form._id}`, _form)

          let _datenow = moment()
          _datenow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          _datenow.toISOString()
          if (status == 'red' && _datenow.format('x') == _form.date_make) {
            pre_form_first_assessment[0].status = status
            await api.insert(`db/homecare/form_first_assessment/data/${pre_form_first_assessment[0]._id}`, pre_form_first_assessment[0])
            // await api.notify(`ผู้ป่วยสีแดง\nเนื่องจาก: SpO2 < 96% (${_form.oxygen_sat}%)\nID/HN:${formFirstAssessment.cid}\nชื่อ${pre_data[0].title}${pre_data[0].firstname} ${pre_data[0]?.lastname}\nอายุ: ${pre_data[0].age} ปี\nเบอร์โทร: ${pre_data[0].tel}\nเขต:${pre_data[0].host}`)
          }

          let _form_daily_assessment = await api.query(
            'db/homecare/form_daily_assessment/query',
            {
              "aggregate": [
                {
                  $match: {
                    "hash_cid": md5(formFirstAssessment.cid),
                    "ref_round": formFirstAssessment.round
                  }
                },
                {
                  $addFields: {
                    date_make: { $toDouble: "$date_make" },
                  }
                },
                { $sort: { date_make: 1 } }
              ]
            }
          )

          setDaily(_form_daily_assessment)
          setForm({
            "_id": null,
            "hash_cid": null,
            "cid": null,
            "temperature": "",
            "oxygen_sat": "",
            "heart_rate": "",
            "no_symptoms": false,
            "fever": false,
            "running_nose": false,
            "sore_throat": false,
            "severe_cough": false,
            "chesty_cough": false,
            "poor_appetite": false,
            "fatigue": false,
            "tired_when_talking": false,
            "tired_when_resting": false,
            "chest_pain_when_breathing": false,
            "diarrhea_3day": false,
            "other_symptoms": null,
            "other_symptoms_value": null,
            "age_60": false,
            "BMI": false,
            "COPD": false,
            "CKD": false,
            "Chronic_Heart_Disease": false,
            "CVA": false,
            "T2DM": false,
            "Cirrhosis": false,
            "Immunocompromise": false,
            "weight": null,
            "height": null,
            "Absolute_lymphocyte_min": false,
            "age": null,
            "has_hr": false,
            "has_o2": false,
            "has_t": false,
            "pregnant": null,
            "eat_e1": false,
            "eat_e2": false,
            "date_atk": false,
            "who_atk": null,
            "check_covid": null,
            "hospital_atk": null,
            xray: null
          });
          setDatemake(null)
          setModalDaily(false)
          setFormFirstAssessment(pre_form_first_assessment[0])
          return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setForm({
            "_id": null,
            "hash_cid": null,
            "cid": null,
            "temperature": "",
            "oxygen_sat": "",
            "heart_rate": "",
            "no_symptoms": false,
            "fever": false,
            "running_nose": false,
            "sore_throat": false,
            "severe_cough": false,
            "chesty_cough": false,
            "poor_appetite": false,
            "fatigue": false,
            "tired_when_talking": false,
            "tired_when_resting": false,
            "chest_pain_when_breathing": false,
            "diarrhea_3day": false,
            "other_symptoms": null,
            "other_symptoms_value": null,
            "age_60": false,
            "BMI": false,
            "COPD": false,
            "CKD": false,
            "Chronic_Heart_Disease": false,
            "CVA": false,
            "T2DM": false,
            "Cirrhosis": false,
            "Immunocompromise": false,
            "weight": null,
            "height": null,
            "Absolute_lymphocyte_min": false,
            "age": null,
            "has_hr": false,
            "has_o2": false,
            "has_t": false,
            "pregnant": null,
            "eat_e1": false,
            "eat_e2": false,
            "date_atk": false,
            "who_atk": null,
            "check_covid": null,
            "hospital_atk": null,
            xray: null
          });
          setDatemake(null)
          setModalDaily(false)
        }}
        cancelText={'ยกเลิก'}
        width={800}
      >

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            วันที่แบบส่งอาการประจำวัน
            <DatePicker
              style={{ width: '285px', height: 50, margin: 6 }}
              size={"large"}
              format={'DD/MM/YYYY'}
              disabledDate={disabledDate}
              locale={locale}
              value={datemake}
              onChange={(date) => {
                date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                date.toISOString()
                setDatemake(date)
                setForm({
                  ...form, ...{
                    date_make: Number(date.format('x')),
                    date_make_see: date.format('DDMMYYYY')
                  }
                })
              }}
            />
          </Grid>
          <Grid item xs={0} md={6} style={{ padding: 0, margin: 0 }} />
          <Grid item xs={4} md={4}>
            <TextField
              disabled={form.has_hr}
              style={{ width: '100%', backgroundColor: form.has_hr ? '#999' : '#fff' }}
              pattern="[0-9]*"
              inputProps={{
                maxLength: 3,
                inputmode: "decimal"
              }}
              id="heart_rate"
              name="heart_rate"
              label="ชีพจร HR"
              variant="outlined"
              value={form.heart_rate}
              onChange={handleChange}
            />

            <FormControlLabel
              control={<Checkbox style={{ marginLeft: '12px' }} checked={form.has_hr} onChange={(event) => {
                setForm({
                  ...form, ...{
                    [event.target.name]: !form[event.target.name],
                    "heart_rate": "",
                  }
                })
              }} name='has_hr' />}
              label="ไม่มีเครื่องวัดชีพจร"
            />
          </Grid>


          <Grid item xs={4} md={4}>
            <TextField
              disabled={form.has_t}
              style={{ width: '100%', backgroundColor: form.has_t ? '#999' : '#fff' }}
              pattern="[0-9]*"
              inputProps={{
                maxLength: 5,
                inputmode: "decimal"
              }}
              id="temperature"
              name="temperature"
              label="อุณหภูมิร่างกาย"
              variant="outlined"
              value={form.temperature}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox style={{ marginLeft: '12px' }} checked={form.has_t} onChange={(event) => {
                setForm({
                  ...form, ...{
                    [event.target.name]: !form[event.target.name],
                    "temperature": "",
                  }
                })
              }} name='has_t' />}
              label="ไม่มีเครื่องอุณหภูมิร่างกาย"
            />

          </Grid>


          <Grid item xs={4} md={4}>
            <TextField
              disabled={form.has_o2}
              style={{ width: '100%', backgroundColor: form.has_o2 ? '#999' : '#fff' }}
              pattern="[0-9]*"
              inputProps={{
                maxLength: 5,
                inputmode: "decimal"
              }}
              id="oxygen_sat"
              name="oxygen_sat"
              label="ออกซิเจนในเลือด O2SAT"
              variant="outlined"
              value={form.oxygen_sat}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox style={{ marginLeft: '12px' }} checked={form.has_o2} onChange={(event) => {
                setForm({
                  ...form, ...{
                    [event.target.name]: !form[event.target.name],
                    "oxygen_sat": "",
                  }
                })
              }} name='has_o2' />}
              label="ไม่มีเครื่องออกซิเจนในเลือด"
            />
          </Grid>



          <Grid item xs={12} md={12}>
            <FormGroup>
              <p class="text-2xl"> มีอาการอะไรบ้าง</p>
              <FormControlLabel
                control={<Checkbox
                  style={{ margin: 4 }}
                  checked={form.no_symptoms}
                  onChange={() => {
                    setForm({
                      ...form, ...{
                        "no_symptoms": !form.no_symptoms,
                        "fever": false,
                        "running_nose": false,
                        "sore_throat": false,
                        "severe_cough": false,
                        "chesty_cough": false,
                        "poor_appetite": false,
                        "fatigue": false,
                        "tired_when_talking": false,
                        "tired_when_resting": false,
                        "chest_pain_when_breathing": false,
                        "diarrhea_3day": false,
                      }
                    })
                  }}
                  name='no_symptoms' />}
                label="ไม่มีอาการใดๆ/No Symptoms"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.running_nose} onChange={handleChangeCheck} name='running_nose' />}
                label="มีน้ำมูก/Running nose"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.sore_throat} onChange={handleChangeCheck} name='sore_throat' />}
                label="เจ็บคอ/Sore throat"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.severe_cough} onChange={handleChangeCheck} name='severe_cough' />}
                label="ไอเยอะ/Severe Cough"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.chesty_cough} onChange={handleChangeCheck} name='chesty_cough' />}
                label="มีเสมหะ/Chesty cough"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.fatigue} onChange={handleChangeCheck} name='fatigue' />}
                label="อ่อนเพลียมาก (Fatigue)"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.tired_when_talking} onChange={handleChangeCheck} name='tired_when_talking' />}
                label="หอบเหนื่อย ขณะพูดหรือออกแรง/Tired when talking or having activity"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.tired_when_resting} onChange={handleChangeCheck} name='tired_when_resting' />}
                label="หอบเหนื่อยขณะพัก/Tired when resting"
              />
              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.chest_pain_when_breathing} onChange={handleChangeCheck} name='chest_pain_when_breathing' />}
                label="เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing"
              />

              <Divider />
              <FormControlLabel
                control={<Checkbox style={{ margin: 4 }} checked={form.diarrhea_3day} onChange={handleChangeCheck} name='diarrhea_3day' />}
                label="ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)"
              />
              <Divider />

            </FormGroup>
          </Grid>

        </Grid>


      </Modal>




      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: 'url(' + bg + ')',
        backgroundSize: 'contain'
      }}>
        <div
          style={{
            borderRadius: 12,
            width: "1200px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: 'column',
            alignSelf: 'center'
          }}
        >

          <div
            style={{
              background: "#fff",
              borderRadius: 12,
              width: "100%",
              display: "flex",
              flexDirection: 'column',
              marginTop: 12,
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            }}
          >

            <div style={{ width: '100%', borderRadius: 24, flexDirection: "row", display: "flex", padding: 12, paddingBottom: 0 }}>
              <img
                class="w-32 h-32"
                src={url}
                alt=""
              />
              <div
                style={{
                  borderRadius: 12,
                  display: "flex",
                  padding: 12,
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <div style={{ fontSize: 24 }}>
                  {`${patient?.title}${patient?.firstname} ${patient?.lastname}`}
                </div>
                <div style={{}}>
                  วันเริ่มทำการรักษา : {date}
                </div>
                <div style={{}}>
                  รพสต. : {`${patient?.host} (${patient?.hostid})`}
                </div>
                <div style={{}}>
                  เขตบริการ : {`${patient?.hostmain} (${patient?.hostmainid})`}
                </div>
                <div style={{}}>
                  สถานะ : {patient?.discharge ? 'discharge' : 'กำลังรักษา'}
                </div>
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  ระดับอาการ :
                  {
                    formFirstAssessment?.status &&
                    <div style={{
                      backgroundColor: MAP_STATUS[formFirstAssessment.status].color,
                      width: 70,
                      marginLeft: 12,
                      display: "flex",
                      borderRadius: 12,
                      justifyContent: 'center',
                      justifyItems: 'center'
                    }}>
                      {MAP_STATUS[formFirstAssessment.status].text}
                    </div>
                  }

                </div>
                <div style={{ flexDirection: 'row', display: 'flex', marginTop: '8px' }}>
                  โทรสอบถาม :
                  {
                    formFirstAssessment?.is_call &&
                    <div style={{
                      backgroundColor: formFirstAssessment?.is_call ? MAP_CALL[formFirstAssessment?.is_call] : '#666',
                      paddingLeft: 4,
                      paddingRight: 4,
                      marginLeft: 12,
                      display: "flex",
                      borderRadius: 12,
                      justifyContent: 'center',
                      justifyItems: 'center'
                    }}>
                      {formFirstAssessment?.is_call}
                    </div>
                  }

                </div>



                <div style={{ flexDirection: 'row', display: 'flex', marginTop: '8px' }}>
                  <RanderQuesue patient={patient} />
                </div>


                < div style={{ flexDirection: 'row-reverse', display: 'flex', width: '100%' }}>


                  {formFirstAssessment?.discharge !== true && <>
                    < Button style={{ backgroundColor: '#FBB454', color: "#fff", margin: '2px' }} onClick={() => {
                      setQuesue({ ...Quesue, isVisit: true })
                    }}>
                      นัดผู้ป่วย
                    </Button>
                    <Button style={{ backgroundColor: 'red', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModal1(true)
                      }}
                    >
                      Discharge
                    </Button>
                    <Button style={{ backgroundColor: 'green', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModal2(true)
                        setStatusColor(formFirstAssessment?.status)
                      }}
                    >
                      เปลี่ยนระดับอาการ
                    </Button>
                    <Button style={{ backgroundColor: 'DodgerBlue', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModalCall(true)
                        setCall(formFirstAssessment?.is_call)
                      }}
                    >
                      เปลี่ยนโทรสอบถาม
                    </Button>
                    <Button style={{ backgroundColor: 'DodgerBlue', margin: 2, color: '#fff' }}
                      onClick={() => {
                        setModalDoctornote(true)
                        setDoctornote(formFirstAssessment?.doctornote)
                      }}
                    >
                      doctor note
                    </Button>
                  </>
                  }
                </div>
              </div>



            </div>
            <Divider />
            <div style={{ padding: 4 }}>
              <Menu mode="horizontal" style={{ backgroundColor: 'rgba(255,0,0,0)' }} defaultSelectedKeys="mail">
                <Menu.Item key="mail" icon={<FieldTimeOutlined />} onClick={() => {
                  setPage(1)
                }}>
                  ข้อมูลรายวัน
                </Menu.Item>
                <Menu.Item key="app" icon={<AppstoreOutlined />}
                  onClick={() => {
                    setPage(2)
                  }}
                >
                  ข้อมูลพื้นฐาน
                </Menu.Item>

                <Menu.Item key="alipay" icon={<AppstoreOutlined />}
                  onClick={() => {
                    setPage(4)
                  }}
                >
                  ข้อมูลติดต่อ
                </Menu.Item>
              </Menu>

            </div>
          </div>


          {
            page === 1 && <>
              <div
                style={{
                  marginTop: 12,
                  borderRadius: 12,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    borderRadius: 12,
                    width: "1200px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: 'row',
                  }}
                >

                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 12,
                      width: "100%",
                      display: "flex",
                      // justifyContent: "space-between",
                      flexDirection: 'column',
                      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                      marginBottom: 48
                    }}
                  >

                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ padding: 12 }}>
                        <div style={{ fontSize: 24 }}>
                          ค่าเฉลี่ยอาการทางคลินิค
                        </div>
                        <div style={{}}>
                          ระบบจะประมวลผลค่าเฉลี่ยอาการทางคลินิครายวันในระหว่างการรักษาของผู้ป่วย
                        </div>
                      </div>
                      <div style={{ paddingTop: '24px', paddingRight: '12px' }}>

                        <Button style={{ backgroundColor: 'green', color: "#fff", margin: '6px' }} onClick={() => {
                          setDonwloadVisit({
                            ...donwloadVisit, isVisit: true
                          })
                        }}>
                          ใบรับรองแพทย์
                        </Button>

                        <Button style={{ backgroundColor: 'green', color: "#fff", margin: '6px' }} onClick={async () => {
                          let user_wecaremaesot = await localStorage.getItem('user')

                          let exportData = {
                            _id: `${patient.cid}_${moment().format('x')}`,
                            time: moment().format('LLLL'),
                            user: user_wecaremaesot
                          }

                          await api.insert(`db/homecare/export_spsc/data/${exportData._id}`, exportData)
                          PdfReport({
                            name: `${patient?.title}${patient?.firstname} ${patient?.lastname}`,
                            date: date,
                            addr: textAddr(patient),
                            patient: patient,
                            formFirstAssessment: formFirstAssessment,
                            array: daily.map((x, i) => ({
                              round: (i + 1),
                              updatetime: x.date_make ? util.getTimeLong(x.date_make) : '',
                              temperature: x.temperature ? x.temperature : '-',
                              oxygen_sat: x.oxygen_sat ? x.oxygen_sat : '-',
                              heart_rate: x.heart_rate ? x.heart_rate : '-',
                              symptoms: symptoms(x)
                            }))
                          })

                        }}>
                          รายงาน สปสช.
                        </Button>
                        <Button style={{ backgroundColor: 'green', color: "#fff", margin: '6px' }} onClick={() => {
                          setModalDaily(true)
                        }}>
                          เพิ่มอาการรายวัน

                        </Button>

                      </div>
                    </div>



                    <Divider />

                    <div style={{
                      display: "flex",
                      justifyContent: 'flex-end',
                      flexDirection: "row",
                    }}>
                      <div style={{ padding: 12 }}>
                        <span style={{ fontSize: 18 }}>อุณหภูมิร่างกาย</span><br />
                        <span style={{ fontSize: 30, color: 'green' }}>{OO.temperature}<sup>°C</sup></span>
                      </div>
                      <div style={{ padding: 12 }}>
                        <span style={{ fontSize: 18 }}>ชีพจร</span><br />
                        <span style={{ fontSize: 30, color: 'red' }}>{OO.heart_rate} bmp</span>
                      </div>
                      <div style={{ padding: 12 }}>
                        <span style={{ fontSize: 18 }}>ออกซิเจนร่างกาย</span><br />
                        <span style={{ fontSize: 30, color: 'blue' }}>{OO.oxygen_sat} %SPO<sub>2</sub></span>
                      </div>

                    </div>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        chart: {
                          type: 'line'
                        },
                        title: {
                          text: null
                        },
                        subtitle: {
                          text: null
                        },
                        xAxis: {
                          categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14']
                        },
                        yAxis: {
                          title: {
                            // text: 'Temperature (°C)'
                            text: null

                          },
                          min: 0,
                          max: 120
                        },
                        tooltip: {
                          headerFormat: '<span style="font-size: 10px">ครั้งที่{point.key}</span><br/>',
                          crosshairs: true,
                          shared: true
                        },
                        plotOptions: {
                          line: {
                            dataLabels: {
                              enabled: true
                            },
                            // enableMouseTracking: false
                          }
                        },
                        series: [{
                          name: 'ออกซิเจนในเลือด',
                          data: arrayhander(daily.map(x => Number(x.oxygen_sat))),
                          color: "green",
                        }, {
                          name: 'อุณหภูมิร่างกาย',
                          data: arrayhander(daily.map(x => Number(x.temperature))),
                          color: "red",
                        }, {
                          name: 'ชีพจร',
                          data: arrayhander(daily.map(x => Number(x.heart_rate))),
                          color: "blue",
                        }]
                      }}
                    />

                    <ColumnGroupingTable datas={daily} />
                  </div>

                </div>

              </div>
            </>
          }


          {
            page === 2 && <>
              <div
                style={{
                  marginTop: 12,
                  borderRadius: 12,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    borderRadius: 12,
                    width: "1200px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: 'row',
                  }}
                >

                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 12,
                      width: "100%",
                      display: "flex",
                      // justifyContent: "space-between",
                      flexDirection: 'column',
                      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                    }}
                  >
                    <div style={{ padding: 12 }}>
                      <div style={{ fontSize: 24 }}>
                        ข้อมูลพื้นฐาน
                      </div>
                      <div style={{}}>
                        ข้อมูลพื้นฐานทางคลินิกเมื่อผู้ป่วยลงทะเบียนใช้งานครั้งแรก
                      </div>
                    </div>


                    <Divider />
                    <div style={{ padding: 12 }}>

                      <div style={{}}> วันที่ตรวจพบเชื้อโควิด-19 : <span style={{ color: 'green' }}>{dateCovid19}</span> </div>
                      <div style={{}}> วิธีตรวจเชื้อโควิด-19 : <span style={{ color: 'green' }}>{check_covid} {who_atk === 'ตรวจด้วยตนเอง' ? who_atk : who_atk + hospital_atk} </span> </div>
                      <div style={{}}> ประวัติการรับวัคซีน :  <span style={{ color: 'green' }}>{vaccinate} เข็ม</span></div>
                      <div style={{}}> อายุ : <span style={{ color: 'green' }}> {age}</span></div>
                      <div style={{}}> น้ำหนัก :  <span style={{ color: 'green' }}> {formFirstAssessment?.weight} kg</span></div>
                      <div style={{}}> ส่วนสูง :  <span style={{ color: 'green' }}> {formFirstAssessment?.height} cm</span></div>

                      <div style={{}}> BMI : <span style={{ color: 'green' }}> {bmi}</span></div>
                      <div style={{}}> อุณหภูมิกาย: <span style={{ color: formFirstAssessment?.has_t ? 'gray' : 'green' }}> {formFirstAssessment?.has_t ? 'ไม่มีเครื่องอุณหภูมิร่างกาย' : formFirstAssessment?.temperature}</span></div>
                      <div style={{}}> ชีพจร ( ครั้ง/นาที ): <span style={{ color: formFirstAssessment?.has_hr ? 'gray' : 'green' }}> {formFirstAssessment?.has_hr ? 'ไม่มีเครื่องวัดชีพจร' : formFirstAssessment?.heart_rate}</span></div>
                      <div style={{}}> ออกซิเจนในเลือด ( O2SAT ): <span style={{ color: formFirstAssessment?.has_o2 ? 'gray' : 'green' }}> {formFirstAssessment?.has_o2 ? 'ไม่มีเครื่องออกซิเจนในเลือด' : formFirstAssessment?.oxygen_sat}</span></div>

                      <p className="mt-8 text-lg">อาการที่พบ</p>
                      <div style={{ marginLeft: 12 }}>
                        <Checkbox checked={formFirstAssessment?.no_symptoms} > ไม่มีอาการใดๆ/No Symptoms</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.running_nose} > มีน้ำมูก/Running nose</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.sore_throat} > เจ็บคอ/Sore throat</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.severe_cough} > ไอเยอะ/Severe Cough</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.chesty_cough} > มีเสมหะ/Chesty cough</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.fatigue} > อ่อนเพลียมาก (Fatigue)</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.tired_when_talking} > ขณะพูดหรือออกแรง/Tired when talking or having activity</Checkbox> <br />
                        <Checkbox checked={formFirstAssessment?.tired_when_resting} > หอบเหนื่อยขณะพัก/Tired when resting</Checkbox> <br />
                        <Checkbox checked={formFirstAssessment?.chest_pain_when_breathing} > เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing</Checkbox> <br />
                        <Checkbox checked={formFirstAssessment?.diarrhea_3day} > ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)</Checkbox> <br />
                        <Checkbox checked={formFirstAssessment?.other_symptoms} > อาการอื่นๆ ระบุ{formFirstAssessment?.other_symptoms ? `${formFirstAssessment?.other_symptoms_volue}` : ''}</Checkbox> <br />
                      </div>

                      <p className="mt-8 text-lg">โรคประจำตัวหรือภาวะเสี่ยง</p>
                      <div style={{ marginLeft: 12, marginTop: 4 }}>
                        <Checkbox checked={formFirstAssessment?.age_60} > อายุ มากกว่า 60 ปี</Checkbox>  <br />
                        {/* <Checkbox checked={formFirstAssessment?.BMI} > BMI มากกว่า 30 หรือ น้ำหนักมากกว่า 90 กิโลกรัม</Checkbox>  <br /> */}
                        <Checkbox checked={Number(bmi) > 30 || formFirstAssessment?.weight > 90} > BMI มากกว่า 30 หรือ น้ำหนักมากกว่า 90 กิโลกรัม</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.COPD} > โรคปอดอุดกั้นเรื้อรัง (Chronic Obstructive Pulmonary Disease หรือ COPD)</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.CKD} > โรคไตวายเรื้อรัง CKD </Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.Chronic_Heart_Disease} > โรคหัวใจเรื้อรัง Chronic Heart Disease</Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.CVA} > โรคหลอดเลือดสมอง CVA </Checkbox>  <br />
                        <Checkbox checked={formFirstAssessment?.T2DM} > โรคเบาหวาน ดื้ออินซูลิ T2DM </Checkbox> <br />
                        <Checkbox checked={formFirstAssessment?.Cirrhosis} > โรคตับแข็ง Cirrhosis </Checkbox> <br />
                        <Checkbox checked={formFirstAssessment?.Immunocompromise} > ภาวะภูมิคุ้มกันต่ำ Immunocompromise</Checkbox> <br />
                        <Checkbox checked={formFirstAssessment?.Absolute_lymphocyte_min} > ค่าเม็ดเลือดขาวน้อยกว่า 1000 cell/mm3</Checkbox> <br />
                        {/* <Checkbox checked={formFirstAssessment?.diarrhea_3day} > อาการอื่นๆ</Checkbox> <br /> */}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </>
          }

          {
            page === 4 && <>
              <div
                style={{
                  marginTop: 12,
                  borderRadius: 12,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    borderRadius: 12,
                    width: "1200px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: 'row',
                  }}
                >

                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 12,
                      width: "100%",
                      display: "flex",
                      // justifyContent: "space-between",
                      flexDirection: 'column',
                      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                    }}
                  >
                    <div style={{ padding: 12 }}>
                      <div style={{ fontSize: 24 }}>
                        ข้อมูลติดต่อ
                      </div>
                      <div style={{}}>
                        ข้อมูลพื้นฐานทางคลินิกเมื่อผู้ป่วยลงทะเบียนใช้งานครั้งแรก
                      </div>
                    </div>
                    <Divider />
                    <div style={{ padding: 12 }}>{
                      patient?.latitude &&
                      <div style={{}}> googlemap : <a
                        onClick={() => window.open(`https://www.google.com/maps/place/${convertToDms(patient.latitude, false)}+${convertToDms(patient.longitude, true)}/@${patient?.latitude},${patient?.longitude},20z`)}
                      >lat: {patient?.latitude}, lng: {patient?.longitude}</a> </div>
                    }
                      <div style={{}}> บ้านเลขที่ : <span style={{ color: 'green' }}>{patient.housenum}</span> </div>
                      <div style={{}}> หมู่ที่ : <span style={{ color: 'green' }}>{patient.villageno}</span> </div>
                      <div style={{}}> ซอย : <span style={{ color: 'green' }}>{patient.lane}</span> </div>
                      <div style={{}}> ถนน : <span style={{ color: 'green' }}>{patient.road}</span> </div>
                      <div style={{}}> ตำบล : <span style={{ color: 'green' }}>{patient.tumbonname}</span> </div>
                      <div style={{}}> อำเภอ : <span style={{ color: 'green' }}>{patient.cityname}</span> </div>
                      <div style={{}}> จังหวัด : <span style={{ color: 'green' }}>{patient.provincename}</span> </div>
                      <div style={{}}> รหัสไปรษณีย์ : <span style={{ color: 'green' }}>{patient.postcode}</span> </div>
                      <div style={{}}> เบอร์โทรศัพท์ : <span style={{ color: 'green' }}>{patient.tel}</span> </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

        </div>

      </div >
    </Layout >
  )
}
