import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2-react';
import FormControl from '@mui/material/FormControl';
import api from '../../util/api';
import util from '../../util';
import axios from "axios";
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { v4 } from "uuid";
import { DatePicker, Progress, Input, Select, Modal, Tooltip, Table as TableAnd, Spin, Upload } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import moment from 'moment'
import * as XLSX from "xlsx";
import 'moment/locale/th';
import {
  useSelector,
  useDispatch
} from 'react-redux'
import { increment } from '../../reducers/simpleReducer'

const { Option } = Select;
const { Search, TextArea } = Input;
const { RangePicker } = DatePicker;

const columnsFile = [
  {
    title: "ลำดับ",
    dataIndex: "index",
    key: "index",
    width: 20,
  },
  {
    title: "ชื่อไฟล์",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "โหลด",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          icon={<DownloadOutlined />}
          onClick={data.onDonwlaod}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "green",
            color: "white",
          }}
        >
          download
        </Button>
      </div >
    ),
    width: 140,
  },
  {
    title: "ลบ",
    align: "center",
    render: (_, data) => (
      <div
        style={{
          width: 120,
          display: "flex",
          flexDirection: "row",
          margin: "0px",
        }}
      >
        <Button
          onClick={data.onRemove}
          shape='round'
          style={{
            width: 120,
            margin: 2,
            backgroundColor: "red",
            color: "white",
          }}
        >
          ลบ
        </Button>
      </div >
    ),
    width: 140,
  },
];



let MONTH = {
  'ม.ค.': '01',
  'ก.พ.': '02',
  'มี.ค.': '03',
  'เม.ย.': '04',
  'พ.ค.': '05',
  'มิ.ย.': '06',
  'ก.ค.': '07',
  'ส.ค.': '08',
  'ก.ย.': '09',
  'ต.ค.': '10',
  'พ.ย.': '11',
  'ธ.ค.': '12'
}

function getCongenitalDisease(x) {
  let result = []
  if (x.COPD) result.push('โรคปอดอุดกั้นเรื้อรัง (Chronic Obstructive Pulmonary Disease หรือ COPD)')
  if (x.CKD) result.push('โรคไตวายเรื้อรัง CKD')
  if (x.Chronic_Heart_Disease) result.push('โรคหัวใจเรื้อรัง Chronic Heart Disease')
  if (x.CVA) result.push('โรคหลอดเลือดสมอง CVA')
  if (x.T2DM) result.push('โรคเบาหวาน ดื้ออินซูลิ T2DM')
  if (x.Cirrhosis) result.push('โรคตับแข็ง Cirrhosis')
  if (x.Immunocompromise) result.push('ภาวะภูมิคุ้มกันต่ำ Immunocompromise')
  if (x.Absolute_lymphocyte_min) result.push('ค่าเม็ดเลือดขาวน้อยกว่า 1000 cell/mm3')
  return result.toString().replace(/,/g, ', ')
}

function getRisk(x) {
  let result = []
  if (x.age_60) result.push('อายุ มากกว่า 60 ปี')
  if (x.weight >= 90 || ((x.weight / ((x.height * x.height) / 10000) > 30))) result.push('BMI มากกว่า 30 หรือ น้ำหนักมากกว่า 90 กิโลกรัม')
  return result.toString().replace(/,/g, ', ')
}

function getNewSymptom(x) {
  let result = []
  if (Array.isArray(x.form_daily_assessment) && x.form_daily_assessment.length > 0) {
    let tmp = [...x.form_daily_assessment.sort((a, b) => (a.date_make < b.date_make) ? -1 : (a.date_make > b.date_make) ? 1 : 0)]
    if (tmp[0].no_symptoms) result.push('ไม่มีอาการใดๆ/No Symptoms')
    if (tmp[0].running_nose) result.push('มีน้ำมูก/Running nose')
    if (tmp[0].sore_throat) result.push('เจ็บคอ/Sore throat')
    if (tmp[0].severe_cough) result.push('ไอเยอะ/Severe Cough')
    if (tmp[0].chesty_cough) result.push('มีเสมหะ/Chesty cough')
    if (tmp[0].fatigue) result.push('อ่อนเพลียมาก (Fatigue)')
    if (tmp[0].tired_when_talking) result.push('ขณะพูดหรือออกแรง/Tired when talking or having activity')
    if (tmp[0].tired_when_resting) result.push('หอบเหนื่อยขณะพัก/Tired when resting')
    if (tmp[0].chest_pain_when_breathing) result.push('เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing')
    if (tmp[0].diarrhea_3day) result.push('ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)')
  }
  return result.toString().replace(/,/g, ', ')
}

async function donwloadxlsx({ data_mock = [], search = "", age, ps, date = null, date2 = null, hostF }) { // status_queue
  console.log("data_mock", data_mock)
  let _data = data_mock
    .filter(x => x.name.search(search) >= 0 || search === '' || x.cid.search(search) >= 0)
    .filter(x => (x.host === hostF || hostF === 'all') && (x.status === age || age === 'all') && ((x.date_atk_filter >= date && x.date_atk_filter <= date2) || date === null) && (x.status_queue || ps === 'all'))
  let data = [['ชื่อ นามสกุล', 'อายุ', 'เลขบัตร', 'น้ำหนัก', 'ส่วนสูง', 'ที่อยู่', 'โทรศัพท์', 'โรคประจำตัว', 'ภาวะเสี่ยง', 'อาการ', 'สี', 'สถานะผู้ป่วย', 'วันที่อัพเดทข้อมูล']]
  console.log("_data", _data)
  _data.forEach(x => {
    data.push([
      x.name,
      x.age,
      x.cid,
      x.weight,
      x.height,
      util.textAddr(x),
      x.tel,
      getCongenitalDisease(x),
      getRisk(x),
      getNewSymptom(x),
      MAP_STATUS[x.status].text,
      x.discharge ? 'discharge' : 'active',
      moment(x.updatetime, 'x').format('L'),
    ])
  })
  let ws = XLSX.utils.aoa_to_sheet(data);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "sheet1");
  XLSX.writeFile(wb, "รายชื่อคนไข้ ณ" + moment().format('L') + ".xlsx");
  let user = await localStorage.getItem('user_wecaremaesot')
  let sDate = moment()
  let _dataexport = {
    _id: sDate.format('x'),
    dateLog: sDate.format('L'),
    timeLog: sDate.format('LT'),
    user: user,
    search,
    age,
    ps,
    date,
    date2,
    hostF,
    size: _data.map(x => ({ cid: x.cid, name: x.name, status: x.status }))
  }
  await api.insert(`db/homecare/exportlog/data/${_dataexport._id}`, _dataexport)
  return
}

let MAP_CALL = {
  'ยังไม่ได้โทร': '#F08080',
  'โทรแล้ว': '#CEFFCF',
  'โทรใหม่อีกครั้ง': '#ffffb1',
}

let MAP_FOLLOW = {
  'come': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'มา'
  },

  'not_come': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'ไม่มา'
  },
  'not_q': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    text: 'ไม่นัดต่อ'
  },
}

let MAP_STATUS_WATH = {
  'wait_confirm_doctor': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'รอนัดจากแพทย์'
  },
  'wait_confirm_patient': {
    color: '#F1A661',
    color_b: '#F1A661',
    text: 'ผู้คัดกรองที่รอลงวันนัด'
  },
  'confirm_patient': {
    color: '#ceffcf',
    color_b: '#82ff84',
    // text: 'ผู้คัดกรองลงวันนัดแล้ว'
    text: 'ลงนัดแล้ว'
  },
  'stop': {
    // color: '#F08080',
    // color_b: '#F08080',
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'ไม่นัด'
  },
  'refer': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'ส่งต่อ'
  },
  'other': {
    color: '#f0f0f0',
    color_b: '#f0f0f0',
    // text: 'ผู้ป่วยยื่นยันวันนัด'
    // text: 'ผู้คัดกรองลงวันนัดแล้ว' / pre
    text: 'อื่นๆ'
  },
  'confirm_patient_new': {
    color: '#F08080',
    color_b: '#F08080',
    // text: 'ผู้คัดกรองลงวันนัดแล้ว'
    text: 'ลงนัดใหม่'
  },
}
let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },
  'blue': {
    color: '#80b8f0 ',
    color_b: '#80b8f0 ',
    text: 'น้ำเงิน'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const ReaderRow = ({ column, row, handleClick }) => {
  const value = row[column.id];
  if (column.id === 'status_queue') {
    if (!MAP_STATUS_WATH[value]) {
      return null
    }

    if (row?.status_come === 'not_q') {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#f0f0f0', width: 150, borderRadius: 24, justifyContent: 'center', display: 'flex', textAlign: 'center' }}> ไม่นัดต่อ</div>
          }
        </TableCell>
      );
    }

    let dateq = row['date_queue_patient']
    let date = (value === 'confirm_patient' || value === 'confirm_patient_new') ? `วันที่ ${dateq ? moment(dateq, 'x').format('L') : '-'}` : ''
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_STATUS_WATH[value].color, width: 150, borderRadius: 24, justifyContent: 'center', display: 'flex', textAlign: 'center' }}> {MAP_STATUS_WATH[value].text} <br />{date}</div>
        }
      </TableCell>
    );

  } else if (column.id === 'sdfsdfdate_atk') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {util.getTimeLong(value)}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'HN') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, display: 'flex' }}> {row['cid']} {value ? '(' + value + ')' : ''}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'is_call') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, backgroundColor: value ? MAP_CALL[value] : '#666', color: '#333', justifyContent: 'center', display: 'flex' }}> {value}</div>
        }
      </TableCell >
    );
  } else if (column.id === 'count_day') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {row.discharge ? '-' : util.diffday(row.date_atk, moment().format('x')) + 1}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {value ? moment(value, 'x').format('L') : '-'}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'discharge') {
    if (row.discharge) {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#eee', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> discharge</div>
          }
        </TableCell>
      );
    } else {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#CEFFCF', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> active</div>
          }
        </TableCell>
      );
    }
  }
  else if (column.id === 'status_come') {

    if (!value) return (
      <TableCell key={column.id} align={column.align}>
        <div style={{ padding: 8, backgroundColor: '#f0f0f0', width: 80, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> -</div>
      </TableCell>
    )
    let count_not_com = row?.count_not_com ? row.count_not_com : '-'
    let date = value === 'not_come' ? `ครั้งที่ ${count_not_com}` : ''
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_FOLLOW[value].color, width: 120, borderRadius: 24, justifyContent: 'center', display: 'flex', textAlign: 'center' }}> {MAP_FOLLOW[value].text}{date}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'doctornote') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ padding: 4 }} onClick={() => handleClick('doctornote', row)}>
              นัดผู้ป่วย
            </Button>) :
            value
        }
      </TableCell>
    );
  } else if (column.id === 'button_com') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ padding: 4 }} onClick={() => handleClick('button_com', row)}>
              แจ้งข้อมูล
            </Button>) :
            value
        }
      </TableCell>
    );

  } else if (column.id === 'button_result') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ padding: 4 }} onClick={() => handleClick('button_result', row)}>
              ดูผลการรักษา
            </Button>) :
            value
        }
      </TableCell>
    );
  }
  return (
    <TableCell key={column.id} align={column.align}>
      {
        column.type === 'button' ?
          (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick('link', `patient-queue/${md5(row.cid)}/${row.round}`)}>
            ดูข้อมูล
          </Button>) :
          value
      }
    </TableCell>
  );

}
const columns = [
  { id: 'HN', label: 'เลขบัตร', minWidth: 50, },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'status_queue', label: 'สถานะการนัด', minWidth: 200, alignSelf: 'center' },
  { id: 'doctornote', label: 'จัดการนัดผู้ป่วย', minWidth: 100, type: 'button', align: 'center' },
  { id: 'status_come', label: 'สถานะตอบกลับ', minWidth: 50, alignSelf: 'center' },
  { id: 'button_com', label: 'แจ้งการมาตามนัด', minWidth: 100, type: 'button', align: 'center' },
  { id: 'button_result', label: 'ดูผลการรักษา', minWidth: 100, type: 'button', align: 'center' },
  { id: 'name', label: 'ดูข้อมูล', minWidth: 100, type: 'button', align: 'center' },
];

function ColumnGroupingTable({ data_mock = [], search = "", age, ps, date = null, date2 = null, onFilter, hostF, updateRow }) {
  const [page, setPage] = React.useState(0);
  const [modal, setModal] = React.useState({
    show: false
  });

  const [DateSc, setDateSc] = React.useState({
    day: '',
    month: '',
    year: '',
  });


  const [fileList, setFileList] = useState([]);
  const [fileListUpload, setFileListUpload] = useState([]);
  const [uploading, setUploading] = useState(false);


  const [textResult, setTextResult] = React.useState('');
  const [isModalOpenFile1, setIsModalOpenFile1] = useState(false);
  const [isModalOpenFile2, setIsModalOpenFile2] = useState(false);
  const [isModalOpenFile3, setIsModalOpenFile3] = useState(false);
  const [stepQ, setStepQ] = useState(null);
  const [other, setOther] = useState(null);
  const [modal2, setModal2] = React.useState({
    show: false
  });

  const [modal3, setModal3] = React.useState({
    show: false
  });

  const [modalForm, setModalForm] = React.useState({
    show: false
  });

  const [doctornote, setDoctornote] = React.useState('');

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [sort, setSort] = React.useState({
    key: 'date_atk',
    state: "up"
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpload = async () => {
    let listDocFile = []
    const formData = new FormData();
    fileListUpload.forEach((file) => {
      formData.append('files[]', file);
      console.log("formData >>: ", formData)
    });
    setUploading(true);

    for (let i = 0; i < fileListUpload.length; i++) {
      let filename = v4().toString().replace(/-/g, "");
      let url = `https://screening.wecaremaesot.org/minio/presignedUrl/test/${filename}`
      listDocFile.push({
        path: '',
        bucket: 'test',
        name: fileListUpload[i].name,
        nameMinio: filename,
      })
      let urlForPut = await axios.post(url)
      console.log("urlForPut",urlForPut)
      let res = await axios.put(urlForPut.data, fileListUpload[i])
      console.log("res", res)
    }
    setFileList([...fileList, ...listDocFile])
    setUploading(false)
    setIsModalOpenFile1(false)
    setIsModalOpenFile2(false)
    setIsModalOpenFile3(false)
    setFileListUpload([])
  };


  let history = useHistory();
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function handleClick(type, data) {
    if (type === 'link') {
      // alert(data)
      history.push(data)
    } else if (type === 'doctornote') {
      setDoctornote(data.doctornote)
      if (['confirm_patient', 'stop', 'refer'].includes(data.status_queue)) {
        setStepQ(data.status_queue)
      } else if (data.status_queue === 'confirm_patient_new') {
        setStepQ('confirm_patient')
      }
      if (data.date_queue_patient) {
        let M = moment(data.date_queue_patient, 'x')
        console.log(M.format('YYYY'))
        console.log(M.format('MM'))
        console.log(M.format('DD'))
        setDateSc({
          day: M.format('DD') + '',
          month: M.format('MM') + '',
          year: M.format('YYYY') + '',
        })
      }
      setFileList(data?.q_listfile)
      setModal({
        data: data,
        show: true
      })
    } else if (type === 'button_com') {
      // if (['confirm_patient', 'stop', 'refer'].includes(data.status_come)) {
      setStepQ(data.status_come)
      // } else if (data.status_queue === 'confirm_patient_new') {
      //   setStepQ('confirm_patient')
      // }
      if (data.date_queue_patient && data?.status_come === 'not_come') {
        let M = moment(data.date_queue_patient, 'x')
        console.log(M.format('YYYY'))
        console.log(M.format('MM'))
        console.log(M.format('DD'))
        setDateSc({
          day: M.format('DD') + '',
          month: M.format('MM') + '',
          year: M.format('YYYY') + '',
        })
      }
      setFileList(data?.q_listfile)
      setDoctornote(data.doctornote)
      setModal2({
        data: data,
        show: true
      })
    } else if (type === 'button_result') {
      setTextResult(data?.textResult ? data.textResult : '')
      setFileList(data?.result_listfile)
      setModal3({
        data: data,
        show: true
      })
    }

  }

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.name.search(search) >= 0 || search === '' || x.cid.search(search) >= 0)
      .filter(x => (x.host === hostF || hostF === 'all') && (x.status === age || age === 'all') && ((x.date_atk_filter >= date && x.date_atk_filter <= date2) || date === null) && (x.status_queue == ps || ps === 'all'))
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)
    setPage(0)
    onFilter(_data)
  }, [search, age, date, ps, hostF]);

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.name.search(search) >= 0 || search === '' || x.cid.search(search) >= 0)
      .filter(x => (x.host === hostF || hostF === 'all') && (x.status === age || age === 'all') && ((x.date_atk_filter >= date && x.date_atk_filter <= date2) || date === null) && (x.status_queue == ps || ps === 'all'))
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)
    onFilter(_data)
  }, [data_mock]);

  let year = []
  let day = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  let dayOfMonth = 31
  if (DateSc.month) {
    let sufixMonth = DateSc.month.slice(DateSc.month.length - 2, DateSc.month.length)
    if (sufixMonth == 'พ.' && (DateSc.date === 30 || DateSc.date === 31)) {
      dayOfMonth = 29
    } else if (['ย.'].includes(DateSc.month) && (DateSc.date === 31)) {
      dayOfMonth = 30
      setDateSc({ ...DateSc, date: null })
    }

    if (sufixMonth == 'พ.') {
      dayOfMonth = 29
    } else if (['ย.'].includes(DateSc.month)) {
      dayOfMonth = 30
    }
  }

  for (let i = 1; i <= dayOfMonth; i++) {
    day.push(`${i}`.padStart(2, "0"))
  }

  let onGenDataTable = (_data = []) => {
    let result = _data.map((x, i) => ({
      ...x, index: i + 1,
      onRemove: () => {
        const newFileList = fileList.slice();
        newFileList.splice(i, 1)
        setFileList([...newFileList])
      },
      onDonwlaod: (d) => {
        console.log("download [x]")
        fetch(`https://screening.wecaremaesot.org/minio/download/test/${x.nameMinio}`, {
          method: 'POST',
          headers: {
            // 'Content-Type': 'application/pdf',
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            // response.blob()
            if (!response.ok) {
              throw new Error("Failed to fetch object");
            }
            return response.blob(); // Assuming it's a binary object
          })
          .then((blob) => {
            console.log("blob", blob)
            // const url = window.URL.createObjectURL(
            //   new Blob([blob], { type: 'application/pdf' }),
            // );
            // const link = document.createElement('a');
            // link.href = url;
            // link.download = x.name;
            // link.click();
            // link.parentNode.removeChild(link);
            const url = window.URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.download = x.name;;
            link.click();
            return "ok";
          });
      }
    }))
    return result
  }


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />


      <Modal
        title="ดูผลการรักษา"
        centered
        maskClosable={false}
        visible={modal3.show}
        onOk={async () => {
          try {
            let _data = await api.query('db/homecare/form_risk/query', {
              "aggregate": [
                {
                  $match: {
                    cid: modal3.data.cid,
                  }
                }
              ]
            })
            let _formFirstAssessment = { ..._data[0] }
            _formFirstAssessment['textResult'] = textResult
            _formFirstAssessment['result_listfile'] = fileList

            await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
            modal3.data['textResult'] = textResult
            modal3.data['result_listfile'] = fileList
            console.log("_formFirstAssessment", _formFirstAssessment)
            updateRow({ ...modal3.data })
            setStepQ(null)
            setTextResult(null)
            setFileList([])
            setDateSc(
              {
                day: '',
                month: '',
                year: '',

              })
            setModal3({ show: false })
            setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัำเร็จ' } })
          } catch (err) {
            console.log("err", err)
          }

        }}
        onCancel={() => {
          setStepQ(null)
          setFileList([])
          setTextResult(null)
          setDateSc(
            {
              day: '',
              month: '',
              year: '',

            })
          setModal3({ show: false })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >HN: {modal3?.data?.HN ? modal3?.data?.HN : '-'} </p>
        <p className="text-lg" >ชื่อนามสกุล: {modal3?.data?.name} </p>
        <p className="text-lg" >เขตบริการ: {`${modal3?.data?.host}`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันที่นัดผู้ป่วยมาโรงบาล: {moment(modal3?.data?.date_queue_patient, 'x').format('l')}  </p>
        </div>

        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <p className="text-lg" >ระบุแผนการรักษา: </p>
          <TextArea
            value={textResult}
            placeholder="ระบุข้อความ"
            onChange={(e) => {
              const _value = e.target.value;
              setTextResult(_value)
            }}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />

          <div className=" bg-white  mt-8 rounded-xl">
            <p style={{ fontSize: 24 }}>ไฟล์รายละเอียดผลการรักษา</p>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <TableAnd
                dataSource={onGenDataTable(fileList)}
                footer={() => {
                  // =>
                  return (
                    <Button
                      onClick={() => {
                        setIsModalOpenFile1(true)
                      }}
                      style={{ backgroundColor: "green", color: "#fff" }}>
                      เพิ่มไฟล์
                    </Button>
                  );
                }}
                bordered
                size="middle"
                columns={columnsFile}
                line
              />
            </div>
          </div>
        </div>

      </Modal>

      <Modal
        title="แจ้งการมาตามนัด"
        centered
        maskClosable={false}
        visible={modal2.show}
        onOk={async () => {
          // stepQ == 'not_come' 
          if (stepQ == 'not_come') {
            if (DateSc.month && DateSc.month && DateSc.month) {

            } else {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `โปรดระบุวันนัดใหม่` } })
            }
          }
          let timeQ = new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`).getTime() + ''
          let timeS = new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`)
          let TimeNow = new Date().setHours(0, 0, 0, 0)
          let Holidays = {
            '1681318800000': true,
            '1681405200000': true,
            '1681664400000': true,
            '1683133200000': true,
            '1683219600000': true,
            '1684256400000': true,

            '1685898000000': true,
            '1690477200000': true,
            '1690822800000': true,
            '1690909200000': true,
            '1691946000000': true,
            '1697130000000': true,
            '1697994000000': true,
            '1701709200000': true,
            '1701709200000': true,
            '1702227600000': true,
          }
          if (new Date().setHours(0, 0, 0, 0) > new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`).getTime()) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถนัดย้อนหนังวัน ${moment(TimeNow + '', 'x').format('LL')}` } })
          }

          if (timeS.getDay() == 0 || timeS.getDay() == 6 || Holidays[timeQ]) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${moment(timeQ, 'x').format('LL')} เป็นวันหยุด` } })
          }

          let _data = await api.query('db/homecare/form_risk/query', {
            "aggregate": [
              {
                $match: {
                  cid: modal2.data.cid,
                }
              }
            ]
          })
          let _formFirstAssessment = { ..._data[0] }



          let _cal_data = await api.query(`db/homecare/form_risk/query`, {
            aggregate: [
              {
                $match: {
                  date_queue_patient: timeQ
                }
              },
              {
                $lookup: {
                  from: "patient_risk",
                  localField: "cid",
                  foreignField: "cid",
                  as: "listPatient"
                }
              },
              {
                $unwind: {
                  path: "$listPatient",
                  preserveNullAndEmptyArrays: true
                }
              },
              {
                $addFields: { "listPatient.type": "$type" }
              },
              {
                $group: {
                  _id: { x: "$date_queue_patient" },
                  date: { $first: "$date_queue_patient" },

                  listPatient: { $push: "$listPatient" }
                }
              }
            ]
          })
          console.log("_cal_data", _cal_data)
          if (_formFirstAssessment.date_queue_patient === timeQ) {
            // hot thing
          } else if (_cal_data.length > 0) {
            let size_patient = _cal_data[0].listPatient.filter(x => x.type === _formFirstAssessment.type).length
            console.log("size_patient", size_patient)
            if (size_patient >= 5) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${moment(timeQ, 'x').format('LL')} คิวตรวจ${_formFirstAssessment.type}เต็มแล้ว` } })
            }
          }


          _formFirstAssessment['status_come'] = stepQ

          if (stepQ === 'not_come') {
            if (!_formFirstAssessment?.count_not_com) {
              _formFirstAssessment['count_not_com'] = 1
            } else {
              _formFirstAssessment['count_not_com'] += 1
            }
            let timeQ = new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`).getTime() + ''
            _formFirstAssessment['date_queue_patient'] = timeQ
            _formFirstAssessment['status_queue'] = 'confirm_patient_new'
            _formFirstAssessment['q_listfile'] = fileList
          } else {
            _formFirstAssessment['count_not_com'] = null
          }

          await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          modal2.data.status_come = stepQ
          if (_formFirstAssessment?.date_queue_patient) {
            modal2.data['date_queue_patient'] = _formFirstAssessment.date_queue_patient
            modal2.data['status_queue'] = _formFirstAssessment.status_queue
            modal2.data['count_not_com'] = _formFirstAssessment.count_not_com
            modal2.data['q_listfile'] = _formFirstAssessment.q_listfile
          }


          updateRow({ ...modal2.data })
          setStepQ(null)
          setDateSc(
            {
              day: '',
              month: '',
              year: '',

            })
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัดสำเร็จ' } })
          setModal2({ show: false })
          setFileList([])
        }}
        onCancel={() => {
          setFileList([])
          setStepQ(null)
          setDateSc(
            {
              day: '',
              month: '',
              year: '',

            })
          setModal2({ show: false })
        }}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
        width={800}
      >
        <p className="text-lg" >HN: {modal2?.data?.HN ? modal2?.data?.HN : '-'} </p>
        <p className="text-lg" >ชื่อนามสกุล: {modal2?.data?.name} </p>
        <p className="text-lg" >เขตบริการ: {`${modal2?.data?.host}`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันที่นัดผู้ป่วยมาโรงบาล: {moment(modal2?.data?.date_queue_patient, 'x').format('l')}  </p>
        </div>

        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <p className="text-lg" >เลือก: </p>
          <Select
            value={stepQ}
            style={{ width: 200 }}
            size={"large"}
            onChange={(value) => {
              // console.log("value", value)
              setStepQ(value)
            }}
          >
            <Option value="come">มา</Option>
            <Option value="not_come" >ไม่มา</Option>
            {/* <Option value="change_come" >เลื่อนนัด</Option> */}
            <Option value="not_q" >ไม่นัดต่อ</Option>
          </Select>

          {
            stepQ == 'not_come' &&
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ flexDirection: 'column', display: 'flex', marginTop: 12 }}>
                <p className="text-lg" >เลือกวันนัดใหม่: </p>
                <div className="flex flex-col w-[700px]">
                  <div className="flex flex-row">
                    <Select
                      placeholder='วัด'
                      value={DateSc.day}
                      style={{ width: 100, marginRight: 4 }}
                      onChange={value => {
                        setDateSc({
                          ...DateSc,
                          day: value
                        })
                      }}
                    >
                      {
                        day.map(x => (
                          <Option value={x}>{x}</Option>
                        ))
                      }

                    </Select>

                    <Select
                      placeholder='เดือน'
                      value={DateSc.month}
                      style={{ width: 100, marginRight: 4 }}
                      onChange={value => {
                        setDateSc({
                          ...DateSc,
                          month: value
                        })
                      }}
                    >
                      {
                        Object.keys(MONTH).map(x => (
                          <Option value={MONTH[x]}>{x}</Option>
                        ))
                      }

                    </Select>

                    <Select
                      placeholder='ปี'
                      value={DateSc.year}
                      style={{ width: 100, marginRight: 4 }}
                      onChange={value => {
                        setDateSc({
                          ...DateSc,
                          year: value
                        })
                      }}
                    >
                      <Option value={2023}>{2566}</Option>
                    </Select>

                  </div>
                  <div className=" bg-white  mt-8 rounded-xl">
                    <p style={{ fontSize: 24 }}>ไฟล์รายละเอียดการนัดหมาย</p>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                      <TableAnd
                        dataSource={onGenDataTable(fileList)}
                        footer={() => {
                          // =>
                          return (
                            <Button
                              onClick={() => setIsModalOpenFile2(true)}
                              style={{ backgroundColor: "green", color: "#fff" }}>
                              เพิ่มไฟล์
                            </Button>
                          );
                        }}
                        bordered
                        size="middle"
                        columns={columnsFile}
                        line
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>

      </Modal>


      <Modal
        title="จัดการนัดผู้ป่วย"
        centered
        visible={modal.show}
        maskClosable={false}
        onOk={async () => {

          let timeQ = new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`).getTime() + ''
          let timeS = new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`)
          let TimeNow = new Date().setHours(0, 0, 0, 0)
          let Holidays = {
            '1681318800000': true,
            '1681405200000': true,
            '1681664400000': true,
            '1683133200000': true,
            '1683219600000': true,
            '1684256400000': true,

            '1685898000000': true,
            '1690477200000': true,
            '1690822800000': true,
            '1690909200000': true,
            '1691946000000': true,
            '1697130000000': true,
            '1697994000000': true,
            '1701709200000': true,
            '1701709200000': true,
            '1702227600000': true,
          }
          if (new Date().setHours(0, 0, 0, 0) > new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`).getTime()) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `ไม่สามารถนัดย้อนหนังวัน ${moment(TimeNow + '', 'x').format('LL')}` } })
          }

          if (timeS.getDay() == 0 || timeS.getDay() == 6 || Holidays[timeQ]) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${moment(timeQ, 'x').format('LL')} เป็นวันหยุด` } })
          }

          let _data = await api.query('db/homecare/form_risk/query', {
            "aggregate": [
              {
                $match: {
                  cid: modal.data.cid,
                }
              }
            ]
          })
          if (_data.length === 0)
            return null
          let _formFirstAssessment = { ..._data[0] }

          if (_formFirstAssessment?.status_come) {
            return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `เนื่องจากมีการตอบกลับแล้วจึงไม่สามารถแก้ไขข้อมูลส่วนนี้ได้` } })
          }

          let _cal_data = await api.query(`db/homecare/form_risk/query`, {
            aggregate: [
              {
                $match: {
                  date_queue_patient: timeQ
                }
              },
              {
                $lookup: {
                  from: "patient_risk",
                  localField: "cid",
                  foreignField: "cid",
                  as: "listPatient"
                }
              },
              {
                $unwind: {
                  path: "$listPatient",
                  preserveNullAndEmptyArrays: true
                }
              },
              {
                $addFields: { "listPatient.type": "$type" }
              },
              {
                $group: {
                  _id: { x: "$date_queue_patient" },
                  date: { $first: "$date_queue_patient" },

                  listPatient: { $push: "$listPatient" }
                }
              }
            ]
          })

          if (_formFirstAssessment.date_queue_patient === timeQ) {
            // TODO: Check Error
          } else if (_cal_data.length > 0) {
            let size_patient = _cal_data[0].listPatient.filter(x => x.type === _formFirstAssessment.type).length

            if (_formFirstAssessment.type === 'มะเร็งลำไส้') {
              if (size_patient >= 1 && timeS.getDay() == 2) {
                return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${moment(timeQ, 'x').format('LL')} คิวตรวจ${_formFirstAssessment.type}เต็มแล้ว` } })
              } else if (size_patient >= 10) {
                return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${moment(timeQ, 'x').format('LL')} คิวตรวจ${_formFirstAssessment.type}เต็มแล้ว` } })
              }
            } else if (size_patient >= 5) {
              return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `วันที่ ${moment(timeQ, 'x').format('LL')} คิวตรวจ${_formFirstAssessment.type}เต็มแล้ว` } })
            }
          }


          _formFirstAssessment['status_queue'] = stepQ
          _formFirstAssessment['other_messages'] = null
          if (stepQ === 'confirm_patient') {
            // let timeQ = new Date(`${DateSc.month}-${DateSc.day}-${DateSc.year}`).getTime() + ''
            _formFirstAssessment.date_queue_patient = timeQ
            _formFirstAssessment.date_queue_doctor = timeQ
            _formFirstAssessment.date_queue_confirm = timeQ
            _formFirstAssessment['q_listfile'] = fileList
          }
          await api.insert(`db/homecare/form_risk/data/${_formFirstAssessment._id}`, _formFirstAssessment)
          modal.data.status_queue = stepQ
          modal.data.date_queue_patient = _formFirstAssessment?.date_queue_confirm
          modal.data.q_listfile = fileList
          updateRow({ ...modal.data })
          setFileList([])
          setStepQ(null)
          setDateSc(
            {
              day: '',
              month: '',
              year: '',

            })
          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกนัดสำเร็จ' } })
          setModal({ show: false })
        }}
        okText={'บันทึก'}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setFileList([])
          setStepQ(null)
          setDateSc(
            {
              day: '',
              month: '',
              year: '',

            })
          setModal({ show: false })
        }}
        width={800}
      >
        <p className="text-lg" >HN: {modal?.data?.HN ? modal?.data?.HN : '-'} </p>
        <p className="text-lg" >ชื่อนามสกุล: {modal?.data?.name} </p>
        <p className="text-lg" >เขตบริการ: {`${modal?.data?.host}`}  </p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p className="text-lg" >วันนัดจากผู้ป่วยจากฟอร์ม: {moment(modal?.data?.date_queue_patient, 'x').format('l')}  </p>
        </div>

        <Select
          value={stepQ}
          style={{ width: 200 }}
          size={"large"}
          onChange={(value) => {
            // console.log("value", value)
            setStepQ(value)
          }}
        >
          <Option value="confirm_patient">นัด</Option>
          <Option value="stop" >ไม่นัด</Option>
          <Option value="refer">ส่งต่อ</Option>
          {/* <Option value="other" >อื่นๆ</Option> */}
        </Select>
        {
          stepQ == 'confirm_patient' &&
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div style={{ flexDirection: 'column', display: 'flex', marginTop: 12 }}>
                <p className="text-lg" >เลือกวันนัด: </p>
                <div className="flex flex-row">
                  <Select
                    placeholder='วัด'
                    value={DateSc.day}
                    style={{ width: 100, marginRight: 4 }}
                    onChange={value => {
                      setDateSc({
                        ...DateSc,
                        day: value
                      })
                    }}
                  >
                    {
                      day.map(x => (
                        <Option value={x}>{x}</Option>
                      ))
                    }

                  </Select>

                  <Select
                    placeholder='เดือน'
                    value={DateSc.month}
                    style={{ width: 100, marginRight: 4 }}
                    onChange={value => {
                      setDateSc({
                        ...DateSc,
                        month: value
                      })
                    }}
                  >
                    {
                      Object.keys(MONTH).map(x => (
                        <Option value={MONTH[x]}>{x}</Option>
                      ))
                    }

                  </Select>

                  <Select
                    placeholder='ปี'
                    value={DateSc.year}
                    style={{ width: 100, marginRight: 4 }}
                    onChange={value => {
                      setDateSc({
                        ...DateSc,
                        year: value
                      })
                    }}
                  >
                    <Option value={2023}>{2565}</Option>
                  </Select>

                </div>


              </div>
            </div>
            <div className=" bg-white  mt-8 rounded-xl">
              <p style={{ fontSize: 24 }}>ไฟล์รายละเอียดการนัดหมาย</p>
              <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <TableAnd
                  dataSource={onGenDataTable(fileList)}
                  footer={() => {
                    // =>
                    return (
                      <Button
                        onClick={() => setIsModalOpenFile3(true)}
                        style={{ backgroundColor: "green", color: "#fff" }}>
                        เพิ่มไฟล์
                      </Button>
                    );
                  }}
                  bordered
                  size="middle"
                  columns={columnsFile}
                  line
                />
              </div>
            </div>
          </div>
        }
      </Modal>

      <Modal
        title="แจ้งการมาตามนัด"
        centered
        visible={modalForm.show}
        onOk={async () => {
          let _data = await api.query('db/homecare/form_first_assessment/query', {
            "aggregate": [
              {
                $match: {
                  cid: modalForm.data.cid,
                  round: modalForm.data.round,
                }
              }
            ]
          })
          if (_data.length > 0) {
            _data[0].doctornote = doctornote
            await api.insert(`db/homecare/form_first_assessment/data/${_data[0]._id}`, _data[0])
          }

          setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
          // setDoctornote('')
          modalForm.data.doctornote = doctornote
          updateRow({ ...modalForm.data })
          setFileList([])
          setModalForm({
            show: false
          })
        }}
        okText={'บันทึก'}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          // setDoctornote('')
          setFileList([])
          setModalForm({
            show: false
          })

        }}
        width={800}
      >
        <p>ชื่อ-นามสกุล: {modal?.data?.name}</p>
        <TextArea
          rows={4}
          placeholder="Note"
          maxLength={512}
          onChange={(event) => {
            setDoctornote(event.target.value)
          }}
          value={doctornote}
        />
      </Modal>

      <Modal
        title="อัพโหลดไฟล์"
        centered
        okText={"เพิ่ม"}
        cancelText={"ปิด"}
        width={800}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpenFile1}
        maskClosable={false}
        onOk={async () => {
          try {
            if (!uploading) {
              handleUpload()
            } else {
              alert('กำลังอัพโหลดข้อมูลอยู่')
            }
          } catch (err) { }
        }}
        onCancel={() => {
          if (!uploading) {
            setIsModalOpenFile1(false)
            setIsModalOpenFile2(false)
            setIsModalOpenFile3(false)
          } else {
            alert('กำลังอัพโหลดข้อมูลอยู่')
          }
        }}
      >
        <Spin tip="upload..." spinning={uploading}>
          <Upload
            onRemove={(file) => {
              const index = fileListUpload.indexOf(file);
              const newFileList = fileListUpload.slice();
              newFileList.splice(index, 1);
              setFileListUpload(newFileList);
            }}
            beforeUpload={(file) => {
              setFileListUpload([...fileListUpload, file])
              return false;
            }}
            fileList={fileListUpload}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Spin>
      </Modal>


      <Modal
        title="อัพโหลดไฟล์"
        centered
        okText={"เพิ่ม"}
        cancelText={"ปิด"}
        width={800}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpenFile2}
        maskClosable={false}
        onOk={async () => {
          try {
            if (!uploading) {
              handleUpload()
            } else {
              alert('กำลังอัพโหลดข้อมูลอยู่')
            }
          } catch (err) { }
        }}
        onCancel={() => {
          if (!uploading) {
            setIsModalOpenFile1(false)
            setIsModalOpenFile2(false)
            setIsModalOpenFile3(false)
          } else {
            alert('กำลังอัพโหลดข้อมูลอยู่')
          }
        }}
      >
        <Spin tip="upload..." spinning={uploading}>
          <Upload
            onRemove={(file) => {
              const index = fileListUpload.indexOf(file);
              const newFileList = fileListUpload.slice();
              newFileList.splice(index, 1);
              setFileListUpload(newFileList);
            }}
            beforeUpload={(file) => {
              setFileListUpload([...fileListUpload, file])
              return false;
            }}
            fileList={fileListUpload}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Spin>
      </Modal>

      <Modal
        title="อัพโหลดไฟล์"
        centered
        okText={"เพิ่ม"}
        cancelText={"ปิด"}
        width={800}
        okButtonProps={{ style: { background: "green" } }}
        open={isModalOpenFile3}
        maskClosable={false}
        onOk={async () => {
          try {
            if (!uploading) {
              handleUpload()
            } else {
              alert('กำลังอัพโหลดข้อมูลอยู่')
            }
          } catch (err) { }
        }}
        onCancel={() => {
          if (!uploading) {
            setIsModalOpenFile1(false)
            setIsModalOpenFile2(false)
            setIsModalOpenFile3(false)
          } else {
            alert('กำลังอัพโหลดข้อมูลอยู่')
          }
        }}
      >
        <Spin tip="upload..." spinning={uploading}>
          <Upload
            onRemove={(file) => {
              const index = fileListUpload.indexOf(file);
              const newFileList = fileListUpload.slice();
              newFileList.splice(index, 1);
              setFileListUpload(newFileList);
            }}
            beforeUpload={(file) => {
              setFileListUpload([...fileListUpload, file])
              return false;
            }}
            fileList={fileListUpload}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Spin>
      </Modal>

      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (

                <TableCell
                  onClick={() => {
                    let _tmpdata = [...data]
                    console.log("_tmpdata", _tmpdata)
                    if (column.id === 'date_atk') {
                      // _tmpdata = [..._tmpdata.sort((a, b) => (a.date_atk < b.date_atk) ? -1 : (a.date_atk > b.date_atk) ? 1 : 0)]
                      _tmpdata = [..._tmpdata.sort((a, b) => b.sort_date_atk - a.sort_date_atk)]
                    } else if (column.id === 'updatetime') {
                      _tmpdata = [..._tmpdata.sort((a, b) => b.updatetime - a.updatetime)]
                    } else if (column.id === 'name') {
                      _tmpdata = [..._tmpdata.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)]
                    } else if (column.id === 'host') {
                      _tmpdata = [..._tmpdata.sort((a, b) => (a.host < b.host) ? -1 : (a.host > b.host) ? 1 : 0)]
                    } else if (column.id === 'status') {
                      _tmpdata = [..._tmpdata.sort((a, b) => (a.status < b.status) ? -1 : (a.status > b.status) ? 1 : 0)]
                    } else if (column.id === 'discharge') {
                      _tmpdata = [..._tmpdata.sort((a, b) => (a.dischargestatus < b.dischargestatus) ? -1 : (a.dischargestatus > b.dischargestatus) ? 1 : 0)]
                    } else if (column.id === 'conutdate') {
                      // _tmpdata = [..._tmpdata.sort((a, b) => (a.conutdate < b.conutdate) ? -1 : (a.conutdate > b.conutdate) ? 1 : 0)]
                      _tmpdata = [..._tmpdata.sort((a, b) => {
                        let _a = a.conutdate
                        let _b = b.conutdate
                        if (_a === '-') _a = 0
                        if (_b === '-') _b = 0
                        if (Number.isNaN(Number(_a))) _a = -1
                        if (Number.isNaN(Number(_b))) _b = -1
                        return _b - _a

                      })]
                    } else if (column.id === 'is_call') {
                      _tmpdata = [..._tmpdata.sort((a, b) => (a.is_call < b.is_call) ? -1 : (a.is_call > b.is_call) ? 1 : 0)]
                    }
                    if (sort.state === "up") _tmpdata.reverse()
                    setData([..._tmpdata])
                    setSort({
                      key: column.id,
                      state: sort.state === 'down' ? 'up' : 'down'
                    })
                    setPage(0);
                  }}
                  style={{ minWidth: column.minWidth, borderColor: '#c0c0c0', borderWidth: 1 }}
                >
                  <div className="w-full flex flex-col text-center ">
                    {column.label}
                  </div>
                  {/* {
                      sort.key === column.id ?
                        (sort.state === 'down' ? <ArrowDownOutlined style={{ color: 'green' }} /> : <ArrowUpOutlined style={{ color: 'green' }} />)
                        : null
                    } */}
                </TableCell>
                //  <Tooltip title={column.tooltip}>
                //               </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {

                      return <ReaderRow column={column} row={row} handleClick={handleClick} />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

const Card = ({ title = '', value = 0, total = 1, text = 'green', isNotShow = false }) => {
  return (<div style={{
    background: "#fff",
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  }}>
    {!isNotShow && <Progress type="circle" percent={(value / total * 100).toFixed(2)} strokeColor={MAP_STATUS[text].color_b} width={80} strokeWidth={10} trailColor="#ccc" />}
    <div
      style={{
        margin: 4,
        background: "#fff",
        display: 'flex',
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: 14, marginTop: 12 }}>
        {title}
      </div>
      <div style={{ fontSize: 22 }}>
        {value} ราย
      </div>
    </div>
  </div>
  )
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [dataShow, setShow] = useState([]);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch()
  let [R, setR] = useState(null)
  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    let user = localStorage.getItem('user_wecaremaesot')
    role = JSON.parse(role)
    user = JSON.parse(user)

    let aggregate = [
      {
        $lookup:
        {
          from: "form_risk",
          localField: "cid",
          foreignField: "cid",
          as: "form_risk"
        }
      },
      {
        $unwind: {
          path: "$form_risk",
          preserveNullAndEmptyArrays: false
        }
      },
      {
        $project: {
          HN: 1,
          cid: 1,
          hash_cid: 1,
          is_call: 1,
          title: "$title",
          firstname: "$firstname",
          lastname: "$lastname",
          name: { $concat: ["$title", "$firstname", " ", "$lastname"] },
          host: 1,
          hostid: 1,
          // updatetime: "$updatetime",
          status: "$status",
          status_queue: "$form_risk.status_queue",
          date_queue_patient: "$form_risk.date_queue_patient",
          textResult: "$form_risk.textResult",
          count_not_com: "$form_risk.count_not_com",
          date_queue_patient_new: "$form_risk.date_queue_patient_new",
          status_come: "$form_risk.status_come",
          updatetime: "$form_risk.date_queue_patient",
          q_listfile: "$form_risk.q_listfile",
          result_listfile: "$form_risk.result_listfile",
        }
      }
    ]

    if (user.hospital !== 'โรงพยาบาลแม่สอด') {
      aggregate.push({
        $match: {
          hostid: user.hospital_code
        }
      })
    }

    let _data = await api.query(
      'db/homecare/patient_risk/query',
      {
        "aggregate": aggregate
      }
    )

    let result = []
    _data.map(x => {
      x['dischargestatus'] = x.discharge ? 'discharge' : 'active'
      if (Array.isArray(x?.form_daily_assessment) && x?.form_daily_assessment.length > 0) {
        x.form_daily_assessment = x.form_daily_assessment.sort((a, b) => a.date_make - b.date_make)
        let firstday = x.form_daily_assessment[0].date_make
        let lastdate = x.form_daily_assessment[x.form_daily_assessment.length - 1].updatetime
        x['conutdate'] = x.discharge ? '-' : `${util.diffday(firstday, moment().format('x')) + 1}`
        x['date_atk'] = util.getTimeLong(firstday)
        x['date_atk_filter'] = Number(firstday)
        x['sort_date_atk'] = Number(firstday)
      } else {
        x['date_atk'] = 'ยังไม่มีฟอร์ม'
        x['conutdate'] = 'ยังไม่มีฟอร์ม'
        x['date_queue'] = x.date_queue_patient
        // status_queue
      }
      result.push(x)
    })
    setR(role[0])
    setData([...result.sort((a, b) => b.sort_date_atk - a.sort_date_atk)])
    console.log("R", R)

  }, []);

  const [user, setUser] = useState("");
  const [age, setAge] = React.useState('all');
  const [ps, setPS] = React.useState('all');
  const [hostF, sethostF] = React.useState('all');
  const [date, setDate] = React.useState(null);
  const [date2, setDate2] = React.useState(null);
  const handleChange = (value) => {
    setAge(value);
  };

  const handleChangePS = (value) => {
    setPS(value);
  };

  return (<Layout ispadding is4>
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1550px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <p className="text-3xl mb-2 ">รายชื่อผู้คัดกรองโรคไม่ติดต่อเรื้อรังNCDs</p>
      {
        R === 'SHPH' &&
        <div className="flex flex-row pb-4">

          < Button
            variant="contained"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              history.push('/AddPatient4')
            }}>
            ลงทะเบียนผู้ป่วยนัด
          </Button>

          < Button
            variant="contained"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              setSearch('')
              setAge('all')
              setPS('all')
              sethostF('all')
              setDate(null)
              setDate2(null)
            }}>
            รายชื่อลงทะเบียนแล้ว
          </Button>
          < Button
            variant="contained"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              setSearch('')
              setAge('all')
              setPS('all')
              sethostF('all')
              setDate(null)
              setDate2(null)
            }}>
            การตอบกลับ
          </Button>
        </div>
      }

      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >

        <div style={{ flexDirection: 'row' }}>
          <FormControl sx={{ m: 1 }}>
            <div>สถานะผู้คัดกรอง</div>
            <Select defaultValue="lucy" style={{ width: 200 }} onChange={handleChangePS} size={"large"} value={ps}>
              <Option value="all">ทั้งหมด</Option>
              <Option value="wait_confirm_doctor">รอนัดจากแพทย์</Option>
              <Option value="confirm_patient" >ลงนัดแล้ว</Option>
              {/* <Option value="refer" >ส่งต่อ</Option>
              <Option value="stop" >ไม่นัด</Option>
              <Option value="other" >อื่นๆ</Option> */}
            </Select>
          </FormControl>
          {
            R !== 'SHPH' &&
            <FormControl sx={{ m: 1 }}>
              <div>สถานพยาบาล</div>
              <Select defaultValue="lucy" style={{ width: 260 }} onChange={(value) => { sethostF(value) }} size={"large"} value={hostF}>
                <Option value="all">ทั้งหมด</Option>
                {[
                  'รพ.สต.บ้านปางส่างคำ',
                  'รพ.สต.บ้านห้วยกระโหลก',
                  'รพ.สต.แม่ตาว',
                  'รพ.สต.บ้านโกกโก่',
                  'รพ.สต.ท่าสายลวด',
                  'รพ.สต.บ้านวังตะเคียน',
                  'รพ.สต.บ้านห้วยหินฝน',
                  'รพ.สต.พระธาตุผาแดง',
                  'รพ.สต.บ้านแม่กุเหนือ',
                  'รพ.สต.บ้านแม่กื้ดสามท่า',
                  'รพ.สต.บ้านแม่กุใหม่',
                  'รพ.สต.พะวอ',
                  'รพ.สต.มหาวัน',
                  'รพ.สต.บ้านปางส้าน',
                  'รพ.สต.บ้านปูเตอร์',
                  'รพ.สต.บ้านเจดีย์โค๊ะ',
                  'รพ.สต.บ้านห้วยยะอุ',
                  'คลินิกชุมชนอบอุ่นเทศบาลนครแม่สอด',
                  'รพ.สต.แม่กุ',
                  'รพ.สต.บ้านแม่กาษา',
                  'รพ.สต.บ้านห้วยไม้แป้น',
                  'โรงพยาบาลแม่สอด',
                  'รพ.สต.บ้านแม่กื๊ดหลวง',
                  'รพ.สต.แม่ปะ',
                ].map(x => <Option value={x}>{x}</Option>)}
              </Select>
            </FormControl>
          }

          <FormControl sx={{ m: 1, minWidth: 200, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหาชื่อนามสกุล"
              style={{ width: 230, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>


          <FormControl sx={{ m: 1, minWidth: 100, height: 54 }}>
            < Button
              variant="contained"
              style={{ marginTop: 20 }}
              onClick={() => {
                setSearch('')
                setAge('all')
                setPS('all')
                sethostF('all')
                setDate(null)
                setDate2(null)
              }}>
              รีเซ็ต
            </Button>
          </FormControl>
        </div>
        <ColumnGroupingTable
          data_mock={data}
          search={search}
          age={age}
          ps={ps}
          date={date}
          date2={date2}
          hostF={hostF}
          onFilter={(x) => {
            setShow(x)
          }}
          updateRow={(dataupdate) => {
            let index = data.findIndex(x => x._id === dataupdate._id)
            data[index] = dataupdate
            setData([...data])
          }}
        />
      </div>
    </div>
  </Layout >)
}
