import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from '../../util/api';
import Layout from '../../components/Layout';
import md5 from 'md5';
import Divider from '@mui/material/Divider';
import video1 from '../../images/video1.jpg'
import video2 from '../../images/video2.jpg'
import video3 from '../../images/video3.jpg'
import util from '../../util'
// import { Modal, Select, DatePicker } from 'antd';
import { Menu, Avatar, Image, Timeline, Modal, Checkbox, Select } from 'antd';

import moment from 'moment';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;

let TEXT_RISK = `ท่านได้ลงทะเบียนผู้ติดเชื้อโควิค-19 เรียบร้อยแล้ว จัดอยู่ในกลุ่มเสี่ยง แนะนำให้มาตรวจที่ opd covid รพ แม่สอด ในวัน และเวลาราชการ และให้ท่านกักตัวจนครบ 7 วัน ใช้ชีวิตประจำวันได้ปกติ สอบถามเพิ่มเติมโทร. 065-4611428`
let TEXT_GREEN = `ท่านได้ลงทะเบียนผู้ติดเชื้อโควิค-19 เรียบร้อยแล้ว จัดอยู่ในกลุ่มไม่มีความเสี่ยง (สีเขียว)ให้ท่านรักษาตามอาการ และกักตัวครบ 7 วัน ใช้ชีวิตประจำวันปกติได้ และหากท่านมีอาการผิดปกติ หรือต้องการใบรับรองแพทย์ ติดต่อที่ opd covid โรงพยาบาลแม่สอด ในวันเวลาราชการ สอบถามเพิ่มเติมโทร. 065-4611428`

const RaderStatus = ({ status = '-', discharge }) => {
  if (status === '-') return <p> Loading ..</p>
  if (discharge) return <p class="bg-blue-100 p-2 m-2 rounded-full" style={{ backgroundColor: '#9fc5e8' }}>สถานะ: ปกติ</p>
  return <p class="bg-blue-100 p-2 m-2 rounded-full" style={{ backgroundColor: MAP_STATUS[status].color }}>สถานะ: {`${MAP_STATUS[status].text}`}</p>
}
const randerC = (user, cert) => {

  if (!cert.done) {
    return <p class="border-2 border-yellow-200 bg-yellow-50 p-2 m-2">โปรดรอวันนัดรับใบรับรองแพทย์จากโรงพยาบาล</p>
  } else if (cert.done) {
    let date = util.getTimeLong(cert.date)
    let time = cert.time
    let addr = cert.addr
    return (<>
      <p class="border-2 border-green-200 bg-green-50 p-2 m-2">โปรดมารับวันที่ {date} เวลา {time} <br /> สถานที่: {addr}</p>
    </>)
  }


  return null
}
const randerQ = (user) => {

  if (!user?.quesue?.active) {
    return <p class="border-2 border-yellow-200 bg-yellow-50 p-2 m-2">ยังไม่มีการนับเข้าพบที่โรงพยาบาล</p>
  }


  if (user?.quesue?.confrim_doctor) {
    let date = moment(user?.quesue?.date_doctor, 'x').format('L')
    // new Date(Number(user?.quesue?.date_doctor)).toLocaleDateString()
    // let date = moment(user?.quesue?.date_doctor, 'x').format('LL')
    let time = user?.quesue?.time_doctor
    let addr = user?.quesue?.addr
    return (<>
      <p class="border-2 border-green-200 bg-green-50 p-2 m-2">คิวเข้าโรงพยาบาลวันที่ {date} ช่วงเวลา {time} <br /> สถานที่: {addr}</p></>
    )
  }

  if (user?.quesue?.active) {
    return <p class="border-2 border-blue-200 bg-blue-50 p-2 m-2">กำลังรอคิวจากโรงพยาบาล</p>
  }

  return null
}

const randerQuesueNew = (user) => {

  if (!user?.quesue?.active) {
    return null
  }


  if (user?.quesue?.confrim_doctor) {
    let date = moment(user?.quesue?.date_doctor, 'x').format('LL')
    // new Date(Number(user?.quesue?.date_doctor)).toLocaleDateString()
    // let date = moment(user?.quesue?.date_doctor, 'x').format('LL')
    let time = user?.quesue?.time_doctor
    let addr = user?.quesue?.addr
    return (<>
      {/* <p class="border-2 border-green-200 bg-green-50 p-2 m-2">คิวเข้าโรงพยาบาลวันที่ {date} ช่วงเวลา {time} <br /> สถานที่: {addr}</p></> */}
      <p class="border-2 border-green-200 bg-green-50 p-2 m-2">คุณมีนัดหมายเข้ารับการรักษา ที่ {addr} วันที่ {date} ช่วงเวลา {time}</p></>
    )
  }


  return null
}

const textAddr = (item) => {
  let { housenum,
    villageno,
    alley,
    lane,
    road,
    tumbonname,
    cityname,
    provincename } = item

  if (villageno) {
    villageno = villageno.replace(/\D/g, "")
  }
  let address = (housenum ? housenum + ' ' : '')
    + (villageno ? 'หมู่ที่ ' + villageno + ' ' : '')
    + (alley ? 'ตรอก' + alley + ' ' : '')
    + (lane ? 'ซอย' + lane + ' ' : '')
    + (road ? 'ถนน' + road + ' ' : '')
    + (tumbonname ? 'ต.' + tumbonname + ' ' : '')
    + (cityname ? 'อ.' + cityname + ' ' : '')
    + (provincename ? 'จ.' + provincename + ' ' : '')
  return ("ที่อยู่: " + address)
}

let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

export default function HistoryPatient(props) {
  let history = useHistory();
  const [isLoad, setLoading] = useState(false);
  const [isModalAlert, setModalAlert] = useState(false);
  const [textModalAlert, setTextModalAlert] = useState('');
  const [makeFirst, setMakeFirst] = useState(false);

  const [date, setDate] = useState('-');
  // const [firstForm, setFirstForm] = useState({});
  const [stausColor, setStausColor] = useState('-');
  const [certType, setCertType] = useState(null);
  const [cert, setCert] = useState({

  });
  const [gps, setGPS] = useState({
    latitude: '-',
    longitude: '-',
  })
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const [formFirstAssessmentRoundLast, setFormFirstAssessmentRoundLast] = useState({
  });

  const [show, setShow] = useState({
    daily: false,
    first: false,
    cert: false,
    quesue: false,
    xray: false,
    video: false,
    discharge: false,
    gps: false,
  });


  React.useEffect(async () => {
    let user_data = await localStorage.getItem('user_data')
    user_data = JSON.parse(user_data)
    let patient = await api.query('db/homecare/patient/query', {
      "query": {
        "cid": user_data.cid
      }
    })

    let form_first_assessment = await api.query('db/homecare/form_first_assessment/query', {
      "aggregate": [
        {
          $match: { "hash_cid": md5(user_data.cid) }
        },
        {
          $sort: {
            round: -1
          }
        },
        { $limit: 1 }
      ]
    })


    let daily = false
    let first = true
    let cert = false
    let quesue = false
    let xray = false
    let video = false
    let gps = false
    let discharge = false
    if (patient.length > 0) {
      setUser(patient[0])
      setGPS({
        latitude: patient[0].latitude ? patient[0].latitude : '-',
        longitude: patient[0].longitude ? patient[0].longitude : '-'
      })
    }



    if (form_first_assessment.length > 0) {
      first = false
      daily = true
      quesue = true
      xray = true
      video = true
      gps = true
      if (form_first_assessment.length && form_first_assessment[0].discharge) {
        first = false
        daily = false
        quesue = false
        xray = false
        video = false
        gps = false
        discharge = true
        let form_certificate = await api.query('db/homecare/certificate/query', {
          "query": {
            "_id": form_first_assessment[0]._id
          }
        })

        if (form_certificate.length > 0) {
          cert = true
          setCert(form_certificate[0])
        }
      }
      setDate(util.getTimeLong(form_first_assessment[0].date_atk))
      setStausColor(form_first_assessment[0].status)
      setFormFirstAssessmentRoundLast(form_first_assessment[0])
      setTextModalAlert(form_first_assessment[0].status === 'green' ? TEXT_GREEN : TEXT_RISK)
      if (!discharge) {
        setModalAlert(true)
      }
    }

    setShow({ daily, first, cert, quesue, xray, video, discharge, gps });
  }, []);


  return (
    <Layout ispadding>

      {/* <Modal
        width={600}
        title="แจ้งเตือน"
        centered
        visible={isModalAlert}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green', display: 'none' } }}
        cancelButtonProps={{ style: { background: 'red', color: '#fff' } }}
        onCancel={() => {
          setModalAlert(false)
        }}
        cancelText={'ปิด'}
      >
        <div>
          {textModalAlert}
          {
            show.quesue &&
            <>
              <br />
              {randerQuesueNew(user)}
            </>
          }
        </div>
        <br />
      </Modal> */}
      {/* {คุณมีนัดหมายเข้ารับการรักษา ที่ opd clinic โรงพยาบาลแม่สอด วันที่______ ________ */}
      {/* {randerQ(user)} */}
      <Modal
        title="ขอใบรับรองแพทย์"
        centered
        visible={visible}
        onOk={async () => {
          // if (certType === null) {
          //   return ('กรุณาเลือกเภทใบรับรองแพทย์')
          // }
          // let form_first_assessment = await api.query('db/homecare/form_first_assessment/query', {
          //   "query": {
          //     "cid": user.cid
          //   }
          // })
          let form_first_assessment = await api.query('db/homecare/form_first_assessment/query', {
            "aggregate": [
              {
                $match: { "hash_cid": md5(user.cid) }
              },
              {
                $sort: {
                  round: -1
                }
              },
              { $limit: 1 }
            ]
          })

          let f_form_daily_assessment = await api.query(
            'db/homecare/form_daily_assessment/query',
            {
              "aggregate": [
                {
                  $match: {
                    "hash_cid": md5(form_first_assessment[0].cid),
                    "ref_round": form_first_assessment[0].round
                  }
                },
                {
                  $addFields: {
                    date_make: { $toDouble: "$date_make" },
                  }
                },
                { $sort: { date_make: 1 } }
              ]
            }
          )


          let l_form_daily_assessment = await api.query(
            'db/homecare/form_daily_assessment/query',
            {
              "aggregate": [
                {
                  $match: {
                    "hash_cid": md5(form_first_assessment[0].cid),
                    "ref_round": form_first_assessment[0].round
                  }
                },
                {
                  $addFields: {
                    date_make: { $toDouble: "$date_make" },
                  }
                },
                { $sort: { date_make: -1 } }
              ]
            }
          )

          let _certificate = {
            _id: form_first_assessment[0]._id,
            cid: user.cid,
            "first_hlep": f_form_daily_assessment[0].date_make,
            "last_hlep": l_form_daily_assessment[0].date_make,
            "date_create": Number(new Date().getTime()),
            "done": false,
            "date": null,
            "time": null,
            "addr": null,
            "is_atk": form_first_assessment[0].form_first?.check_covid === 'ATK',
            patient: user,
            done: false,
            form_first: form_first_assessment[0]
          }
          await api.insert(`db/homecare/certificate/data/${_certificate._id}`, _certificate)
          setShow({
            ...show, ...{
              cert: true
            }
          })
          setCert({ ..._certificate })
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
        width={800}
        okText={'ใช่'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
      >
        <p>คุณต้องการขอใบรับรองแพทย์หรือไม่?</p>
        {/* <Select
          value={certType}
          style={{ width: '285px', height: 50 }}
          size={"large"}
          onChange={(value) => {
            setCertType(value)
          }}
        >
          <Option value="ATK">ใบรับรองแพทย์ ATK</Option>
          <Option value="PCR">ใบรับรองแพทย์ PCR</Option>
        </Select> */}

      </Modal>
      <div class="w-full flex justify-center mx-auto ">
        <div class="grid grid-cols-6 lg:grid-cols-12 p-5 gap-4 flex justify-self-center	w-[1200px]">
          <div class="flex flex-col content-center shadow-lg bg-white p-4 rounded-lg col-span-6 md:col-span-6 row-span-3">
            <img
              class="w-52 h-52 rounded-full mx-auto"
              src={user.url}
              alt=""
              width="384"
              height="512"
            />
            <div class="mx-auto">
              <RaderStatus status={stausColor} discharge={show.discharge} />
            </div>

            <p class="text-3xl text-center">{user?.title ? `${user?.title}${user?.firstname} ${user?.lastname}` : '-'}</p>
            <p class="text-lg text-center">วันเริ่มทำการรักษา: {date}</p>
            <p class="text-md text-center">{textAddr(user)}</p>


            {
              show.quesue &&
              <div class={"flex-row flex justify-around "}>
                < button class="bg-green-600 p-2 m-2 text-white rounded flex justify-self-center w-full lg:w-3/8  flex justify-center"
                  onClick={() => {
                    history.push('/patient-queue')
                  }}
                >
                  ขอนัดแพทย์
                </ button>
              </div>
            }
          </div>

          {
            show.first &&
            <div class="shadow-lg bg-white p-4 rounded-lg col-span-6">
              <p class="text-3xl pt-2 text-center">แบบประเมินแรกรับ</p>
              <Divider />
              < button
                class="bg-green-600 p-2 mt-3 text-xl text-white rounded flex justify-self-center w-full flex justify-center"
                onClick={() => {
                  history.push('/first-form')
                }}
              >
                ทำประเมินแรกรับ
              </ button>
            </div>

          }


          {
            show.daily &&
            <div class="shadow-lg bg-white p-4 rounded-lg col-span-6">
              <p class="text-2xl">ประเมินอาการประจำวันของท่าน</p>
              {/* <p class="text-2xl">ประเมินอาการประจำวันของท่าน</p> */}
              <Divider />
              {/* <p class="border-2 border-yellow-200 bg-yellow-50 p-2 m-2">
                {textModalAlert}
              </p> */}

              <p style={{ marginTop: 4 }}>ให้ผู้ป่วยกรอกข้อมูลตามอาการที่เป็นจริง ณ เวลาที่ส่ง แบบฟอร์ม โดยส่งอาการวันละ 1 ครั้งก่อน 10.00 น. เป็นเวลา 7วัน </p>
              < button
                class="bg-green-600 p-2 mt-2 text-white rounded flex justify-self-center w-full lg:w-1/2  flex justify-center"
                onClick={() => {
                  history.push('/form-daily-assessment')
                }}
              >
                ทำแบบส่งอาการประจำวันของผู้ป่วย
              </ button>
              {/* < button
                class="bg-green-600 p-2 mt-2 text-white rounded flex justify-self-center w-full lg:w-1/2  flex justify-center"
                onClick={() => {
                  history.push('/history-patient')
                }}
              >
                ดูประวัติย้อนหลัง
              </ button> */}

            </div>
          }
          {
            show.quesue &&
            <div class="shadow-lg bg-white p-4 rounded-lg col-span-6">
              <p class="text-2xl">นัดหมายเข้ารับการรักษาที่โรงพยาบาล</p> <Divider />
              {
                <div class="mx-auto">
                  {
                    randerQ(user)
                  }
                </div>
              }
            </div>
          }

          {
            show.gps &&
            <div class="shadow-lg bg-white p-4 rounded-lg col-span-6">
              <p class="text-2xl">ระบุพิกัดสถานที่กักตัว</p>
              <Divider />
              <p style={{ marginTop: 4 }}>latitude: {gps.latitude} longitude: {gps.longitude} </p>
              <p style={{ marginTop: 4 }}>*โปรดดึงพิกัดที่บ้านท่าน</p>
              < button
                class="bg-green-600 p-2 mt-2 text-white rounded flex justify-self-center w-full lg:w-1/2  flex justify-center"
                onClick={() => {
                  navigator.geolocation.getCurrentPosition(async (position) => {
                    console.log("position", position)
                    let patient = await api.query('db/homecare/patient/query', {
                      "query": {
                        "cid": user.cid
                      }
                    })
                    patient[0]['latitude'] = position.coords.latitude
                    patient[0]['longitude'] = position.coords.longitude
                    patient[0]['gps_accuracy'] = position.coords.accuracy
                    await api.insert(`db/homecare/patient/data/${patient[0]._id}`, patient[0])
                    setGPS({
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                    })
                  });
                }}
              >
                กดปุ่ม "ระบุพิกัดสถานที่กักตัว"
              </ button>
            </div>
          }

          {
            show.discharge &&
            <div class="shadow-lg bg-white p-4 rounded-lg col-span-6">
              {/* <p class="text-2xl">ใบรับรองแพทย์</p> */}
              <p class="text-2xl">แจ้งเตือน</p>
              <Divider />
              {/* <p class="border-2 border-green-200 bg-yellow-50 p-2 m-2">
                {`คุณกักตัวครบ 7 วันแล้ว สามารถออกไปใช้ชีวิตประจำวันได้ปกติ หากต้องการใบรับรองแพทย์ ติดต่อขอรับได้ที่ รพ.แม่สอด ขอให้ท่านและครอบครัวมีสุขภาพแข็งแรงปลอดภัยจากโรคภัยต่างๆ`}
              </p> */}
              {
                show.cert ?
                  randerC(user, cert) :
                  <>
                    < button
                      class="bg-green-600 p-2 mt-2 text-white rounded flex justify-self-center w-full lg:w-1/2  flex justify-center"
                      onClick={() => {
                        setVisible(true)
                      }}
                    >
                      ขอใบรับรองแพทย์
                    </ button>
                  </>
              }
            </div>
          }

          {
            show.discharge &&
            <div class="shadow-lg bg-white p-4 rounded-lg col-span-6">
              <p class="text-3xl pt-2 text-center">แบบประเมินแรกรับ</p>
              <Divider />
              <p class="text-lg pt-2 ">ในกรณีที่ท่านป่วยเป็น covid-19 ใหม่อีกครั้ง โปรดทำฟอร์มแรกรับใหม่</p>
              < button
                class="bg-green-600 p-2 mt-3 text-xl text-white rounded flex justify-self-center w-full flex justify-center"
                onClick={() => {
                  let dayDischarge = moment(formFirstAssessmentRoundLast.discharge_time, 'x')
                  let dayNow = moment()
                  let countDay = dayNow.diff(dayDischarge, 'days') + 1
                  if (countDay < 3) {
                    return alert(`คุณถูกจำหน่ายออกการรักษา ณ วันที่ ${dayDischarge.format('LL')} สามารถกลับมาลงทะเบียนป่วยใหม่อีกครั้ง`)
                  } else {
                    history.push('/first-form')
                  }
                }}
              >
                ทำประเมินแรกรับ
              </ button>
            </div>

          }


          {
            show.video &&
            <>
              <div class="shadow-lg bg-white p-4 rounded-lg col-span-6">
                <p className="text-2xl">วิดีโอสอนการกักตัวที่บ้าน (Home Isolation)</p>
                <Divider />
                {/* video1 */}

                <div
                  className="mt-6"
                  onClick={() => {
                    window.location.href = 'https://www.youtube.com/watch?v=ox4QMUMgLLY'
                  }}
                >
                  <img src={video1} alt="Girl in a jacket" width="100%" />
                  <p className="text-lg">1. แนวทางปฏิบัติสําหรับผู้ป่วยแยก กักตัวที่บ้าน (Home Isolation)</p>
                </div>

                <div
                  className="mt-6"
                  onClick={() => {
                    window.location.href = 'https://www.youtube.com/watch?v=KKJa_SqO6Fs'
                  }}
                >
                  <img src={video2} alt="Girl in a jacket" width="100%" />
                  <p className="text-lg">2. วิธีใช้เครื่องวัดออกซิเจนปลายนิ้ว</p>
                </div>

                <div
                  className="mt-6"
                  onClick={() => {
                    window.location.href = 'https://www.youtube.com/watch?v=CLhZXoiXR3o'
                  }}
                >
                  <img src={video3} alt="Girl in a jacket" width="100%" />
                  <p className="text-lg">3. วิธีวัดไข้ (อุณหภูมิร่างกาย)</p>
                </div>
              </div>
            </>
          }
        </div>
      </div>

    </Layout >)
}