import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from '../../util/api';
import Layout from '../../components/Layout';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import md5 from 'md5';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

import { DatePicker, Space } from 'antd';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';

function onChange(date, dateString) {
  console.log(date, dateString);
}


let MONTH = {
  'ม.ค.': '01',
  'ก.พ.': '02',
  'มี.ค.': '03',
  'เม.ย.': '04',
  'พ.ค.': '05',
  'มิ.ย.': '06',
  'ก.ค.': '07',
  'ส.ค.': '08',
  'ก.ย.': '09',
  'ต.ค.': '10',
  'พ.ย.': '11',
  'ธ.ค.': '12'
}

let MAP_GENDER = {
  'นาง': 'นาง',
  'น.ส.': 'นางสาว',
  'นางสาว': 'นางสาว',
  'นาย': 'นาย',
  'เด็กชาย': 'เด็กชาย',
  'เด็กหญิง': 'เด็กหญิง',
  'ด.ช.': 'เด็กชาย',
  'ด.ญ.': 'เด็กหญิง',
}


export default function Home(props) {
  let history = useHistory();
  const [form, setForm] = useState({
    "_id": 'null ss',
    "hash_cid": null,
    "date": null,
    "Absolute_lymphocyte_min": false,
    no_symptoms1: false,
    no_symptoms2: false,
    no_symptoms3: false,
    no_symptoms4: false,
  });

  const [patient, setPatient] = useState({
    "_id": null,
    "hash_cid": null,
    "Absolute_lymphocyte_min": false,
  });

  React.useEffect(async () => {
    // let userid = props?.match?.params?.userid
    // let _data = await api.query(
    //   'db/homecare/patient/query',
    //   {
    //     "query": {
    //       "hash_cid": userid
    //     }
    //   }
    // )
  }, []);



  let year = []
  let day = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  for (let i = 1; i < 31; i++) {
    day.push(i)
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  return (
    <Layout ispadding>
      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        justifySelf: 'center'
      }}>
        <div class="flex justify-self-center bg-white w-[800px] p-4 pt-6 m-4 mt-8 flex-col">
          <p class="text-2xl text-center">ขอนัดแพทย์</p>
          <Divider class="mb-6" />
          <FormGroup>
            <p class="text-xl mb-2">เลือกเวลาที่การเข้าพบ</p>

            <DatePicker
              disabledDate={disabledDate}
              size={"large"}
              format={'DD/MM/YYYY'}
              locale={locale}
              onChange={(date, dateString) => {
                setForm({
                  ...form, ...{
                    // '2015/01/01'
                    date: date,
                    dateFormat: `${dateString}`.replace(/\//g, '-'),
                    // e.target.value
                  }
                })
              }}
              value={form.date}
            />

            <p class="text-xl mt-6">เลือกเหตุผล</p>
            <FormControlLabel
              control={<Checkbox checked={form.no_symptoms1}
                onChange={() => {
                  setForm({
                    ...form, ...{
                      "no_symptoms1": !form.no_symptoms1,
                      // "no_symptoms4": false,
                    }
                  })
                }}
                name='no_symptoms' />}
              label="เหนื่อย"
            />
            <FormControlLabel
              control={<Checkbox checked={form.no_symptoms2}
                onChange={() => {
                  setForm({
                    ...form, ...{
                      "no_symptoms2": !form.no_symptoms2,
                      // "no_symptoms4": false,
                    }
                  })
                }}
                name='no_symptoms' />} 
              label="แน่นหน้าอก"
            />

            <FormControlLabel
              control={<Checkbox checked={form.no_symptoms3}
                onChange={() => {
                  setForm({
                    ...form, ...{
                      "no_symptoms3": !form.no_symptoms3,
                      // "no_symptoms4": false,
                    }
                  })
                }}
                name='ไอมาก' />}
              label="ไอมาก"
            />

            {/* <FormControlLabel
              control={<Checkbox checked={form.no_symptoms4}
                onChange={() => {
                  setForm({
                    ...form, ...{
                      "no_symptoms1": false,
                      "no_symptoms2": false,
                      "no_symptoms3": false,
                      "no_symptoms4": true,
                    }
                  })
                }}
                name='no_symptoms4' />}
              label="ขอใบรับรองแพทย์"
            /> */}
          </FormGroup>

          <div class={'w-full flex justify-center mt-6'}>
            < button
              class="bg-green-600 p-2 mt-2 text-white rounded flex justify-self-center w-full lg:w-1/2  flex justify-center"
              onClick={async () => {
                try {
                  let _form = { ...form }
                  if (!_form.date) {
                    alert('โปรดเลือกวัน')
                  }
                  if ((_form.no_symptoms1 || _form.no_symptoms2 || _form.no_symptoms3) == false) {
                    alert('เลือกเหตุผล')
                  }

                  let user_data = await localStorage.getItem('user_data')
                  user_data = JSON.parse(user_data)

                  let patient = await api.query(
                    'db/homecare/patient/query',
                    {
                      "query": {
                        "hash_cid": md5(user_data.cid)
                      }
                    }
                  )

                  patient[0]['quesue'] = {
                    "active": true,
                    "date": _form.date.format("x"),
                    "date_doctor": null,
                    "time_doctor": null,
                    "confrim_doctor": null,
                    "patient_symptoms1": _form.no_symptoms1,
                    "patient_symptoms2": _form.no_symptoms2,
                    "patient_symptoms3": _form.no_symptoms3,
                    // "patient_symptoms44": _form.no_symptoms4,
                  }


                  await api.insert(`db/homecare/patient/data/${patient[0]._id}`, patient[0])
                  alert('สำเร็จ')
                  history.push('/home-patient')
                } catch (err) {
                  console.log("err >>", err)
                }
              }}
            >
              บันทึก
              </button>
          </div>

        </div>
      </div>
    </Layout >)
}

