import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { checkSum } from '../../util/validatecid';
import { useHistory } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';

let MONTH = {
  'ม.ค.': '01',
  'ก.พ.': '02',
  'มี.ค.': '03',
  'เม.ย.': '04',
  'พ.ค.': '05',
  'มิ.ย.': '06',
  'ก.ค.': '07',
  'ส.ค.': '08',
  'ก.ย.': '09',
  'ต.ค.': '10',
  'พ.ย.': '11',
  'ธ.ค.': '12'
}

export default function Home() {
  let history = useHistory();
  const [cid, setCid] = useState("");
  const [form, setForm] = useState({
    cid: '',
    date: '',
    month: '',
    year: '',
  })

  const handleChange = (event) => {
    setForm({ ...form, ...{ [event.target.name]: event.target.value } })
  }


  let year = []
  let month = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
  let day = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  for (let i = 1; i < 31; i++) {
    day.push(i)
  }

  function handleClick(path) {
    history.push(path);
  }


  return (
    <Box
      sx={{
        flexGrow: 1,
        background: "#111",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "#fff",
          borderRadius: 12,
          padding: "18px",
          display: "flex",
          maxWidth: "1200px",
          alignSelf: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            borderRadius: 12,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            marginBottom: "24px",
          }}
        >
          <div
            style={{
              background: "#fff",
              borderRadius: 12,
              width: "400px",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
            }}
          >
          </div>
        </div>
        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <TextField
              pattern="[0-9]*"
              inputProps={{
                maxLength: 13,
                inputmode: "tel"
              }}
              id="cid"
              name="cid"
              label="บัตรประชาชน"
              variant="outlined"
              value={form.cid}
              onChange={handleChange}
            />
          </ FormControl>

          <div style={{ flexDirection: 'row' }}>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                value={form.date}
                onChange={(e) => {
                  setForm({ ...form, ...{ date: e.target.value } })
                }}
                select // tell TextField to render select
                label="วันเกิด"
              >
                {
                  day.map(x => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))
                }
              </TextField>
            </ FormControl>


            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                value={form.month}
                onChange={(e) => {
                  setForm({ ...form, ...{ month: e.target.value } })
                }}
                select // tell TextField to render select
                label="เดือนเกิด"
              >
                {
                  Object.keys(MONTH).map(x => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))
                }
              </TextField>
            </ FormControl>


            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                value={form.year}
                onChange={(e) => {
                  setForm({ ...form, ...{ year: e.target.value } })
                }}
                select // tell TextField to render select
                label="ปีเกิด"
              >
                {
                  year.map(x => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))
                }
              </TextField>
            </ FormControl>
          </div>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Button variant="contained" onClick={async () => {

              let dob = `${form.year - 543}-` + `${form.month}`.padStart(2, '0') + `-${form.date}`.padStart(2, '0') +
                console.log("dob", dob)
             
            }} >
              ถัดไป
            </Button>
          </FormControl>
        </div>
      </div>
    </Box >
  );
}
