import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function Home() {
  let history = useHistory();
  React.useEffect(async () => {
    let user_data = await localStorage.getItem('user_data')
    let role = await localStorage.getItem('role')
    if (!user_data) history.push("/login");
    role = JSON.parse(role)
    if (role.includes('doctor')) history.push("/main");
    else if (role.includes('patient')) history.push("/home-patient");
    else history.push("/login");
    console.log("role", JSON.parse(role))
    // setUserInfo({ user: user, name: _data.firstname + ' ' + _data.lastname, role: ROLE_MAP[_role[0]], key_role: _role[0] })
    // dile go by role
  }, []);
  return (
    <div >
    </div>
  );
}
