import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from '../../util/api';
import Layout from '../../components/Layout';
import md5 from 'md5';
import Divider from '@mui/material/Divider';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import util from '../../util'
import { Menu, Avatar, Image, Timeline, Modal, Checkbox, Select } from 'antd';
import Button from '@mui/material/Button';
import moment from 'moment';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;


const columns = [
  { id: 'index', label: 'ลำดับ', minWidth: 30 },
  { id: 'date_make', label: 'วันเวลาที่ทำแบบฟอร์ม', minWidth: 170 },
  { id: 'temperature', label: 'อุณหภูมิร่างกาย', minWidth: 170, align: 'center' },
  { id: 'oxygen_sat', label: 'ออกซิเจนในเลือด', minWidth: 170, align: 'center' },
  { id: 'heart_rate', label: 'ชีพจร', minWidth: 170, align: 'center' },
  { id: '-', label: 'ดูแบบฟอร์ม', minWidth: 170, type: 'button' },
];

function ColumnGroupingTable({ datas = [] }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [ismodal1, setModal1] = useState(false);
  const [modal1, setmodaldata1] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  const [data, setData] = useState([]);

  useEffect(() => {
    if (datas) {
      setData([...datas]
        .sort((a, b) => b.date_make - a.date_make)
        .map((x, i) => ({ ...x, ...{ index: i + 1 } })))
    }
  }, [datas])

  let ReaderRow = ({ value, column, row }) => {
    if (column.id === 'date_make') {
      return (<p>
        {util.getTimeLong(value)}
        {/* {moment(value, 'x').format('L')} */}
        {/* {new Date(value).toLocaleDateString()} */}
      </p>)
    } else if (column.type === 'button') {
      return (<Button style={{ backgroundColor: 'green', color: "#fff" }} onClick={() => {
        console.log("row: ", row)
        setModal1(true)
        setmodaldata1(row)
      }}>
        ดูแบบฟอร์ม
      </Button>)
    } else {
      return (<p>
        {value ? value : '-'}
      </p>)
    }
    return null
  }

  return (
    <Paper sx={{ width: '100%' }}>
      <Modal
        title="นัดหมายคนไข้"
        centered
        footer={null}
        title={null}
        onCancel={() => {
          setModal1(false)
          modal1(null)
        }}
        visible={ismodal1}
        width={800}
      >
        <div className="text-center text-xl"> แบบฟอร์มส่งอาการประจำวันของผู้ป่วย Home Isolate</div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        {/* <div style={{ fontSize: 18 }}> วันเวลาที่ทำแบบฟอร์ม : <span style={{ color: 'green' }}> {new Date(modal1?.updatetime).toLocaleDateString()}</span></div> */}
        <div style={{ fontSize: 18 }}> วันเวลาที่ทำแบบฟอร์ม : <span style={{ color: 'green' }}>  {moment(modal1?.date_make, 'x').format('L')}</span></div>
        <div style={{ fontSize: 18 }}> อุณหภูมิร่างกาย : <span style={{ color: 'green' }}> {modal1?.temperature ? modal1?.temperature : '-'}<sup>°C</sup></span></div>
        <div style={{ fontSize: 18 }}> ออกซิเจนในเลือด O2SAT : <span style={{ color: 'green' }}> {modal1?.oxygen_sat ? modal1?.oxygen_sat : '-'}%SPO<sub>2</sub></span></div>
        <div style={{ fontSize: 18 }}> ชีพจร HR : <span style={{ color: 'green' }}>  {modal1?.heart_rate ? modal1?.heart_rate : '-'}  bmp</span></div>
        <div style={{ fontSize: 18 }}> อาการของผู้ป่วย</div>


        <div style={{ marginLeft: 12 }}>
          <Checkbox checked={modal1?.no_symptoms} > ไม่มีอาการใดๆ/No Symptoms</Checkbox>  <br />
          <Checkbox checked={modal1?.running_nose} > มีน้ำมูก/Running nose</Checkbox>  <br />
          <Checkbox checked={modal1?.sore_throat} > เจ็บคอ/Sore throat</Checkbox>  <br />
          <Checkbox checked={modal1?.severe_cough} > ไอเยอะ/Severe Cough</Checkbox>  <br />
          <Checkbox checked={modal1?.chesty_cough} > มีเสมหะ/Chesty cough</Checkbox>  <br />
          <Checkbox checked={modal1?.fatigue} > อ่อนเพลียมาก (Fatigue)</Checkbox>  <br />
          <Checkbox checked={modal1?.tired_when_talking} > หอบเหนื่อย ขณะพูดหรือออกแรง/Tired when talking or having activity</Checkbox> <br />
          <Checkbox checked={modal1?.tired_when_resting} > หอบเหนื่อยขณะพัก/Tired when resting</Checkbox> <br />
          <Checkbox checked={modal1?.chest_pain_when_breathing} > เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing</Checkbox> <br />
          <Checkbox checked={modal1?.diarrhea_3day} > ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)</Checkbox> <br />
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
          <Button style={{ backgroundColor: 'green', color: "#fff" }} onClick={() => {
            setModal1(false)
            modal1(null)
          }}>
            ปิด
       </Button>

        </div>

      </Modal>
      <TableContainer sx={{ maxHeight: '100%', width: '100%' }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          < ReaderRow column={column} value={value} row={row} />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}


let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

export default function Home(props) {
  let history = useHistory();
  const [isLoad, setLoading] = useState(false);
  const [makeFirst, setMakeFirst] = useState(false);
  const [date, setDate] = useState('-');
  const [certType, setCertType] = useState(null);
  const [cert, setCert] = useState({

  });
  const [gps, setGPS] = useState({
    latitude: '-',
    longitude: '-',
  })
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const [daily, setDaily] = useState([]);

  const [show, setShow] = useState({
    daily: false,
    first: false,
    cert: false,
    quesue: false,
    xray: false,
    video: false,
    discharge: false,
    gps: false,
  });


  React.useEffect(async () => {
    let user_data = await localStorage.getItem('user_data')
    user_data = JSON.parse(user_data)
    let patient = await api.query('db/homecare/patient/query', {
      "query": {
        "cid": user_data.cid
      }
    })

    let form_first_assessment = await api.query('db/homecare/form_first_assessment/query', {
      "query": {
        "cid": user_data.cid
      }
    })

    let form_certificate = await api.query('db/homecare/certificate/query', {
      "query": {
        "cid": user_data.cid
      }
    })

    let daily = false
    let first = true
    let cert = false
    let quesue = false
    let xray = false
    let video = false
    let gps = false
    let discharge = false
    if (patient.length > 0) {
      setUser(patient[0])
      setGPS({
        latitude: patient[0].latitude ? patient[0].latitude : '-',
        longitude: patient[0].longitude ? patient[0].longitude : '-'
      })
    }



    if (form_first_assessment.length > 0) {
      first = false
      daily = true
      quesue = true
      xray = true
      video = true
      gps = true
      if (patient.length && patient[0].discharge) {
        first = false
        daily = false
        quesue = false
        xray = false
        video = false
        gps = false
        discharge = true
        if (form_certificate.length > 0) {
          cert = true
          setCert(form_certificate[0])
        }
      }
      // patient[0
      setDate(util.getTimeLong(form_first_assessment[0].updatetime))
    }

    setShow({ daily, first, cert, quesue, xray, video, discharge, gps });
  }, []);

  console.log("user", user)

  return (
    <Layout ispadding>

      <div class="w-full flex justify-center mx-auto ">
        <div class="grid grid-cols-6 lg:grid-cols-12 p-5 gap-4 flex justify-self-center	w-[1200px]">

          <div class="flex flex-col content-center shadow-lg bg-white p-4 rounded-lg col-span-6 md:col-span-12 row-span-3">
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: 'line'
                },
                title: {
                  text: 'sdfsdf'
                },
                subtitle: {
                  text: null
                },
                xAxis: {
                  categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14']
                },
                yAxis: {
                  title: {
                    // text: 'Temperature (°C)'
                    text: null

                  },
                  min: 0,
                  max: 120
                },
                tooltip: {
                  headerFormat: '<span style="font-size: 10px">ครั้งที่{point.key}</span><br/>',
                  crosshairs: true,
                  shared: true
                },
                plotOptions: {
                  line: {
                    dataLabels: {
                      enabled: true
                    },
                    // enableMouseTracking: false
                  }
                },
                series: [{
                  name: 'ออกซิเจนในเลือด',
                  data: [120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120],
                  // data: arrayhander(daily.map(x => Number(x.oxygen_sat))),
                  color: "green",
                }, {
                  name: 'อุณหภูมิร่างกาย',
                  data: [110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110],
                  // data: arrayhander(daily.map(x => Number(x.temperature))),
                  color: "red",
                }, {
                  name: 'ชีพจร',
                  data: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
                  // data: arrayhander(daily.map(x => Number(x.heart_rate))),
                  color: "blue",
                }]
              }}
            />


          </div>

          <div class="flex flex-col content-center shadow-lg bg-white p-4 rounded-lg col-span-6 md:col-span-12 row-span-3">

            <ColumnGroupingTable datas={daily} />


          </div>

        </div>
      </div>

    </Layout >)
}