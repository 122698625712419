import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from '../../util/api';
import Layout from '../../components/Layout';
import md5 from 'md5';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Divider from '@mui/material/Divider';
import SweetAlert from 'sweetalert2-react';
import moment from 'moment';

import { DatePicker, Input, Button } from 'antd';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import util from '../../util'


export default function Home(props) {
  let history = useHistory();
  let [isSend, setSend] = useState(false)
  let [firstForm, setFirstForm] = useState(null)
  let [form, setForm] = useState({
    "_id": null,
    "hash_cid": null,
    "cid": null,
    "temperature": null,
    "oxygen_sat": null,
    "heart_rate": null,
    "no_symptoms": false,
    "fever": false,
    "running_nose": false,
    "sore_throat": false,
    "severe_cough": false,
    "chesty_cough": false,
    "poor_appetite": false,
    "fatigue": false,
    "tired_when_talking": false,
    "tired_when_resting": false,
    "chest_pain_when_breathing": false,
    "diarrhea_3day": false,
    "other_symptoms": null,
    "has_hr": false,
    "has_o2": false,
    "has_t": false,
    "date_make": null,
    "other_symptoms_value": null
  });

  const handleChange = (event) => {
    setForm({ ...form, ...{ [event.target.name]: event.target.value, no_symptoms: false } })
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  function handleChangeCheck(event, value) {
    setForm({
      ...form, ...{
        no_symptoms: false,
        [event.target.name]: !form[event.target.name]
      }
    })
  }
  let [datemake, setDatemake] = useState(null)



  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    let user_data = await localStorage.getItem('user_data')
    user_data = JSON.parse(user_data)
    if (role !== '["patient"]') {
      return history.push("/login");
    }

    let _data = await api.query(
      'db/homecare/form_first_assessment/query',
      {
        "aggregate": [
          {
            $match: { "hash_cid": md5(user_data.cid) }
          },
          {
            $sort: {
              round: -1
            }
          },
          { $limit: 1 }
        ]
      }
    )
    console.log("[ _data ]", _data)

    if (_data.length > 0) {
      setFirstForm(_data[0])
      let _date = moment()
      _date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      _date.toISOString()
      setDatemake(_date)
      setForm({ ...form, ...{ date_make: _date.format('x') } })
    } else {
      setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'โปรดทำแบบประเมินแรกรับเพื่อเข้าใช้งานก่อน' } })
      history.push('/home-patient')
    }
  }, []);

  console.log("datemake: ", datemake)
  console.log("form: ", form)
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  return (
    <Layout ispadding>
      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        justifySelf: 'center'
      }}>
        <SweetAlert
          show={show.show}
          title={show.title}
          text={show.message}
          onConfirm={() => {
            setShow({ ...show, ...{ show: false } })
          }}
        />

        <div class="flex justify-self-center bg-white w-[800px] p-4 pt-8 m-4 mt-8 flex-col">
          <p class="text-center text-xl md:text-3xl mb-2">แบบส่งอาการประจำวันของผู้ป่วย Home Isolate</p>
          <Divider style={{ marginBottom: 12 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              วันที่แบบส่งอาการประจำวัน
              <DatePicker
                style={{ width: '285px', height: 50, margin: 6 }}
                size={"large"}
                format={'DD/MM/YYYY'}
                disabledDate={disabledDate}
                locale={locale}
                value={datemake}
                onChange={(date) => {
                  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  date.toISOString()
                  setDatemake(date)
                  setForm({
                    ...form, ...{
                      date_make: Number(date.format('x')),
                      date_make_see: date.format('DDMMYYYY')
                    }
                  })
                }}
              />
            </Grid>
            <Grid item xs={0} md={6} style={{ padding: 0, margin: 0 }} />
            <Grid item xs={12} md={6}>
              <TextField
                disabled={form.has_hr}
                style={{ width: '100%', backgroundColor: form.has_hr ? '#999' : '#fff' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 3,
                  inputmode: "decimal"
                }}
                id="heart_rate"
                name="heart_rate"
                label="ชีพจร HR"
                variant="outlined"
                value={form.heart_rate}
                onChange={handleChange}
              />

              <FormControlLabel
                control={<Checkbox checked={form.has_hr} onChange={(event) => {
                  setForm({
                    ...form, ...{
                      [event.target.name]: !form[event.target.name],
                      "heart_rate": "",
                    }
                  })
                }} name='has_hr' />}
                label="ไม่มีเครื่องวัดชีพจร"
              />
            </Grid>


            <Grid item xs={12} md={6}>
              <TextField
                disabled={form.has_t}
                style={{ width: '100%', backgroundColor: form.has_t ? '#999' : '#fff' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 5,
                  inputmode: "decimal"
                }}
                id="temperature"
                name="temperature"
                label="อุณหภูมิร่างกาย"
                variant="outlined"
                value={form.temperature}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox checked={form.has_t} onChange={(event) => {
                  setForm({
                    ...form, ...{
                      [event.target.name]: !form[event.target.name],
                      "temperature": "",
                    }
                  })
                }} name='has_t' />}
                label="ไม่มีเครื่องอุณหภูมิร่างกาย"
              />

            </Grid>


            <Grid item xs={12} md={6}>
              <TextField
                disabled={form.has_o2}
                style={{ width: '100%', backgroundColor: form.has_o2 ? '#999' : '#fff' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 5,
                  inputmode: "decimal"
                }}
                id="oxygen_sat"
                name="oxygen_sat"
                label="ออกซิเจนในเลือด O2SAT"
                variant="outlined"
                value={form.oxygen_sat}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox checked={form.has_o2} onChange={(event) => {
                  setForm({
                    ...form, ...{
                      [event.target.name]: !form[event.target.name],
                      "oxygen_sat": "",
                    }
                  })
                }} name='has_o2' />}
                label="ไม่มีเครื่องออกซิเจนในเลือด"
              />
            </Grid>



            <Grid item xs={12} md={12}>
              <FormGroup>
                <p class="text-2xl"> มีอาการอะไรบ้าง</p>
                <FormControlLabel
                  control={<Checkbox
                    checked={form.no_symptoms}
                    onChange={() => {
                      setForm({
                        ...form, ...{
                          "no_symptoms": !form.no_symptoms,
                          "fever": false,
                          "running_nose": false,
                          "sore_throat": false,
                          "severe_cough": false,
                          "chesty_cough": false,
                          "poor_appetite": false,
                          "fatigue": false,
                          "tired_when_talking": false,
                          "tired_when_resting": false,
                          "chest_pain_when_breathing": false,
                          "diarrhea_3day": false,
                        }
                      })
                    }}
                    name='no_symptoms' />}
                  label="ไม่มีอาการใดๆ/No Symptoms"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.running_nose} onChange={handleChangeCheck} name='running_nose' />}
                  label="มีน้ำมูก/Running nose"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.sore_throat} onChange={handleChangeCheck} name='sore_throat' />}
                  label="เจ็บคอ/Sore throat"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.severe_cough} onChange={handleChangeCheck} name='severe_cough' />}
                  label="ไอเยอะ/Severe Cough"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.chesty_cough} onChange={handleChangeCheck} name='chesty_cough' />}
                  label="มีเสมหะ/Chesty cough"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.fatigue} onChange={handleChangeCheck} name='fatigue' />}
                  label="อ่อนเพลียมาก (Fatigue)"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.tired_when_talking} onChange={handleChangeCheck} name='tired_when_talking' />}
                  label="หอบเหนื่อย ขณะพูดหรือออกแรง/Tired when talking or having activity"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.tired_when_resting} onChange={handleChangeCheck} name='tired_when_resting' />}
                  label="หอบเหนื่อยขณะพัก/Tired when resting"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.chest_pain_when_breathing} onChange={handleChangeCheck} name='chest_pain_when_breathing' />}
                  label="เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing"
                />

                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.diarrhea_3day} onChange={handleChangeCheck} name='diarrhea_3day' />}
                  label="ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)"
                />
                <Divider />
              </FormGroup>
            </Grid>

            <div class={'w-full flex justify-center mt-6'}>
              < button
                disabled={isSend}
                className="bg-green-600 p-2 mt-2 text-white rounded flex justify-self-center w-full lg:w-1/2  flex justify-center"
                onClick={async () => {
                  try {
                    setSend(true)
                    let _form = { ...form }
                    let status = 'green'
                    if (!_form.date_make) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่วันที่' } })

                    if (_form.has_hr === false) {
                      if (!_form.heart_rate) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรของคุณ' } })
                      let _heart_rate = Number(_form.heart_rate)
                      if (Number.isNaN(_heart_rate)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรของคุณเป็นตัวเลข' } })
                      if (_heart_rate < 30 || _heart_rate > 300) {
                        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ชีพจรในช่วง 30 - 300 bmp' } })
                      }
                      _form['heart_rate'] = _heart_rate
                    }

                    if (_form.has_t === false) {
                      if (!_form.temperature) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายของคุณ' } })
                      let _temperature = Number(_form.temperature)
                      if (Number.isNaN(_temperature)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายของคุณเป็นตัวเลข' } })
                      if (_temperature < 33 || _temperature > 43) {
                        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่อุณหภูมิร่างกายในช่วง 33 - 43 °C' } })
                      }
                      _form['temperature'] = _temperature
                    }


                    if (_form.has_o2 === false) {
                      if (!_form.oxygen_sat) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ออกซิเจนในเลือดของคุณ' } })
                      let _oxygen_sat = Number(_form.oxygen_sat)
                      if (Number.isNaN(_oxygen_sat)) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กรุณาใส่ออกซิเจนในเลือดของคุณเป็นตัวเลข' } })
                      if (_oxygen_sat < 50 || _oxygen_sat > 100) {
                        return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'กกรุณาใส่ออกซิเจนในเลือดในช่วง 50 - 100 Sp02' } })
                      }
                      _form['oxygen_sat'] = _oxygen_sat
                    }

                    if (
                      (_form.fever ||
                        _form.running_nose ||
                        _form.sore_throat ||
                        _form.severe_cough ||
                        _form.chesty_cough ||
                        _form.poor_appetite ||
                        _form.fatigue ||
                        _form.tired_when_talking ||
                        _form.tired_when_resting ||
                        _form.chest_pain_when_breathing ||
                        _form.diarrhea_3day ||
                        _form.other_symptoms ||
                        _form.no_symptoms) === false
                    ) return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'โปรดตอบข้อ 2.มีอาการอะไรบ้าง (ถ้าไม่มีอาการโปรดเลือกว่าไม่มีอาการใดๆ)' } })

                    if (_form.has_o2 === false && typeof _form['oxygen_sat'] === 'number' && (_form['oxygen_sat'] >= 50 && _form['oxygen_sat'] < 96)) {
                      status = 'red'
                    }

                    let user_data = await localStorage.getItem('user_data')
                    user_data = JSON.parse(user_data)
                    _form['cid'] = user_data.cid
                    let pre_data = await api.query(`db/homecare/patient/query`, { query: { cid: user_data.cid } })
                    let pre_form_first_assessment = await api.query('db/homecare/form_first_assessment/query',
                      {
                        "aggregate": [
                          {
                            "$match": {
                              "cid": user_data.cid,
                              "round": firstForm.round
                            }
                          },
                        ]
                      })

                    let first_daily_assessment = await api.query(
                      'db/homecare/form_daily_assessment/query',
                      {
                        "aggregate": [
                          {
                            $match: {
                              "cid": user_data.cid,
                              "ref_round": firstForm.round
                            }
                          },
                          {
                            $addFields: {
                              date_make: { $toDouble: "$date_make" },
                            }
                          },
                          { $sort: { date_make: 1 } }
                        ]
                      }
                    )

                    _form['_id'] = `${user_data.cid}_r${firstForm.round}_d${datemake.format('YYYYMMDD')}`
                    _form['ref_round'] = firstForm.round
                    _form['hash_cid'] = md5(user_data.cid)
                    _form['updatetime'] = new Date().getTime()

                    let _datenow = moment()
                    _datenow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    _datenow.toISOString()

                    let pre_form_daily_assessment = await api.query('db/homecare/form_daily_assessment/query',
                      {
                        "aggregate": [
                          {
                            "$match": {
                              "cid": user_data.cid,
                              "date_make": _form.date_make,
                              "ref_round": firstForm.round
                            }
                          },
                        ]
                      })
                    if (pre_form_daily_assessment.length > 0) {
                      setSend(false)
                      return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: `คุณได้ส่งแบบส่งอาการประจำวัน ${util.getTimeLong(_form.date_make)} แล้ว` } })
                    }

                    await api.insert(`db/homecare/form_daily_assessment/data/${_form._id}`, _form)
                    if (status == 'red' && _datenow.format('x') == _form.date_make) {
                      let count_day = 1
                      if (first_daily_assessment.length > 0) {
                        count_day = util.diffday(first_daily_assessment[0].date_make, moment().format('x')) + 1
                      }
                      pre_form_first_assessment[0].status = status
                      let exportData = {
                        _id: `${user_data.cid}_${moment().format('x')}`,
                        time: moment().format('LLLL'),
                        pre_form_first_assessment: pre_form_first_assessment[0],
                        form_daily_assessment: _form,
                        user_data
                      }
                      await api.insert(`db/homecare/form_first_assessment_err/data/${exportData._id}`, exportData)
                      await api.insert(`db/homecare/form_first_assessment/data/${pre_form_first_assessment[0]._id}`, pre_form_first_assessment[0])
                      // TODO: check
                      await api.notify(`ผู้ป่วยสีแดง\nเนื่องจาก: SpO2 < 96% (${_form.oxygen_sat}%)\nID/HN:${user_data.cid}\nชื่อ${pre_data[0].title}${pre_data[0].firstname} ${pre_data[0]?.lastname}\nอายุ: ${pre_data[0].age} ปี\nจำนวนวันที่รักษา:${count_day}วัน\nเบอร์โทร: ${pre_data[0].tel}\nเขต:${pre_data[0].host}`)
                    }
                    setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกสำเร็จ' } })
                    setTimeout(() => {
                      history.push('/home-patient')
                    }, 500)
                    // return null
                  } catch (err) {
                    setSend(false)
                  }

                }}
              >
                {isSend ? 'Loadding ...' : 'บันทึก'}
              </button>
            </div>
          </Grid>
        </div>
      </div>
    </Layout >)
}

