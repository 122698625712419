import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import api from '../../util/api';
import util from '../../util';
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { Form, DatePicker, Space, Progress, Input, Select, Divider, Modal, Checkbox } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import PdfCertificateATK from "../../services/PrintoutPdf/PdfCertificateATK";
import moment from 'moment'
import SweetAlert from 'sweetalert2-react';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;
const { TextArea, Search } = Input;


const { RangePicker } = DatePicker;

let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const ReaderRow = ({ column, row, handleClick, setVisible, update }) => {
  const value = row[`${column.id}`];
  if (column.id === 'date_atk') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {util.getTimeLong(row.first_hlep)}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'date') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {util.getTimeLong(row.date)}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          'ss'
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(`${value}`, 'x').format('LL')}</div>
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(value, 'x').format('LL') new Date(value).toLocaleString()}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          'ss'
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(`${value}`, 'x').format('LL')}</div>
          // <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(value, 'x').format('LL') new Date(value).toLocaleString()}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'quesue' && column.type === 'button') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Button variant="contained" style={{ margin: 8 }} onClick={() => setVisible(row)}>
          เลือกเวลานัด
        </Button>
        {/* < Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
          เลือกเวลานัด
          </Button> */}
      </TableCell>
    );
  }
  else if (column.id === 'date_create') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {util.getTimeLong(row.date_create)}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'host') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8 }}> {row.patient.host}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'checkbox') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Checkbox checked={row.select}
          onChange={(event) => {
            update(row, row.select)
          }}
        />
      </TableCell>
    );
  }

  return (
    <TableCell key={column.id} align={column.align}>
      {
        column.type === 'button' ?
          (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
            ดูข้อมูล
          </Button>) :
          value
      }
    </TableCell>
  );

}

// form_first.date_atk
const columns = [
  { id: 'checkbox', label: 'เลือก', minWidth: 10, align: 'center' },
  // { id: 'index', label: 'ลำดับ', minWidth: 20, align: 'center' },
  { id: 'date_create', label: 'วันที่ส่งคำขอ', minWidth: 120, align: 'center' },
  { id: 'date_atk', label: 'วันที่เข้ารักษา', minWidth: 120, align: 'center' },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  // { id: 'quesue', label: 'นัดเวลา', minWidth: 170, type: 'button', align: 'center' },
  // { id: 'name', label: 'ดูข้อมูล', nWidth: 170, type: 'button', align: 'center' },
];


const columns2 = [
  { id: 'checkbox', label: 'เลือก', minWidth: 10, align: 'center' },
  { id: 'date_create', label: 'วันที่ส่งคำขอ', minWidth: 120, align: 'center' },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'date', label: 'วันที่แจ้งให้ผู้ป่วยมารับ', minWidth: 120, align: 'center' },
  { id: 'addr', label: 'สถานที่รับเอกสาร', minWidth: 170 },
  // { id: 'quesue', label: 'นัดเวลา', minWidth: 170, type: 'button', align: 'center' },
  // { id: 'name', label: 'ดูข้อมูล', nWidth: 170, type: 'button', align: 'center' },
];

function ColumnGroupingTable({ data_mock = [], search = "", age, setVisible, update }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  React.useEffect(async () => {
    let _data = data_mock
      // x.host.indexOf(search) >= 0 ||
      .filter(x => x.name.indexOf(search) > 0 || search === '')
      // .filter(x => x.status === age || age === 'all')
      .map((x, i) => ({
        ...x,
        ...{ index: i + 1 }
      }))
    setData(_data)

  }, [data_mock, search, age]);


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {

                      return <ReaderRow
                        column={column}
                        row={row}
                        handleClick={handleClick}
                        setVisible={setVisible}
                        update={update}
                      />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

function ColumnGroupingTable2({ data_mock = [], search = "", age, setVisible, update }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  React.useEffect(async () => {
    let _data = data_mock
      // x.host.indexOf(search) >= 0 ||
      .filter(x => x.name.indexOf(search) > 0 || search === '')
      // .filter(x => x.status === age || age === 'all')
      .map((x, i) => ({
        ...x,
        ...{ index: i + 1 }
      }))
    setData(_data)

  }, [data_mock, search, age]);


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns2.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns2.map((column) => {

                      return <ReaderRow
                        column={column}
                        row={row}
                        handleClick={handleClick}
                        setVisible={setVisible}
                        update={update}
                      />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

  const [doctor, setDocter] = React.useState({
    name: null,
    host: null,
    certid: null,
  });

  const [listDoctor, setListDocter] = React.useState([
    // {
    //   name: 'นพ.สันต์ ใจยอดศิลป์',
    //   host: 'โรงพยาบาลแม่สอด',
    //   certid: 'ว60303',
    // },
  ]);

  const [doneVisit, setDoneVisit] = useState({
    isVisit: false,
    date: null,
    dateD: null
  });

  const [donwloadVisit, setDonwloadVisit] = useState({
    isVisit: false,
    date: null,
    dateD: null
  });

  const [dataQueue, setDataQueue] = React.useState({
    "active": null,
    "date": null,
    "date_doctor": null,
    "time_doctor": null,
    "confrim_doctor": null,
    "addr": null,
    "name": null,
    "status": null,
    "patient": null,
  });

  React.useEffect(async () => {
    let _data = await api.query('db/homecare/certificate/query', { "aggregate": [] })
    let _listDoctor = await api.query('db/cores/role/query', {
      "aggregate": [
        {
          $match: {
            roles: 'doctor',
            $and: [
              { "profile.certid": { $ne: null } },
              { "profile.certid": { $ne: '' } }
            ]
          }
        }
      ]
    })

    setData(_data.map(x => ({
      ...x,
      ...{
        name: `${x.patient.title}${x.patient.firstname} ${x.patient.lastname}`,
        select: false
      }

    })))

    setListDocter(_listDoctor.map(x => ({
      name: `${x.profile.title}${x.profile.firstname} ${x.profile.lastname}`,
      host: `${x.profile.hospital}`,
      certid: `${x.profile.certid}`,
    })))

  }, []);

  const [page, setPage] = useState(1);
  const [age, setAge] = React.useState('all');
  const [buildingCert, setBuildingCert] = React.useState('');
  const [time, setTime] = React.useState(null);

  let year = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  function handleClick(path) {
    history.push(path);
  }

  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  return (<Layout ispadding>
    <SweetAlert
      show={show.show}
      title={show.title}
      text={show.message}
      onConfirm={() => {
        setShow({ ...show, ...{ show: false } })
      }}
    />

    <Modal
      title="แจ้งคนไข้มารับใบรับรองแพทย์"
      centered
      visible={doneVisit.isVisit}
      onCancel={() => {
        setBuildingCert('')
        setTime(null)
        setDoneVisit({ ...doneVisit, isVisit: false })
      }}
      okText={'ตกลง'}
      cancelText={'ปิด'}
      okButtonProps={{ style: { background: 'green' } }}
      onOk={async () => {
        try {
          if (!time) {
            return alert('โปรดเลือกวันที่มารับ')
          }

          if (!buildingCert) {
            return alert('โปรดเลือกสถานที่มารับ')
          }

          let _data = data.filter(x => x.select)
          for (let i = 0; i < _data.length; i++) {
            let x = _data[i]
            let index = data.findIndex(y => x._id == y._id)
            data[index]['done'] = true
            data[index]['date'] = time.format('x')
            data[index]['time'] = '10.00 น.'
            data[index]['addr'] = buildingCert
            //         "date" : null,
            // "time" : null,
            // "addr" : null,
            let _cert = { ...data[index] }
            delete _cert.select
            data[index].select = false
            await api.insert(`db/homecare/certificate/data/${_cert._id}`, _cert)
          }
          setData([...data])
          setBuildingCert('')
          setTime(null)
          setDoneVisit({ ...doneVisit, isVisit: false })
        } catch (err) {
          console.log("err: ", err)
        }
      }}
      width={800}
    >
      <p class="text-xl"> จำนวนคนที่ต้องแจ้ง: {data.filter(x => x.select).length} คน</p>
      <p > เลือกวันที่มารับ: *</p>
      <DatePicker
        style={{ width: '385px', height: 40 }}
        format={'DD/MM/YYYY'}
        locale={locale}
        size={"large"}
        value={time}
        onChange={(date, dateString) => {
          setTime(date)
        }}
      />
      <p className="mt-4">สถานที่มารับ: * </p>
      <Select
        value={buildingCert}
        style={{ width: '385px', height: 50 }}
        size={"large"}
        onChange={(value) => {
          setBuildingCert(value)
        }}
      >
        {
          [
            'รพ.สต.บ้านปางส่างคำ',
            'รพ.สต.บ้านห้วยกระโหลก',
            'รพ.สต.แม่ตาว',
            'รพ.สต.บ้านโกกโก่',
            'รพ.สต.ท่าสายลวด',
            'รพ.สต.บ้านวังตะเคียน',
            'รพ.สต.บ้านห้วยหินฝน',
            'รพ.สต.พระธาตุผาแดง',
            'รพ.สต.บ้านแม่กุเหนือ',
            'รพ.สต.บ้านแม่กื้ดสามท่า',
            'รพ.สต.บ้านแม่กุใหม่',
            'รพ.สต.พะวอ',
            'รพ.สต.มหาวัน',
            'รพ.สต.บ้านปางส้าน',
            'รพ.สต.บ้านปูเตอร์',
            'รพ.สต.บ้านเจดีย์โค๊ะ',
            'รพ.สต.บ้านห้วยยะอุ',
            'คลินิกชุมชนอบอุ่นเทศบาลนครแม่สอด',
            'รพ.สต.แม่กุ',
            'รพ.สต.บ้านแม่กาษา',
            'รพ.สต.บ้านห้วยไม้แป้น',
            'โรงพยาบาลแม่สอด (ตึกส้ม)',
            'รพ.สต.บ้านแม่กื๊ดหลวง',
            'รพ.สต.แม่ปะ',
          ].map(x => (
            <Option value={x}>{x}</Option>
          ))
        }
      </Select>

      {/* <Input
        placeholder="ระบุสถานที่มารับ" style={{ width: 350, marginBottom: 12 }}
        value={buildingCert}
        onChange={(event) => {
          setBuildingCert(`${event.target.value}`)
        }}
      /> */}
    </Modal>


    <Modal
      title="ดาวโหลดรับรองแพทย์"
      centered
      visible={donwloadVisit.isVisit}
      onCancel={() => {
        setDonwloadVisit({ ...donwloadVisit, isVisit: false })
      }}
      okText={'ดาวโหลด'}
      cancelText={'ปิด'}
      okButtonProps={{ style: { background: 'green' } }}
      onOk={async () => {
        if (doctor.certid) {
          PdfCertificateATK({
            name: doctor.name,
            certid: doctor.certid,
            host: doctor.host,
            date: '',
            addr: '',
            array: data.filter(x => x.select),
            //  data.filter(x => x.quesue.confrim_doctor && donwloadVisit.date === x.quesue.date_doctor)
          })
        }
        // setDonwloadVisit({ ...donwloadVisit, isVisit: false })
      }}
      width={800}
    >
      <p class="text-xl"> จำนวนคนที่ต้องการปริ้น {data.filter(x => x.select).length} คน</p>
      <Select
        value={doctor.certid}
        style={{ width: '385px', height: 50, margin: 6 }}
        size={"large"}
        onChange={(value) => {
          // setDataQueue({ ...dataQueue, time_doctor: value })
          let _data = listDoctor.find(x => x.certid === value)
          setDocter({ ..._data })
        }}
      >

        {
          listDoctor.map(x => (
            <Option value={x.certid}>{x.name} ({x.certid})</Option>
          ))
        }
      </Select>


    </Modal>


    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",

      }}
    >
      <p className="text-3xl mb-4">รายชื่อผู้ขอใบรับรองแพทย์</p>

      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          "boxShadow": "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between'
          }}
        >
          <div>
            < Button variant={page === 1 ? "contained" : 'outlined'} style={{ margin: 8 }}
              onClick={() => {
                setPage(1)
              }}
            >
              รายชื่อผู้ป่วยที่ขอใบรับรองแพทย์
            </Button>

            < Button variant={page === 2 ? "contained" : 'outlined'} style={{ margin: 8 }}
              onClick={() => {
                setPage(2)
              }}
            >
              รายชื่อผู้ป่วยที่แจ้งรับแล้ว
            </Button>
          </div>
          <div>
            < Button style={{ margin: 8, background: '#2980B9', color: '#fff' }}
              onClick={() => {
                // setPage(1)
                setDonwloadVisit({ ...donwloadVisit, isVisit: true })
              }}
            >
              ดาวโหลดใบรับรองแพทย์
            </Button>
            {
              page === 1 &&
              < Button style={{ margin: 8, background: '#2980B9', color: '#fff' }}
                onClick={() => {
                  // setPage(1)
                  setDoneVisit({ ...donwloadVisit, isVisit: true })
                }}
              >
                แจ้งรับใบรับรองแพทย์
              </Button>
            }
            {/* < Button style={{ margin: 8, background: 'red', color: '#fff' }}
              onClick={() => {

              }}
            >
              แจ้งรับใบรับรองแพทย์
          </Button> */}
          </div>
        </div>
        <div style={{ flexDirection: 'row' }}>
          <FormControl sx={{ m: 1, minWidth: 300, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหาชื่อนามสกุล และ สถานพยาบาล"
              style={{ width: 400, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>
        </div>


        {
          page === 1 &&
          <ColumnGroupingTable
            data_mock={data.filter(x => !x.done)}
            search={search}
            age={age}
            update={(datanew, value) => {
              let _index = data.findIndex(_x => _x._id === datanew._id)
              data[_index].select = !data[_index].select
              setData([...data])
            }}
          />
        }

        {
          page === 2 &&
          <ColumnGroupingTable2
            data_mock={data.filter(x => x.done)}
            search={search}
            age={age}
            update={(datanew, value) => {
              let _index = data.findIndex(_x => _x._id === datanew._id)
              data[_index].select = !data[_index].select
              setData([...data])
            }}
          />
        }
      </div>
    </div>
  </Layout >)
}
