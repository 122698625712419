
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "./Pdfmake_js/vfs_fonts"
// import { getFormByCid, DOC_FORM_V3 } from '../../services/surveyServices'
// import { downloadImage } from '../../services/imageServices'
import moment from 'moment';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var font = {
  THSarabun: {
    normal: 'THSarabun.ttf',
    bold: 'THSarabunBold.ttf',
    italics: 'THSarabunItalics.ttf'
  }
};


var call_value = ({
  name = '',
  date = '',
  addr = '',
  array = [],
  arrayNew = []
}) => {
  console.log(">> arrayNew", arrayNew)

  let tmpServicesConslude = [
    {
      columns: [
        {
          text: ['รายชื่อผู้ป่วยนัดพบแพทย์'],
        },
      ],
      style: 'header'
    },
    {
      columns: [
        {
          text: ['รายชื่อผู้ป่วยนัดพบแพทย์ณวันที่' + date],
        },
      ],
      style: 'subheader'
    },
  ]

  let tmp = arrayNew
    .sort((a, b) => (a.quesue?.time_doctor > b.quesue?.time_doctor) ? 1 : ((b.quesue?.time_doctor > a.quesue?.time_doctor) ? -1 : 0))
  tmpServicesConslude.push(
    {
      style: 'tableExample',
      table: {
        // 'auto'
        // widths: [25, 80, 60, 80, 150, '*', 80],
        widths: [25, 80, 60, 150, '*', 80],

        // นางอินทร์ สิทธิ์	3333333333335		หมู่ที่ 6 ถนน11 ต.แม่กุ อ.แม่สอด จ.ตาก 	0912312312
        body: [
          // {text: 'Header 2', style: 'tableHeader', alignment: 'center'}
          [
            { text: 'ลำดับ', style: 'tableHeader', alignment: 'center' },
            { text: 'เลขบัตร', style: 'tableHeader', alignment: 'center' },
            { text: 'วัน/เดือน/ปี', style: 'tableHeader', alignment: 'center' },
            // { text: 'เวลานัด', style: 'tableHeader', alignment: 'center' },
            { text: 'ชื่อผู้ป่วย', style: 'tableHeader', alignment: 'center' },
            { text: 'ที่อยู่', style: 'tableHeader', alignment: 'center' },
            { text: 'โทรศัพท์ติดต่อ', style: 'tableHeader', alignment: 'center' },
          ]
          , ...tmp
            .map((x, i) => [
              { text: i + 1, style: 'tableHeader', alignment: 'center' },
              // { text: moment(x?.quesue?.date_doctor, 'x').format('L'), style: 'tableHeader', alignment: 'center' },
              { text: x?.cid, style: 'tableHeader', alignment: 'center' },
              { text: x?.dob, style: 'tableHeader', alignment: 'center' }, 
              // { text: `${x.quesue.time_doctor}`, style: 'tableHeader' },
              { text: `${x.title}${x.firstname} ${x.lastname}`, style: 'tableHeader' },
              { text: `${textAddr(x)}`, style: 'tableHeader' },
              { text: `${x.tel}`, style: 'tableHeader' },
              // { text: `${x?.quesue?.time_doctor}`, style: 'tableHeader' },

            ])]
      }
    },
  )


  var docDefinition = {
    info: {
      title: 'รายชื่อผู้ป่วย',
      author: '-',
      subject: 'รายชื่อผู้ป่วย',
      keywords: 'รายชื่อผู้ป่วย',
    },
    defaultStyle: {
      font: 'THSarabun',
      fontSize: 14,
    },
    pageOrientation: 'landscape',
    content: tmpServicesConslude,
    styles: {
      header: {
        fontSize: 20,
        bold: true,
        alignment: 'center',
        margin: [10, 0, 0, 10]
      },
      subheader: {
        fontSize: 14,
        bold: true,
        // decoration: 'underline',
        margin: [0, 0, 0, 5]
      },
      content: {
        margin: [40, 0, 0, 5]
      },
      subcontent: {
        margin: [50, 0, 0, 5]
      },
      tableHeader: {
        bold: true,
        fontSize: 14,
      },
      tableExample: {
        margin: [0, 0, 0, 15]
      },
      paddingCaseNumber: {
        margin: [200, 0, 0, 0],
      },
      paddingFirstpage: {
        margin: [0, 160, 0, 0],
        fontSize: 36,
        bold: true
      },
      paddingTop: {
        margin: [0, 20, 0, 0],
      }
    },
    footer: {
      margin: [20, 0],
      columns: [
        {
          alignment: 'right',
          text: 'วันที่ออกรายงาน: ' + new Date().toLocaleString(),
        }
      ]
    },
    // function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
  }
  pdfMake.fonts = font;
  pdfMake.createPdf(docDefinition).open();
  // pdfMake.createPdf(docDefinition).open(`รายงานผลประเมินอาการรายวันของ.pdf`);
  // pdfMake.createPdf(docDefinition).open();
  // IMG_CHILD = null

  // }
}


const textAddr = (item) => {
  let { housenum,
    villageno,
    alley,
    lane,
    road,
    tumbonname,
    cityname,
    provincename } = item

  if (villageno) {
    villageno = villageno.replace(/\D/g, "")
  }
  let address = (housenum ? housenum + ' ' : '')
    + (villageno ? 'หมู่ที่ ' + villageno + ' ' : '')
    + (alley ? 'ตรอก' + alley + ' ' : '')
    + (lane ? 'ซอย' + lane + ' ' : '')
    + (road ? 'ถนน' + road + ' ' : '')
    + (tumbonname ? 'ต.' + tumbonname + ' ' : '')
    + (cityname ? 'อ.' + cityname + ' ' : '')
    + (provincename ? 'จ.' + provincename + ' ' : '')
  return (address)
}

var PdfVisit = (data) => {
  // dataCarePlan = DataCareplan
  // dataChild = DataChild
  call_value(data)
  // Promise.all([
  //   getFormByCid(`${dataChild.cid}`, '3'),
  //   downloadImage('ooscprofile', `${dataChild.cid}_2018_profile`),
  //   downloadImage('ooschomevisit', `${dataChild.cid}_2018_homevisit_1`),
  //   downloadImage('ooschomevisit', `${dataChild.cid}_2018_homevisit_2`),
  //   downloadImage('ooscfamily', `${dataChild.cid}_2018_kidwithfamily`),
  //   // downloadImage('ooscsignature', `${dataChild.cid}_2018_ooscsignature_parent`),
  //   // downloadImage('ooscsignature', `${dataChild.cid}_2018_ooscsignature_child`),
  // ])
  //   .then(res => {
  //     // dataForm = res[0]
  //     // call_value( res[1], res[2], res[3], res[4], res[5], res[6])
  //   })
}
export default PdfVisit
