import React, { useState } from "react";
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import SweetAlert from 'sweetalert2-react';
import api from '../../util/api';
import util from '../../util';
import Layout from '../../components/LayoutPC';
import PdfVisit from "../../services/PrintoutPdf/PdfVisit3";
import { Badge, Select, Modal, Calendar, Divider, Table, Input, Space } from 'antd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/th_TH';
import 'moment/locale/th';


let FIX_Cl = {
  '1': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '2': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 20 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '3': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '4': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ],
  '5': [
    'มะเร็งเต้านม 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งปากมดลูก 5 ราย งานผู้ป่วยนอกแผนกศัลยกรรม',
    'มะเร็งลำไส้ 10 ราย งานผู้ป่วยนอกแผนกศัลยกรรม'
  ]
}

moment.locale('de')

let Holidays = {
  '1681318800000': true,
  '1681405200000': true,
  '1681664400000': true,
  '1683133200000': true,
  '1683219600000': true,
  '1684256400000': true,

  '1685898000000': true,
  '1690477200000': true,
  '1690822800000': true,
  '1690909200000': true,
  '1691946000000': true,
  '1697130000000': true,
  '1697994000000': true,
  '1701709200000': true,
  '1701709200000': true,
  '1702227600000': true,
}


let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },

  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const getFirstDateAndLastDateOnThePanel = (date) => {
  const firstDate = moment(date).startOf("month");
  const lastDate = moment(date).endOf("month");

  const firstDateDay = firstDate.day();
  firstDate.subtract(firstDateDay, "days");
  lastDate.add(42 - Number(lastDate.format("DD")) - firstDateDay, "days");

  return {
    firstDate,
    lastDate,
  };
};


function CountType({ isb, text, listPatient = [], total }) {
  console.log("text", text)

  let count = listPatient.filter(x => x.type === text).length
  let color = ''
  if (isb === false) {
    color = 'text-gray-500'
  } else if (count === 0) {
    color = "text-gray-500"
  } else if (count < total) {
    color = "text-green-600 text-bold"
  } else {
    color = "text-red-500"
  }
  return <p style={{ color: isb ? 'black' : '#D3D3D3' }}><span className={isb ? 'text-green-500' : 'text-gray-500'}>- </span>{`${text}  `}<span className={color}>{count}</span>{` / ${total} คน`}</p>
}
function ColumnGroupingTable2({ data_mock = [] }) {
  let columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'index',
      width: 100,
      key: 'index',
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      render: (_, data) => (
        <Space size="middle">
          <a href={`/patient-queue/${data.hash_cid}/undefined`}>{data['name']}</a>
        </Space>
      )
    },
    {
      title: 'ชื่อ รพ.สต.',
      dataIndex: 'host',
      key: 'host',
      sorter: (a, b) => a.host - b.host,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
    },


  ]
  return (
    <div>
      <Table
        size="small"
        bordered
        pagination={{ defaultPageSize: 5, showSizeChanger: false }}
        columns={columns}
        dataSource={data_mock}
      />
    </div >
  );
}

export default function Home(props) {
  let NowDate = moment()

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  const [modalTest, setModalTest] = React.useState({
    show: false
  });

  const [modalTest2, setModalTest2] = React.useState({
    show: false
  });

  let [dataModal, setDataModal] = React.useState({

  })

  let [ModalDataDay, setModaDatalDay] = React.useState({
    listDay: [],
    amount: 0,
    text: '',
    date: null
  })

  const [DataCalendar, setCalendar] = useState({})
  const dateCellRender = (value) => {
    value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    let isb = moment(value).isSame(moment(), 'day') || moment().isBefore(value)
    let listData = {
      amount: 0,
      data: [],
      listPatient: [],
      date: value.format('x')
    }
    let isNotAddEvent = false
    if (DataCalendar[`${value.format('x')}`]) {
      listData = DataCalendar[`${value.format('x')}`]
      isNotAddEvent = true
    }
    let day = value.day();
    let stop = false
    let content = null
    console.log("day 2", day)
    if (Holidays[`${value.format('x')}`]) {
      stop = true
      content = <>
        <p style={{ width: '100%', fontSize: 12, color: isb ? 'black' : '#D3D3D3', display: 'flex', justifyContent: 'center', paddingTop: 20 }}>วันหยุด</p>
      </>
    } else if (day == 2) {
      content = <>
        <CountType isb={isb} text={'มะเร็งเต้านม'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งปากมดลูก'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งลำไส้'} listPatient={listData?.listPatient} total={20} />
      </>
    } else if (day !== 0 && day !== 6 && day !== 2) {
      content = <>
        <CountType isb={isb} text={'มะเร็งเต้านม'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งปากมดลูก'} listPatient={listData?.listPatient} total={5} />
        <CountType isb={isb} text={'มะเร็งลำไส้'} listPatient={listData?.listPatient} total={10} />
      </>
    } else {
      stop = true
      content = <>
        <p style={{ width: '100%', fontSize: 12, color: isb ? 'black' : '#D3D3D3', display: 'flex', justifyContent: 'center', paddingTop: 20 }}>วันหยุด</p>
      </>
    }
    return (
      <div className="w-full h-full" onClick={() => {
        setModalTest({ show: true })
        setDataModal({ ...listData, day })
      }}>
        <ul className="events">
          {content}
        </ul>
      </div>
    );
  };

  React.useEffect(async () => {
    let data = await api.query(`db/homecare/form_risk/query`, {
      aggregate: [
        {
          $lookup: {
            from: "patient_risk",
            localField: "cid",
            foreignField: "cid",
            as: "listPatient"
          }
        },
        {
          $unwind: {
            path: "$listPatient",
            preserveNullAndEmptyArrays: true
          }
        },
        {
          $addFields: { "listPatient.type": "$type" }
        },
        {
          $group: {
            _id: { x: "$date_queue_patient" },
            date: { $first: "$date_queue_patient" },

            listPatient: { $push: "$listPatient" }
          }
        }
      ]
    })
    let result = {}
    data.forEach(x => {
      result[`${x.date}`] = { ...x }
    })

    setCalendar({ ...result })

  }, []);

  const onChangeText = (e) => {
    const reg = /^-?\d*(\.\d*)?$/;
    const _value = e.target.value;
    if (reg.test(_value) || _value === '' || _value === '-') {
      ModalDataDay.amount = _value
      setModaDatalDay({ ...ModalDataDay })
    }
  };

  const onChangeArray = (e) => {
    const _value = e.target.value;
    ModalDataDay.text = _value
    setModaDatalDay({ ...ModalDataDay })
  };

  return (<Layout ispadding is4>
    <SweetAlert
      show={show.show}
      title={show.title}
      text={show.message}
      onConfirm={() => {
        setShow({ ...show, ...{ show: false } })
      }}
    />
    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1550px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Modal
        title={null}
        centered
        open={modalTest.show}
        onOk={async () => {
        }}
        okText={null}
        // okText={'บันทึก'}
        closable={false}
        cancelText={'ปิด'}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { background: '#FF5677', color: "white" } }}
        onCancel={() => {
          setModalTest({ show: false })
        }}
        width={1100}
      >
        <div className='w-full flex flex-row'>
        </div>
        {/* < Divider /> */}
        <div className="w-full h-[580px]">
          <div>
            <div className="w-full flex justify-between">
              <p className="text-2xl" style={{ color: 'black' }}>ข้อมูลนัดผู้ป่วยณ {moment(dataModal.date, 'x').format('LL')}</p>
            </div>
            < Divider style={{ margin: 6 }} />
            <div class="w-full">
              <div class="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-1 sm:flex-row flex-col">
                <div class="flex-grow">
                  <h2 class="text-gray-900 text-lg title-font font-medium mb-1">{`จำนวนที่เปิดนัดคนไข้ที่ 15 คน`}</h2>
                  <p className="text-md">ข้อมูลเพื่มเติม</p>
                  {
                    FIX_Cl[dataModal?.day] &&
                    FIX_Cl[dataModal?.day].map(item => (
                      <li key={item} style={{ color: 'black' }}>
                        <p className="text-md">- {item}</p>
                      </li>
                    ))
                  }
                </div>
              </div>
            </div>

          </div>
          <div className="flex flex-row mt-6 mb-2">
            <p className="text-xl flex flex-1" style={{ color: 'black' }}>รายชื่อคนไข้ที่นัด</p>
            < Button
              startIcon={<PictureAsPdf />}
              variant="contained"
              style={{ backgroundColor: '#FF5677', height: "32px" }}
              onClick={() => {
                PdfVisit({
                  name: '',
                  date: util.getTimeLong(dataModal.date),
                  addr: '',
                  array: [],
                  arrayNew: dataModal?.listPatient
                })
              }}>
              print pdf
            </Button>
          </div>
          < ColumnGroupingTable2
            data_mock={
              Array.isArray(dataModal?.listPatient) ?
                dataModal?.listPatient.map((x, i) => ({ ...x, ...{ name: `${x.title}${x.firstname} ${x.lastname}`, index: i + 1 } }))
                : []
            }
          />
        </div>
      </Modal>

      <Modal
        title={null}
        // "จัดการข้อมูล 12 มีนาคม 2566"
        centered
        open={modalTest2.show}
        onOk={async () => {
          try {
            let _data = {}
            _data['_id'] = `${ModalDataDay.date}`
            _data['date'] = Number(ModalDataDay.date)
            _data['data'] = ModalDataDay.listDay
            _data['amount'] = ModalDataDay.amount
            api.insert(`db/homecare/calender/data/${_data._id}`, _data)
            setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: 'บันทึกแบบคัดกรองสำเร็จ' } })

            if (DataCalendar[`${ModalDataDay.date}`]) {
              DataCalendar[`${ModalDataDay.date}`].amount = ModalDataDay.amount
              DataCalendar[`${ModalDataDay.date}`].data = [...ModalDataDay.listDay]
            } else {
              DataCalendar[`${ModalDataDay.date}`] = {
                "_id": `${ModalDataDay.date}`,
                "date": Number(ModalDataDay.date),
                "amount": ModalDataDay.amount,
                "data": ModalDataDay.listDay,
                "form_risk": {},
                "listPatient": []
              }
            }
            setCalendar({ ...DataCalendar })
            if (modalTest.show) {
              setDataModal({
                ...dataModal,
                ...{
                  amount: ModalDataDay.amount,
                  data: ModalDataDay.listDay,
                }
              })
            }
            setModalTest2({ show: false })
            ModalDataDay.amount = 0
            ModalDataDay.listDay = []
            setModaDatalDay({ ...ModalDataDay })

          } catch (err) {
            console.log('err', err)
          }
        }}
        okText={'บันทึก'}
        closable={false}
        cancelText={'ปิด'}
        okButtonProps={{ style: { background: 'green' } }}
        onCancel={() => {
          setModalTest2({ show: false })
          ModalDataDay.amount = 0
          ModalDataDay.listDay = []
          setModaDatalDay({ ...ModalDataDay })
        }}
        width={650}
      >
        <div className='w-full flex flex-row'>
        </div>
        {/* < Divider /> */}
        <div className="w-full h-[550px]">
          <div>
            <p className="text-2xl" style={{ color: 'black' }}>ตั้งค่าวัน {moment(ModalDataDay.date, 'x').format('LL')}</p>
            < Divider style={{ margin: 6 }} />
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <p>จำนวนคนไข้ที่เปิดรับนัด* </p>
                <Input
                  style={{ width: '150px', height: 50, }}
                  placeholder=""
                  name="number"
                  maxLength={4}
                  value={ModalDataDay.amount}
                  onChange={onChangeText}
                  addonAfter="คน"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <p>ข้อมูลเพื่มเติม</p>
                <Input
                  style={{ width: '350px', height: 35, }}
                  placeholder="ข้อความ"
                  value={ModalDataDay.text}
                  onChange={onChangeArray}
                  name="number"
                />
                < Button variant="contained" style={{ marginLeft: 12 }} onClick={() => {
                  if (ModalDataDay.text) {
                    ModalDataDay.listDay.push(ModalDataDay.text)
                    ModalDataDay.text = ''
                    setModaDatalDay({ ...ModalDataDay })
                  } else {
                    alert('โปรดใส่ข้อความเพื่มเติม')
                  }
                }}>
                  เพื่ม
                </Button>
              </Grid>
            </Grid>
            < Divider style={{ margin: 6 }} />
            <div class="w-full">
              <div class="flex-grow">
                {
                  Array.isArray(ModalDataDay?.listDay) &&
                  ModalDataDay?.listDay.map((item, index) => (
                    <div class="mt-2 w-full">
                      <div class="border-2 rounded-lg border-gray-200 border-opacity-50 p-1 flex flex-row">
                        <div class="flex-1">
                          <p class="leading-relaxed text-base">{item}</p>
                        </div>
                        < Button variant="contained" style={{ marginLeft: 12, backgroundColor: '#FF5677' }} onClick={() => {
                          const index = ModalDataDay?.listDay.indexOf(item);
                          if (index > -1) { // only splice array when item is found
                            ModalDataDay?.listDay.splice(index, 1); // 2nd parameter means remove one item only
                          }
                          setModaDatalDay({ ...ModalDataDay })
                        }}>
                          ลบ
                        </Button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <div className="w-full flex flex-row">
        <div className="flex-1">
          <p className="text-3xl mb-4">จัดการนัดผู้ป่วย</p>
        </div>
      </div>
      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div style={{ flexDirection: 'row' }}>
          <Calendar
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];

              const current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current.month(i);
                months.push(localeData.monthsShort(current));
              }

              for (let index = start; index < end; index++) {
                monthOptions.push(
                  <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                  </Select.Option>
                );
              }
              const month = value.month();

              const year = value.year();
              const options = [];
              for (let i = year - 10; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>
                );
              }
              return (
                <div style={{ padding: 10 }}>
                  <div className="flex flex-row m-auto w-[280px] text-center justify-between">
                    <ArrowBackIosNewIcon value="month" onClick={() => {
                      const newValue = value.clone();
                      newValue.subtract(1, 'months')
                      onChange(newValue);
                    }}>back</ArrowBackIosNewIcon>
                    <div className="text-2xl">{value.format('MMMM')} {Number(value.format('YYYY')) + 543}</div>
                    <ArrowForwardIosIcon value="month" onClick={() => {
                      const newValue = value.clone();
                      newValue.add(1, 'months')
                      onChange(newValue);
                    }}></ArrowForwardIosIcon>
                  </div>
                  <Divider style={{ padding: 0, marginBottom: 0 }} />
                </div>
              );
            }}
            locale={locale}
            dateRender={(current) => {
              const num = (current.toString().length < 2) ? "0" + current : current
              return { num }
            }}
            dateCellRender={dateCellRender}
            onSelect={(date) => {
            }} />
        </div>

      </div>
    </div >
  </Layout >)
}
