import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import AddressData from "../../util/AddressData";
import encryption from '../../util/encryption';
// const encryption = require('./encryption')
// https://wecaremaesot.org/encryption-b
import api from '../../util/api';
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { Form, RadioForm, DatePicker, Space, Progress, Input, Select, Divider, Modal } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import moment from 'moment'
import SweetAlert from 'sweetalert2-react';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const USER = {
  "title": "",
  "firstname": "",
  "lastname": "",
  "certid": null,
  "hospital": "",
  "role": "",
}

const raderRole = (data) => {
  let _role = '-'
  if (Array.isArray(data?.roles) && data?.roles.length > 0) {
    if (data?.roles[0] === 'doctor') {
      _role = 'หมอ'
    } else if (data?.roles[0] === 'SHPH') {
      _role = 'SHPH'
    } else if (data?.roles[0] === 'patient') {
      _role = 'ผู้ป่วย'
    } else if (data?.roles[0] === 'admin') {
      _role = 'admin'
    }

    return _role
  }
}

const ReaderRow = ({ column, row, handleClick }) => {
  const value = row[column.id];

  if (column.id === 'repass') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick()}>
          รีเซ็ตพาสเวิร์ด
        </Button>
      </TableCell>
    );

  } else if (column.id === 'updatetime') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(value, 'x').format('L')}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'discharge') {
    if (row.discharge) {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#eee', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> discharge</div>
          }
        </TableCell>
      );
    } else {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#CEFFCF', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> active</div>
          }
        </TableCell>
      );
    }
  } else {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
              ดูข้อมูล
            </Button>) :
            value
        }
      </TableCell>
    );
  }
}
const columns = [
  // { id: 'index', label: 'ลำดับ', minWidth: 20 },
  // { id: 'updatetime', label: 'วันที่อัพเดทข้อมูล', minWidth: 90, align: 'center' },
  { id: 'user', label: 'username', minWidth: 200 },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  { id: 'role', label: 'บทบาทหน้าที่', minWidth: 170 },
  { id: 'hospital', label: 'สถานพยาบาล', minWidth: 170 },
  // { id: 'role', label: 'รพสต.', minWidth: 170 },
  { id: 'repass', label: 'รีเซ็ตพาสเวิร์ด', minWidth: 170 },
  // { id: 'status', label: 'ระดับผู้ป่วย', minWidth: 100, alignSelf: 'center' },
  // { id: 'discharge', label: 'สถานะผู้ป่วย', minWidth: 100, alignSelf: 'center' },
  // { id: 'name', label: 'ดูข้อมูล', nWidth: 170, type: 'button', align: 'center' },
];

function ColumnGroupingTable({ data_mock = [], search = "", age }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.user.indexOf(search) >= 0 || x.name.indexOf(search) >= 0 || search === '')
      .filter(x => x.status === age || age === 'all')
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)
    setPage(0)

  }, [data_mock, search, age]);

  const [visible, setVisible] = useState(false);
  const [dataShow, setDataShow] = useState({
    user: ''
  });
  const [pass, setPass] = useState({
    pass: '',
    confirmPass: ''
  });

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />
      <Modal
        title="รีเซ็ตพาสเวิร์ด"
        centered
        visible={visible}
        onOk={async () => {
          if (pass.pass.length < 6) return alert('โปรดใส่อย่างน้อย 6 ตัว')
          if (pass.pass !== pass.confirmPass) return alert('รหัสผ่านใหม่ไม่ตรงกัน')

          let _pass = await api.encryption(pass.pass)
          let pre_data = await api.query(`db/cores/user/query`, {
            query: { User: dataShow.user }
          })
          if (pre_data.length > 0) {
            pre_data[0].Pass_Salt = _pass.pass_salt
            pre_data[0].Pass_Hash = _pass.pass_hash
            console.log("pre_data", pre_data)
            await api.insert(`db/cores/user/data/${pre_data[0]._id}`, pre_data[0])
          }
          setVisible(false)
          setPass({
            pass: '',
            confirmPass: ''
          })
          setAlert('รีเซ็ตพาสเวิร์ดสำเร็จ')
        }}
        onCancel={() => {
          setVisible(false)
          setPass({
            pass: '',
            confirmPass: ''
          })
        }}
        width={800}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
      >
        <p>Username: {dataShow.user}</p>
        <p>Password: </p>
        <Input
          name="pass"
          type="password"
          placeholder="Password" style={{ width: 200, marginBottom: 12 }}
          value={pass.pass}
          onChange={(event) => {
            setPass({ ...pass, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
          }}
        />

        <p>Confirm Password: </p>
        <Input
          name="pass"
          type="password"
          placeholder="Password" style={{ width: 200, marginBottom: 12 }}
          value={pass.confirmPass}
          onChange={(event) => {
            setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
          }}
        />
        <p></p>
      </Modal>

      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {
                      return (
                        <ReaderRow
                          column={column}
                          row={row}
                          handleClick={() => {
                            setDataShow({
                              user: row.user
                            })
                            setVisible(true)
                          }}
                        />
                      )
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const OBJhospital = {}
  const [addUser, setAddUser] = useState({ ...USER });

  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    role = JSON.parse(role)
    let aggregate = []
    let _data = await api.query('db/cores/role/query', {
      query: {
        _id: {
          $ne: 'adminmaesot',
        },
        roles: { $nin: ['doctor'] }
      }
    })
    setData(_data.map(x => ({
      ...x,
      name: `${x?.profile?.title}${x?.profile?.firstname} ${x?.profile?.lastname}`,
      hospital: `${x?.profile?.hospital}`,
      role: raderRole(x)
    })))
    // roles

  }, []);
  const [user, setUser] = useState("");
  const [visible, setVisible] = useState(false);
  const [age, setAge] = React.useState('all');
  const handleChange = (value) => {
    setAge(value);
  };

  let year = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  function handleClick(path) {
    history.push(path);
  }

  AddressData
    .hospitals
    .forEach(x => {
      OBJhospital[x.hospital_name] = x
    })
  return (<Layout ispadding>

    <Modal
      title="เพิ่มผู้ให้งาน"
      centered
      visible={visible}
      onOk={async () => {
        await api.register(
          {
            "user": 'test',
            "pass": 'test',
            "application": "homecare",
            "profile": {
              "cid": 'test',
              "title": 'test',
              "firstname": 'test',
              "lastname": 'test',
              "email": 'test',
              "tel": 'test',
              "tel": 'test',
            },
            "roles": ["doctor"]
          }
          ,
          {
            "cid": 'test',
            "title": 'test',
            "firstname": 'test',
            "lastname": 'test',
            "email": 'test',
            "tel": 'test',
            "tel": 'test',
          }
        )
      }}


      //   if (pass.pass.length < 6) return alert('โปรดใส่อย่างน้อย 6 ตัว')
      //   if (pass.pass !== pass.confirmPass) return alert('รหัสผ่านใหม่ไม่ตรงกัน')
      //   let _pass = await api.encryption(pass.pass)
      //   let pre_data = await api.query(`db/cores/user/query`, {
      //     query: { User: dataShow.user }
      //   })
      //   if (pre_data.length > 0) {
      //     pre_data[0].Pass_Salt = _pass.pass_salt
      //     pre_data[0].Pass_Hash = _pass.pass_hash
      //     console.log("pre_data", pre_data)
      //     await api.insert(`db/cores/user/data/${pre_data[0]._id}`, pre_data[0])
      //   }
      //   setVisible(false)
      //   setPass({
      //     pass: '',
      //     confirmPass: ''
      //   })
      // }}
      onCancel={() => {
        setVisible(false)
        // setPass({
        //   pass: '',
        //   confirmPass: ''
        // })
      }}
      width={800}
      okText={'บันทึก'}
      okButtonProps={{ style: { background: 'green' } }}
      cancelText={'ยกเลิก'}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p>username: * </p>
          <Input
            name="username"
            placeholder="username" style={{ width: 200, marginBottom: 12 }}
            // value={pass.pass}
            onChange={(event) => {
              // setPass({ ...pass, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p>password: * </p>
          <Input
            name="username"
            placeholder="username" style={{ width: 200, marginBottom: 12 }}
            // value={pass.pass}
            onChange={(event) => {
              // setPass({ ...pass, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> คำนำหน้า: </p>
          <Input
            // name="pass"
            // type="password"
            placeholder="Password" style={{ width: 200, marginBottom: 12 }}
            // value={pass.confirmPass}
            onChange={(event) => {
              // setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> ชื่อ: </p>
          <Input
            // name="pass"
            // type="password"
            placeholder="Password" style={{ width: 200, marginBottom: 12 }}
            // value={pass.confirmPass}
            onChange={(event) => {
              // setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> นามสกุล: </p>
          <Input
            // name="pass"
            // type="password"
            placeholder="Password" style={{ width: 200, marginBottom: 12 }}
            // value={pass.confirmPass}
            onChange={(event) => {
              // setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> โรงพยาบาล/รพสต.*: </p>
          <Select defaultValue="lucy" style={{ width: 300 }} >
            {/* onChange={handleChange} size={"large"} value={age} */}
            <Option value="all">โรงพยาบาลแม่สอด</Option>
            {
              Object.keys(OBJhospital)
                .map(_x => (
                  <Option value={_x}>{_x}</Option>
                ))
            }
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> บทบาทหน้าที่*: </p>
          <Select defaultValue="lucy" style={{ width: 120 }} >
            {/* onChange={handleChange} size={"large"} value={age} */}
            <Option value="all">แพทย์</Option>
            <Option value="green">พยาบาล</Option>
            {/* <Option value="all">โรงพยาบาลแม่สอด</Option> */}
            {/* <Option value="green">รพ.สต.</Option> */}
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> เลขที่ใบอนุญาต: </p>
          <Input
            // name="pass"
            // type="password"
            placeholder="Password" style={{ width: 200, marginBottom: 12 }}
            // value={pass.confirmPass}
            onChange={(event) => {
              // setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
      </div>


    </Modal>

    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
        // backgroundColor: "#fff",
        // 
      }}
    >
      <p className="text-3xl mb-4">รายชื่อผู้ป่วย</p>


      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          "boxShadow": "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
          {/* <FormControl sx={{ m: 1 }}>
            <div>ระดับผู้ป่วย</div>
            <Select defaultValue="lucy" style={{ width: 220 }} onChange={handleChange} size={"large"} value={age}>
              <Option value="all">แสดงทั้งหมด</Option>
              <Option value="green">ผู้ป่วยสีเชียว</Option>
              <Option value="lightyellow" >ผู้ป่วยสีเหลืองอ่อน</Option>
              <Option value="yellow" >ผู้ป่วยสีเหลืองเข้ม</Option>
            </Select>
          </FormControl> */}

          <FormControl sx={{ m: 1, minWidth: 300, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหา username"
              style={{ width: 400, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120, height: 54 }}>
            {/* < Button variant="contained"
              style={{ margin: 8 }}
              onClick={() => {
                setVisible(true)
              }}>
              + เพิ่มผู้ให้งาน
         </Button> */}
          </FormControl>
        </div>
        <ColumnGroupingTable data_mock={data} search={search} age={age} />
      </div>
    </div>
  </Layout >)
}
