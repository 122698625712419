import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from '../../util/api';
import Layout from '../../components/Layout';
import md5 from 'md5';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import { DatePicker, Input, Select } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import SweetAlert from 'sweetalert2-react';

const { Option } = Select;

export default function Home(props) {
  let history = useHistory();
  const [datetime, setDate] = useState(null)
  const [data, setData] = useState(null)
  const [form, setForm] = useState({
    "_id": null,
    "hash_cid": null,
    "cid": null,
    "temperature": null,
    "oxygen_sat": null,
    "heart_rate": null,
    "no_symptoms": false,
    "fever": false,
    "running_nose": false,
    "sore_throat": false,
    "severe_cough": false,
    "chesty_cough": false,
    "poor_appetite": false,
    "fatigue": false,
    "tired_when_talking": false,
    "tired_when_resting": false,
    "chest_pain_when_breathing": false,
    "diarrhea_3day": false,
    "other_symptoms": null,
    "other_symptoms_value": null,
    "age_60": false,
    "BMI": false,
    "COPD": false,
    "CKD": false,
    "Chronic_Heart_Disease": false,
    "CVA": false,
    "T2DM": false,
    "Cirrhosis": false,
    "Immunocompromise": false,
    "weight": null,
    "height": null,
    "Absolute_lymphocyte_min": false,
    "age": null,
    "has_hr": false,
    "has_o2": false,
    "has_t": false,
    "pregnant": null,
    "eat_e1": false,
    "eat_e2": false,
    "date_atk": false,
    "who_atk": null,
    "check_covid": null,
    "hospital_atk": null,
    xray: null
  });

  const handleChange = (event) => {
    setForm({ ...form, ...{ [event.target.name]: event.target.value } })
  }

  function handleChangeCheck(event, value) {
    setForm({ ...form, ...{ [event.target.name]: !form[event.target.name], "no_symptoms": false, } })
  }

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }

  function handleChangeCheckF(event, value) {
    setForm({ ...form, ...{ [event.target.name]: !form[event.target.name] } })
  }
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  console.log("form", form)

  React.useEffect(async () => {
    let user_data = await localStorage.getItem('user_data')
    user_data = JSON.parse(user_data)
    let _data = await api.query(
      'db/homecare/patient/query',
      {
        "query": {
          "hash_cid": md5(user_data.cid)
        }
      }
    )
    setData(_data[0])
  }, []);

  return (
    <Layout ispadding>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />

      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        justifySelf: 'center'
      }}>


        <div class="flex justify-self-center bg-white w-[800px] p-4 pt-6 m-4 mt-8 flex-col">

          <p class="text-center text-xl md:text-3xl mb-2">ทำแบบประเมินแรกรับเพื่อเข้าใช้งาน</p>
          <Divider class="mb-6" />


          <Grid container spacing={2}>

            <Grid item xs={12} md={6}>
              วันที่ตรวจพบเชื้อโควิด-19
              <DatePicker
                style={{ width: '285px', height: 50, marginTop: 6 }}
                size={"large"}
                value={datetime}
                disabledDate={disabledDate}
                format={'DD/MM/YYYY'}
                locale={locale}
                onChange={(date, dateString) => {
                  setDate(date)
                  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  date.toISOString()
                  setForm({ ...form, ...{ date_atk: date.format('x') } })
                }}
              />
            </Grid>
            <Grid item xs={0} md={6} style={{ padding: 0, margin: 0 }} />

            <Grid item xs={12} md={6}>
              <p>วิธีตรวจเชื้อโควิด-19</p>
              <Select
                value={form.check_covid}
                size="large"
                style={{ width: 230 }}
                onChange={(e) => {
                  setForm({
                    ...form, ...{
                      check_covid: e,
                      hospital_atk: null,
                      who_atk: null,
                    }
                  })
                }}

              >
                <Option value="ATK">ATK </Option>
                <Option value="PCR">RT-PCR</Option>
              </Select>
            </Grid>
            {
              form.check_covid !== null &&
              <>
                <Grid item xs={0} md={6} style={{ padding: 0, margin: 0 }} />

                <Grid item xs={12} md={6}>

                  <p>ใครเป็นคนตรวจ {form?.check_covid ? form?.check_covid : ''}</p>
                  <Select
                    value={form.who_atk}
                    size="large"
                    style={{ width: 230 }}
                    onChange={(e) => {
                      setForm({
                        ...form, ...{
                          who_atk: e,
                          hospital_atk: null
                        }
                      })
                    }}

                  >
                    <Option value="ตรวจมาจากโรงพยาบาล">ตรวจมาจากโรงพยาบาล</Option>
                    {
                      form.check_covid !== 'PCR' && < Option value="ตรวจด้วยตนเอง">ตรวจด้วยตนเอง</Option>
                    }
                  </Select>
                </Grid>
              </>
            }

            {
              form.who_atk === 'ตรวจมาจากโรงพยาบาล' &&
              <>
                <Grid item xs={0} md={6} style={{ padding: 0, margin: 0 }} />
                <Grid item xs={12} md={6}>
                  <p>โรงพยาบาลที่ตรวจ ATK หรือ PCR</p>
                  <Select
                    value={form.hospital_atk}
                    size="large"
                    style={{ width: 200 }}
                    onChange={(e) => {
                      setForm({
                        ...form, ...{ hospital_atk: e }
                      })
                    }}
                  >
                    <Option value="รพ.แม่สอด">รพ.แม่สอด</Option>
                    <Option value="รพ.แม่สอดราม">รพ.แม่สอดราม</Option>
                    <Option value="รพ.แม่สอดอินเตอร์">รพ.แม่สอดอินเตอร์</Option>
                  </Select>
                </Grid>
              </>
            }
            <Grid item xs={0} md={6} style={{ padding: 0, margin: 0 }} />
            <Grid item xs={6} md={4}>
              <TextField
                style={{ width: '100%' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 3,
                  inputmode: "tel"
                }}
                id="weight"
                name="weight"
                label="นำ้หนัก"
                variant="outlined"
                value={form.weight}
                onChange={handleChange}
              />
            </Grid>


            <Grid item xs={6} md={4}>
              <TextField
                style={{ width: '100%' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 3,
                  inputmode: "tel"
                }}
                id="height"
                name="height"
                label="ส่วนสูง"
                variant="outlined"
                value={form.height}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={0} md={4} style={{ padding: 0, margin: 0 }} />

            <Grid item xs={12} md={6}>
              <TextField
                disabled={form.has_hr}
                style={{ width: '100%', backgroundColor: form.has_hr ? '#999' : '#fff' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 3,
                  inputmode: "decimal"
                }}
                id="heart_rate"
                name="heart_rate"
                label="ชีพจร HR"
                variant="outlined"
                value={form.heart_rate}
                onChange={handleChange}
              />

              <FormControlLabel
                control={<Checkbox checked={form.has_hr} onChange={(event) => {
                  setForm({
                    ...form, ...{
                      [event.target.name]: !form[event.target.name],
                      "heart_rate": "",
                    }
                  })
                }} name='has_hr' />}
                label="ไม่มีเครื่องวัดชีพจร"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                disabled={form.has_o2}
                style={{ width: '100%', backgroundColor: form.has_t ? '#999' : '#fff' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 5,
                  inputmode: "decimal"
                }}
                id="temperature"
                name="temperature"
                label="อุณหภูมิร่างกาย"
                variant="outlined"
                value={form.temperature}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox checked={form.has_t} onChange={(event) => {
                  setForm({
                    ...form, ...{
                      [event.target.name]: !form[event.target.name],
                      "temperature": "",
                    }
                  })
                }} name='has_t' />}
                label="ไม่มีเครื่องอุณหภูมิร่างกาย"
              />

            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                disabled={form.has_o2}
                style={{ width: '100%', backgroundColor: form.has_o2 ? '#999' : '#fff' }}
                pattern="[0-9]*"
                inputProps={{
                  maxLength: 5,
                  inputmode: "decimal"
                }}
                id="oxygen_sat"
                name="oxygen_sat"
                label="ออกซิเจนในเลือด O2SAT"
                variant="outlined"
                value={form.oxygen_sat}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox checked={form.has_o2} onChange={(event) => {
                  setForm({
                    ...form, ...{
                      [event.target.name]: !form[event.target.name],
                      "oxygen_sat": "",
                    }
                  })
                }} name='has_o2' />}
                label="ไม่มีเครื่องออกซิเจนในเลือด"
              />
            </Grid>


            <Grid item xs={12} md={12}>
              <FormGroup>
                <p class="text-2xl"> 1. เคยเอกซ์เรย์ในช่วง 1-2 สัปดาห์ไหม</p>
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.xray === 'ไม่เคย'} onChange={() => {
                    setForm({
                      ...form, ...{
                        xray: 'ไม่เคย',
                      }
                    })
                  }} name='running_nose' />}
                  label="ไม่เคย"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.xray === "เคย มีผลปกติ"} onChange={() => {
                    setForm({
                      ...form, ...{
                        xray: 'เคย มีผลปกติ',
                      }
                    })
                  }} name='running_nose' />}
                  label="เคย มีผลปกติ"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.xray === "เคย มีผลผิดปกติเล็กน้อย"} onChange={() => {
                    setForm({
                      ...form, ...{
                        xray: 'เคย มีผลผิดปกติเล็กน้อย',
                      }
                    })
                  }} name='running_nose' />}
                  label="เคย มีผลผิดปกติเล็กน้อย"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.xray === "เคย มีผลผิดปกติมาก"} onChange={() => {
                    setForm({
                      ...form, ...{
                        xray: 'เคย มีผลผิดปกติมาก',
                      }
                    })
                  }} name='running_nose' />}
                  label="เคย มีผลผิดปกติมาก"
                />
                <Divider />

              </FormGroup>
            </Grid>


            <Grid item xs={12} md={12}>
              <FormGroup>
                <p class="text-2xl"> 2. มีอาการอะไรบ้าง</p>
                <FormControlLabel
                  control={<Checkbox checked={form.no_symptoms}
                    onChange={() => {
                      setForm({
                        ...form, ...{
                          "no_symptoms": !form.no_symptoms,
                          "fever": false,
                          "running_nose": false,
                          "sore_throat": false,
                          "severe_cough": false,
                          "chesty_cough": false,
                          "poor_appetite": false,
                          "fatigue": false,
                          "tired_when_talking": false,
                          "tired_when_resting": false,
                          "chest_pain_when_breathing": false,
                          "diarrhea_3day": false,
                        }
                      })
                    }}
                    name='no_symptoms' />}
                  label="ไม่มีอาการใดๆ/No Symptoms"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.running_nose} onChange={handleChangeCheck} name='running_nose' />}
                  label="มีน้ำมูก/Running nose"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.sore_throat} onChange={handleChangeCheck} name='sore_throat' />}
                  label="เจ็บคอ/Sore throat"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.severe_cough} onChange={handleChangeCheck} name='severe_cough' />}
                  label="ไอเยอะ/Severe Cough"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.chesty_cough} onChange={handleChangeCheck} name='chesty_cough' />}
                  label="มีเสมหะ/Chesty cough"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.fatigue} onChange={handleChangeCheck} name='fatigue' />}
                  label="อ่อนเพลียมาก (Fatigue)"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.tired_when_talking} onChange={handleChangeCheck} name='tired_when_talking' />}
                  label="หอบเหนื่อย ขณะพูดหรือออกแรง/Tired when talking or having activity"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.tired_when_resting} onChange={handleChangeCheck} name='tired_when_resting' />}
                  label="หอบเหนื่อยขณะพัก/Tired when resting"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.chest_pain_when_breathing} onChange={handleChangeCheck} name='chest_pain_when_breathing' />}
                  label="เจ็บอกเมื่อหายใจเข้าออก/Chest pain when breathing"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.diarrhea_3day} onChange={handleChangeCheck} name='diarrhea_3day' />}
                  label="ท้องเสีย มากกว่า 3 ครั้ง/วัน หรือถ่ายเป็นมูกเลือด/Diarrhea (liquid stool or bleeding mucous stool)"
                />

                <p class="text-2xl mt-8"> 3. โรคประจำตัว</p>

                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.COPD} onChange={handleChangeCheckF} name='COPD' />}
                  label="โรคปอดอุดกั้นเรื้อรัง (Chronic Obstructive Pulmonary Disease หรือ COPD)"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.CKD} onChange={handleChangeCheckF} name='CKD' />}
                  label="โรคไตวายเรื้อรัง CKD"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.Chronic_Heart_Disease} onChange={handleChangeCheckF} name='Chronic_Heart_Disease' />}
                  label="โรคหัวใจเรื้อรัง Chronic Heart Disease"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.CVA} onChange={handleChangeCheckF} name='CVA' />}
                  label="โรคหลอดเลือดสมอง CVA"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.T2DM} onChange={handleChangeCheckF} name='T2DM' />}
                  label="โรคเบาหวาน ดื้ออินซูลิ T2DM"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.Cirrhosis} onChange={handleChangeCheckF} name='Cirrhosis' />}
                  label="โรคตับแข็ง Cirrhosis"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.Immunocompromise} onChange={handleChangeCheckF} name='Immunocompromise' />}
                  label="ภาวะภูมิคุ้มกันต่ำ Immunocompromise"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.Absolute_lymphocyte_min} onChange={handleChangeCheckF} name='Absolute_lymphocyte_min' />}
                  label="ค่าเม็ดเลือดขาวน้อยกว่า 1000 cell/mm3 (Absolute lymphocyte น้อยกว่า 1000 cell/mm3)"
                />
                {/* <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.other_symptoms} onChange={handleChangeCheckF} name='other_symptoms' />}
                  label="โรคอื่นๆ"
                />{
                  form.other_symptoms &&
                  <Input placeholder="โปรดระบุโรค" />
                } */}

                <Divider />


                <p class="text-2xl mt-8"> 4. ท่านมีรับประทานยาต่อไปนี้หรือไม่</p>

                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={(form.eat_e1 === false) && (form.eat_e2 === false)} onChange={() => {
                    setForm({
                      ...form, ...{
                        eat_e1: false,
                        eat_e2: false,
                      }
                    })
                  }} name='COPD' />}
                  label="ไม่มี"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.eat_e1} onChange={handleChangeCheckF} name='eat_e1' />}
                  label="ฟ้าทะลายโจร"
                />
                <Divider />
                <FormControlLabel
                  control={<Checkbox checked={form.eat_e2} onChange={handleChangeCheckF} name='eat_e2' />}
                  label="ยาฟาวิพิราเวียร์ (ยาต้านไวรัส)"
                />
                <Divider />

                {
                  (data?.gender && data?.gender === 'ญ') && <>
                    <p class="text-2xl mt-8"> 5. ท่านตั้งครรภ์อยู่หรือไม่</p>

                    <Divider />
                    <FormControlLabel
                      control={<Checkbox checked={form.pregnant === 'ไม่ใช่'} onChange={() => {
                        setForm({
                          ...form, ...{
                            pregnant: 'ไม่ใช่',
                          }
                        })
                      }} name='is_p' />}
                      label="ไม่ใช่"
                    />
                    <Divider />
                    <FormControlLabel
                      control={<Checkbox checked={form.pregnant === 'ใช่'} onChange={() => {
                        setForm({
                          ...form, ...{
                            pregnant: 'ใช่',
                          }
                        })
                      }}
                        name='COPD' />}
                      label="ใช่"
                    />
                    <Divider />
                    <FormControlLabel
                      control={<Checkbox checked={form.pregnant === 'ประจำเดือนขาด แต่ยังไม่ได้ตรวจการตั้งครรภ์'} onChange={() => {
                        setForm({
                          ...form, ...{
                            pregnant: 'ประจำเดือนขาด แต่ยังไม่ได้ตรวจการตั้งครรภ์',
                          }
                        })
                      }} name='is_p' />}
                      label="ประจำเดือนขาด แต่ยังไม่ได้ตรวจการตั้งครรภ์"
                    />
                    <Divider />
                  </>
                }

              </FormGroup>
            </Grid>

            <div class={'w-full flex justify-center mt-6'}>
              < button
                class="bg-green-600 p-2 mt-2 text-white rounded flex justify-self-center w-full lg:w-1/2  flex justify-center"
                onClick={async () => {
                  try {
                    let _form = { ...form }
                    if (!_form.date_atk) return setAlert('กรุณาใส่วันที่ตรวจพบเชื้อโควิด-19 โดยวิธี PCR หรือ ATK')
                    if (!_form.check_covid) return setAlert('กรุณาระบุวิธีตรวจเชื้อโควิด-19')
                    if (!_form.who_atk) return setAlert('กรุณาระบุใครเป็นคนตรวจ ATK หรือ PCR')

                    if (_form.who_atk === "ตรวจมาจากโรงพยาบาล" && !_form.hospital_atk) return setAlert('กรุณาระบุโรงพยาบาลที่ตรวจ ATK หรือ PCR')

                    if (!_form.weight) return setAlert('กรุณาใส่ความนำ้หนักของคุณ')
                    let _weight = Number(_form.weight)
                    if (Number.isNaN(_weight)) return setAlert('กรุณาใส่นำ้หนักของคุณเป็นตัวเลข')
                    if (_weight < 20 || _weight > 250) setAlert('กรุณาใส่นำ้หนักในช่วง 20 - 250 กิโลกรัม(kg)')
                    _form['weight'] = _weight

                    if (!_form.height) return setAlert('กรุณาใส่ความสูงของคุณ')
                    let _height = Number(_form.height)
                    if (Number.isNaN(_height)) return setAlert('กรุณาใส่ความสูงของคุณเป็นตัวเลข')
                    if (_height < 20 || _height > 250) return setAlert('กรุณาใส่ความสูงในช่วง 20 - 250 เซ็นติเมตร(cm)')
                    _form['height'] = _height


                    if (_form.has_hr === false) {
                      if (!_form.heart_rate) return setAlert('กรุณาใส่ชีพจรของคุณ')
                      let _heart_rate = Number(_form.heart_rate)
                      if (Number.isNaN(_heart_rate)) return setAlert('กรุณาใส่ชีพจรของคุณเป็นตัวเลข')
                      if (_heart_rate < 30 || _heart_rate > 300) return setAlert('กรุณาใส่ชีพจรในช่วง 30 - 300 bmp')
                      _form['heart_rate'] = _heart_rate
                    }

                    if (_form.has_t === false) {
                      if (!_form.temperature) return setAlert('กรุณาใส่อุณหภูมิร่างกายของคุณ')
                      let _temperature = Number(_form.temperature)
                      if (Number.isNaN(_temperature)) return setAlert('กรุณาใส่อุณหภูมิร่างกายของคุณเป็นตัวเลข')
                      if (_temperature < 33 || _temperature > 43) return setAlert('กรุณาใส่อุณหภูมิร่างกายในช่วง 33 - 43 °C')
                      _form['temperature'] = _temperature
                    }

                    if (_form.has_o2 === false) {
                      if (!_form.oxygen_sat) return setAlert('กรุณาใส่ออกซิเจนในเลือดของคุณ')
                      let _oxygen_sat = Number(_form.oxygen_sat)
                      if (Number.isNaN(_oxygen_sat)) return setAlert('กรุณาใส่ออกซิเจนในเลือดของคุณเป็นตัวเลข')
                      if (_oxygen_sat < 50 || _oxygen_sat > 100) return setAlert('กรุณาใส่ออกซิเจนในเลือดในช่วง 50 - 100 Sp02')
                      _form['oxygen_sat'] = _oxygen_sat
                    }

                    if (!_form.xray) return setAlert('โปรดตอบข้อ 1.ผลเอกซ์เรย์ในช่วง 1-2 เดือนของคุณ')

                    if (
                      (_form.fever ||
                        _form.running_nose ||
                        _form.sore_throat ||
                        _form.severe_cough ||
                        _form.chesty_cough ||
                        _form.poor_appetite ||
                        _form.fatigue ||
                        _form.tired_when_talking ||
                        _form.tired_when_resting ||
                        _form.chest_pain_when_breathing ||
                        _form.diarrhea_3day ||
                        _form.other_symptoms ||
                        _form.no_symptoms) === false
                    ) return setAlert('โปรดตอบข้อ 2.มีอาการอะไรบ้าง (ถ้าไม่มีอาการโปรดเลือกว่าไม่มีอาการใดๆ)')

                    if (data?.gender === 'ญ' && !_form.pregnant) return setAlert('โปรดตอบข้อ 5.ท่านตั้งครรภ์อยู่หรือไม่')

                    let status = 'green'
                    let user_data = await localStorage.getItem('user_data')
                    user_data = JSON.parse(user_data)
                    let form_first_assessment = await api.query('db/homecare/form_first_assessment/query', {
                      "aggregate": [
                        {
                          $match: { "hash_cid": md5(user_data.cid) }
                        },
                        {
                          $project: {
                            hash_cid: 1
                          }
                        }
                      ]
                    })
                    _form['cid'] = user_data.cid
                    _form['_id'] = `${user_data.cid}-r${form_first_assessment.length + 1}`
                    _form['round'] = `${form_first_assessment.length + 1}`
                    // ${+ _form.date_atk}
                    let pre_data = await api.query(`db/homecare/patient/query`, { query: { cid: _form.cid } })

                    if (pre_data[0].age > 60) {
                      _form.age_60 = true
                    }
                    if (
                      _form.age_60 ||
                      _form.BMI ||
                      _form.COPD ||
                      _form.CKD ||
                      _form.Chronic_Heart_Disease ||
                      _form.CVA ||
                      _form.T2DM ||
                      _form.Cirrhosis ||
                      _form.Immunocompromise ||
                      _form.Absolute_lymphocyte_min
                    ) {
                      status = 'lightyellow'
                    }

                    if (_form.has_o2 === false && typeof _form['oxygen_sat'] === 'number' && (_form['oxygen_sat'] >= 50 && _form['oxygen_sat'] < 96)) {
                      status = 'red'
                    }
                    let bmi = (_form.weight / ((_form.height * _form.height) / 10000)).toFixed(2);
                    pre_data[0].height = _form.height
                    pre_data[0].weight = _form.weight
                    pre_data[0].status = status
                    _form['status'] = status
                    _form['hash_cid'] = md5(user_data.cid)
                    _form['updatetime'] = new Date().getTime()
                    _form['is_call'] = `ยังไม่ได้โทร`
                    _form['doctornote'] = ``
                    let _datenow = moment()
                    _datenow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    _datenow.toISOString()
                    let _daily = {
                      "_id": `${user_data.cid}_r${_form.round}_d${_datenow.format('YYYYMMDD')}`,
                      "hash_cid": md5(user_data.cid),
                      "cid": user_data.cid,
                      "temperature": _form['temperature'],
                      "oxygen_sat": _form['oxygen_sat'],
                      "heart_rate": _form['heart_rate'],
                      "no_symptoms": _form['no_symptoms'],
                      "fever": _form['fever'],
                      "running_nose": _form['running_nose'],
                      "sore_throat": _form['sore_throat'],
                      "severe_cough": _form['severe_cough'],
                      "chesty_cough": _form['chesty_cough'],
                      "poor_appetite": _form['poor_appetite'],
                      "fatigue": _form['fatigue'],
                      "tired_when_talking": _form['tired_when_talking'],
                      "tired_when_resting": _form['tired_when_resting'],
                      "chest_pain_when_breathing": _form['chest_pain_when_breathing'],
                      "diarrhea_3day": _form['diarrhea_3day'],
                      "other_symptoms": _form['other_symptoms'],
                      "has_hr": _form['has_hr'],
                      "has_o2": _form['has_o2'],
                      "has_t": _form['has_t'],
                      "date_make": `${_datenow.format('x')}`,
                      "other_symptoms_value": null,
                      "ref_round": `${_form.round}`,
                      "updatetime": new Date().getTime(),
                    }


                    await api.insert(`db/homecare/form_first_assessment/data/${_form._id}`, _form)
                    await api.insert(`db/homecare/patient/data/${pre_data[0]._id}`, pre_data[0])
                    await api.insert(`db/homecare/form_daily_assessment/data/${_daily._id}`, _daily)

                    if (status == 'red') {
                      // TODO: check
                      await api.notify(`ผู้ป่วยสีแดง\nเนื่องจาก: SpO2 < 96% (${_form.oxygen_sat}%)\nID/HN:${user_data.cid}\nชื่อ${pre_data[0].title}${pre_data[0].firstname} ${pre_data[0]?.lastname}\nอายุ: ${pre_data[0].age} ปี\nจำนวนวันที่รักษา:1 วัน\nเบอร์โทร: ${pre_data[0].tel}\nเขต:${pre_data[0].host}`)
                    }
                    setAlert('สำเร็จ')
                    history.push('/home-patient')
                  } catch (err) {
                    console.log('err', err)
                  }

                }}
              >
                บันทึกแบบฟอร์ม
              </button>
            </div>

          </Grid>

        </div>
      </div >
    </Layout >)
}

