import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import api from '../../util/api';
import Layout from '../../components/Layout';
import md5 from 'md5';
import { Form, RadioForm, DatePicker, Space, Progress, Input, Select, Divider, Modal, Checkbox } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;
const { Search } = Input;

const { RangePicker } = DatePicker;

let MAP_STATUS = {
  'green': {
    color: '#ceffcf',
    color_b: '#82ff84',
    text: 'เขียว'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลือง'
  },
  'lightyellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองอ่อน'
  },
  'yellow': {
    color: '#ffffb1',
    color_b: '#ffea7e',
    text: 'เหลืองเข้ม'
  },
  'red': {
    color: '#F08080',
    color_b: '#F08080',
    text: 'แดง'
  },
  
  'dischart': {
    color: '#3490FF',
    color_b: '#3490FF',
    text: 'เหลืองเข้ม'
  }
}

const ReaderRow = ({ column, row, handleClick, setVisible }) => {
  const value = row[`${column.id}`];
  if (column.id === 'status') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, backgroundColor: MAP_STATUS[value].color, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {MAP_STATUS[value].text}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    console.log("value.date", value)
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {new Date(value).toLocaleString()}</div>
        }
      </TableCell>
    );
  }
  else if (column.id === 'quesue' && column.type === 'button') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Button variant="contained" style={{ margin: 8 }} onClick={() => setVisible(row)}>
          เลือกเวลานัด
          </Button>
        {/* < Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
          เลือกเวลานัด
          </Button> */}
      </TableCell>
    );
  }
  else if (column.id === 'quesue') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {new Date(Number(value.date)).toLocaleDateString()}</div>
        }
      </TableCell>
    );
  }

  else if (column.type === 'checkbox') {
    return (
      <TableCell key={column.id} align={column.align}>
        {/* indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} */}
        <Checkbox />
      </TableCell>
    );
  }





  return (
    <TableCell key={column.id} align={column.align}>
      {
        column.type === 'button' ?
          (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
            ดูข้อมูล
          </Button>) :
          value
      }
    </TableCell>
  );

}
const columns = [
  { id: 'index', label: 'ลำดับ', minWidth: 20, align: 'center', type: 'checkbox' },
  { id: 'quesue', label: 'วันที่ขอใบรับรองแพทย์', minWidth: 100 },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200, },
  { id: 'host', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'name', label: 'ดูข้อมูล', nWidth: 170, type: 'button', align: 'center' },
];

function ColumnGroupingTable({ data_mock = [], search = "", age, setVisible }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  React.useEffect(async () => {
    let _data = data_mock
      .filter(x => x.host.indexOf(search) >= 0 || x.name.indexOf(search) > 0 || search === '')
      .filter(x => x.status === age || age === 'all')
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)

  }, [data_mock, search, age]);


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {

                      return <ReaderRow column={column} row={row} handleClick={handleClick} setVisible={setVisible} />

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');


  React.useEffect(async () => {
    console.log(">> start")
    let _data = await api.query(
      'db/homecare/patient/query',
      // {
      //   "query": {
      //     "hostid": "10723"
      //   }
      // }
      {
        "aggregate": [
        ]
      }
    )
    setData(_data.filter(x => x.quesue?.active).map(x => ({ ...x, name: `${x.title}${x.firstname} ${x.lastname}` })))
  }, []);

  const [user, setUser] = useState("");
  const [age, setAge] = React.useState('all');
  const handleChange = (value) => {
    setAge(value);
  };

  let year = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  function handleClick(path) {
    history.push(path);
  }

  const [visible, setVisible] = useState(false);
  // {search}
  // let _data = data
  return (<Layout ispadding>

    <Modal
      title="นัดหมายคนไข้"
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={800}
    >
      <p class="text-xl">ชื่อนามสกุล: นายชัยวัฒน์ อินทร์ประสิทธิ์ </p>
      <p class="text-xl">ระดับ: สีเขียว</p>
      <p class="text-xl">เวลาที่ขอนัด: 4/5/2022</p>
      <Divider />
      <p class="text-xl mt-6 mb-/">เลือกวันเวลาและสถานที่</p>
      <div class="flex-col flex">
        <div class="w-285px">
          <DatePicker
            style={{ width: '285px', height: 50, margin: 6 }}
            size={"large"}
            format={'DD/MM/YYYY'}
            locale={locale}
            onChange={(date, dateString) => {
            }}
          />
        </div>
        <FormControl sx={{ m: 1, width: 285 }}>
          <TextField
            pattern="[0-9]*"
            inputProps={{
              maxLength: 100,
            }}
            id="user"
            name="user"
            label="สถานที่"
            variant="outlined"
          />
        </ FormControl>
      </div>
    </Modal>

    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >


      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          "boxShadow": "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div style={{ flexDirection: 'row' }}>
          <FormControl sx={{ m: 1, minWidth: 300, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหาชื่อนามสกุล และ สถานพยาบาล"
              style={{ width: 400, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>
        </div>
        <ColumnGroupingTable data_mock={data} search={search} age={age} setVisible={() => {
          setVisible(true)
        }} />
      </div>
    </div>
  </Layout >)
}
