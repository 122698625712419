import axios from 'axios';
import qs from 'qs';

const TIMEOUT = 180000;
const is_dev = true
const URL_ENDPOINT = 'https://screening.wecaremaesot.org'
// redirect_uri: 'http://localhost:3000/register-line',
// 'http://localhost:3000'
// 'https://wecaremaesot.org'

const LINE_CLI_ID = '1656988735'
const LINE_CLI_SECRET = '196c23f1bf807609cf04fa3f95d84915'
// const LINE_REDIRECT = is_dev ? 'http://localhost:3000/register-line' : 'https://wecaremaesot.org/register-line'
// const LINE_REDIRECT = is_dev ? 'http://localhost:3000/register-line' : 'https://wecaremaesot.org/register-line'
const LINE_REDIRECT = 'https://hbtest-2271e.web.app/register-line'

const API = async ({
  method = 'post',
  url = null,
  data = null,
  headers = null,
  timeout = TIMEOUT,
}) => {
  try {
    let axiosCall = axios.CancelToken.source();
    setTimeout(() => {
      axiosCall.cancel(`ไม่สามารถเชื่อมต่อเครือข่ายได้ในขณะนี้(TIME OUT)`)
    }, timeout);
    let res = await axios({ method, url: url, data, headers, cancelToken: axiosCall.token });
    // if (!res.data.ok) return new Promise.reject(new Error(res.data.message));
    // else
    return res
  } catch (err) {
    console.log(">>", err)
    if (err.message === "Network Error") {
      return Promise.reject(new Error('ไม่สามารถเชื่อมต่ออินเตอร์เน็ต'));
    } else {
      return Promise.reject(err);
    }
  }
};

const query = async (url, body, _isRetry = false) => {
  try {
    // if (_isRetry) await reLogin() 
    let token = localStorage.getItem('key')
    let headers = {
      Authorization: "JWT " + token,
      "Content-Type": 'application/json',
    }
    let res = await API({ method: 'post', url: `${URL_ENDPOINT}/${url}`, data: body, headers })
    if (res.data.message) return new Promise.reject(new Error(res.data.message));
    return (res.data)
  } catch (err) {
    console.log("ss", err)
    if (err.message === 'Request failed with status code 401') {
      let token = localStorage.getItem('key')
      return await query(url, body, token, true)
    } else {
      return Promise.reject(err)
    }
  }
}

const insert = async (url, body, _isRetry = false) => {
  try {
    console.log("url, body, _isRetry = false", url, body, _isRetry = false)
    // if (_isRetry) await reLogin() 
    let token = localStorage.getItem('key')
    let headers = {
      Authorization: "JWT " + token,
      "Content-Type": 'application/json',
    }
    let res = await API({ method: 'post', url: `${URL_ENDPOINT}/${url}`, data: body, headers })
    if (res.data.message) return new Promise.reject(new Error(res.data.message));
    return (res.data)
  } catch (err) {
    if (err.message === 'Request failed with status code 401') {
      let token = localStorage.getItem('key')
      return await query(url, body, token, true)
    } else {
      return new Promise.reject(err)
    }
  }
}

const auth = async (credential, remember) => {
  try {
    let response = await API({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      },
      url: `https://screening.wecaremaesot.org/auth/login`,
      data: credential
    })

    if (response.data.user.disable) {
      return Promise.reject(new Error('บัญชีของคุณถูกระงับการใช้'));
    }
    localStorage.setItem('user', response.data.user.user)
    localStorage.setItem('user_wecaremaesot', JSON.stringify(response.data.user.profile))
    localStorage.setItem('user_data', JSON.stringify(response.data.user.profile))
    localStorage.setItem('key', response.data?.token)
    localStorage.setItem('role', JSON.stringify(response.data.user.roles))

    return response.data
  } catch (err) {
    if (err.response) {
      return Promise.reject(new Error(JSON.stringify(err.response.data.message)))
    } else {
      return Promise.reject(new Error(err.message))
    }
  }
}

const redirectLineLogin = () => {
  let client_id = LINE_CLI_ID
  let redirect_uri = LINE_REDIRECT
  let state = 'iseelogin'
  // navigate(`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=profile%20openid%20email`)
}

const authLineGetProfile = async (code, state) => {

  const data_token = qs.stringify({
    grant_type: 'authorization_code',
    code: code,
    redirect_uri: LINE_REDIRECT,
    client_id: LINE_CLI_ID,
    client_secret: LINE_CLI_SECRET,
  });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  try {
    let res_token = await axios.post('https://api.line.me/oauth2/v2.1/token', data_token, headers)
    let id_token = res_token.data.id_token
    const data_verify = qs.stringify({
      id_token: id_token,
      client_id: LINE_CLI_ID,
    });
    let res_id = await axios.post('https://api.line.me/oauth2/v2.1/verify', data_verify, headers)
    console.log("res_id", res_id)

    let _data = await query(
      'db/cores/role/query', {
      "query": {
        "line.sub": res_id.data.sub,
      }
    })
    if (_data.length > 0) {
      // application: "homecare"
      // line: { iss: 'https://access.line.me', sub: 'Uf297d9969ef41d495d49e1a7b5b001c7', aud: '1656988735', exp: 1650012566, iat: 1650008966, … }
      // profile: { cid: '2788956083801', title: 'นางสาว', firstname: 'ชัยวัฒน์', lastname: 'ชัยวัฒน์', email: 'i.brite1211@gmail.com', … }
      // roles: ['patient']
      // timestamp: 1650009055315
      // updatetime: 1650009055315
      // user: "2788956083801"
      // _id: "c7afea30bc9011ecaa6197a0969904e9"
      // console.log("_data", _data)
      localStorage.setItem('img_profile', res_id.data.picture)
      localStorage.setItem('user', _data[0].user)
      localStorage.setItem('user_wecaremaesot', JSON.stringify(_data[0].profile))
      localStorage.setItem('user_data', JSON.stringify(_data[0].profile))
      // localStorage.setItem('key', _data[0].data.token)
      localStorage.setItem('role', JSON.stringify(_data[0].roles))
    }
    return ({
      statu: _data.length > 0 ? 'ok' : 'register',
      data: res_id
    })

    // res_id
    // return ()
    // console.log("_data", _data)
    // console.log("res_id",res_id)
    // U1234567890abcdef1234567890abcdef
    // let response = await API({
    //   method: 'post',
    //   headers: { 'Content-Type': 'application/json' },
    //   url: `${URL_ENDPOINT}/api/linelogin`,
    //   data: { user_id: res_id.data.sub }
    // })
    // if (response.data.message === "Login Success") {
    //   localStorage.setItem('img_profile', res_id.data.picture)
    //   localStorage.setItem('user', response.data.role_db.user)
    //   localStorage.setItem('user_data', JSON.stringify(response.data.role_db.profile))
    //   localStorage.setItem('key', response.data.token)
    //   localStorage.setItem('role', JSON.stringify(response.data.role_db.roles))
    // }
    // response.data.line = {}
    // response.data.line.name = res_id.data.name
    // response.data.line.email = res_id.data.email
    // response.data.line.img = res_id.data.picture
    // return response.data
  } catch (err) {
    if (err.response) {
      return Promise.reject(JSON.stringify(err.response.data.message))
    } else {
      return Promise.reject(JSON.stringify(err.message))
    }
  }

}

const register = async (data, patient) => {
  try {

    let headers = {
      "Content-Type": 'application/json',
    }
    let res = await API({ method: 'post', url: `${URL_ENDPOINT}/register`, data: data, headers })
    if (!res.ok && res.msg === '"Username Already Existing"') {
      alert('ผู้ใช้งานนี้มีในระบบแล้ว')
      return ({ ok: false })
    }

    // if (res.data.message) return new Promise.reject(new Error(res.data.message));
    await insert(`db/homecare/patient/data/${patient._id}`, patient)
    return ({ ok: true })
  } catch (err) {
    console.log(" err >>", err)
    if (err.message === 'Request failed with status code 400') {
      return Promise.reject("ผู้ใช้งานนี้มีในระบบแล้ว")
    } else {
      return Promise.reject(JSON.stringify(err.message))
    }
  }
}

const resetpass = async (data) => {
  try {
    let response = await API({
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      url: `${URL_ENDPOINT}/api/resetpass`,
      data: JSON.stringify(data)
    })
    return response.data
  } catch (err) {
    if (err.response) {
      if (err.response.data.message === 'Email require 400 Bad request') {
        return Promise.reject('รีเซ็ตรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้งภายหลัง')
      } else if (err.response.data.message === 'Email not found') {
        return Promise.reject('ไม่พบ Email นี้ในระบบ')
      } else if (err.response.data.message === 'Email more than 1') {
        return Promise.reject('มี Email ซ้ำกันในระบบ กรุณาลองใหม่อีกครั้งภายหลัง')
      } else {
        return Promise.reject(JSON.stringify(err.message))
      }
    } else {
      return Promise.reject(JSON.stringify(err.message))
    }
  }
}

const editprofile = async (data) => {
  try {
    let token = localStorage.getItem('key')
    let response = await API({
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      url: `${URL_ENDPOINT}/api/editprofile`,
      data: JSON.stringify(data)
    })
    if (data.pass) {
      let response_changepass = await API({
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        url: `${URL_ENDPOINT}/api/changepass`,
        data: JSON.stringify({ user: data.user, pass: data.pass })
      })
    }
    return response.data
  } catch (err) {
    if (err.response) {
      if (err.response.data.message === 'Edit Profile Role 400 Bad request') {
        return Promise.reject('Edit Profile Role Error')
      } else if (err.response.data.message === 'User Not Found') {
        onLogout()
      } else if (
        err.response.data.message === 'User Not Match' || err.response.data.message === 'jwt must be provided' ||
        err.response.data.message === 'invalid token' || err.response.data.message === 'jwt expired' || err.response.data.message === 'jwt malformed'
      ) {
        onLogout()
      } else if (err.response.data.message === 'Change Password 400 Bad request') {
        return Promise.reject('')
      } else {
        return Promise.reject(JSON.stringify(err.message))
      }
    } else {
      return Promise.reject(JSON.stringify(err.message))
    }
  }
}

const checkPatient = async (cid, dob) => {
  try {
    let token = localStorage.getItem('key')
    let response = await API({
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      url: `${URL_ENDPOINT}/patient`,
      data: JSON.stringify({ "cid": cid })
    })

    if (response.data.user?.data?.result?.patient?.birth_date === dob) {
      return ({
        case: 'have_dob',
        ...response.data
      })
    }

    if (!response.data.user?.data?.result?.patient && response.data.user?.data?.Message === "OK") {
      return ({
        case: 'not_have_dob',
        ...response.data
      })
    }

    if (response.data.user?.data?.result?.patient?.birth_date !== dob) {
      return Promise.reject('fail birth date ')
    }

  } catch (err) {
    console.log(">>", err)
    if (err.response) {
      if (err.response.data.message === 'Edit Profile Role 400 Bad request') {
        return Promise.reject('Edit Profile Role Error')
      } else if (err.response.data.message === 'User Not Found') {
        onLogout()
      } else if (
        err.response.data.message === 'User Not Match' || err.response.data.message === 'jwt must be provided' ||
        err.response.data.message === 'invalid token' || err.response.data.message === 'jwt expired' || err.response.data.message === 'jwt malformed'
      ) {
        onLogout()
      } else if (err.response.data.message === 'Change Password 400 Bad request') {
        return Promise.reject('')
      } else {
        return Promise.reject(JSON.stringify(err.message))
      }
    } else {
      return Promise.reject(JSON.stringify(err.message))
    }
  }
}


const checkPatientDoctor = async (cid) => {
  try {
    let token = localStorage.getItem('key')
    let response = await API({
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      url: `${URL_ENDPOINT}/patient`,
      data: JSON.stringify({ "cid": cid })
    })
    return ({
      case: 'have_dob',
      ...response.data
    })
  } catch (err) {
    console.log(">>", err)
    if (err.response) {
      if (err.response.data.message === 'Edit Profile Role 400 Bad request') {
        return Promise.reject('Edit Profile Role Error')
      } else if (err.response.data.message === 'User Not Found') {
        onLogout()
      } else if (
        err.response.data.message === 'User Not Match' || err.response.data.message === 'jwt must be provided' ||
        err.response.data.message === 'invalid token' || err.response.data.message === 'jwt expired' || err.response.data.message === 'jwt malformed'
      ) {
        onLogout()
      } else if (err.response.data.message === 'Change Password 400 Bad request') {
        return Promise.reject('')
      } else {
        return Promise.reject(JSON.stringify(err.message))
      }
    } else {
      return Promise.reject(JSON.stringify(err.message))
    }
  }
}


const onLogout = () => {
  localStorage.removeItem('img_profile')
  localStorage.removeItem('user')
  localStorage.removeItem('userdata')
  localStorage.removeItem('key')
  localStorage.removeItem('role')
  // navigate('/login')
}



const notify = async (message) => {
  try {
    let token = localStorage.getItem('key')
    // console.log("ok notify")
    let response = await API({
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': token },
      // url: `https://wecaremaesot.org/notify`,
      // url: `http://134.209.106.173:3010/notify`,
      url: `https://screening.wecaremaesot.org/notifydemo`,
      data: JSON.stringify({ "message": message })
    })
    return ('ok')
  } catch (err) {
    if (err.response) {
      return Promise.reject(JSON.stringify(err.response.data.message))
    } else {
      return Promise.reject(JSON.stringify(err.message))
    }
  }

}


const encryption = async (pass) => {
  try {
    let headers = {
      // Authorization: "JWT " + token,
      "Content-Type": 'application/json',
    }
    let res = await API({ method: 'post', url: `https://screening.wecaremaesot.org/encryption-b`, data: { pass }, headers })
    return (res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export default {
  query,
  auth,
  insert,
  redirectLineLogin,
  authLineGetProfile,
  register,
  editprofile,
  resetpass,
  onLogout,
  checkPatient,
  checkPatientDoctor,
  LINE_REDIRECT,
  notify,
  encryption
}
