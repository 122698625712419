import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import AddressData from "../../util/AddressData";
import encryption from '../../util/encryption';
// const encryption = require('./encryption')
// https://wecaremaesot.org/encryption-b
import api from '../../util/api';
import Layout from '../../components/LayoutPC';
import md5 from 'md5';
import { Switch, Form, RadioForm, DatePicker, Space, Progress, Input, Select, Divider, Modal } from 'antd';
// import { AudioOutlined } from '@ant-design/icons';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import moment from 'moment'
import SweetAlert from 'sweetalert2-react';

import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const USER = {
  "user": "",
  "pass": "",
  "title": "",
  "firstname": "",
  "lastname": "",
  "certid": null,
  "hospital": "",
  "hospitalid": "",
  "role": "",
  "disable": false,
}

const raderRole = (data) => {
  let _role = '-'
  if (Array.isArray(data?.roles) && data?.roles.length > 0) {
    if (data?.roles[0] === 'doctor') {
      _role = 'หมอ'
    } else if (data?.roles[0] === 'SHPH') {
      _role = 'SHPH'
    } else if (data?.roles[0] === 'patient') {
      _role = 'ผู้ป่วย'
    } else if (data?.roles[0] === 'admin') {
      _role = 'admin'
    }

    return _role
  }
}

const ReaderRow = ({ column, row, handleClick }) => {
  const value = row[column.id];

  if (column.id === 'repass') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick('reset')}>
          รีเซ็ตพาสเวิร์ด
        </Button>
      </TableCell>
    );
  } else if (column.id === 'edit') {
    return (
      <TableCell key={column.id} align={column.align}>
        < Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick('edit')}>
          แก้ข้อมูล
        </Button>
      </TableCell>
    );
  } else if (column.id === 'updatetime') {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          <div style={{ padding: 8, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> {moment(value, 'x').format('L')}</div>
        }
      </TableCell>
    );
  } else if (column.id === 'disable') {
    if (row.disable) {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#eee', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> disable </div>
          }
        </TableCell>
      );
    } else {
      return (
        <TableCell key={column.id} align={column.align}>
          {
            <div style={{ padding: 8, backgroundColor: '#CEFFCF', width: 100, borderRadius: 24, justifyContent: 'center', display: 'flex' }}> active</div>
          }
        </TableCell>
      );
    }
  } else {
    return (
      <TableCell key={column.id} align={column.align}>
        {
          column.type === 'button' ?
            (< Button variant="contained" style={{ margin: 8 }} onClick={() => handleClick(`patient/${md5(row.cid)}`)}>
              ดูข้อมูล
            </Button>) :
            value
        }
      </TableCell>
    );
  }
}
const columns = [
  // { id: 'index', label: 'ลำดับ', minWidth: 20 },
  // { id: 'updatetime', label: 'วันที่อัพเดทข้อมูล', minWidth: 90, align: 'center' },
  { id: 'user', label: 'username', minWidth: 200 },
  { id: 'name', label: 'ชื่อ-นามสกุล', minWidth: 200 },
  // { id: 'role', label: 'บทบาทหน้าที่', minWidth: 170 },
  { id: 'hospital', label: 'สถานพยาบาล', minWidth: 170 },
  { id: 'disable', label: 'สถานะ', minWidth: 170, },
  { id: 'edit', label: 'แก้ข้อมูล', minWidth: 170, align: 'center' },
  { id: 'repass', label: 'รีเซ็ตพาสเวิร์ด', minWidth: 170, align: 'center' },
  // { id: 'status', label: 'ระดับผู้ป่วย', minWidth: 100, alignSelf: 'center' },
  // { id: 'discharge', label: 'สถานะผู้ป่วย', minWidth: 100, alignSelf: 'center' },
  // { id: 'name', label: 'ดูข้อมูล', nWidth: 170, type: 'button', align: 'center' },
];

function ColumnGroupingTable({ data_mock = [], search = "", age, updateChild }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  function handleClick(path) {
    history.push(path);
  }

  React.useEffect(async () => {
    console.log("update")
    let _data = data_mock
      .filter(x => x.user.indexOf(search) >= 0 || x.name.indexOf(search) >= 0 || search === '')
      .filter(x => x.status === age || age === 'all')
      .map((x, i) => ({ ...x, ...{ index: i + 1 } }))
    setData(_data)
    setPage(0)

  }, [data_mock, search, age]);
  const [addUser, setAddUser] = useState({ ...USER });
  const OBJhospital = {}
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [dataShow, setDataShow] = useState({
    user: ''
  });

  const [dataEdit, setDataEdit] = useState({
    user: ''
  });

  const [pass, setPass] = useState({
    pass: '',
    confirmPass: ''
  });

  AddressData
    .hospitals
    .forEach(x => {
      OBJhospital[x.hospital_name] = x
    })

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }


  return (
    <Paper sx={{ width: '100%', minHeight: '600px', margin: 1 }}>
      <SweetAlert
        show={show.show}
        title={show.title}
        text={show.message}
        onConfirm={() => {
          setShow({ ...show, ...{ show: false } })
        }}
      />
      <Modal
        title="รีเซ็ตพาสเวิร์ด"
        centered
        visible={visible}
        onOk={async () => {
          if (pass.pass.length < 6) return setAlert('โปรดใส่พาสเวิร์ดอย่างน้อย 6 ตัว')
          if (pass.pass !== pass.confirmPass) return setAlert('รหัสผ่านใหม่ไม่ตรงกัน')

          let _pass = await api.encryption(pass.pass)
          let pre_data = await api.query(`db/cores/user/query`, {
            query: { User: dataShow.user }
          })
          if (pre_data.length > 0) {
            pre_data[0].Pass_Salt = _pass.pass_salt
            pre_data[0].Pass_Hash = _pass.pass_hash
            console.log("pre_data", pre_data)
            await api.insert(`db/cores/user/data/${pre_data[0]._id}`, pre_data[0])
          }
          setVisible(false)
          setPass({
            pass: '',
            confirmPass: ''
          })
          setAlert('รีเซ็ตพาสเวิร์ดสำเร็จ')
        }}
        onCancel={() => {
          setVisible(false)
          setPass({
            pass: '',
            confirmPass: ''
          })
        }}
        width={800}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
      >
        <p>Username: {dataShow.user}</p>
        <p>Password: </p>
        <Input
          name="pass"
          type="password"
          placeholder="Password" style={{ width: 200, marginBottom: 12 }}
          value={pass.pass}
          onChange={(event) => {
            setPass({ ...pass, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
          }}
        />

        <p>Confirm Password: </p>
        <Input
          name="pass"
          type="password"
          placeholder="Password" style={{ width: 200, marginBottom: 12 }}
          value={pass.confirmPass}
          onChange={(event) => {
            setPass({ ...pass, ...{ confirmPass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
          }}
        />
        <p></p>
      </Modal>

      <Modal
        title="แก้ไขผู้ใช้งาน"
        centered
        visible={visibleEdit}
        onOk={async () => {
          if (!dataEdit.title) return setAlert('โปรดเลือกคำนำหน้า')
          if (!dataEdit.firstname) return setAlert('โปรดใส่ชื่อ')
          if (!dataEdit.lastname) return setAlert('โปรดใส่นามสกุล')
          if (!dataEdit.hospital) return setAlert('โปรดเลือกโรงพยาบาล/รพ.สต')
          let _data = await api.query('db/cores/role/query', {
            "aggregate": [
              {
                $match: {
                  user: dataEdit.user,
                }
              }
            ]
          })
          let copy = JSON.parse(JSON.stringify(_data[0]))
          _data[0].profile.title = dataEdit.title
          _data[0].profile.firstname = dataEdit.firstname
          _data[0].profile.lastname = dataEdit.lastname
          _data[0].profile.certid = dataEdit.certid
          _data[0].profile.hospital = dataEdit.hospital
          _data[0].profile.hospital_code = dataEdit.hospital_code
          _data[0].disable = dataEdit.disable
          copy['_id'] = md5(`${new Date().getTime()}`)
          copy['time'] = new Date().toLocaleDateString()
          console.log("copy", copy)
          await api.insert(`db/cores/rolePrechange/data/${copy._id}`, copy)
          await api.insert(`db/cores/role/data/${_data[0]._id}`, _data[0])
          updateChild(_data)
          setVisibleEdit(false)
          setDataEdit({ ...USER })
        }}

        onCancel={() => {
          setDataEdit({ ...USER })
          setVisibleEdit(false)
        }}
        width={800}
        okText={'บันทึก'}
        okButtonProps={{ style: { background: 'green' } }}
        cancelText={'ยกเลิก'}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <p>username: {dataEdit.user} </p>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <p> คำนำหน้า: *</p>
            <Select

              placeholder="เลือกคำนำหน้า"
              style={{ width: 120 }}
              onChange={(e) => {
                setDataEdit({ ...dataEdit, ...{ title: e } })
              }}
              value={dataEdit?.title}
            >
              <Option value="นายแพทย์">นายแพทย์</Option>
              <Option value="แพทย์หญิง">แพทย์หญิง</Option>
              <Option value="นาย">นาย</Option>
              <Option value="นาง">นาง</Option>
              <Option value="นางสาว">นางสาว</Option>
            </Select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <p> ชื่อ: </p>
            <Input
              placeholder="ชื่อ" style={{ width: 200, marginBottom: 12 }}
              value={dataEdit?.firstname}
              onChange={(event) => {
                setDataEdit({ ...dataEdit, ...{ firstname: `${event.target.value}`.replace(/\s/g, '') } })
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <p> นามสกุล: *</p>
            <Input
              placeholder="นามสกุล"
              style={{ width: 200, marginBottom: 12 }}
              value={dataEdit?.lastname}
              onChange={(event) => {
                setDataEdit({ ...dataEdit, ...{ lastname: `${event.target.value}`.replace(/\s/g, '') } })
              }}
            />
          </div>
        </div>


        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <p> โรงพยาบาล/รพ.สต.: *</p>
            <Select
              placeholder="โปรดเลือกโรงพยาบาล/รพสต."
              style={{ width: 300 }}
              onChange={(e) => {
                setDataEdit({ ...dataEdit, ...{ hospital: e } })
              }}
              value={dataEdit?.hospital}
            >
              {/* <Option value="โรงพยาบาลแม่สอด">โรงพยาบาลแม่สอด</Option> */}
              {
                Object.keys(OBJhospital)
                  .map(_x => (
                    <Option value={_x}>{_x}</Option>
                  ))
              }
            </Select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <p> เลขที่ใบอนุญาต: </p>
            <Input
              value={dataEdit?.certid}
              placeholder="ระบุเลขที่ใบอนุญาต" style={{ width: 200, marginBottom: 12 }}
              onChange={(event) => {
                setDataEdit({ ...dataEdit, ...{ certid: event.target.value } })
              }}
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'row', margin: 4 }}>
            <p className="mr-4"> Disable User:</p>
            <div>
              <Switch
                style={{ backgroundColor: dataEdit?.disable ? 'red' : '#888' }}
                onChange={(value) => {
                  setDataEdit({ ...dataEdit, ...{ disable: value } })
                }}
                checked={dataEdit?.disable}
              />
            </div>
          </div>
        </div>


      </Modal>

      <TableContainer sx={{ maxHeight: 1040 }}>
        <Table >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {
                      return (
                        <ReaderRow
                          column={column}
                          row={row}
                          handleClick={async (type) => {
                            if (type === 'reset') {
                              setDataShow({
                                user: row.user
                              })
                              setVisible(true)

                            } else if (type === 'edit') {
                              let _data = await api.query('db/cores/role/query', {
                                "aggregate": [
                                  {
                                    $match: {
                                      user: row.user,
                                    }
                                  }
                                ]
                              })
                              setDataEdit({
                                "user": _data[0].user,
                                "title": _data[0].profile.title,
                                "firstname": _data[0].profile.firstname,
                                "lastname": _data[0].profile.lastname,
                                "certid": _data[0].profile.certid,
                                "hospital": _data[0].profile.hospital,
                                "hospital_code": _data[0].profile.hospital_code,
                                "disable": _data[0].disable,
                              })
                              setVisibleEdit(true)
                            }
                          }}
                        />
                      )
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper >
  );
}

export default function Home(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const OBJhospital = {}
  const [addUser, setAddUser] = useState({ ...USER });

  React.useEffect(async () => {
    let role = await localStorage.getItem('role')
    role = JSON.parse(role)
    let aggregate = []
    // let _data = await api.query('db/cores/role/query', { query: { _id: { $ne: 'adminmaesot' } } })
    let _data = await api.query('db/cores/role/query', {
      "aggregate": [
        {
          $match: {
            roles: { $in: ['doctor', 'SHPH'] },
          }
        }
      ]
    })
    setData(_data.map(x => ({
      ...x,
      name: `${x?.profile?.title}${x?.profile?.firstname} ${x?.profile?.lastname}`,
      hospital: `${x?.profile?.hospital}`,
      role: raderRole(x)
    })))
    // roles

  }, []);
  const [user, setUser] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [age, setAge] = React.useState('all');
  const handleChange = (value) => {
    setAge(value);
  };

  let year = []
  let nowDate = 2565
  for (let i = 0; i < 100; i++) {
    year.push(nowDate - i)
  }

  function handleClick(path) {
    history.push(path);
  }

  AddressData
    .hospitals
    .forEach(x => {
      OBJhospital[x.hospital_name] = x
    })


  const [show, setShow] = useState({
    show: false,
    title: '',
    message: '',
  });

  function setAlert(msg) {
    return setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: msg } })
  }


  return (<Layout ispadding>
    <SweetAlert
      show={show.show}
      title={show.title}
      text={show.message}
      onConfirm={() => {
        setShow({ ...show, ...{ show: false } })
      }}
    />
    <Modal
      title="เพิ่มผู้ใช้งาน"
      centered
      visible={visible}
      onOk={async () => {
        try {
          if (addUser.user.length < 6) return setAlert('โปรดใส่ username อย่างน้อย 6 ตัว')
          if (addUser.pass.length < 6) return setAlert('โปรดใส่ password อย่างน้อย 6 ตัว')
          if (!addUser.title) return setAlert('โปรดเลือกคำนำหน้า')
          if (!addUser.firstname) return setAlert('โปรดใส่ชื่อ')
          if (!addUser.lastname) return setAlert('โปรดใส่นามสกุล')
          if (!addUser.hospital) return setAlert('โปรดเลือกโรงพยาบาล/รพ.สต')

          let _hospital = OBJhospital[addUser.hospital]
          await api.register(
            {
              "user": addUser.user,
              "pass": addUser.pass,
              "application": "homecare",
              "profile": {
                "title": addUser.title,
                "firstname": addUser.firstname,
                "lastname": addUser.lastname,
                "certid": addUser.certid,
                "hospital": addUser.hospital,
                "hospital_code": _hospital.hospital_code,
                "disable": addUser.disable,
              },
              "roles": ["doctor"]
            },
            { ...addUser, "hospital_code": _hospital.hospital_code, }
          )
          let _dataq = await api.query('db/cores/role/query', {
            "aggregate": [
              {
                $match: {
                  user: addUser.user,
                }
              }
            ]
          })

          _dataq = _dataq.map(x => ({
            ...x,
            name: `${x?.profile?.title}${x?.profile?.firstname} ${x?.profile?.lastname}`,
            hospital: `${x?.profile?.hospital}`,
            role: raderRole(x)

          }))

          setData([
            ..._dataq,
            ...data
          ])
          setAddUser({ ...USER })
          setVisible(false)
          setAlert('เพิ่มผู้ใช้งานสำเร็จ')
        } catch (err) {
          if (err.message) {
            setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: err.message } })
          } else {
            setShow({ ...show, ...{ show: true, title: 'แจ้งเตือน', message: err } })
          }
        }


      }}
      onCancel={() => {
        setAddUser({ ...USER })
        setVisible(false)
      }}
      width={800}
      okText={'บันทึก'}
      okButtonProps={{ style: { background: 'green' } }}
      cancelText={'ยกเลิก'}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p>username: * </p>
          <Input
            name="username"
            placeholder="username" style={{ width: 200, marginBottom: 12 }}
            value={addUser.user}
            onChange={(event) => {
              setAddUser({ ...addUser, ...{ user: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p>password: * </p>
          <Input
            name="password"
            placeholder="password" style={{ width: 200, marginBottom: 12 }}
            value={addUser.pass}
            onChange={(event) => {
              setAddUser({ ...addUser, ...{ pass: `${event.target.value}`.replace(/[^A-Za-z0-9!@#*_+-]/g, '') } })
            }}
          />
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> คำนำหน้า: *</p>
          <Select

            placeholder="เลือกคำนำหน้า"
            style={{ width: 120 }}
            onChange={(e) => {
              setAddUser({ ...addUser, ...{ title: e } })
            }}
            value={addUser.title}
          >
            <Option value="นายแพทย์">นายแพทย์</Option>
            <Option value="แพทย์หญิง">แพทย์หญิง</Option>
            <Option value="นาย">นาย</Option>
            <Option value="นาง">นาง</Option>
            <Option value="นางสาว">นางสาว</Option>
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> ชื่อ: </p>
          <Input
            placeholder="ชื่อ" style={{ width: 200, marginBottom: 12 }}
            value={addUser.firstname}
            onChange={(event) => {
              setAddUser({ ...addUser, ...{ firstname: `${event.target.value}`.replace(/\s/g, '') } })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> นามสกุล: *</p>
          <Input
            placeholder="นามสกุล"
            style={{ width: 200, marginBottom: 12 }}
            value={addUser.lastname}
            onChange={(event) => {
              setAddUser({ ...addUser, ...{ lastname: `${event.target.value}`.replace(/\s/g, '') } })
            }}
          />
        </div>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> โรงพยาบาล/รพ.สต.: *</p>
          <Select
            placeholder="โปรดเลือกโรงพยาบาล/รพสต."
            style={{ width: 300 }}
            onChange={(e) => {
              setAddUser({ ...addUser, ...{ hospital: e } })
            }}
            value={addUser.hospital}
          >
            {/* <Option value="โรงพยาบาลแม่สอด">โรงพยาบาลแม่สอด</Option> */}
            {
              Object.keys(OBJhospital)
                .map(_x => (
                  <Option value={_x}>{_x}</Option>
                ))
            }
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
          <p> เลขที่ใบอนุญาต: </p>
          <Input
            placeholder="ระบุเลขที่ใบอนุญาต" style={{ width: 200, marginBottom: 12 }}
            onChange={(event) => {
              setAddUser({ ...addUser, ...{ certid: event.target.value } })
            }}
          />
        </div>
      </div>


    </Modal>


    <div
      style={{
        borderRadius: 12,
        padding: "18px",
        display: "flex",
        width: "1200px",
        height: "100%",
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <p className="text-3xl mb-4">รายชื่อบุคลากร</p>


      <div
        style={{
          borderRadius: 12,
          padding: 24,
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          "boxShadow": "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>

          <FormControl sx={{ m: 1, minWidth: 300, height: 54 }}>
            <div>ค้นหา</div>
            <Search
              value={search}
              placeholder="ค้นหา username"
              style={{ width: 400, height: 54 }}
              size={"large"}
              onChange={({ target: { value } }) => {
                setSearch(value)
              }} />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120, height: 54 }}>
            < Button variant="contained"
              style={{ margin: 8 }}
              onClick={() => {
                setVisible(true)
              }}>
              + เพิ่มผู้ให้งาน
            </Button>
          </FormControl>
        </div>
        <ColumnGroupingTable data_mock={data} search={search} age={age} updateChild={(_datas) => {

          _datas = _datas.map(x => ({
            ...x,
            name: `${x?.profile?.title}${x?.profile?.firstname} ${x?.profile?.lastname}`,
            hospital: `${x?.profile?.hospital}`,
            role: raderRole(x)
          }))
          let index = data.findIndex(x => x._id === _datas[0]._id)
          if (index) {
            data[index] = _datas[0]
            setData([...data])
          }
          setAlert('แก้ไขผู้ใช้งานสำเร็จ')
        }} />
      </div>
    </div>
  </Layout >)
}
